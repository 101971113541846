import Api from "./Api";
import axios from "axios";

export default {
  getDevicesLogs(payload) {
    return Api({ daemonRequest: true }).get(`/kiosks/requests/entity/${payload.companyId}/${payload.user_id}/daemon`);
  },
  getAppsVersions() {
    return Api({ daemonRequest: true }).get(`/kiosks/app-versions/daemon`);
  },
  addDevice(payload) {
    return Api().post(`/devices`, payload);
  },
  editDevice(payload) {
    return Api().put(`/device/${payload.company_id}/${payload.id}`, payload);
  },
  getAllDevices(companyId) {
    return Api().get(`/devices/${companyId}`);
  },
  openDoor(payload) {
    return Api({ daemonRequest: true }).post(`/kiosks/open-door`, payload);
  },
  restartDevice(payload) {
    return Api({ daemonRequest: true }).post(`/kiosks/push-restart`, payload);
  },
  massRestartDevice(payload) {
    return Api({ daemonRequest: true }).post(`/kiosks/mass-push-restart`, payload);
  },
  installApp(payload) {
    return Api({ daemonRequest: true }).post(`/kiosks/update-app`, payload);
  },
  massInstallApp(payload) {
    return Api({ daemonRequest: true }).post(`/kiosks/mass-install-app`, payload);
  },
  uninstallApp(payload) {
    return Api({ daemonRequest: true }).post(`/kiosks/uninstall-app/daemon`, payload);
  },
  restartApp(payload) {
    return Api({ daemonRequest: true }).post(`/kiosks/restart-app`, payload);
  },
  clearAppData(payload) {
    return Api({ daemonRequest: true }).post(`/kiosks/clear-app-data`, payload);
  },
  clearAppCache(payload) {
    return Api({ daemonRequest: true }).post(`/kiosks/clear-app-cache`, payload);
  },
  forceStopApp(payload) {
    return Api({ daemonRequest: true }).post(`/kiosks/force-stop-app`, payload);
  },
  launchApp(payload) {
    return Api({ daemonRequest: true }).post(`/kiosks/launch-app`, payload);
  },
  deleteRequestJob(payload) {
    return Api({ daemonRequest: true }).post(`/kiosks/delete-request`, payload);
  },
  refreshFaceLibrary(payload) {
    return Api({ daemonRequest: true }).post(`/kiosks/refresh-face-library`, payload);
  },
  getDeviceInfo(payload) {
    return Api({ daemonRequest: true }).post(`/kiosks/get-info/${payload.macAddress}/daemon`, payload);
  },
  getScreenshot(payload) {
    return Api({ daemonRequest: true }).post(`/kiosks/remote-show-screen`, payload);
  },
  getMassScreenshot(payload) {
    return Api({ daemonRequest: true }).post(`/kiosks/mass-screenshot`, payload);
  },
  moveDevice(payload) {
    return Api().post(`/device/${payload.id}`, payload);
  },
  massMoveDevices(payload) {
    return Api().post(`/move-devices`, payload);
  },
  deregisterDevice(payload) {
    return Api().delete(`/device/${payload.companyId}/${payload.id}`);
  },
  massDeregisterDevices(payload) {
    return Api().post(`/deregister-devices/${payload.companyId}`, payload.body);
  },
  updateDeviceSettings(payload) {
    return Api().put(`/device_settings/${payload.companyId}/device/${payload.id}`, payload.settings);
  },
  applyConfig(payload) {
    return Api({ daemonRequest: true }).post(`/kiosks/apply-config`, payload);
  },
  massApplyConfigs(payload) {
    return Api({ daemonRequest: true }).post(`/kiosks/mass-apply-config`, payload);
  },
  getInstalledApps(mac_address) {
    return Api({ daemonRequest: true }).get(`/kiosks/get-apps/${mac_address}/daemon`);
  },
  getInstalledAppsGrid(payload) {
    return Api({ daemonRequest: true }).post(`/kiosks/get-apps/${payload.macAddress}/daemon`, payload);
  },
  getDeviceLogs(payload) {
    return Api({ daemonRequest: true }).get(`/kiosks/requests/${payload.mac_address}/${payload.user_id}/daemon`);
  },
  getDeviceStatusAndTypesLogs() {
    return Api({ daemonRequest: true }).get(`/kiosks/get-status-types`);
  },
  remoteControlNotifyToStart(payload) {
    return Api({ daemonRequest: true }).post(`/kiosks/notify-remote-screen-share-start`, payload);
  },
  getRoomInfo(macAddress) {
    return Api({ daemonRequest: true }).get(`kiosks/room-data/${macAddress}/daemon`)
  },
  refreshGetScreen(payload) {
    return Api({ daemonRequest: true }).post(`kiosks/refresh-remote-integration`, payload)
  },
  getScreenAuth() {
    return axios.get(`${process.env.VUE_APP_GET_SCREEN_URL}/?apikey=${process.env.VUE_APP_GET_SCREEN_KEY}`);
  },
  createConnectionGetScreen() {
    return axios.post(`${process.env.VUE_APP_GET_SCREEN_URL}/support/create?apikey=${process.env.VUE_APP_GET_SCREEN_KEY}`);
  },
  getScreenAgentsList(name) {
    return axios.get(`${process.env.VUE_APP_GET_SCREEN_URL}/agents/list?apikey=${process.env.VUE_APP_GET_SCREEN_KEY}&search=${name}`);
  },
  getScreenAgentConnection(deviceId) {
    return axios.post(`${process.env.VUE_APP_GET_SCREEN_URL}/agents/connect?apikey=${process.env.VUE_APP_GET_SCREEN_KEY}&device_id=${deviceId}`);
  },
  getScreenRemoveAgentConnection(payload) {
    return axios.post(`${process.env.VUE_APP_GET_SCREEN_URL}/agents/logout?apikey=${process.env.VUE_APP_GET_SCREEN_KEY}`, payload);
  },
};