<script setup>
import { ref } from "vue";
    import { useStore } from "vuex";
    import useValidation from "@/utils/helpers/validations";
    const { checkEmailValidation } = useValidation();

    const store = useStore();
    const email = ref(null);
    let isValidEmail = ref(true);
    let isError = ref(false);
    let isSend = ref(false);
    let isSubmitted = ref(false);

    const validEmail = () => {
        isError.value = false;
        isValidEmail.value = checkEmailValidation(email.value);
        return isValidEmail.value;
    };

    const onSubmit = () => {
        isError.value = false;
        isSubmitted.value = true;
        const data = {
            email: email.value,
        };
        store.dispatch("forgetPassword", data)
        .then( (res) => {
            console.log('res', res);
            isSend.value = true            
        })
        .catch((e) => {
            console.log("e", e);
            isError.value = true;
            isSubmitted.value = false;
        });
    };
</script>

<template>
    <div class="auth-container col-lg-5 col-md-9 m-auto">
        <div class="auth-card">
            <div class="text-center">
                <img class="logo" src="@/assets/images/logo/logo.png">
            </div>

            <div v-if="!isSend">
                <p class="welcome-message mt-24">WELCOME</p>
                <p class="second-welcome-message">Forgot Password</p>
                <p class="third-welcome-message mt-40">Please enter your email address for password reset</p>
                <div class="form-floating mb-3">
                    <input @input="validEmail" v-model="email" type="email" class="form-control" :class="{'is-invalid' : !isValidEmail || isError}" id="email" placeholder="name@example.com"/>
                    <label for="email">Email address</label>
                    <div v-if="!isValidEmail" class="invalid-feedback">
                        Invalid Email.
                    </div>
                </div>
                <button @click="onSubmit" :disabled="!email || isSubmitted" type="button" class="form-action-btn btn btn-success mt-40">
                    Send <span v-if="isSubmitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
            </div>

            <div v-else class="d-flex justify-content-center">
                <div class="success-card-email mt-40">
                    <div class="text-center mt-53">
                        <img src="@/assets/images/08-Email.svg">
                    </div>

                    <p class="first-success-card-email-message mt-25 text-center">Please check your email</p>
                    <p class="second-success-card-email-message mt-15 text-center">We sent you an email with a reset password link.</p>

                </div>
            </div>

        </div>
    </div>
</template>