
<script setup>
  import { ref, defineEmits, onBeforeMount, computed, defineProps } from "vue";
  import { useStore } from "vuex";

  const props = defineProps({
    configsOptions : Array,
    
  });

  const emit = defineEmits([ "refresh" ]);
  const store = useStore();

  const company_id = localStorage.getItem("currentCompanyId");
  const getModalInfo = computed(()=> store.getters.getModalInfo);
  const getModalAction = computed(()=> store.getters.getModalAction);

  let id = ref(null);
  let name = ref(null);
  let config = ref(null);
  let isSubmitted = ref(false);

  const onSubmit = () => {
    isSubmitted.value = true;
    const data = {
      id : id.value,
      name : name.value,
      config_id : config.value.id,
      company_id,
    }; 
    
    store.dispatch("editDevice", data).then((res) => {
      console.log('res', res);
      emit("refresh");
      resetStoreData();
      isSubmitted.value = false;
    }).catch((e) => {
      console.log(e);
      isSubmitted.value = false;
    });
  }

  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);
  };

  onBeforeMount(() => {
    if(getModalInfo.value && getModalInfo.value.data_type == 'device' && getModalAction.value == 'edit_device'){
      console.log('getModalInfo', getModalInfo.value);
      id.value = getModalInfo.value.id;
      name.value = getModalInfo.value.name;
      config.value = getModalInfo.value.configuration;

    }
  });
</script>

<template>
  <DModal id="editDeviceModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Edit Device </template>
    <template #body>
    
      <div class="form-floating mb-3 ">
        <input v-model="name" type="text" class="form-control"  id="editDeviceName" placeholder="Device Name" />
        <label for="editDeviceName">Device Name</label>
      </div>

      <div class="p-relative">
        <span v-if="config" class="multiselect-title-span" >Configuration</span>
        <VMultiselect v-model="config" :options="props.configsOptions" trackBy="id" label="name"
          :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Configuration"
        />
      </div>

    </template>

    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button :disabled="!name || !config || isSubmitted" 
          @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
            Edit <span v-if="isSubmitted"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
          </button>
          <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </template>
  </DModal>
</template>