<script setup>
    import { ref, computed, onMounted } from 'vue';
    import DefaultLayout from './layouts/DefaultLayout.vue';
    import { useStore } from "vuex";
    import {
        DxDataGrid,
        DxColumn,
        // DxFilterRow,
        DxHeaderFilter,
        DxToolbar,
        DxItem,
        DxPaging,
        DxPager,
        DxSorting,
        DxScrolling,
        DxColumnChooser,
        DxStateStoring,
        DxLoadPanel,
        DxSearchPanel,
    } from 'devextreme-vue/data-grid';
    // import DxDropDownButton from "devextreme-vue/drop-down-button";
    // import { default as Button } from "devextreme-vue/button";
    import { exportDataGrid } from "devextreme/excel_exporter";
    import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
    import { Workbook } from "exceljs";
    import saveAs from "file-saver";
    import { jsPDF } from "jspdf";
    import PreviewLogsActionCell from "@/components/kiosks/PreviewLogsActionCell.vue";
    // import deleteRequestJobModal from "@/components/kiosks/deleteRequestJobModal.vue";
    import Api from "../services/Api";
    import CustomStore from 'devextreme/data/custom_store';

    const companyId = computed(()=>localStorage.getItem("currentCompanyId"));
    const adminPreferenceId = computed(()=>localStorage.getItem("adminPreferenceId"));
    const user = computed(()=>JSON.parse(localStorage.getItem('userDetails')));
    
    const dataGridRefName = ref(null);
    let showClearAll = ref(false);
    // const allowedOperations = ref(["contains"]);
    // const buttonData = ref(["Excel", "CSV", "PDF"]);
    const store = useStore();
    
    let statusLogsDataFilter = ref([]);
    let typesLogsDataFilter = ref([]);

    let multipleFilters = ref([]);
    let allFilterHeaders = ref(['type', 'status']);
    
    const dataGrid = computed(()=>{
        return dataGridRefName.value.instance;
    });

    const isNotEmpty = (value) => {
        return value !== undefined && value !== null && value !== "";
    }

    const customStore = new CustomStore({
        key: 'id',
        async load(loadOptions) {
            let searchText = null;
            let sortText = 'desc';
            if(loadOptions.filter && loadOptions.filter[0]){
                searchText = loadOptions.filter[0].filterValue;
            }
            if(loadOptions.sort && loadOptions.sort[0]){
                sortText = loadOptions.sort[0].desc ? 'desc' : 'asc';
            }
            console.log('searchText', searchText);
        //   let params = "?";
          let filterQuery = "";
          let sortObject = { sortField: null, sortOrder: "" };
          [
            "skip",
            "take",
            "requireTotalCount",
            "requireGroupCount",
            "sort",
            "filter",
            "totalSummary",
            "group",
            "groupSummary",
          ].forEach((i) => {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) {
            // params += `${i}=${JSON.stringify(loadOptions[i])}&`;
              if (i == "filter")
                filterQuery += `${JSON.stringify(loadOptions[i])}`;
              if (i == "sort") {
                sortObject.sortField = loadOptions[i][0].selector;
                sortObject.sortOrder = loadOptions[i][0].desc ? "desc" : "asc";
              }
            }
          });
          // params.slice(0, -1);
          // params = params.slice(0, -1);
          let filters;
          let finalFilterObject = {};
          multipleFilters.value = [];
          if (filterQuery) filters = JSON.parse(filterQuery);
          if (filters) console.log("filters", filters);
          if (filters && typeof filters[0] === "string") {
            if (filters[0] == "!") {
              console.log("not");
              if (typeof filters[1] === "object") {
                const excludeIds = [];
                const columnHeader =
                  typeof filters[1][0] === "object"
                    ? filters[1][0][0] + "HeaderFilter"
                    : filters[1][0] + "HeaderFilter";
                const columnHeaderFilter =
                  columnHeader.charAt(0).toLowerCase() + columnHeader.slice(1);
                console.log(columnHeader); // name of column filter
                if (typeof filters[1][0] === "object") {
                  console.log("obj");
                  filters[1].map((item) => {
                    if (typeof item === "object") {
                      console.log("item", item[2][0]);
                      excludeIds.push(item[2][0]);
                    }
                  });
                } else {
                  console.log("else");
                  // filters[1].map(item => {
                  // if (typeof item === "object") {
                  console.log("item", filters[1][2][0]);
                  excludeIds.push(filters[1][2][0]);
                  // }
                  // });
                }
                console.log(this[columnHeaderFilter]);
                const arr = this[columnHeaderFilter].filter(
                  (el) => !excludeIds.includes(el.value[2][0])
                );
                console.log("arr", arr, excludeIds);
                if (typeof filters[1][0] === "object") {
                  finalFilterObject[filters[1][0][0]] = arr.map((item) => {
                    return item.value[2][0];
                  });
                } else {
                  finalFilterObject[filters[1][0]] = arr.map((item) => {
                    return item.value[2][0];
                  });
                }
                console.log("finalFilterObject", finalFilterObject);
                if (multipleFilters.value.length > 0) {
                  multipleFilters.value.push(finalFilterObject);
                }
              }
            } else {
              if((filters[1] === "contains" || filters[1] === "=") && allFilterHeaders.value.includes(filters[0])) filters[2] = [filters[2]]; // make it array;
              finalFilterObject[filters[0]] =
                filters[1] === "contains" || filters[1] === "="
                  ? filters[2]
                  : filters[2];
            }
            if (multipleFilters.value.length > 0) {
              multipleFilters.value.push(finalFilterObject);
            }
          } else {
            // const isOr = filters[1] === 'or';
            // const isAnd = filters[1] === 'and';
            if (filters)
              for (const filter of filters) {
                // console.log('filter', filter);
                // not filter
                if (filter[0] == "!") {
                  // console.log('multiple filters not');
                  if (typeof filter[1] === "object") {
                    const excludeIds = [];
                    const columnHeader =
                      typeof filter[1][0] === "object"
                        ? filter[1][0][0] + "HeaderFilter"
                        : filter[1][0] + "HeaderFilter";
                    const columnHeaderFilter =
                      columnHeader.charAt(0).toLowerCase() +
                      columnHeader.slice(1);
                    // console.log(columnHeader); // name of column filter
                    if (typeof filter[1][0] === "object") {
                      // console.log('obj');
                      filter[1].map((item) => {
                        if (typeof item === "object") {
                          // console.log('item', item[2][0]);
                          excludeIds.push(item[2][0]);
                        }
                      });
                    } else {
                      // console.log('else');
                      // filters[1].map(item => {
                      // if (typeof item === "object") {
                      // console.log('item', filter[1][2][0]);
                      excludeIds.push(filter[1][2][0]);
                      // }
                      // });
                    }
                    // console.log(this[columnHeaderFilter]);
                    const arr = this[columnHeaderFilter].filter(
                      (el) => !excludeIds.includes(el.value[2][0])
                    );
                    // console.log('arr', arr, excludeIds);
                    if (typeof filter[1][0] === "object") {
                      finalFilterObject[filter[1][0][0]] = arr.map((item) => {
                        return item.value[2][0];
                      });
                    } else {
                      finalFilterObject[filter[1][0]] = arr.map((item) => {
                        return item.value[2][0];
                      });
                    }
                    // console.log('finalFilterObject', finalFilterObject);
                    if (multipleFilters.value.length > 0) {
                      multipleFilters.value.push(finalFilterObject);
                    }
                  }
                }
                let orExist;
                let andExist;
                if (typeof filter === "object") {
                  // console.log('obj', filter);
                  orExist = filter.find((item) => item === "or");
                  andExist = filter.find((item) => item === "and");
                  if (filter[1] == "or") {
                    // Header Filter - Gather In One Condition
                    if (
                      filter[0][1] === ">=" ||
                      filter[0][1] === "<=" ||
                      filter[0][1] === "<"
                    ) {
                      const finalFilter = {};
                      const condition = [];
                      for (const filterItem of filter) {
                        if (filterItem[2]) condition.push(filterItem[2][0]);
                      }
                      finalFilter[filter[0][0]] = {
                        start: condition[0].replace(/\//g, "-"),
                        end: condition[1].replace(/\//g, "-"),
                      };
                      multipleFilters.value.push(finalFilter);
                    } else {
                      // Gather In One Condition
                      for (const filterItem of filter) {
                        if (filterItem[1] === "contains" || filterItem[1] === "=") {
                          if (allFilterHeaders.value.includes(filterItem[0])) filterItem[2] = [filterItem[2]]; // make it array;
                          const finalFilter = {};
                          finalFilter[filterItem[0]] =
                            filterItem[1] === "contains" || filterItem[1] === "="
                              ? filterItem[2]
                              : filterItem[2];

                          // Header Filter - Gather In One Condition
                          const filterExist = multipleFilters.value.find(
                            (item) => item[filterItem[0]]
                          );
                          if (!filterExist)
                            multipleFilters.value.push(finalFilter);
                          else filterExist[filterItem[0]].push(filterItem[2][0]);
                        //   console.log('wee', filterItem, multipleFilters.value);
                        }
                      }
                    }
                  } // -------------- Header Filter --------------------
                  if (filter[1] == "and" && filter[0][1] == ">=") {
                    // Range Condition
                    const finalFilter = {};
                    const condition = [];
                    //"Arrival":{"start":"2022-03-10 00:00:00","end":"2022-03-12 23:59:59"}
                    for (const filterItem of filter) {
                      if (typeof filterItem === "object" && filterItem[2])
                        condition.push(filterItem[2]);
                    }
                    finalFilter[filter[0][0]] = {
                      start: condition[0].replace(/\//g, "-"),
                      end: condition[1].replace(/\//g, "-"),
                    };
                    multipleFilters.value.push(finalFilter);
                  } // -------------- Range Condition --------------------
                }
                if (
                  filter.length > 0 &&
                  filter[0] &&
                  filter[0] !== "Actions" &&
                  !orExist &&
                  !andExist
                ) {
                  if (filter[1] === "contains" || filter[1] === "=") {
                    if (allFilterHeaders.value.includes(filter[0])) filter[2] = [filter[2]]; // make it array;
                    const finalFilter = {};
                    finalFilter[filter[0]] =
                      filter[1] === "contains" || filter[1] === "="
                        ? filter[2]
                        : filter[2];

                    // Header Filter - Gather In One Condition
                    const filterExist = multipleFilters.value.find(
                      (item) => item[filter[0]]
                    );
                    if (!filterExist) multipleFilters.value.push(finalFilter);
                    else filterExist[filter[0]].push(filter[2][0]);
                  }
                  if (
                    filter[1] === ">=" ||
                    filter[1] === "<=" ||
                    filter[1] === "<"
                  ) {
                    const finalFilter = {};
                    const filter2 =
                      typeof filter[2] !== "number" && filter[2].includes("/")
                        ? filter[2].replace(/\//g, "-")
                        : filter[2];
                    finalFilter[filter[0]] = { start: "", end: "" };
                    // Range Condition
                    const filterExist = multipleFilters.value.find(
                      (item) => item[filter[0]]
                    );
                    if (!filterExist) {
                      finalFilter[filter[0]].start = filter2;
                      multipleFilters.value.push(finalFilter);
                    } else (filterExist[filter[0]]).end = filter2;
                  }
                } else if (andExist) {
                  for (const andFilter of filter) {
                    if (
                      andFilter.length > 0 &&
                      andFilter[0] &&
                      andFilter[0] !== "Actions"
                    ) {
                      if (andFilter[1] === "contains" || andFilter[1] === "=") {
                        if (allFilterHeaders.value.includes(andFilter[0])) andFilter[2] = [andFilter[2]]; // make it array;
                        const finalFilter = {};
                        finalFilter[andFilter[0]] =
                          andFilter[1] === "contains" || andFilter[1] === "="
                            ? andFilter[2]
                            : andFilter[2];
                        multipleFilters.value.push(finalFilter);
                      }
                      if (andFilter[1] === "and" && andFilter[0][1] == ">=") {
                        // range condition
                        const finalFilter = {};
                        const condition = [];
                        for (const filterItem of andFilter) {
                          const filterItem2 =
                            typeof filterItem[2] !== "number" &&
                            filterItem[2].includes("/")
                              ? filterItem[2].replace(/\//g, "-")
                              : filterItem[2];
                          if (typeof filterItem === "object" && filterItem[2])
                            condition.push(filterItem2);
                        }
                        finalFilter[andFilter[0][0]] = {
                          start: condition[0].replace(/\//g, "-"),
                          end: condition[1].replace(/\//g, "-"),
                        };
                        multipleFilters.value.push(finalFilter);
                      }
                    }
                  }
                }
              }
          }
          finalFilterObject =
            multipleFilters.value && multipleFilters.value.length > 0
              ? Object.assign({}, ...multipleFilters.value)
              : finalFilterObject;
          // console.log(
          //   "fffff",
          //   finalFilterObject,
          //   JSON.stringify(finalFilterObject)
          // );

        const queryObject = {
                "skip": loadOptions.skip,
                "take": loadOptions.take,
                "search": finalFilterObject,
                "sort": sortText,
            }

            console.log('queryObject', queryObject);
            try {
                const result = await Api({ daemonRequest: true }).post(`/kiosks/requests/entity/${companyId.value}/${user.value.user_id}/daemon`, queryObject);
                // console.log('result', result);

                await result.data.map( (row) => {
                    row.type = row.label;
                    row.status = row.status_formatted;
                    row.reason_of_failure = row.reason_of_failure ? row.reason_of_failure : '-' ;
                })
            
              return {
                data: result.data,
                totalCount: result.total,
              };
            } catch (err) {
                console.log('err', err);
            }
        },
    });

    const contentReady = (e) =>{
        let sortedExp = getSortedColumns();
        let filterExp = e.component.getCombinedFilter();
        if(filterExp || sortedExp) {
            showClearAll.value = true;
        }else{
            showClearAll.value = false;
        }
        setColumnChooserPosition();
    }

    const fetchDeviceStatusAndTypesLogs = () => {
        store.dispatch('fetchDeviceStatusAndTypesLogs')
        .then( async (res) => {
            let status = res.data[0].status;
            let types = res.data[1].types;
            for(let key in status){
                statusLogsDataFilter.value.push({text :status[key].charAt(0).toUpperCase() + status[key].slice(1), value : status[key] })
            }
            for(let key in types){
                let type = (types[key].charAt(0).toUpperCase() + types[key].slice(1)).replaceAll('_', ' ');
                typesLogsDataFilter.value.push({text : type, value : types[key] })
            }
        }).catch( (e) => {
            console.log(e)
        })
    }

    const getSortedColumns = () =>{
        let allColumns = Array.from(Array(dataGrid.value.columnCount()).keys()).map(index => dataGrid.value.columnOption(index));
        allColumns = allColumns.find(col => col.sortIndex != null);
        return allColumns;
    }

    const customLoad = async () => {
        await store.dispatch('onTableHeader', {url: "/devices-logs-page",companyId : companyId.value})
        .catch((e)=> console.log(e));
        let value = store.getters['getTableHeaders'];
        console.log("customLoad", value);
        return value;
    }

    const customSave = (gridState) => {
        gridState.pageIndex = 0;
        gridState.pageSize = 10;
        let value = JSON.stringify(gridState);
        value = value.replace(',"selectedRowKeys":[]', "");

        if (!store.getters['getTableHeaders']) {
        console.log('createTableHeaders');
        store.dispatch('createTableHeaders',{
            url: "/devices-logs-page",
            headers: value,
            filters: "[]",
            companyId : companyId.value,
        }).catch((e)=> console.log(e));
        } else if (value != JSON.stringify(store.getters['getTableHeaders'])) {
        console.log('updateTableHeaders');
        store.dispatch('updateTableHeaders',{
            url: "/devices-logs-page",
            headers: value,
            filters: "[]",
            companyId : companyId.value,
            adminPreferenceId : adminPreferenceId.value,
        }).catch((e)=> console.log(e));
        }
        console.log("customSave value",value != JSON.stringify(store.getters['getTableHeaders']));
    }

    const setColumnChooserPosition = () => {
        var columnChooserView = dataGrid.value.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
        }
        columnChooserView._popupContainer.option("position", {
            of: dataGrid.value.element(),    
            my: "left top",
            at: "left top",
            offset: "0 50",
        });
    }

    const onEditorPreparing = (e) => {
        if (e.parentType == "filterRow") {
            e.editorOptions.placeholder = "Search";
        }
    }

    const clearFilter = () =>{
        dataGrid.value.clearFilter("row");
        dataGrid.value.clearFilter("header");
        dataGrid.value.clearFilter("search");
        dataGrid.value.clearSorting();
    }

    // const changeFullText = (data)  =>{
    //   // console.log('changeFullText', data.id);
    //   const newReasonFailure = trimText(
    //     data.reason_of_failure,
    //     !data.showingFullText
    //   );

    //     dataGrid.value.getDataSource().store()
    //       .push([
    //         {
    //           type: "update",
    //           key: { visit_id: data.visit_id, user_id: data.user_id },
    //           data: {
    //             showingFullText: !data.showingFullText,
    //             reason_of_failure: newReasonFailure,
    //           },
    //         },
    //       ]);
     
    // }

    // const trimText = (data, showingFullText) => {
    //   if (showingFullText) {
    //     return data;
    //   }
    //   return data && data !== "-" && data.length > 37
    //     ? `${data.slice(0, 37).trim()}...`
    //     : data;
    // }

    const onItemClick = (type) => {
        if (type == "Excel") {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet("Main sheet");
            exportDataGrid({
                component: dataGrid.value,
                worksheet: worksheet,
                customizeCell: function (options) {
                    const excelCell = options;
                    excelCell.font = { name: "Arial", size: 12 };
                    excelCell.alignment = { horizontal: "left" };
                },
            }).then(function () {
                workbook.xlsx.writeBuffer().then(function (buffer) {
                    saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "devices-logs.xlsx"
                    );
                });
            });
        // e.cancel = true;
        } else if (type == "CSV") {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet("Main sheet");
            exportDataGrid({
            component: dataGrid.value,
                worksheet: worksheet,
                customizeCell: function (options) {
                    const excelCell = options;
                    excelCell.font = { name: "Arial", size: 12 };
                    excelCell.alignment = { horizontal: "left" };
                },
            }).then(function () {
                workbook.csv.writeBuffer().then(function (buffer) {
                    saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "devices-logs.csv"
                    );
                });
            });
        } else if (type == "PDF") {
            const doc = new jsPDF("landscape");
            exportDataGridToPdf({
                jsPDFDocument: doc,
                component: dataGrid.value,
                customizeCell: function(options) {
                    const { gridCell, pdfCell } = options;
                    if(gridCell.rowType === 'data') {
                        // data columns style
                        pdfCell.styles = {
                        font: 'times',
                        fontStyle: 'lato',
                        fontSize: 8,
                        cellWidth: 'auto'
                        }
                    }else{
                        // header columns style
                        pdfCell.styles = {
                        font: 'times',
                        fontStyle: 'lato',
                        fontSize: 8,
                        fillColor : '#2680EB',
                        textColor : '#FFFFFF',
                        cellWidth: 'auto'
                        }
                    }
                }
            }).then(() => {
                const pageCount = doc.internal.getNumberOfPages();
                for(let i = 1; i <= pageCount; i++) {
                    doc.setPage(i);
                    const pageSize = doc.internal.pageSize;
                    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
                    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                    const header = 'Device Logs';
                    const headerWidth = doc.getTextDimensions(header).w;
                    const footer = `Page ${i} of ${pageCount}`;

                    // Header
                    doc.text(header, (pageWidth - headerWidth) / 2, 10);

                    // Footer
                    doc.text(footer, pageWidth / 2 - (doc.getTextWidth(footer) / 2), pageHeight - 5, { baseline: 'bottom' });
                }
                doc.save("devices-logs.pdf");
            });
        }
    }

    onMounted (() => {
        fetchDeviceStatusAndTypesLogs();
    });

</script>

<template>

    <!-- <deleteRequestJobModal v-if="getModalState" @callPreviewLogs="refreshGrid"/> -->

    <DefaultLayout>
        <div class="d-flex align-items-center">
            <h4 class="grid-header-title">Device Logs</h4>
        </div>

        <section class="grid-white-section main-page-container-animation">
  
            <DxDataGrid :data-source="customStore" ref="dataGridRefName" :remote-operations="true"
            :showBorders="false" :columnAutoWidth="true" :allowColumnReordering="true" :allowColumnResizing="true"
            :rowAlternationEnabled="false" :showColumnLines="false" :showRowLines="true" :repaint-changes-only="true"
            :two-way-binding-enabled="true" :no-data-text="'No logs yet'" @editor-preparing="onEditorPreparing($event)"
            @content-ready="contentReady($event)"
            >
                <DxLoadPanel :show-indicator="true" :show-pane="true" :enabled="true"/>
                <DxScrolling mode="standard" :use-native="true" />
                <DxPaging :page-size="10" />
                <DxStateStoring :enabled="true" type="custom"  :customSave="customSave" :customLoad="customLoad"/>
        
        
                <DxPager
                :visible="true"
                :allowed-page-sizes="[10, 25, 50, 100]"
                :display-mode="'full'"
                :show-page-size-selector="true"
                :show-info="true"
                :show-navigation-buttons="true"
                />
        
                <!-- <DxFilterRow :visible="true" /> -->
                <DxSearchPanel :width="200" :visible="true" placeholder="Search ..."/>

                <DxSorting mode="single" />
                <DxHeaderFilter :visible="true" />
                <DxColumnChooser :enabled="true" mode="select" />

                <DxToolbar>
                  <DxItem location="before" template="columnButtonTemplate" />
                  <DxItem location="before" template="exportButtonTemplate" />
                  <DxItem location="before" template="clearAllTemplate"/>
                  <DxItem location="after" name="searchPanel" />

                </DxToolbar>
      
                <DxColumn data-field="id" caption="ID" data-type="string" :visible="false" :allow-header-filtering="false"
                :allow-filtering="false" :allow-searching="false"/>

                <DxColumn data-field="device_name" caption="Device Name" :allow-searching="true" 
                :allow-header-filtering="false" data-type="string"/>

                <DxColumn data-field="date" format="yyyy/MM/dd HH:mm:ss" caption="Date" :allow-editing="false"
                :allow-header-filtering="false" :allow-filtering="false" :allow-searching="false" data-type="string"/>

                <DxColumn data-field="type" caption="Type" :allow-header-filtering="true" :allow-filtering="false" :allow-searching="false"  data-type="string">
                    <DxHeaderFilter :data-source="typesLogsDataFilter"/>
                </DxColumn>

                <DxColumn data-field="status" caption="Status" :allow-filtering="false" :allow-searching="false" cell-template="status-cell" :allow-header-filtering="true" data-type="string">
                    <DxHeaderFilter :data-source="statusLogsDataFilter"/>
                </DxColumn>

                <DxColumn data-field="reason_of_failure" :width="200" :visible="true" caption="Status Message" :allow-header-filtering="false"
                :allow-filtering="true" data-type="string" :allow-searching="true" css-class="text-rows"/>

                <DxColumn data-field="actions" caption="Actions" :allow-filtering="false" cell-template="action-cell"
                :allow-exporting="false" :allow-editing="false" :allow-sorting="false"
                 data-type="object" :width="100"/>

                <template #status-cell="{data}">
                    <span :class="{'success-style': data.data.status_formatted == 'Succeeded',
                        'failed-style': data.data.status_formatted == 'Failed','other-status-style':
                          data.data.status_formatted != 'Failed' && data.data.status_formatted != 'Succeeded',
                        }"
                    > {{ data.data.status_formatted }}
                    </span>
                </template>

                <!-- <template #action-cell="{ data }">
                    <a v-if=" data.data.downloadable_file && ( 
                        data.data.type == 'remote_show_screens' || 
                        data.data.type == 'manual_pull_database' || 
                        data.data.type == 'kiosk_logging'
                        )"
                      :href="`${data.data.downloadable_file}`"
                      target="_blank"
                      download
                      class="btn btn-primary download-grid-button"
                      >Download</a
                    >
                    <span v-else>-</span>
                </template> -->

                <template #action-cell="{ data }">
                  <PreviewLogsActionCell :data="data.data" @refreshGrid="res"/>
                </template>


                <!-- <template #columnButtonTemplate>
                    <button class="custom-columns mx-1" @click="dataGrid.showColumnChooser()">
                        <span class="custom-text">Columns</span>
                        <span class="custom-icon">
                            <i class="dx-icon dx-icon-spindown dx-icon-right"></i>
                        </span>
                    </button>
                </template>

                <template #exportButtonTemplate>
                    <DxDropDownButton class="exportGridHeader" :items="buttonData" text="Export" @item-click="onItemClick" />
                </template>

                <template #clearAllTemplate>
                    <div v-if="showClearAll" class="clear-cols">
                      <div class="custom-line"></div>
                        <Button text="Clear All" @click="clearFilter()"
                        styling-mode="outlined" type="danger" class="clear-colums-filter"/>
                    </div>
                </template> -->

                <template #exportButtonTemplate>
                    <button  @click="onItemClick('Excel')" type="button" class="btn action-btn m-5 font-20"><i class="bi bi-filetype-xlsx"></i> </button>
                  </template>
          
                  <template #columnButtonTemplate>
                    <button  @click="dataGrid.showColumnChooser()" type="button" class="btn action-btn font-20 m-5"><i class="bi bi-columns"></i> </button>
                  </template>
          
                  <template #clearAllTemplate>
                    <div v-if="showClearAll" class="clear-cols">
                      <div class="custom-line"></div>
                      <button @click="clearFilter()" type="button" class="btn action-btn clear-colums-filter m-5">Clear Filters </button>
                    </div>
                  </template>

                  <!-- <template #reason-failure-cell="{ data }">
                    <div
                      style="
                        white-space: normal;
                        overflow-wrap: break-word;
                        height: 90;
                        width: 90;
                      "
                    >
                      {{ data.value }}
          
                      <a
                        class="read_more"
                        style="cursor: pointer"
                        v-if="
                          data.data.reason_of_failure &&
                          data.data.reason_of_failure.length > 37 &&
                          data.data.reason_of_failure !== '-'
                        "
                        @click="changeFullText(data.data)"
                      >
                        Read {{ data.data.showingFullText ? "Less" : "More" }}
                      </a>
                    </div>
                  </template> -->

            </DxDataGrid>
        </section>
    </DefaultLayout>
</template>