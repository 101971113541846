<script setup>
    import { ref, computed, reactive, onBeforeMount } from "vue";
    import DefaultLayout from "@/views/layouts/DefaultLayout.vue";
    import { useStore } from "vuex";
    import {
        DxFileManager, 
        DxPermissions, 
        DxToolbar, 
        DxContextMenu, 
        DxItem,
        DxFileSelectionItem, 
        DxItemView, 
        DxDetails, 
        DxColumn,
        DxNotifications,
    } from 'devextreme-vue/file-manager';

    import { Modal }  from "bootstrap";
    import addJob from "../components/jobs/addJob.vue";
    import editJob from "../components/jobs/editJob.vue";
    import deleteFolderAndJob from "../components/jobs/deleteFolderAndJob.vue";
    import createFolder from "../components/jobs/createFolder.vue";
    import editFolder from "../components/jobs/editFolder.vue";
    import applyJob from "../components/jobs/applyJob.vue";
    import duplicateJob from "../components/jobs/duplicateJob.vue";
    import _debounce from 'lodash.debounce';

    let getModalState = computed(()=> store.getters.getModalState);
    const companyId = computed( ()=> localStorage.getItem("currentCompanyId"));
    const store = useStore();
    const fileManagerRefName = ref(null);
    let rootItems = ref([]);
    let filesOptions = ref([]);
    // let firstTime = ref(true);   
    let searchValue = ref('');
    const fileManager = computed(()=>{
        return fileManagerRefName.value.instance;
    });

    const state = reactive({ 
        addJobModal: null,
        editJobModal: null,
        deleteFolderAndJobModal: null,
        createFolderModal : null,
        editFolderModal : null,
        applyJobModal : null,
        duplicateJobModal : null,
    });

    const getNewFileMenuOptions = () => {
        return {
            items: [
                {
                    text: 'Create new Job',
                    icon: 'plus',
                    items: [
                        {
                            icon : 'download',
                            text: 'Install App',
                            action: 'install_app',
                        },
                        {
                            icon : 'codeblock',
                            text: 'Run Script',
                            action: 'run_script',
                        },
                        {
                            icon : 'upload',
                            text: 'Transfer File',
                            action: 'transfer_file',
                        },
                    ],
                },
            ],
            onItemClick,
        };

    }

    const getNewFolderMenuOptions = () => {
        return {
            items: [
                {
                    text: 'Create new Folder',
                    icon: 'newfolder',
                    action : 'create_folder',
                    
                },
            ],
            onItemClick,
        };
    }
    const contextMenuFolderItems = [
        {
            text: 'Create new Folder',
            icon: 'newfolder',
            action : 'create_folder',
        },
        {
            text: 'Create new Job',
            icon: 'plus',
            items: [
                {
                    icon : 'download',
                    text: 'Install App',
                    action: 'install_app',
                },
                {
                    icon : 'codeblock',
                    text: 'Run Script',
                    action: 'run_script',
                },
                {
                    icon : 'upload',
                    text: 'Transfer File',
                    action: 'transfer_file',
                },
            ],
        },
        {
            text: 'Rename',
            icon: 'edit',
            action : 'edit_folder_and_job',
        },
        {
            name: 'move',   
            action : 'move_folder_and_job',
        },
        {
            text: 'Delete',
            icon: 'remove',
            action : 'delete_folder_and_job',
        },
    ];

    const contextMenuFileItems = [
        // {
        //     text: 'Apply',
        //     icon: 'todo',
        //     action : 'apply_job',
        // },
        {
            text: 'Edit',
            icon: 'edit',
            action : 'edit_folder_and_job',
        },
        {
            text: 'Duplicate',
            icon: 'copy',
            action : 'duplicate_job',
        },
        {
            text: 'Move to',
            name: 'move',
            action : 'move_folder_and_job',
        },
        {
            text: 'Delete',
            icon: 'remove',
            action : 'delete_folder_and_job',
        },
    ]

    const contextMenuRoottems = [
        {
            text: 'Create new Folder',
            icon: 'newfolder',
            action : 'create_folder',
        },
        {
            text: 'Create new Job',
            icon: 'plus',
            items: [
                {
                    icon : 'download',
                    text: 'Install App',
                    action: 'install_app',
                },
                {
                    icon : 'codeblock',
                    text: 'Run Script',
                    action: 'run_script',
                },
                {
                    icon : 'upload',
                    text: 'Transfer File',
                    action: 'transfer_file',
                },
            ],        
        },
    ]
    

    const debouncedOnChange = computed(()=> _debounce(onChangeSearch, 300))

    const onChangeSearch = async (e) => {
        console.log('e', e.target.value);
        if(e.target && (e.target.value == '' || e.target.value.length >= 2)){
            searchValue.value = await e.target.value;
            console.log('searchValue length', searchValue.value.length);
            console.log('searchValue', searchValue.value);
            fetchJobsGrid();
        }
    }

    const clearSearch = async() => {
        searchValue.value = '';
        fetchJobsGrid()
    }

    const customizeIcon = (fileManagerItem) => {
        // console.log('fileManagerItem',fileManagerItem);
        if (fileManagerItem.isDirectory) {
            return '<svg version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" enable-background="new 0 0 48 48">'+
                '<path fill="#FFA000" d="M38,12H22l-4-4H8c-2.2,0-4,1.8-4,4v24c0,2.2,1.8,4,4,4h31c1.7,0,3-1.3,3-3V16C42,13.8,40.2,12,38,12z"/>'+
                '<path fill="#FFCA28" d="M42.2,18H15.3c-1.9,0-3.6,1.4-3.9,3.3L8,40h31.7c1.9,0,3.6-1.4,3.9-3.3l2.5-14C46.6,20.3,44.7,18,42.2,18z"/>'+
                '</svg>';
        } else if(fileManagerItem.dataItem.jobType == 'Install App'){
            return '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">'+
                '<path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>'+
                '<path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>'+
                '</svg>';
        } else if (fileManagerItem.dataItem.jobType == 'Run Script') {
            return '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-code" viewBox="0 0 16 16">'+
                '<path d="M5.854 4.854a.5.5 0 1 0-.708-.708l-3.5 3.5a.5.5 0 0 0 0 .708l3.5 3.5a.5.5 0 0 0 .708-.708L2.707 8zm4.292 0a.5.5 0 0 1 .708-.708l3.5 3.5a.5.5 0 0 1 0 .708l-3.5 3.5a.5.5 0 0 1-.708-.708L13.293 8z"/>'+
                '</svg>'
        }
    }

    const refreshFileManager = () => {
        console.log("file manager refreshed");
        document.querySelector(".modal-backdrop").remove();
        document.querySelector(".dx-device-desktop").classList.remove('modal-open');
        fetchJobsGrid();
    };

    const onItemClick = async ({ itemData, viewArea, fileSystemItem }) => {
        await resetStoreData();
        console.log('itemData',itemData);
        console.log('viewArea',viewArea);
        console.log('fileSystemItem',fileSystemItem);
        let data = {};
        let directory = await fileManager.value.getCurrentDirectory()
        // console.log('directory',directory);
        if( directory && fileSystemItem && fileSystemItem.dataItem) {
            fileSystemItem.dataItem.current_parent_id = await directory.dataItem?.id;
            data = await fileSystemItem.dataItem;
        }else if(directory && fileSystemItem === undefined){
            data = await directory.dataItem
        }
        action(itemData.action, data)
    }

    const action = async (action, data) => {
        // console.log('data', data);
        await store.commit('setModalAction', action);
        store.commit('setModalState', true);
        await store.commit('setModalData', data);

        if(action == 'install_app' || action == 'run_script' || action == 'transfer_file'){
            state.addJobModal = new Modal("#addJobModal", {});
            state.addJobModal.show();
        }else if(action == 'create_folder'){
            state.createFolderModal = new Modal("#createFolderModal", {});
            state.createFolderModal.show();
        }else if(action == 'edit_folder_and_job'){
            if(data.type == 'folder'){
                state.editFolderModal = new Modal("#editFolderModal", {});
                state.editFolderModal.show();
            }else if(data.type == 'job'){
                state.editJobModal = new Modal("#editJobModal", {});
                state.editJobModal.show();
            }
        }else if(action == 'delete_folder_and_job'){
            state.deleteFolderAndJobModal = new Modal("#deleteFolderAndJobModal", {});
            state.deleteFolderAndJobModal.show();
        }else if(action == 'apply_job'){
            state.applyJobModal = new Modal("#applyJobModal", {});
            state.applyJobModal.show();
        }else if(action == 'duplicate_job'){
            state.duplicateJobModal = new Modal("#duplicateJobModal", {});
            state.duplicateJobModal.show();
        }
    }
    
    const onContextMenuShowing = (e) => {
        console.log('e onContextMenuShowing', e);
        if(!e.fileSystemItem?.dataItem) {
            e.component.option('contextMenu.items', contextMenuRoottems);
        } else if (e.fileSystemItem.dataItem?.type == 'folder') {
            e.component.option('contextMenu.items', contextMenuFolderItems);
        }else if (e.fileSystemItem?.dataItem?.type == 'job') {
            e.component.option('contextMenu.items', contextMenuFileItems);
        }
    }

    const onItemMoved = async (e) => {
        console.log('e onItemMoved', e);

        let payload = {
            id : e.sourceItem.dataItem.id,
            parent_id : e.parentDirectory.dataItem.id,
            company_id : companyId.value,
            name : e.itemName,
        }

        if(e.sourceItem.dataItem.type == 'folder'){
            await moveFolder(payload)
        }else if(e.sourceItem.dataItem.type == 'job'){
            await moveJob(payload)
        }

    }

    const fetchJobsGrid = () => {
    
        let data = { 
            companyId : companyId.value ,
            payload : {
                "search": searchValue.value
            }
        }

        store.dispatch('fetchJobsGrid', data).then(async(res) => {
            // console.log('res.data', res.data);
            rootItems.value = await res.data?.data;
            setTimeout(()=>{
                fileManager.value.refresh();
            },200)
        }).catch( (e) => {
            console.log(e)
        });
        
        
    }

    const moveJob = (payload) => {

        store.dispatch('moveJob', payload).then(async(res) => {
            console.log('res.data', res.data);
        }).catch( (e) => {
            console.log(e)
        });

    }

    const moveFolder = (payload) => {

        store.dispatch('editFolder', payload).then(async(res) => {
            console.log('res.data', res.data);
        }).catch( (e) => {
            console.log(e)
        });

    }

    const resetStoreData = () =>{
        store.commit('setModalState', false);
        store.commit('setModalData', {});
        store.commit('setModalAction', null);
    };

    onBeforeMount( () => {
        fetchJobsGrid();
    });

</script>

<template>
    <DefaultLayout>
        
        <addJob v-if="getModalState" @refresh="refreshFileManager" :filesOptions="filesOptions"/>
        <editJob v-if="getModalState" @refresh="refreshFileManager" />
        <createFolder v-if="getModalState" @refresh="refreshFileManager" />
        <editFolder v-if="getModalState" @refresh="refreshFileManager" />
        <deleteFolderAndJob v-if="getModalState" @refresh="refreshFileManager" />
        <applyJob v-if="getModalState" @refresh="refreshFileManager" />
        <duplicateJob v-if="getModalState" @refresh="refreshFileManager" />
    
        <DxFileManager class="main-page-container-animation" ref="fileManagerRefName" :file-system-provider="rootItems"
            :customize-thumbnail="customizeIcon" root-folder-name="Jobs" :selection-mode="'single'"
            :height="724" @context-menu-item-click="onItemClick" @context-menu-showing="onContextMenuShowing($event)"
            @item-moved="onItemMoved($event)">

            <DxNotifications :show-popup="false"/>
            <DxPermissions :create="true" :copy="true" :move="true" :delete="true" :rename="true"/>
        
            <DxItemView :show-parent-folder="false">

                <DxDetails>

                    <DxColumn data-field="thumbnail" />
                    <DxColumn data-field="name" />
                    <DxColumn data-field="jobType" caption="Type" :width="95"/>
                    <DxColumn :visible="searchValue ? true : false" data-field="path" caption="Path"/>
                    <DxColumn caption="Created At" data-field="createdAtFormatted"/>
                    
                </DxDetails>

            </DxItemView>
        
            <DxToolbar>
                <DxItem name="showNavPane" :visible="true"/>
                <DxItem name="separator"/>
                <!-- <DxItem text="Create new Folder" icon="newfolder" action="create_folder" /> -->
                <DxItem widget="dxMenu" location="before" :options="getNewFolderMenuOptions()"/>
                <DxItem widget="dxMenu" location="before" :options="getNewFileMenuOptions()"/>

                <DxItem template="searchPanelTemplate" location="after"/>

                <DxItem name="separator" location="after"/>
                <DxItem name="switchView"/>

                <!-- <DxItem name="separator" location="after"/> -->
                <!-- <DxItem name="refresh"/> -->

                <!-- <DxFileSelectionItem name="refresh"/> -->

            
                <!-- <DxFileSelectionItem name="rename"/> -->
                <!-- <DxFileSelectionItem name="separator"/> -->
                <!-- <DxFileSelectionItem text="Edit" icon="edit" action="edit_folder_folder_job" /> -->
                <!-- <DxItem name="delete" text="Delete" icon="remove" action="delete_folder_and_job" /> -->
                <!-- <DxFileSelectionItem name="separator"/> -->
                <DxFileSelectionItem name="clearSelection"/>

            </DxToolbar>

            <DxContextMenu>
                <DxItem text="Create new Folder" icon="newfolder" action="create_folder"/>
                <DxItem text="Create new job" icon="plus">
                    <DxItem text="Install App" icon="download" action="install_app"/>
                    <DxItem text="Run Script" icon="codeblock" action="run_script"/>
                    <DxItem text="Transfer File" icon="upload" action="transfer_file"/>
                </DxItem>

                <DxItem text="Edit" icon="edit" action="edit_folder_and_job" />
                <DxItem name="move" action="move_folder_and_job" />
                <DxItem text="Delete" icon="remove" action="delete_folder_and_job" />

              
            </DxContextMenu>

            <template #searchPanelTemplate>
                <div class="d-flex custom-search-file-manage-contanier">
                    <div class="custom-search-file-manager-icon"><span><i class="bi bi-search"></i></span></div>
                    <input type="text" @input="debouncedOnChange($event)" :value="searchValue"  class="form-control mr-10" placeholder="Search ..."/>
                    <div v-if="searchValue" @click="clearSearch()" class="custom-search-file-manager-remove-icon-contanier"><span><i class="bi bi-x custom-search-file-manager-remove-icon"></i></span></div>
                </div>
            </template>
        </DxFileManager>

    </DefaultLayout>
</template>