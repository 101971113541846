<script setup>
  import { ref, defineEmits, onBeforeMount, computed} from "vue";
  import { useStore } from "vuex";
  const company_id = localStorage.getItem("currentCompanyId");
  const getModalInfo = computed(()=> store.getters.getModalInfo);
  const getModalAction = computed(()=> store.getters.getModalAction);

  const emit = defineEmits(['refresh']);
  const store = useStore();
  let id = ref(null);
  let name = ref(null);
  let type = ref(null);
  let isSubmitted = ref(false);


  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);

  };

  const onSubmit = () => {
    isSubmitted.value = true;


    store.dispatch("deleteJob", {id : id.value , companyId : company_id})
    .then((res) => {
      console.log('res', res);
      resetStoreData();
      emit('refresh');
    })
    .catch((e) => {
      console.log("e", e);
      isSubmitted.value = false;
    });

  };

  onBeforeMount(()=>{
    if(getModalInfo.value && getModalAction.value == 'delete_folder_and_job'){
      console.log('getModalInfo', getModalInfo.value);
      id.value = getModalInfo.value.id;
      name.value = getModalInfo.value.name;
      type.value = getModalInfo.value.type;
    }
  });
</script>
<template>
  <DModal id="deleteFolderAndJobModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Delete Job </template>

    <template #body>

      <div class="delete-text mb-3">
        <span v-if="type == 'job'">Are you sure you want to <b>"Delete"</b> the job <b>{{ name }}</b> ?</span>
        <span v-if="type == 'folder'">Are you sure you want to <b>"Delete"</b> the folder <b>{{ name }}</b> ?</span>
      </div>
      <p v-if="type == 'folder'" class="delete-text">Please ensure you have migrated all associated jobs first.</p>

    </template>

    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button @click="resetStoreData" type="button" class="btn btn-primary add-button mr-30" data-bs-dismiss="modal">Cancel</button>
          <button :disabled="isSubmitted" @click="onSubmit" type="button" class="btn btn-secondary cancel-button" >
            Delete <span v-if="isSubmitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </template>
  </DModal>
</template>
  