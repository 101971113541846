<script setup>
  import { ref, defineEmits, defineProps } from "vue";
  import { useStore } from "vuex";
  import router from "@/router";
  const props = defineProps({
    closeModal: Function,
  });

  const store = useStore();
  const emit = defineEmits(["refresh"]);
  const company_id = localStorage.getItem("currentCompanyId");
  let description = ref(null);
  let name = ref(null);
  let isSubmitted = ref(false);

  const resetData = () => {
    name.value = null;
    description.value = null;
    isSubmitted.value = false;
  };

  const onSubmit = () => {
    isSubmitted.value = true;
    const data = {
      name: name.value,
      description: description.value,
      company_id,
    };

    store.dispatch("addConfig", data).then((res) => {
      // console.log('res', res);
      router.push("/configurations/"+ res.data.id)
      isSubmitted.value = false;
      emit("refresh");
      resetData();
      props.closeModal();
    }).catch((e) => {
      console.log(e);
      isSubmitted.value = false;
    });
  };
</script>

<template>
  <DModal id="configModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Add Config </template>

    <template #body>

      <div class="form-floating mb-3">
        <input type="text" v-model.trim="name" class="form-control" id="name" placeholder=""/>
        <label for="name">Name</label>
      </div>

      <div class="form-floating mb-3">
        <textarea v-model="description" class="form-control none-resize h-105" placeholder="description" id="description"/>
        <label for="description">Description</label>
      </div>

    </template>
    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button :disabled="!name || isSubmitted"
            @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
            Add <span v-if="isSubmitted"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
          </button>
          <button @click="resetData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </template>
  </DModal>
</template>
