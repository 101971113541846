<script setup>
  import { ref, onBeforeMount, defineEmits, computed } from "vue";
  // import useValidation from '@/utils/helpers/validations';
  import { useStore } from "vuex";
  import moment from "moment-timezone";

  const store = useStore();
  // const { checkEmailValidation } = useValidation();
  const emit = defineEmits(['refresh']);
  const company_id = localStorage.getItem('currentCompanyId');

  let id = ref(null);
  let name = ref(null);
  let contact_email = ref(null);
  let timezone = ref(null);
  let timeZoneNames = ref([]);
  let isValidEmail = ref(true);
  let isSubmitted = ref(false);
  const getModalInfo = computed(()=> store.getters.getModalInfo);

  onBeforeMount( () => {
    timeZoneNames = moment.tz.names();
    if(getModalInfo.value && getModalInfo.value.data_type == 'site'){
      console.log('getModalInfo', getModalInfo.value);
      id.value = getModalInfo.value.id;
      name.value = getModalInfo.value.name;
      contact_email.value = getModalInfo.value.contact_email;
      timezone.value = getModalInfo.value.timezone;
    }
  });

  const resetData = () => {
    id.value = null;
    name.value = null;
    contact_email.value = null;
    timezone.value = null;
    isValidEmail.value = true;
    isSubmitted.value = false;
  };

  // const validEmail = () => {
  //   isValidEmail.value = checkEmailValidation(contact_email.value);
  //   return isValidEmail.value;
  // };

  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);
  };

  const onSubmit = () => {
    isSubmitted.value = true;
    const data = {
      id : id.value,
      name : name.value, 
      // contact_email :contact_email.value, 
      timezone : timezone.value, 
      company_id 
    };
    
    store.dispatch('editSite', data).then(() => {
      resetData();
      resetStoreData();
      emit('refreshSitesArr');
      emit('refresh');
    }).catch( (e) => {
        isSubmitted.value = false;
        console.log(e)
    });
  };
  
</script>
<template>
  <DModal id="editSiteModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Edit Site </template>
    
    <template #body>
      <div class="form-floating mb-3">
        <input v-model.trim="name" type="text" class="form-control" id="editSideName" placeholder="Site Name" />
        <label for="editSideName">Site Name</label>
      </div>

      <!-- <div class="form-floating mb-3"> 
        <input @input="validEmail" v-model="contact_email"  type="email" :class="{'is-invalid' : !isValidEmail}" class="form-control" id="contactEmail" placeholder="Contact Email" />
        <label for="contactEmail">Contact Email</label> 
        <div v-if="!isValidEmail" class="invalid-feedback">
          Invalid Email.
        </div>
      </div> -->

      <div class="p-relative"> 
        <span v-if="timezone" class="multiselect-title-span">Time Zone</span>
        <VMultiselect v-model="timezone" :options="timeZoneNames" :disabled="!timeZoneNames.length" :loading="!timeZoneNames.length" 
        :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Time Zone"/>
      </div>

    </template>

    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button :disabled="!name || !timezone || isSubmitted" @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">Edit 
            <span v-if="isSubmitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
          <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal"> Cancel </button>
        </div>
      </div>
    </template>
  </DModal>
</template>
