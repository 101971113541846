<script setup>
import { ref, defineProps, onMounted, reactive } from "vue";
import { Modal }  from "bootstrap";
import { useStore } from "vuex";
import useValidation from '@/utils/helpers/validations';

const { checkLoggedInUserRole } = useValidation();
const store = useStore();

const props = defineProps({
  data: Object,
});

let items = ref([]);

const state = reactive({
    editAppModal: null,
    deleteAppModal: null,
});

const action = async (action, data) => {

  await store.commit('setModalAction', action);
  store.commit('setModalState', true);
  await store.commit('setModalData', data);

  if(action == 'edit_app'){
    state.editAppModal = new Modal("#editAppModal", {});
    state.editAppModal.show();
  } else if(action == 'delete_app'){
    state.deleteAppModal = new Modal("#deleteAppModal", {});
    state.deleteAppModal.show();
  }
}

onMounted(() => {

  items.value.push(
    // {
    //   icon : 'bi bi-pencil-square',
    //   text: "Edit App", 
    //   action : 'edit_app',
    //   visible: true,
    //   data : props.data,
    // },
    {
      icon : 'bi bi-trash3',
      text: "Delete",
      action: "delete_app",
      visible: true,
      disabled: !checkLoggedInUserRole() && props.data.can_delete ? false :  true,
      data : props.data,
    },
  );
    // console.log('items', items.value);  
});
</script>

<template>
    <div>
      <button v-for="(item, index) in items" :disabled="item.disabled" :key="index" @click="action(item.action, props.data)" type="button" class="btn action-btn" :title="item.text"><i :class="item.icon"></i></button>
    </div>
</template>