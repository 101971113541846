import CryptoJS from "crypto-js";

const key = process.env.VUE_APP_DAEMON_KEY;
var CryptoJSAesJson = {
  stringify: function (cipherParams) {
    var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
    if (cipherParams.iv) j.iv = cipherParams.iv.toString();
    if (cipherParams.salt) j.s = cipherParams.salt.toString();
    return JSON.stringify(j);
  },
  parse: function (jsonStr) {
    var j = JSON.parse(jsonStr);
    var cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(j.ct),
    });
    if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
    if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
    return cipherParams;
  },
};

export default function useEncryption(){

  function encryptionPassword(password) {

    password = btoa(CryptoJS.AES.encrypt(
      JSON.stringify(password),
      key,
      { format: CryptoJSAesJson }
    ).toString());

    return password;
  }

  function decryptionPassword(password){

    password = CryptoJS.AES.decrypt(atob(password), key, {
      format: CryptoJSAesJson,
    }).toString(CryptoJS.enc.Utf8);

    return password;
  }

  function showPassword(id, selector){
    const passwordField = document.getElementById(id);
    const togglePassword = document.querySelector(selector);

    togglePassword.addEventListener("click", function () {
        if (passwordField.type === "password") {
            passwordField.type = "text";
            togglePassword.classList.remove("bi-eye-slash");
            togglePassword.classList.add("bi-eye");
        } else {
            passwordField.type = "password";
            togglePassword.classList.remove("bi-eye");
            togglePassword.classList.add("bi-eye-slash");
        }
    }); 
  }

  return { encryptionPassword, decryptionPassword, showPassword }
}