<template>
  <div class="actions-item-skeleton-container info-white-section">
    <Skeletor class="info-skeletor" width="50" height="60" as="div" />
    <Skeletor class="info-skeletor" width="50" height="60" as="div" />
    <Skeletor class="info-skeletor" width="50" height="60" as="div" />
    <Skeletor class="info-skeletor" width="50" height="60" as="div" />
  </div>
</template>

<style>
.actions-item-skeleton-container {
  display: flex;
  justify-content: center;
  column-gap: 30px;
  padding: 5px 20px;
}
.info-skeletor{
  border-radius: 5px;
}
</style>
