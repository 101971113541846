<script setup>
  import { ref, defineProps, defineEmits } from "vue";
  const props = defineProps({
    closeModal: Function,
    rolesOptions : Array,
    sitesOptions : Array,
  });
  import useValidation from '@/utils/helpers/validations';
  // import VueCountryCode from "vue-country-code";

  import { useStore } from "vuex";
  const store = useStore();
  const { checkEmailValidation } = useValidation();
  const emit = defineEmits(['refresh', 'refreshSitesArr']);
  const companyId = localStorage.getItem('currentCompanyId');
  let first_name = ref(null);
  let last_name = ref(null);
  let email = ref(null);
  let phone = ref('');
  let country_code = ref('');
  let role = ref(null);
  let sites = ref([]);
  let resources = ref([]);
  let isValidEmail = ref(true);
  let isValidPhone = ref(true);
  let isSubmitted = ref(false);
  let isEmailLoading = ref(false);
  let isPhoneLoading = ref(false);
  let showSitesSecttion = ref(true);
  let emailErrorMessage = ref('');
  let phoneErrorMessage = ref('');

  const resetData = () => {
    first_name.value = null;
    last_name.value = null;
    email.value = null;
    // phone.value = null;
    country_code.value = null;
    sites.value = [];
    resources.value = [];
    role.value = null;
    isValidEmail.value = true;
    isValidPhone.value = true;
    isSubmitted.value = false;
    isEmailLoading.value = false;
    isPhoneLoading.value = false;
    emailErrorMessage.value = null;
    phoneErrorMessage.value = null;
  };

  const validEmail = () => {
    isEmailLoading.value = false;
    emailErrorMessage.value = null;
    isPhoneLoading.value = false;
    isValidEmail.value = checkEmailValidation(email.value);
    // console.log('isValidEmail.value', isValidEmail.value);
    if(isValidEmail.value){
      checkDuplicateField('email', email.value);
    }else{
      emailErrorMessage.value = 'Invalid Email.';
    }
  };

  // const validPhone = () => {
  //   isPhoneLoading.value = true;
  //   phoneErrorMessage.value = null;
  //   isValidPhone.value = true;
  //   if(phone.value){
  //     checkDuplicateField('phone', phone.value);
  //   }
  // }

  const checkDuplicateField = (field, value) => {
    if(field == 'email'){
      isEmailLoading.value = true;
      isValidEmail.value = true;
    }
    // else if(field == 'phone'){
      // isPhoneLoading.value = true;
    // }

    store.dispatch('checkDuplicateField', {
      companyId : companyId,
      params :{
        field : field,
        value : value,
        country_code : field == 'phone' ? country_code.value : null,
      },
    }).then( (res)=> {
      // console.log('res.data', res.data);
      if(field == 'email'){
        isEmailLoading.value = false;
        if(!res.data){
          emailErrorMessage.value = 'Email address already exists, register with a different one.';
          isValidEmail.value = false;
        }else{
          isValidEmail.value = true;
        }
      }
      // else if(field == 'phone'){
      //   isPhoneLoading.value = false;
      //   if(!res.data){
      //     phoneErrorMessage.value = 'Phone already exists, register with a different one.';
      //     isValidPhone.value = false;
      //   }else{
      //     isValidPhone.value = true;
      //   }
      // }
      
    }).catch( (e) => {
      console.log(e)
    });
  }

  const changeCountryCode = (country)=>{
    if(country){
      country_code.value = country.dialCode;
      // if(phone.value) validPhone();
      // console.log('country_code.valueeeeeeeee', country_code.value);
    }
  }

  const changeUserRole = () => {
    // console.log(role.value);
    showSitesSecttion.value = true;
    if(role.value.name == 'Owner') {
      sites.value = [];
      showSitesSecttion.value = false;
    }
  }

  const onSubmit = async () => {
    isSubmitted.value = true;

    await sites.value.map( async (obj) => {
      let newObj = {};
      newObj.type = 'site';
      newObj.value = obj.id;
      resources.value.push(newObj);
    });

    const data = { 
      first_name : first_name.value, 
      last_name : last_name.value, 
      email : email.value, 
      phone : phone.value, 
      country_code : phone.value ? parseInt(country_code.value) : null,
      role_id : role.value.id,
      resources : resources.value,
      companyId 
    };

    store.dispatch('addUser', data).then(() => {
      emit('refresh');
      resetData();
      props.closeModal();
    }).catch( (e) => {
      isSubmitted.value = false;
      console.log(e)
    });
  }
</script>
<template>
  <DModal id="userModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Add User </template>
    
    <template #body>

        <div class="form-floating mb-3">
          <input v-model.trim="first_name" type="text" class="form-control" id="firstName" placeholder="First Name" />
          <label for="firstName">First Name</label>
        </div>

        <div class="form-floating mb-3">
          <input v-model.trim="last_name" type="text" class="form-control" id="lastName" placeholder="Last Name" />
          <label for="lastName">Last Name</label>
        </div>

        <div class="form-floating mb-3"> 
          <input @input="validEmail" v-model.trim="email" type="email" :class="{'is-invalid' : !isValidEmail}" class="form-control" id="emailAddress" placeholder="Email Address" />
          <label for="emailAddress">Email Address</label> 
          <span v-if="isEmailLoading" class="spinner-border spinner-border text-success pull-right inputSpinner" role="status" aria-hidden="true"></span>
          <div v-if="!isValidEmail" class="invalid-feedback" v-text="emailErrorMessage"></div>
        </div>

        <div class="form-phone mb-3">
          <div class="phone-label d-flex align-items-center">Phone Number (Optional)</div>
          <vue-tel-input v-model="phone" @country-changed="changeCountryCode" placeholder="Phone Number (Optional)" :class="{'is-invalid' : !isValidPhone}" class="form-control" id="phoneNumber"></vue-tel-input>
          <!-- <input v-model="phone" @input="validPhone" type="text" :class="{'is-invalid' : !isValidPhone}" class="form-control" id="phoneNumber" placeholder="Phone Number" /> -->
          <!-- <label for="phoneNumber">Phone Number (Optional)</label> -->
          <span v-if="isPhoneLoading" class="spinner-border spinner-border text-success pull-right inputSpinner" role="status" aria-hidden="true"></span>
          <div v-if="!isValidPhone" class="invalid-feedback" v-text="phoneErrorMessage"></div>
        </div>

        <div class="p-relative mb-3">
          <span v-if="role" class="multiselect-title-span">Role</span>
          <VMultiselect @select="changeUserRole" v-model="role" :options="props.rolesOptions" :disabled="!props.rolesOptions.length"
          :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Role" trackBy="id" label="name"
          />
        </div>

        <div v-if="showSitesSecttion" class="p-relative mb-3">
          <span v-if="sites.length" class="multiselect-title-span">Sites</span>
          <VMultiselect :class="{'multiselect-options' : sites.length}" v-model="sites" :options="props.sitesOptions" :disabled="!props.sitesOptions.length" :multiple="true"
            :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Sites" trackBy="id" label="name"
          />
        </div>
    
    </template>

    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button :disabled="!first_name || !last_name || !email || !role || (!sites.length && showSitesSecttion) || !isValidEmail || !isValidPhone || isSubmitted" @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">Add 
            <span v-if="isSubmitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
          <button @click="resetData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal"> Cancel </button>
        </div>
      </div>
    </template>
  </DModal>
</template>
