<script setup>
  import { ref, onBeforeMount, computed, defineProps, defineEmits } from "vue";
  import { useStore } from "vuex";
  const props = defineProps({
    closeModal: Function,
  });
  const emit = defineEmits(["updateCompany"]);

  const store = useStore();
  const currentCompany = computed(()=> JSON.parse(localStorage.getItem("currentCompany")));
  let id = ref(null);
  let name = ref(null);
  let isSubmitted = ref(false);

  const onSubmit = () => {
    isSubmitted.value = true;
    const data = {
      id: id.value,
      name : name.value,
    };

    store.dispatch("editCompany", data).then(async (res) => {
        await localStorage.setItem('currentCompany', JSON.stringify(res.data));
        await store.commit('setCurrentCompany', JSON.stringify(res.data));

        isSubmitted.value = false;
        emit('updateCompany');
        props.closeModal();
    }).catch((e) => {
      console.log(e);
      isSubmitted.value = false;
    });
  };

  onBeforeMount( () => {
    if(currentCompany.value){
      name.value = currentCompany.value.name;
      id.value = currentCompany.value.id;
    }
  });
</script>

<template>
  <DModal id="editCompanyModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Edit Company </template>
    <template #body>
      <div class="form-floating mb-3">
        <input type="text" v-model="name" class="form-control" id="companyName" placeholder=""/>
        <label for="companyName">Company Name</label>
      </div>

      <!-- <p class="delete-text"><b>Note:</b> To change the area’s parent, click the action “Move Area” from the “Sites & Devices” page.    </p> -->

    </template>
    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button :disabled="!name || isSubmitted"
            @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
            Edit <span v-if="isSubmitted"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
          </button>
          <button type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </template>
  </DModal>
</template>
