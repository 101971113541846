<script setup>
  import { ref, defineProps, defineEmits, onBeforeMount, computed, onMounted } from "vue";
  import { useStore } from "vuex";
  import "leaflet/dist/leaflet.css";
  import * as L from 'leaflet';
  import 'leaflet.markercluster/dist/MarkerCluster.css';
  import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
  import "leaflet.markercluster";
  import 'leaflet-geosearch/dist/geosearch.css';

  import icon from '@/assets/images/location_icon.png'
  const markers = L.markerClusterGroup();

  const props = defineProps({
    sitesOptions : Array,
  });
  
  const store = useStore();
  const emit = defineEmits(["refresh"]);
  const company_id = localStorage.getItem("currentCompanyId");
  const site = ref(null);
  const parent_area = ref(null);
  let parentAreas = ref([]);
  let description = ref(null);
  let name = ref(null);
  let latitude = ref(null);
  let longitude = ref(null);
  let isSubmitted = ref(false);
  let isLoadingParentAreas = ref(false);
  const getModalInfo = computed(()=> store.getters.getModalInfo);
  const getModalAction = computed(()=> store.getters.getModalAction);
  import * as GeoSearch from 'leaflet-geosearch';


  let addFromSiteActions = ref(false)
  const initialMap = ref(null);

  const myIcon = L.icon({
    iconUrl: icon,
    iconSize: [30, 30],
    iconAnchor: [15, 30],
    popupAnchor: [-3, -76],
    shadowSize: [60, 30],
    shadowAnchor: [22, 94]
  });

  const changeSiteVal = () => {
    parent_area.value = null
    parentAreas.value = [];
    // console.log('site', site.value);
    if(site.value){
      isLoadingParentAreas.value = true;
      store.dispatch("fetchParentAreas", {
        siteId :site.value.id,
        companyId : company_id,
      }).then((res) => {
        // console.log('res', res.data);
        parentAreas.value = res.data;
        isLoadingParentAreas.value = false;
      });
    }
  }

  const resetData = () => {
    site.value = null;
    name.value = null;
    latitude.value = null;
    longitude.value = null;
    description.value = null;
    parent_area.value = null;
    parentAreas.value = [];
    isLoadingParentAreas.value = false;
    isSubmitted.value = false;
  };

  const onSubmit = () => {
    isSubmitted.value = true;
    const data = {
      name: name.value,
      latitude : latitude.value.toString(),
      longitude : longitude.value.toString(),
      description: description.value,
      site_id: site.value.id,
      parent_id: parent_area.value ? parent_area.value.id : null,
      company_id,
    };

    store.dispatch("addArea", data).then(() => {
      isSubmitted.value = false;
      resetStoreData();
      resetData();
      emit("refresh");
    }).catch((e) => {
      console.log(e);
      isSubmitted.value = false;
    });
  };

  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);
  };

  const pickLocation = (e) => {
    console.log('e', e);

    markers.clearLayers();

    let marker = new L.marker([e.latlng.lat , e.latlng.lng], {icon: myIcon})
    .bindTooltip(`lat: ${e.latlng.lat} <br> long: ${e.latlng.lng}`)
    .bindPopup(`lat: ${e.latlng.lat} <br> long: ${e.latlng.lng}`)
    .addTo(initialMap.value);


    markers.addLayer(marker);
    initialMap.value.addLayer(markers);

    initialMap.value.latLngToLayerPoint(marker.getLatLng());


    latitude.value = e.latlng.lat;
    longitude.value = e.latlng.lng;
  }

  onMounted(()=> {
    if(getModalAction.value == 'add_area' || getModalAction.value == 'add_area_with_site'){
      setTimeout(() => {
        initialMap.value = L.map('mapArea', {zoomControl: true,zoom:1,zoomAnimation:false,fadeAnimation:true,markerZoomAnimation:true}).setView([0, 0], 15);

        const search = new GeoSearch.GeoSearchControl({
          provider: new GeoSearch.OpenStreetMapProvider(),
        });

        initialMap.value.addControl(search);

        navigator.geolocation.getCurrentPosition(function (position) {
          var latitude = position.coords.latitude;
          var longitude = position.coords.longitude;

          // Center the map on the user's location
          initialMap.value.setView([latitude, longitude], 15);
            
        }, (error) => { // location access denied
            console.error('Error getting user location:', error);
        });
        initialMap.value.on('click', pickLocation);
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
        }).addTo(initialMap.value);
      },500);
    }
    
  });

  onBeforeMount(() => {
    if(getModalInfo.value && getModalAction.value == 'add_area_with_site'){
      console.log('getModalInfo', getModalInfo.value);
      addFromSiteActions.value = true;
      props.sitesOptions.map((obj) => {
        if(obj.id == getModalInfo.value.id){
          site.value = obj;
        }
      })

      store.dispatch("fetchParentAreas", {
        siteId :site.value.id,
        companyId : company_id,
      }).then((res) => {
        // console.log('res', res.data);
        parentAreas.value = res.data;
        isLoadingParentAreas.value = false;
      });
    }
  });
</script>

<template>
  <DModal id="areaModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Add Area </template>

    <template #body>
      <div class="mb-3">
      <span class="map-hint">Please pick up the area location on the map</span>
      </div>

      <div class="map-card p-0 mb-3">
        <div id="mapArea" style="height:34vh;"></div>
      </div>

      <div class="p-relative mb-3">
        <span v-if="site" class="multiselect-title-span">Site</span>
        <VMultiselect @select="changeSiteVal" @remove="changeSiteVal" v-model.trim="site" :options="props.sitesOptions" :disabled="!props.sitesOptions.length || addFromSiteActions "
          :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Site" trackBy="id" label="name"
        />
      </div>

      <div class="form-floating mb-3">
        <input type="text" v-model.trim="name" class="form-control" id="areaName" placeholder=""/>
        <label for="areaName">Area Name</label>
      </div>

      <div class="form-floating mb-3">
        <textarea v-model="description" class="form-control none-resize h-105" placeholder="description" id="description"/>
        <label for="description">Description</label>
      </div>

      <div class="p-relative">
        <span v-if="parent_area" class="multiselect-title-span" >Parent Area</span>
        <VMultiselect v-model="parent_area" :options="parentAreas" trackBy="id" label="name"
          :disabled="!site || isLoadingParentAreas" :loading="isLoadingParentAreas" :searchable="true"
          :close-on-select="true" :show-labels="false" placeholder="Parent Area"
        />
      </div>
    </template>
    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button :disabled="!site || !name || !latitude || !longitude || isSubmitted"
            @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
            Add <span v-if="isSubmitted"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
          </button>
          <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </template>
  </DModal>
</template>
