import Api from "./Api";

export default {
    getJobs(payload) {
        return Api().post(`/jobs/${payload.companyId}`, payload.payload);
    },
    getJobsList(companyId) {
        return Api().get(`/list-jobs/${companyId}`);
    },
    addJob(payload) {
        return Api({ hasFile: true }).post('/jobs', payload);
    },
    editJob(payload) {
        return Api({ hasFile: true }).post(`/job/${payload.id}`, payload.payload);
    },
    getJob(payload) {
        return Api().get(`/job/${payload.companyId}/${payload.id}`);
    },
    getJobsActions(companyId) {
        return Api().get(`/available-actions /${companyId}`);
    },
    getAllFilesCompany(companyId) {
        return Api().get(`/files/${companyId}`);
    },
    moveJob(payload) {
        return Api().put(`/move_job/${payload.id}`, payload);
    },
    applyJob(payload) {
        return Api().post(`/apply_job`, payload);
    },
    duplicateJob(payload) {
        return Api().post(`/duplicate-job`, payload);
    },
    deleteJob(payload) {
        return Api().delete(`/job/${payload.companyId}/${payload.id}`);
    },
};