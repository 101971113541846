import Api from "./Api";

export default {

    createFolder(payload) {
        return Api().post('/job_folder', payload);
    },
    editFolder(payload) {
        return Api().put(`/job_folder/${payload.company_id}/${payload.id}`, payload);
    },
    deleteFolder(payload) {
        return Api().delete(`/job_folder/${payload.companyId}/${payload.id}`);
    },

};