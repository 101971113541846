<script setup>
    import { ref, computed, reactive, onMounted, onBeforeMount } from "vue";
    import DefaultLayout from "./layouts/DefaultLayout.vue";
    import editCompany from "../components/company/editCompany.vue";
    import { Modal }  from "bootstrap";
    import { useStore } from "vuex";
    import CompanySkeleton from "./partials/CompanyDetailsSkeleton.vue"

    const store = useStore();
    let currentCompany = computed(()=> JSON.parse(localStorage.getItem("currentCompany")));
    // let manageSubscriptionUrl = computed(()=> '<a href="javascript:void(0)" data-cb-type="portal" class="none-underline login-text">Manage Subscription</a>');
    
    let generatedToken = ref('');
    let copyText = ref('Copy to clipboard');
    let copyToClipboardIcon = ref('bi bi-copy');
    let isLoading = ref(false);
    let name = ref('');
    let manageSubscritionLink = ref('');

    let plan = ref('-');
    let period = ref('-');
    let devicesNumber = ref('-');
    let startedAt = ref('-');
    let nextBillingAt = ref('-');
    let status = ref('-');
    let totalAmount = ref('-');
    let loadingStatus = ref(true);

    const state = reactive({
        editCompanyModal: null,
    });

    const editCompanyModal = (type) => {
        if (type == "show") state.editCompanyModal.show();
        else state.editCompanyModal.hide();
    };

    const generateManageSubscriptionUrl = () => {
        store.dispatch("manageSubscription", 
        {
            companyId : currentCompany.value.id,
            chargebeeId : currentCompany.value.chargebee_id,
        }).then((res) => {
            console.log('res', res);
            // window.open(res.data,"_blank");
            manageSubscritionLink.value = res.data;
            setTimeout(() => {
                let manageSubscritionLinkId = document.getElementById('manageSubscritionLinkId');
                console.log('manageSubscritionLinkId', manageSubscritionLinkId);
                manageSubscritionLinkId.click();
            },100)
        }).catch((e)=> console.log(e));
    };

    // const generateToken = () => {
    //     isLoading.value = true;
    //     store.dispatch('generateToken', currentCompany.value.id)
    //     .then((res) => {
    //         console.log('res', res);
    //         generatedToken.value = res.data;
    //         isLoading.value = false;
    //     }).catch( (e) => {
    //         console.log(e)
    //     });
    // }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(generatedToken.value);
        copyToClipboardIcon.value = 'bi bi-check';
        copyText.value = 'Copied';
        setTimeout(()=>{
            copyToClipboardIcon.value = 'bi bi-copy';
            copyText.value = 'Copy to clipboard';
        },1000)
    }

    const updateCompanyData = () => {
        currentCompany = computed(()=> JSON.parse(localStorage.getItem("currentCompany")));
        name.value = currentCompany.value.name;
    }

    onMounted(async () => {
        state.editCompanyModal = new Modal("#editCompanyModal", {});
    });

    onBeforeMount(() =>{
        name.value = currentCompany.value.name;

        store.dispatch("fetchSubscriptionInfo", currentCompany.value.id).then((res) => {
            console.log('res', res);
            if(res.data){
                plan.value = res.data.plan ?? '-';
                period.value = res.data.billing_period_unit ?? '-';
                devicesNumber.value = res.data.devices ?? '-';
                startedAt.value = res.data.started_at ?? '-';
                nextBillingAt.value = res.data.next_billing_at ?? '-';
                status.value = res.data.status ?? '-';
                totalAmount.value = res.data.total_amount ?? '-';
            }
        }).catch((e)=> console.log(e))
        .finally(()=>loadingStatus.value = false);
    })

</script>

<template>
    <DefaultLayout>
        <div class="main-page-container-animation">

            <editCompany @updateCompany="updateCompanyData" :closeModal="editCompanyModal"/>
            <div class="companies-card details-card">
                <span class="d-flex align-items-center justify-content-between">
                    <h3 v-if="!loadingStatus" class="card-title">{{ name }}
                        <button @click="editCompanyModal('show')" title="Edit" pill class="btn btn-success btn-sm ml-20"><i class="bi bi-pencil-square"></i></button>
                        <!-- <button :disabled="isLoading" @click="generateToken" pill class="btn btn-success btn-sm ml-20">Generate Token
                            <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button> -->
                    </h3>
                    <template v-else>
                        <CompanySkeleton :isSingle="true" :width="200" as="h3"/>
                    </template>

                    <div v-if="!loadingStatus">
                        <a @click="generateManageSubscriptionUrl" href="javascript:void(0)" class="none-underline login-text">Manage Subscription</a>
                        <a class="d-none" id="manageSubscritionLinkId" :href="manageSubscritionLink"></a>
                    </div>
                    <template v-else>
                        <CompanySkeleton :isSingle="true" :width="200" as="h3"/>
                    </template>
                </span>
                <div class="company-details mt-50 d-flex">
                    <div class="w-50">
                        <div v-if="generatedToken && !isLoading" class="input-group mb-3">
                            <input readonly type="text" class="form-control" :placeholder="generatedToken" aria-label="Generated Token" aria-describedby="button-addon2">
                            <button @click="copyToClipboard" :title="copyText" class="btn action-btn " type="button" id="button-addon2"><i :class="copyToClipboardIcon"></i></button>
                        </div>
                        

                        <div v-if="!loadingStatus">
                            <p class="company-info-header">Plan</p>
                            <p class="company-info-content">{{ plan }}</p>                        
                        </div>

                        <div v-else class="d-flex flex-column">
                            <CompanySkeleton />
                        </div>

                        <div class="setting-seperator text-center"></div>

                        <div v-if="!loadingStatus">
                            <p class="company-info-header">Billing Period </p>
                            <p class="company-info-content">{{ period }}</p>
                        </div>

                        <div v-else class="d-flex flex-column">
                            <CompanySkeleton />
                        </div>

                        <div class="setting-seperator text-center"></div>

                        <div v-if="!loadingStatus">
                            <p class="company-info-header">Number of Devices</p>
                            <p class="company-info-content">{{ devicesNumber }}</p>
                        </div>
                        <div v-else class="d-flex flex-column">
                            <CompanySkeleton />
                        </div>

                        <div class="setting-seperator text-center"></div>

                        <div v-if="!loadingStatus">
                            <p class="company-info-header">Started At</p>
                            <p class="company-info-content">{{ startedAt }}</p>
                        </div>
                        <div v-else class="d-flex flex-column pb-3">
                            <CompanySkeleton />
                        </div>
                        
                    </div>
                    <div class="w-50">
                        <div v-if="generatedToken && !isLoading" class="input-group mb-3">
                            <input readonly type="text" class="form-control" :placeholder="generatedToken" aria-label="Generated Token" aria-describedby="button-addon2">
                            <button @click="copyToClipboard" :title="copyText" class="btn action-btn " type="button" id="button-addon2"><i :class="copyToClipboardIcon"></i></button>
                        </div>

                        <div v-if="!loadingStatus">
                            <p class="company-info-header">Next Billing </p>
                            <p class="company-info-content">{{ nextBillingAt }}</p>
                        </div>
                        <div v-else class="d-flex flex-column">
                            <CompanySkeleton />
                        </div>

                        <div class="setting-seperator text-center"></div>


                        <div v-if="!loadingStatus">
                            <p class="company-info-header">Status</p>
                            <p class="company-info-content">{{ status }}</p>
                        </div>
                        <div v-else class="d-flex flex-column">
                            <CompanySkeleton />
                        </div>

                        <div class="setting-seperator text-center"></div>

                        <div v-if="!loadingStatus">
                            <p class="company-info-header">Total Amount</p>
                            <p class="company-info-content">{{ totalAmount }}</p>
                        </div>
                        <div v-else class="d-flex flex-column">
                            <CompanySkeleton />
                        </div>


                        <div class="setting-seperator text-center"></div>

                    </div>
                </div>
            </div>
        </div>
    </DefaultLayout>
</template>