import { computed } from "vue";
import { io } from "socket.io-client";

let companyId = computed(()=>localStorage.getItem("currentCompanyId"));
let user = computed(()=> JSON.parse(localStorage.getItem("user")));

const socket = io(`${process.env.VUE_APP_SCREEN_PULSE_SOCKET_URL}`, {
    query: `isFront=true&token=${user.value?.access_token}&companyId=${companyId.value}`,
    transports: ['websocket'] // Force WebSocket transport
});

socket.on("connect", () => {
    console.log('socket is connected');
});

socket.emit('joinRoom', companyId.value);

socket.on("disconnect", () => {
    console.log('socket is disconnected');
});


export { socket}; 