<script setup>
  import { ref, onBeforeMount, defineEmits, computed } from "vue";
  import { useStore } from "vuex";
  import moment from "moment-timezone";

  const getModalInfo = computed(()=> store.getters.getModalInfo);
  const getModalAction = computed(()=> store.getters.getModalAction);

  const store = useStore();
  const emit = defineEmits(["refresh"]);
  const companyId = localStorage.getItem("currentCompanyId");

  let device_name = ref(null);
  let time_zone = ref(null);
  let timeZoneNames = ref([]);
  let isSubmitted = ref(false);
  // let brightnessPercentage = ref(0);
  // let volumePercentage = ref(0);
  let system_fan = ref(true);
  let show_status_bar = ref(false);
  let schedule_reboot = ref(false);
  let schedule_reboot_time = ref({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  let brightness = ref(null);
  let volume = ref(null);
  let settingsArr = ref([]);

  const onSubmit = () => {
    isSubmitted.value = true;
    let scheduleRebootTimeString = moment(schedule_reboot_time.value).format('HH:mm:ss');
    // const data = {
    //   id : getModalInfo.value.id,
    //   data_type : getModalInfo.value.data_type,
    //   _name: kiosk_name.value,
    //   time_zone : time_zone.value,
    //   system_fan : system_fan.value ? 1 : 0,
    //   show_status_bar : show_status_bar.value ? 1 : 0, 
    //   schedule_reboot : schedule_reboot.value ? 1 : 0,
    //   schedule_reboot_time : scheduleRebootTimeString,
    //   brightness : brightness.value,
    //   volume :volume.value,
    //   companyId,
    // };

    settingsArr.value.map((obj)=>{
      if(obj.name == 'device_name'){
        obj.value = device_name.value;
      }else if(obj.name == 'system_fan'){
        obj.value =  system_fan.value ? 1 : 0 ;
      }else if(obj.name == 'schedule_reboot'){
        obj.value =  schedule_reboot.value ? 1 : 0 ;
      }else if(obj.name == 'show_status_bar'){
        obj.value =  show_status_bar.value ? 1 : 0 ;
      }else if(obj.name == 'schedule_reboot_time'){
        obj.value = scheduleRebootTimeString;
      }else if(obj.name == 'brightness'){
        obj.value = brightness.value;
      }else if(obj.name == 'volume'){
        obj.value = volume.value;
      }else if(obj.name == 'time_zone'){
        obj.value = time_zone.value;
      }
    });

    const data = {
      id : getModalInfo.value.id,
      settings : settingsArr.value,
      companyId,
    };

    store.dispatch("updateDeviceSettings", data)
    .then(() => {
      // console.log('res', res);
      resetStoreData();
      emit("refresh");
    }).catch((e) => {
      isSubmitted.value = false;
      console.log(e);
    }); 
  };

  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);
  };

  onBeforeMount( async() => {
    timeZoneNames = moment.tz.names();
    if(getModalInfo.value && getModalInfo.value.data_type == 'device' && getModalAction.value == 'device_settings'){
      console.log('getModalInfo', getModalInfo.value);
      if(getModalInfo.value.settings && getModalInfo.value.settings.length){
        await getModalInfo.value.settings.map( async (obj) => {
          if(obj.name == 'system_fan'){
            system_fan.value = await parseInt(obj.pivot.value) == 1 ? true : false;
          }else if(obj.name == 'schedule_reboot'){
            schedule_reboot.value = await parseInt(obj.pivot.value) == 1 ? true : false;
          }else if(obj.name == 'show_status_bar'){
            show_status_bar.value = await parseInt(obj.pivot.value) == 1 ? true : false;
          }else if(obj.name == 'device_name'){
            device_name.value = await obj.pivot.value
          }else if(obj.name == 'schedule_reboot_time'){
            let scheduleRebootTime = obj.pivot.value.split(":");
            schedule_reboot_time.value = await {
              hours: scheduleRebootTime[0],
              minutes: scheduleRebootTime[1],
              seconds: scheduleRebootTime[2],
            }
          }else if(obj.name == 'brightness'){
            brightness.value = await parseInt(obj.pivot.value)
          }else if(obj.name == 'volume'){
            volume.value = await parseInt(obj.pivot.value)
          }else if(obj.name == 'time_zone'){
            time_zone.value = await obj.pivot.value
          }
          let newObj = {};
          newObj.setting_id = obj.id;
          newObj.name = obj.name;
          newObj.value = obj.pivot.value;
          settingsArr.value.push(newObj);
        });
      }
    }
  });
</script>

<template>
  <DModal id="deviceSettingsModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Device Settings </template>

    <template #body>
      <div class="d-flex justify-content-between align-items-center mb-3 text-center">
        <div class="first-one" style="width: 85px!important;">
          <label class="form-check-label text" for="systemFanSwitch" style="width: 85px!important;">System Fan</label>
          <div class="form-check form-switch mt-24 d-flex">
            <input v-model="system_fan" class="form-check-input" type="checkbox" role="switch" id="systemFanSwitch"/>
            </div>
          </div>
          <div class="line-between"></div>
          <div class="second-one" style="width: 125px!important;">
            <label class="form-check-label text" for="scheduleRebootSwitch" style="width: 125px!important;">Schedule Reboot</label>
            <div class="form-check form-switch mt-24 d-flex">
              <input v-model="schedule_reboot" class="form-check-input" type="checkbox" role="switch" id="scheduleRebootSwitch" />
            </div>
          </div>
          <div class="line-between"></div>
          <div class="last-one" style="width: 115px!important;">
            <label class="form-check-label text" for="hideStatusBarSwitch" style="width: 115px!important;" >Hide Status Bar</label >
            <div class="form-check form-switch mt-24 d-flex">
              <input v-model="show_status_bar" class="form-check-input" type="checkbox" role="switch" id="hideStatusBarSwitch" />
            </div>
          </div>
        </div> 
    
      <div class="seperator text-center"></div>

      <div class="form-floating mb-3 mt-20">
        <input  v-model="device_name" type="text" class="form-control" id="kioskName" placeholder="Device Name"/>
        <label for="kioskName">Device Name</label>
      </div>

      <div class="p-relative">
        <span v-if="time_zone"  :class="!timeZoneNames.length || time_zone?'disabled-span-multiselect':'multiselect-title-span'" >Time Zone</span>

        <VMultiselect v-model="time_zone" :options="timeZoneNames" :searchable="true"
          :disabled="!timeZoneNames.length || time_zone ? true : false" :loading="!timeZoneNames.length" 
          :close-on-select="true" :show-labels="false" placeholder="Time Zone"
        />
      </div>

      <div class="d-flex align-items-center justify-content-between" style="margin-top: 14px;">
        <p class="mb-0 ml-20  reboot-time">Reboot Time</p>
        <div class="timepacker">
          <VueDatePicker v-model="schedule_reboot_time" time-picker auto-apply format="HH:mm:ss" enable-seconds :clearable="true">
            <template #input-icon>
              <i class="input-slot-image">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path :fill=" schedule_reboot_time ? '#2D8425' : '#787878'" d="M8.75 0C3.9375 0 0 3.9375 0 8.75C0 13.5625 3.9375 17.5 8.75 17.5C13.5625 17.5 17.5 13.5625 17.5 8.75C17.5 3.9375 13.5625 0 8.75 0ZM11.8125 10.5C11.55 10.9375 11.025 11.025 10.5875 10.85L8.3125 9.5375C8.05 9.3625 7.875 9.1 7.875 8.75V4.375C7.875 3.85 8.225 3.5 8.75 3.5C9.275 3.5 9.625 3.85 9.625 4.375V8.225L11.4625 9.275C11.9 9.5375 11.9875 10.0625 11.8125 10.5Z" />
                  </svg>
              </i>
            </template>
            <template #clear-icon="{ clear }">
              <span @click="clear" >
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" >
                  <path fill="#F46A6A"
                    d="M11 0C4.91333 0 0 4.91333 0 11C0 17.0867 4.91333 22 11 22C17.0867 22 22 17.0867 22 11C22 4.91333 17.0867 0 11 0ZM16.5978 14.7156C17.1111 15.2289 17.1111 16.06 16.5978 16.5733C16.3289 16.8422 16.0111 16.9644 15.6689 16.9644C15.3267 16.9644 14.9844 16.8422 14.74 16.5733L11.0733 12.9067L7.38222 16.5733C7.11333 16.8422 6.79556 16.9644 6.45333 16.9644C6.11111 16.9644 5.76889 16.8422 5.52444 16.5733C5.01111 16.06 5.01111 15.2289 5.52444 14.7156L9.21556 11.0244L5.52444 7.35778C5.01111 6.84444 5.01111 6.01333 5.52444 5.5C6.03778 4.98667 6.86889 4.98667 7.38222 5.5L11.0733 9.19111L14.7644 5.5C15.2778 4.98667 16.1089 4.98667 16.6222 5.5C17.1356 6.01333 17.1356 6.84444 16.6222 7.35778L12.9311 11.0489L16.5978 14.7156Z"
                  />
                </svg>
              </span>
          </template>
          </VueDatePicker>

        </div>
      </div>

      <div class="seperator text-center" style="margin-bottom:40px"></div>

      <div class="d-flex align-items-center justify-content-center mb-3" >
        <label for="brightnessRange" class="form-label brightness">Brightness</label>
        <div class="ms-auto percentage-box">
          <p class="percentege">{{ brightness }}%</p>
        </div>
      </div>
      <input type="range" class="form-range mt-44" id="brightnessRange" v-model="brightness"/>

      <div class="d-flex align-items-center justify-content-center mb-3">
        <label for="volumeRange" class="volume">Volume</label>
        <div class="ms-auto percentage-box">
          <p class="percentege">{{ volume }}%</p>
        </div>
      </div>
      <input type="range" class="form-range mt-44" id="volumeRange" v-model="volume"/>
    </template>

    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button :disabled="!device_name || !time_zone || isSubmitted"
            @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
            Save <span v-if="isSubmitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
          <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">
            Cancel
          </button>
        </div>
      </div>
    </template>
  </DModal>
</template>


