<script setup>
    import { ref, computed } from "vue";
    import DefaultLayout from "@/views/layouts/DefaultLayout.vue";
    import { useStore } from "vuex";

    import {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxPager,
        DxSorting,
        DxScrolling,
        DxLoadPanel,
        DxSearchPanel,
        DxMasterDetail,
    } from 'devextreme-vue/data-grid';
    import CustomStore from 'devextreme/data/custom_store';
    import 'whatwg-fetch';
    import Api from "../services/Api";
    import moment from "moment";
    const companyId = computed(()=>localStorage.getItem("currentCompanyId"));
    const store = useStore();
    const dataGridRefName = ref(null);

    // const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';

    const dataGrid = computed(()=>{
        return dataGridRefName.value.instance;
    });

    const customStore = new CustomStore({
        key: 'id',
        async load(loadOptions) {
            // console.log('loadOptions', loadOptions);
            let searchText = null;
            let sortText = 'desc';
            if(loadOptions.filter && loadOptions.filter[0]){
                searchText = loadOptions.filter[0].filterValue;
            }
            if(loadOptions.sort && loadOptions.sort[0]){
                sortText = loadOptions.sort[0].desc ? 'desc' : 'asc';
            }
            // console.log('searchText', searchText);
            const queryObject = {
                "skip": loadOptions.skip,
                "take": loadOptions.take  ,
                "search": searchText,
                "sort": sortText,
            }

            // console.log('queryObject', queryObject);

            try {
                const result = await Api().post(`/inbox/${companyId.value}`, queryObject);
                // console.log('result', result);

                await result.data.data.map( (row) => {
                    row.receivedAt = moment(row.created_at).utc().format('D/M/Y hh:mm A')
                })

                return {
                    data: result.data.data,
                    totalCount: result.data.total,
                    // summary: result.summary,
                    // groupCount: result.groupCount,
                };
            } catch (err) {
                console.log('err', err);
            }
        },
    });

    // const onClickedRowClicked = (e) => {
    //     console.log('e', e);
        // fetchMessagesDetails(e.data?.id);
    // }

    const rowPrepared = (e) => {
        // console.log('e', e);
        if(e.data && e.data.is_read === 0) {
            e.rowElement.classList.add('unread-row');
        }
    }

    const rowExpanded = (e) => {
        console.log('e', e);
        fetchMessagesDetails(e.key);
    }


    const fetchMessagesDetails = (id) => {
        store.dispatch("fetchMessagesDetails",{companyId : companyId.value, id} )
        .then((res) => {
            console.log('res', res.data);
            dataGrid.value.refresh();
        })
        .catch((e)=> {
            console.log(e);
        });
    }

</script>

<template>
    <DefaultLayout>
        <div class="d-flex align-items-center">
            <h4 class="grid-header-title">Inbox</h4>
        </div>

        <section class="grid-white-section main-page-container-animation">

            <DxDataGrid :data-source="customStore" ref="dataGridRefName" :remote-operations="true"
            :showBorders="false" :columnAutoWidth="true" :allowColumnReordering="true" :allowColumnResizing="true"
            :rowAlternationEnabled="false" :showColumnLines="false" :showRowLines="true" :repaint-changes-only="true"
            :two-way-binding-enabled="true" :no-data-text="'No messages yet'" @row-expanded="rowExpanded"
            @row-prepared="rowPrepared" 
            >

                <DxPaging :page-size="10" />
                <DxPager :visible="true" :allowed-page-sizes="[10, 25, 50, 100]"
                :display-mode="'full'" :show-page-size-selector="true"
                :show-info="true" :show-navigation-buttons="true"/>
                <DxSorting mode="single" />
                <DxScrolling mode="standard" :use-native="true" />
                <DxLoadPanel :show-indicator="true" :show-pane="true" :enabled="true"/>

                <DxSearchPanel :width="200" :visible="true" placeholder="Search ..."/>

                <DxColumn data-field="title" caption="Title" data-type="string" :allow-sorting="false" />
                <DxColumn data-field="content" caption="Content" data-type="string" :allow-sorting="false" />
                <DxColumn data-field="receivedAt" caption="Received at" data-type="string" />

                <DxMasterDetail :enabled="true" template="masterDetailTemplate" />

                <template #masterDetailTemplate="{ data }">
                    <div>
                        <span>{{data.data.content}}</span>
                    </div>
                </template>
                

            </DxDataGrid>
        </section>
       
    </DefaultLayout>
</template>