<template>
  <Responsive class="w-full">
    <template #main="{ width }">
      <Chart
        direction="circular"
        :size="{ width, height: 200 }"
        :data="data"
        :margin="{
          left: Math.round((width - 200) / 2),
          top: 40,
          right: 10,
          bottom: 22,
        }"
        :config="{ controlHover: false }"
      >
        <template #layers>
          <Pie
            :dataKeys="['name', 'value']"
            :pie-style="{ innerRadius: 100, padAngle: 0.05 }"
          />
        </template>
        <template #widgets>
          <!-- <Tooltip
            :config="{
              name: {label:'Name'},
              value: { label: 'Value' },
            }"
            hideLine
          /> -->
        </template>
      </Chart>
    </template>
  </Responsive>
</template>

<script setup>
import { ref, defineProps } from "vue";
import { Chart, Responsive, Pie } from "vue3-charts"; //Tooltip

const props = defineProps({
  diskData: {},
});

const data = ref([
  { name: "Available", value: props.diskData?.available },
  { name: "Used", value: props.diskData?.used },
]);
</script>

<style>
  .axis{
    display: none;
  }
  .w-full{
    width: 100%;
  }
</style>