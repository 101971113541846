import router from "@/router";
import axios from 'axios';

export default ({ daemonRequest = false, hasFile = false } = {}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.access_token;
  const url =  daemonRequest ? process.env.VUE_APP_SCREEN_PULSE_SOCKET_URL : process.env.VUE_APP_BASE_URL;
  const options = {
    baseURL : url+'/api/',
    headers: {
      "Accept": "application/json",
      "Content-type": hasFile ? "multipart/form-data" : "application/json",
      "Authorization" : "Bearer "+token,
    }
  };
  const instance = axios.create(options);
  instance.interceptors.response.use(response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response);
    }
  }, error => {
    if (error && error.response && error.response.status) {
      if(error.response && error.response.data  && !error.response.data.success  && error.response.data.locked ){
        router.push("/company-details");
      }
      switch (error.response.status) {
        case 400:
          //do something
          break;
        case 401:
          localStorage.clear();
          router.push("/login");
          break;
        case 403:
          break;
        case 404:
          router.push("/404");
          break;
        case 502:
          alert(error.response);
          alert("502 Server Erorr Please Call Administration : ",error.response);
          break;
          case 500:
          alert("500 Server Erorr Please Call Administration : ",error.response);
          break;
          default:
      }
      return Promise.reject(error.response);
    }
  });
  return instance;
};
