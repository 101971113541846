<script setup>
  import { ref, defineProps, defineEmits, onBeforeMount, computed } from "vue";
  import { useStore } from "vuex";

  const props = defineProps({
    sitesOptions : Array,
  });

  const emit = defineEmits(['refresh']);
  const store = useStore();
  const company_id = localStorage.getItem("currentCompanyId");
  const getModalInfo = computed(()=> store.getters.getModalInfo);

  let site = ref(null);
  let area = ref(null);
  let id = ref(null);
  let isLoadingAreas = ref(null);
  let areasOptions = ref([]);

  let isSubmitted = ref(false);

  const changeSiteVal = () => {
    area.value = null
    areasOptions.value = [];
    // console.log('site', site.value);
    if(site.value){
      isLoadingAreas.value = true;
      store.dispatch("fetchParentAreas", {
        siteId :site.value.id,
        companyId : company_id,
      }).then((res) => {
        // console.log('res', res.data);
        if(res.data && res.data.length){
          areasOptions.value = res.data.filter((obj)=>{
            if(obj.id != id.value) return obj
          });
        }
        isLoadingAreas.value = false;
      });
    }
  }
  
  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);

  };

  const onSubmit = () => {
    isSubmitted.value = true;
    let data = {
      id : id.value,
      site_id: site.value.id,
      parent_id: area.value.id,
      company_id: company_id,
    }

    store.dispatch("moveArea", data)
    .then((res) => {
      console.log('res', res);
      resetStoreData();
      emit('refresh');
    })
    .catch((e) => {
      console.log("e", e);
      isSubmitted.value = false;
    });
  };

  onBeforeMount( () => {
    if(getModalInfo.value && getModalInfo.value.data_type == 'area'){
      console.log('getModalInfo', getModalInfo.value);
      id.value = getModalInfo.value.id;
    }
  });
</script>

<template>
  <DModal id="moveAreaModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Move Area </template>

    <template #body>
      <div class="p-relative mb-3">
        <span v-if="site" class="multiselect-title-span" >Site</span>
        <VMultiselect @select="changeSiteVal" @remove="changeSiteVal" v-model="site" :options="props.sitesOptions" trackBy="id" label="name"
        :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Site" :disabled="!props.sitesOptions.length"
        />
      </div>

      <div class="p-relative mb-3">
        <span v-if="area" class="multiselect-title-span" >Area</span>
        <VMultiselect v-model="area" :options="areasOptions" trackBy="id" label="name" :disabled="!site || isLoadingAreas" 
        :loading="isLoadingAreas" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Area"
        />
      </div>
    </template>

    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button :disabled="!site || !area || isSubmitted" 
          @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
            Move <span v-if="isSubmitted"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
          </button>
          <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </template>
  </DModal>
</template>
