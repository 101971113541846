<script setup>

    import { onBeforeMount, onMounted, ref } from 'vue';
    import { useRoute } from 'vue-router'

    const route = useRoute()
    let currency = ref(null);
    let plan = ref(null);
    let quantity = ref(null);
    let period = ref(null);
    let redirect = ref(null);
    let subscriptionLink = ref(null);

    onMounted( async () => {
        subscriptionLink.value = await `<a style="display:none" id="subscriptionLinkId" href="javascript:void(0)" data-cb-type="checkout" data-cb-item-0="${route.query.plan}-${route.query.currency}-${route.query.period}" data-cb-item-0-quantity="${route.query.quantity}" class="none-underline" ><p class="login-text mt-24 ">SUBSCRIBE</p></a><br>`;
        setTimeout( () => {
            let subscriptionLinkId = document.getElementById('subscriptionLinkId');
            console.log('subscriptionLinkId', subscriptionLinkId);
            subscriptionLinkId.click();
        },100)
        
    })

    
    onBeforeMount(async () =>{
        if(route.query.plan && route.query.quantity && route.query.currency && route.query.period){
            redirect.value = true;
            currency.value =  route.query.currency;
            plan.value = route.query.plan;
            quantity.value = route.query.quantity;
            period.value = route.query.period;
            // window.open(`https://scsc-test.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=${route.query.plan}-${route.query.currency}-${route.query.period}&subscription_items[quantity][0]=${route.query.quantity}&layout=in_app`,
            //         "_blank");
            subscriptionLink.value = await `<a style="display:none" id="subscriptionLinkId" href="javascript:void(0)" data-cb-type="checkout" data-cb-item-0="${route.query.plan}-${route.query.currency}-${route.query.period}" data-cb-item-0-quantity="${route.query.quantity}" class="none-underline" ><p class="login-text mt-24 ">SUBSCRIBE</p></a><br>`;
            
        }else{
            redirect.value = false;
        }
    })

</script>

<template>
    <div v-html="subscriptionLink"></div>
</template>