<script setup>
    import { ref, onBeforeMount, computed, defineEmits } from "vue";
    import { useStore } from "vuex";
  
    const store = useStore();
    const emit = defineEmits(["refresh"]);
    const company_id = localStorage.getItem("currentCompanyId");
    import useValidation from '@/utils/helpers/validations';

    const { checkApkUrlValidation } = useValidation();


    let id = ref(null);
    let name = ref(null);
    let type = ref(null);
    let description = ref(null);
    let url = ref(null);
    let script = ref(null);
    let parent_id = ref(null);
    let isSubmitted = ref(false);
    let isValidUrl = ref(true);

    let urlFile = ref(null);
    let file = ref(null);
    let path = ref(null);
    let fileType = ref('new_file');
    let filesOptions = ref([]);
    let isLoadingFiles = ref(true);


    const getModalInfo = computed(()=> store.getters.getModalInfo);
    const getModalAction = computed(()=> store.getters.getModalAction);

    const validUrl = () => {
      isValidUrl.value = true;
      if(url.value.length > 0){
        isValidUrl.value = checkApkUrlValidation(url.value);
        return isValidUrl.value;
      }
    };

    const changeFileSourceVal = () => {
        urlFile.value = null;
        file.value = null;
        if(!filesOptions.value.length) fetchAllFilesCompany(); // fetch here to waiting for the job is finishing after uploading the files 
    }

    const uploadFile = async (e)=>{ 
        let selectedFile = e.target.files[0];
        file.value = selectedFile;        
    }

    const disabledSubmitedButton = () => {
        let value = false;
        if(!name.value || isSubmitted.value ){
            value = true;
        }else if (type.value == 'run_script' && !script.value){
            value = true;
        }else if(type.value == 'install_app' && (!url.value || !isValidUrl.value) ){
            value = true;
        }else if(type.value == 'transfer_file' && !path.value) {
            value = true;
        }
        return value;
    }

    const onSubmit = () => {
        isSubmitted.value = true;
        const data = {
            id: id.value,
            payload : {
                name: name.value,
                description: description.value,
                type : type.value,
                parent_id: parent_id.value,
                url : type.value == 'install_app' ? url.value : type.value == 'transfer_file' && fileType.value == 'files_library'  ? urlFile.value?.fileDetails?.url : null,
                script : script.value ?? null,
                path : path.value ?? null,
                file : file.value ?? null,
                company_id,
                _method: 'PUT'
            }
        };

        console.log('data', data);
        store.dispatch("editJob", data).then(() => {
            isSubmitted.value = false;
            resetStoreData();
            emit("refresh");
        }).catch((e) => {
            console.log(e);
            isSubmitted.value = false;
        });
    };

    const resetStoreData = () =>{
        store.commit('setModalState', false);
        store.commit('setModalData', {});
        store.commit('setModalAction', null);
    };

    const fetchAllFilesCompany = () => {
        isLoadingFiles.value = true;
        store.dispatch("fetchAllFilesCompany", company_id).then((res) => {
            console.log('res', res.data);
            filesOptions.value = res.data;
            isLoadingFiles.value = false;

        }).catch((e) => {
            console.log(e);
        });
    }

    onBeforeMount(() => {
        if(getModalInfo.value && getModalInfo.value.type == 'job' && getModalAction.value == 'edit_folder_and_job' ){
            console.log('getModalInfo', getModalInfo.value);
            id.value = getModalInfo.value.id ;
            name.value = getModalInfo.value.name;
            description.value = getModalInfo.value.jobDetails.description;
            type.value = getModalInfo.value.jobDetails?.type;
            url.value = getModalInfo.value.jobDetails?.metadata?.url ? getModalInfo.value.jobDetails?.metadata?.url : null;
            path.value = getModalInfo.value.jobDetails?.metadata?.path ? getModalInfo.value.jobDetails?.metadata?.path : null;
            script.value = getModalInfo.value.jobDetails?.metadata?.script ? getModalInfo.value.jobDetails?.metadata?.script : null;
            parent_id.value = getModalInfo.value.parent_id ? getModalInfo.value.parent_id : null;

            console.log('parent_id.value', parent_id.value);
        }
    });
</script>


<template>
    <DModal id="editJobModal" :main_classes="[]" :dialog_classes="[]">
        <template #title> Edit Job </template>
  
        <template #body>
        
            <div class="form-floating mb-3">
                <input type="text" v-model.trim="name" class="form-control" id="jobName" placeholder=""/>
                <label for="jobName">Job Name</label>
            </div>
    
            <div class="form-floating mb-3">
                <textarea v-model="description" class="form-control none-resize h-105" placeholder="description" id="jobDescription"/>
                <label for="jobDescription">Description</label>
            </div>


            <div v-if="type == 'install_app'" class="form-floating mb-3">
                <input @input="validUrl" type="text" v-model.trim="url" class="form-control" id="jobUrl" :class="{'is-invalid' : !isValidUrl}" placeholder="URL"/>
                <label for="jobUrl">URL</label>
                <div v-if="!isValidUrl" class="invalid-feedback">
                    Invalid URL.
                  </div>
            </div>
    
            <div v-if="type == 'run_script'" class="form-floating mb-3">
                <textarea v-model.trim="script" class="form-control none-resize h-150" placeholder="Script" id="jobScript"/>
                <label for="jobScript">Script</label>
            </div>

            <div v-if="type == 'transfer_file'">

                <div class="form-floating mb-3">
                    <input type="text" v-model.trim="path" class="form-control" id="path" placeholder=""/>
                    <label for="path">Path</label>
                </div>

                <div class="col-12 d-flex  unique-radio-inputs p-0 mb-3">
        
                    <div class="col-6 radioTwo p-0" >
                        <input @change="changeFileSourceVal" class="form-check-input" type="radio" name="type" id="newFileOption" value="new_file" v-model="fileType"/>
                        <label class="form-check-label" for="newFileOption">New File</label>
                    </div>

                    <div class="col-6 radioOne p-0" >
                        <input @change="changeFileSourceVal" class="form-check-input" type="radio" name="type" id="filesLibraryOption" value="files_library" v-model="fileType" />
                        <label class="form-check-label" for="filesLibraryOption">Files Libarary</label>
                    </div>
            
                </div>
            
                <div v-if="fileType == 'files_library'" class="p-relative">
                    <span v-if="urlFile" class="multiselect-title-span" >Files</span>
                    <VMultiselect v-model="urlFile" :options="filesOptions" :disabled="isLoadingFiles" trackBy="id" label="name" :isLoading='isLoadingFiles'
                        :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Select a File"
                    />
                </div>
        
                <div v-if="fileType == 'new_file'" class="form-floating">
                    <input ref="appFile" type="file" @change="uploadFile" id="newFile" class="form-control w-100"  placeholder="File" />
                    <label for="newFile">File</label>
                </div>

                <div class="text-center mt-3">
                    <span>
                        <a class="custom_link" target="_blank" :href="url">Click here to show file uploaded</a>
                    </span>
                </div>
               
                  
            </div>
    
        </template>
        <template #footer>
            <div class="w-100">
                <div class="seperator text-center"></div>
                <div class="d-flex mt-20 justify-content-center">
                    <button :disabled="disabledSubmitedButton()"
                    @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
                    Edit <span v-if="isSubmitted"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
                    </button>
                    <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
      </template>
    </DModal>
  </template>
  