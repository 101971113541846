import Api from "./Api";
export default {
  addArea(payload) {
    return Api().post(`/areas`, payload);
  },
  editArea(payload) {
    return Api().put(`/area/${payload.id}`, payload);
  },
  deleteArea(payload) {
    return Api().delete(`/area/${payload.companyId}/${payload.id}`);
  },
  moveArea(payload) {
    return Api().post(`/area/${payload.id}`, payload);
  },
  getAllAreas(payload) {
    return Api().get(`/site_areas/${payload.companyId}/${payload.siteId}`);
  },
  getParentAreas(payload) {
    return Api().get(`/site_parent_areas/${payload.companyId}/${payload.siteId}`);
  },
};
