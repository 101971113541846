import Api from "./Api";
import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  "Accept": "application/json",
  "Access-Control-Allow-Origin" : '*' 
};

export default {
  login(payload){
    return axios.post(`${process.env.VUE_APP_BASE_URL}/api/login`, payload, {
      headers: headers,
    });
  },
  register(payload) {
    return axios.post(`${process.env.VUE_APP_BASE_URL}/api/register`, payload, {
      headers: headers,
    });
  },
  forgetPassword(payload) {
    return axios.post(`${process.env.VUE_APP_BASE_URL}/api/forgot-password`, payload, {
      headers: headers,
    });
  },
  resetPassword(payload) {
    return axios.post(`${process.env.VUE_APP_BASE_URL}/api/reset-password`, payload, {
      headers: headers,
    });
  },
  getUserBytoken() {
    return Api().get('/profile');
  },
  logout() {
    return Api().get(`/logout`);
  },
  checkUserPassword(payload) {
    return Api().post('/check-login', payload);
  },
};