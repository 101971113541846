<script setup>
  import { ref, computed, onBeforeMount, onMounted, reactive } from "vue";
  import DefaultLayout from "./layouts/DefaultLayout.vue";
  import {
    DxTreeList,
    DxColumn,
    DxColumnChooser,
    DxHeaderFilter,
    DxToolbar,
    DxItem,
    // DxFilterRow,
    DxScrolling,
    DxLoadPanel,
    DxStateStoring,
    DxSelection,
    DxSearchPanel,
  } from "devextreme-vue/tree-list";
  // import notify from 'devextreme/ui/notify';
  // import { DxTooltip } from 'devextreme-vue/tooltip';
  // import DxDropDownButton from "devextreme-vue/drop-down-button";
  // import { default as Button } from "devextreme-vue/button";
  import { useToast } from "vue-toastification";    
  import { Modal }  from "bootstrap";
  import { socket } from "@/socket";
  import checkUserPassword from "../components/users/checkUserPassword.vue";

  import addSite from "../components/sites/addSite.vue";
  import addArea from "../components/areas/addArea.vue";
  import addDevice from "../components/kiosks/addDevice.vue";
  // import addDeviceWithDetails from "../components/kiosks/addDeviceWithDetails.vue";
  // import addDeviceWithGenerateToken from "../components/kiosks/addDeviceWithGenerateToken.vue";

  import editSite from "../components/sites/editSite.vue";
  import deleteSite from "../components/sites/deleteSite.vue";
  import setSitePassword from "../components/sites/setSitePassword.vue";
  import passwordHistory from "../components/sites/passwordHistory";
  import editArea from "../components/areas/editArea.vue";
  import moveArea from "../components/areas/moveArea.vue";
  import deleteArea from "../components/areas/deleteArea.vue";

  import editDevice from "../components/kiosks/editDevice.vue";
  import openDoor from "../components/kiosks/openDoor.vue";
  import restartDevice from "../components/kiosks/restartDevice.vue";
  import installApp from "../components/kiosks/installApp.vue";
  import uninstallApps from "../components/kiosks/uninstallApps.vue";
  // import clearAppData from "../components/kiosks/clearAppData.vue";
  import deviceInfo from "../components/kiosks/deviceInfo.vue";
  import moveDevice from "../components/kiosks/moveDevice.vue";
  import restartApp from "../components/kiosks/restartApp.vue";
  import refreshFaceLibrary from "../components/kiosks/refreshFaceLibrary.vue";
  import previewLogs from "../components/kiosks/previewLogs.vue";
  import deRegisterDevice from "../components/kiosks/deRegisterDevice.vue";
  import deviceSettings from "../components/kiosks/deviceSettings.vue";
  import remoteControl from "../components/kiosks/remoteControl.vue";
  import deviceToken from "../components/kiosks/deviceToken.vue";
  import deviceConfiguration from "../components/kiosks/deviceConfiguration.vue";
  import deviceApps from "../components/kiosks/deviceApps.vue";
  import DiskChart from "./partials/sitesAndDevices/DiskChart.vue"
  // import SiteActionCell from "../components/sites/SiteActionCell.vue";
  // import AreaActionCell from "../components/areas/AreaActionCell.vue";
  // import DeviceActionCell from "../components/kiosks/DeviceActionCell.vue";
  import moment from "moment";
  import DeviceInfoSkeletor from "./partials/sitesAndDevices/skeleton/DeviceInfoSkeleton.vue"
  import DiskInfoSkeletor from "./partials/sitesAndDevices/skeleton/DiskSectionSkeleton.vue"
  import BatterySkeletor from "./partials/sitesAndDevices/skeleton/BatterySectionSkeleton.vue"
  import MemorySkeletor from "./partials/sitesAndDevices/skeleton/MemorySectionSkeleton.vue"
  import KioskActionsBarSkeletor from "./partials/sitesAndDevices/skeleton/KioskActionSectionSkeleton.vue"
 
  import applyJob from "../components/jobs/applyJob.vue";
  import useValidation from '@/utils/helpers/validations';

  const { checkLoggedInUserRole } = useValidation();

  import { useStore } from "vuex";
  const availableKioskActions = [
    {
      action:'refresh_device_info',
      text:'Refresh',
      icon:'bi-arrow-repeat'
    },
    {
      action:'restart_device',
      text:'Restart',
      icon:'bi-phone-flip'
    },
    {
        action:'device_apps',
        text:'Get Apps',
        icon:'bi-columns-gap'
    },
    {
      action:'remote_control',
      text:'Remote',
      icon:'bi-pc-display-horizontal'
    }
  ]

  const store = useStore();
  const toast = useToast();
  let getModalState = computed(()=> store.getters.getModalState);
  const companyId = computed(()=>localStorage.getItem("currentCompanyId"));
  const adminPreferenceId = computed(()=>localStorage.getItem("adminPreferenceId"));
  const user = computed(()=>JSON.parse(localStorage.getItem('userDetails')));
  const userEmail = computed(()=>JSON.parse(localStorage.getItem('user')));
  // const currentCompany = computed(()=>JSON.parse(localStorage.getItem("currentCompany")));
  const hideSkeletor = computed(()=> deviceData.value && !fetchDeviceDataLoading.value && showSideInfo.value)
  // const showKioskActionBar = computed(()=>showSideInfo.value && deviceData.value && !fetchDeviceDataLoading.value)
  const dataGridTreeRefName = ref(null);
  const allowedOperations = ref(["contains"]);
  // const buttonData = ref(["Excel", "CSV", "PDF"]);
  const expandedRowKeys = ref([1, 2]);
  const selectedRowKeys = ref([1, 29, 42]);
  let sitesGrid = ref([]);
  let sitesOptions = ref([]);
  let appsOptions = ref([]);
  let configsOptions = ref([]);
  let showClearAll = ref(false);
  let showSideInfo = ref(false);
  let showMassAction = ref(true);
  let deviceData = ref({deviceInfo:[]});
  let fetchDeviceDataLoading = ref(false);
  let ifDisableParentNode = ref(false);
  let isSelectedDevices = ref(true);
  let devicesNames = ref([]);
  let mac_addresses = ref([]);
  let deviceDataObj = ref({});
  const selectionMode = ref('all');

  const dataTreeGrid = computed(() => {
    return dataGridTreeRefName.value?.instance || null;
  });

  const state = reactive({

    checkUserPasswordModal: null,
    
    siteModal: null,
    areaModal: null,
    deviceModal: null,

    editSiteModal: null,
    deleteSiteModal: null,
    addAreaWithSiteModal : null,
    setSitePasswordModal : null,
    passwordHistoryModal : null,

    editAreaModal: null,
    deleteAreaModal : null,
    moveAreaModal : null,
    addDeviceWithAreaModal : null,

    openDoorModal: null,
    deviceAppsModal: null,
    restartAppModal : null,
    restartDeviceModal : null,
    deviceSettingsModal : null,
    installAppModal : null,
    uninstallAppsModal : null,
    clearAppDataModal : null,
    getDeviceInfoModal : null,
    moveDeviceModal : null,
    deRegisterDeviceModal : null,
    refreshFaceLibrary : null,
    deviceAppInfoModal : null,
    previewLogsModal : null,
    remoteControlModal : null,
    deviceTokenModal : null,
    editDeviceModal : null,
    deviceConfigurationModal : null,
    applyJobModal : null,
  });

  const siteModal = (type) => {
    if (type == "show") state.siteModal.show();
    else state.siteModal.hide();
  };

  const areaModal = async () => {
    // await setModalState();
    // state.areaModal = new Modal("#areaModal", {});
    // if (type == "show") state.areaModal.show();
    // else state.areaModal.hide();
    action('add_area', '')
  };

  const deviceModal = async (type) => {
    await setModalState();
    state.deviceModal = new Modal("#deviceModal", {});
    if (type == "show") state.deviceModal.show();
    else state.deviceModal.hide();
  };

  const contentReady = (e) => {
    let sortedExp = getSortedColumns();
    let filterExp = e.component.getCombinedFilter();
    if (filterExp || sortedExp) {
      showClearAll.value = true;
    } else {
      showClearAll.value = false;
    }
    setColumnChooserPosition();
  };

  const setModalState = () => {
    store.commit('setModalState', true);
  }

  const getSortedColumns = () => {
    if (dataTreeGrid.value) {
      let allColumns = Array.from(
        Array(dataTreeGrid.value.columnCount()).keys()
      ).map((index) => dataTreeGrid.value.columnOption(index));
      allColumns = allColumns.find((col) => col.sortIndex != null);
      return allColumns;
    }
    return null;
  };
    
  const setColumnChooserPosition = () => {
    if (dataTreeGrid.value) {
      var columnChooserView = dataTreeGrid.value.getView("columnChooserView");
      if (columnChooserView && !columnChooserView._popupContainer) {
        columnChooserView._initializePopupContainer();
        columnChooserView.render(); 
      }
      columnChooserView._popupContainer.option("position", {
        of: dataTreeGrid.value.element(),
        my: "left top",
        at: "left top",
        offset: "0 50",
      });
    }
  };

  const onEditorPreparing = (e) => {
    if (e.parentType == "filterRow") {
      e.editorOptions.placeholder = "Search";
    }
  };

  const onFocusedRowChanged = async (e) => {
    console.log('onFocusedRowChanged', e);
    if(e.row?.data?.data_type == 'device' && e.row?.data?.mac_address && e.row?.data?.mac_address != '-' && e.row?.isSelected === false && e.row?.isExpanded === false){
      // console.log('e.row?.data?', e.row?.data)
      if(e.row?.data?.is_offline){
        toast.warning('This device is offline'); 
      }
      
      showSideInfo.value = true;
      deviceData.value = {deviceInfo:[]}
      deviceDataObj.value = e.row.data;
      await store.commit('setDeviceDataObject', deviceDataObj.value);
      fetchDeviceInfo(e.row?.data);
  
    }else if(e.row?.data?.data_type == 'device' && e.row?.data?.mac_address == '-' && e.row?.isSelected === false && e.row?.isExpanded === false){
      toast.error('Please add mac address for this device');
      showSideInfo.value = false;
    }else{
      showSideInfo.value = false;
    }
  }

  const onClickedRowChanged = (e) =>{
    console.log('onClickedRowChanged', e);
    if(!showSideInfo.value && e.data?.data_type == 'device' && e.isSelected === false && e.isExpanded === false){
      showSideInfo.value = true;
      deviceData.value = {deviceInfo:[]};
      deviceDataObj.value = e.data;
      fetchDeviceInfo(e.data);
    }
  }

  const getSelectedData = (mass_action) =>{
    // console.log('action', mass_action);
    let devicesData = [];
    let offlineDevicesData = [];
    let rows = dataTreeGrid.value.getSelectedRowsData(selectionMode.value);
    // console.log('rows', rows);
    devicesData = rows.filter((row)=>{
      if(row.data_type == 'device'){
        if(row.is_online == 0){
          offlineDevicesData.push(row)
        }
        return row;
      }
    })
    // console.log('devicesData', devicesData);  
    if(offlineDevicesData.length){
      toast.warning('Action will be applied on all device(s) once they are online or reconnected');
    }

    action(mass_action, devicesData);
  }

  const onSelectionChanged = async (e) => {
    let isFoundDevice = false;
    isSelectedDevices.value = true;
    // console.log('onSelectionChanged', e);
    if(e && e.selectedRowsData && e.selectedRowsData.length){
      let rows = dataTreeGrid.value.getSelectedRowsData(selectionMode.value);
      await rows.map((obj) => {
        if(obj.data_type == 'device'){
          isSelectedDevices.value = false;  
          showSideInfo.value = false;
          isFoundDevice = true;
        }
      });
      if(!isFoundDevice) toast.warning('You should select at least one device to use mass actions');      
    }else {
      isSelectedDevices.value = true;
    }
  }

  const clearFilter = () => {
    if (dataTreeGrid.value) {
      dataTreeGrid.value.clearFilter("row");
      dataTreeGrid.value.clearFilter("header");
      dataTreeGrid.value.clearFilter("search");
      dataTreeGrid.value.clearSorting();
    }
  };

  const showColumnChooser = () => {
    if (dataTreeGrid.value) {
      dataTreeGrid.value.showColumnChooser();
    } else {
      console.log("column show error");
    }
  };

  const fetchAllSites = () => {
    store.dispatch("fetchSites", companyId.value).then((res) => {
      sitesOptions.value = res.data;
    }).catch((e) => console.log(e));
  }

  const refreshTreeListGrid = () => {
    console.log("tree grid refreshed");
    document.querySelector(".modal-backdrop").remove();
    document.querySelector(".dx-device-desktop").classList.remove('modal-open');
    fetchSitesGrid();
  };

  const removeBackgroundModal = () => {
    document.querySelector(".modal-backdrop").remove();
    document.querySelector(".dx-device-desktop").classList.remove('modal-open');
  }

  const refreshTreeList = () => {
    dataTreeGrid.value.beginCustomLoading();
    console.log("tree list refreshed");
    fetchSitesGrid();
  };

  const customLoad = async () => {
    await store.dispatch("onTableHeader", { url: "/sites-and-devices-grid", companyId : companyId.value })
      .catch((e) => console.log(e));
    let value = store.getters["getTableHeaders"];
    console.log("customLoad", value);
    return value;
  };
  
  const OnCellPrepared = (e) => {
    if (ifDisableParentNode.value && e.row?.rowType == 'data' && e.cellElement.className) {
      if ((e.data?.data_type === "site" || e.data?.data_type === "area") && e.cellElement.className === "dx-treelist-cell-expandable") {
        // console.log('OnCellPrepared', e);
        const checkbox = e.cellElement.querySelector(".dx-select-checkbox");
        checkbox.hidden = true;
      }
    }
  }
  
  const customSave = (gridState) => {
    gridState.pageIndex = 0;
    gridState.pageSize = 5;
    gridState.focusedRowKey = '';

    let value = JSON.stringify(gridState);
    value = value.replace(',"selectedRowKeys":[]', "");
    if (!store.getters["getTableHeaders"]) {
      console.log("createTableHeaders");
      store
        .dispatch("createTableHeaders", {
          url: "/sites-and-devices-grid",
          headers: value,
          filters: "[]",
          companyId : companyId.value
        })
        .catch((e) => console.log(e));
    } else if (value != JSON.stringify(store.getters["getTableHeaders"])) {
      console.log("updateTableHeaders");
      store
        .dispatch("updateTableHeaders", {
          url: "/sites-and-devices-grid",
          headers: value,
          filters: "[]",
          companyId : companyId.value,
          adminPreferenceId : adminPreferenceId.value,
        })
        .catch((e) => console.log(e));
    }
    console.log(
      "customSave value",
      value != JSON.stringify(store.getters["getTableHeaders"])
    );
  };

  const onItemClick = (type) => {

    // let type = null;
    // if (e.itemData == "Excel") {
    //   type = 'excel';
    // } else if (e.itemData == "CSV") {
    //   type = 'csv';
    // } else if (e.itemData == "PDF") {
    //   type = 'pdf';
    // }

    store.dispatch('exportSitesGrid', {companyId : companyId.value, type : type })
    .then((res)=>{
      console.log('res', res);
    })
    .catch((e)=> console.log(e))
  }

  const fetchDeviceInfo = (data) => {
    fetchDeviceDataLoading.value = true;
    const mainCategories = ["network", "disk", "memory", "battery"];

    let payload = { 
      entityId: companyId.value,
      kioskName : data.name,
      macAddress : data.mac_address,
      userName : user.value.first_name+' '+ user.value.last_name,
      userId : user.value.user_id,
      connectionType : 'daemon',
    }

    store.dispatch("fetchDeviceInfo", payload)
    .then((res) => {
      let dataInfo = JSON.parse(res.data);
      delete dataInfo.manufacturer
      // console.log('dataInfo', dataInfo); 
      Object.keys(dataInfo).forEach((key) => {
        if (mainCategories.includes(key))
          Object.assign(deviceData.value, { [key]: dataInfo[key] });
        else deviceData.value.deviceInfo.push({ [key]: dataInfo[key] });
      });
      fetchDeviceDataLoading.value = false;
    })
    .catch((e) => {
      console.log(e);
      fetchDeviceDataLoading.value = false;
      showSideInfo.value = false;
      deviceDataObj.value = {};

    });
};

  const checkDeviceMenuItems = (data) => {
    if(data.mac_address && data.mac_address != '-') return true 
    else return false
  }

  const action = async (action, data) => {

    if(data.data_type == 'device' && !data.is_online){
      if(action == 'remote_control'){
        toast.warning("The device is offline, you can't use remote control");
        return
      }else{
        toast.warning("Action will be applied on device once it's online or reconnected");
      }
    }
    // console.log('data', data);
    await store.commit('setModalAction', action);
    store.commit('setModalState', true);
    await store.commit('setModalData', data);


    if(action == 'check_user_password'){
      state.checkUserPasswordModal= new Modal("#checkUserPasswordModal", {});
      state.checkUserPasswordModal.show();
    }else if(action == 'edit_site'){
      state.editSiteModal = new Modal("#editSiteModal", {});
      state.editSiteModal.show();
    }else if(action == 'delete_site'){
      state.deleteSiteModal = new Modal("#deleteSiteModal", {});
      state.deleteSiteModal.show();
    }else if(action == 'set_site_password'){
      state.setSitePasswordModal = new Modal("#setSitePasswordModal", {});
      state.setSitePasswordModal.show();
    }else if(action == 'password_history'){
      state.passwordHistoryModal = new Modal("#passwordHistoryModal", {});
      state.passwordHistoryModal.show();
    }else if(action == 'add_area_with_site' || action == 'add_area'){
      state.addAreaWithSiteModal = new Modal("#areaModal", {});
      state.addAreaWithSiteModal.show();
    } else if(action == 'edit_area'){
      state.editAreaModal = new Modal("#editAreaModal", {});
      state.editAreaModal.show();
    }else if(action == 'delete_area'){
      state.deleteAreaModal = new Modal("#deleteAreaModal", {});
      state.deleteAreaModal.show();
    }else if(action == 'move_area'){
      state.moveAreaModal = new Modal("#moveAreaModal", {});
      state.moveAreaModal.show();
    }else if(action == 'add_device'){
      state.addDeviceWithAreaModal = new Modal("#deviceModal", {});
      state.addDeviceWithAreaModal.show();
    }else if(action == 'move_device' || action == 'mass_move_devices' ){
      state.moveDeviceModal = new Modal("#moveDeviceModal", {});
      state.moveDeviceModal.show();
    }else if(action == 'edit_device'){
      state.editDeviceModal = new Modal("#editDeviceModal", {});
      state.editDeviceModal.show();
    }else if(action == 'device_apps'){
      state.deviceAppsModal = new Modal("#deviceAppsModal", {});
      state.deviceAppsModal.show();
    }else if(action == 'deregister_device' || action == 'mass_deregister_devices'){
      state.deRegisterDeviceModal = new Modal("#deRegisterDeviceModal", {});
      state.deRegisterDeviceModal.show();
    }else if(action == 'open_door'){
      state.openDoorModal = new Modal("#openDoorModal", {});
      state.openDoorModal.show();
    }else if(action == 'install_app' || action == 'mass_install_app'){
      state.installAppModal = new Modal("#installAppModal", {});
      state.installAppModal.show();
    }else if(action == 'uninstall_apps'){
      state.uninstallAppsModal = new Modal("#uninstallAppsModal", {});
      state.uninstallAppsModal.show();
    }else if(action == 'restart_app' || action == 'mass_restart_app'){
      state.restartAppModal = new Modal("#restartAppModal", {});
      state.restartAppModal.show();
    }else if(action == 'device_settings'){
      state.deviceSettingsModal = new Modal("#deviceSettingsModal", {});
      state.deviceSettingsModal.show();
    }else if(action == 'clear_app_data'){
      state.clearAppDataModal = new Modal("#clearAppDataModal", {});
      state.clearAppDataModal.show();
    }else if(action == 'restart_device' || action == 'mass_restart_devices'){
      state.restartDeviceModal = new Modal("#restartDeviceModal", {});
      state.restartDeviceModal.show();
    }else if(action == 'refresh_face_library'){
      state.refreshFaceLibrary = new Modal("#refreshFaceLibraryModal", {});
      state.refreshFaceLibrary.show();
    }else if(action == 'get_device_info'){
      state.getDeviceInfoModal = new Modal("#deviceInfoModal", {});
      state.getDeviceInfoModal.show();
    }else if(action == 'preview_logs'){
      state.previewLogsModal = new Modal("#previewLogsModal", {});
      state.previewLogsModal.show();
    }else if(action == 'remote_control'){
      state.remoteControlModal = new Modal("#remoteControlModal", {});
      state.remoteControlModal.show();
    }else if(action == 'device_token'){
      state.deviceTokenModal = new Modal("#deviceTokenModal", {});
      state.deviceTokenModal.show();
    }else if(action == 'device_config' || action == 'mass_devices_config' 
    || action == 'site_config' || action == 'area_config'){
      state.deviceConfigurationModal = new Modal("#deviceConfigurationModal", {});
      state.deviceConfigurationModal.show();
    }else if(action == 'screenshot' || action == 'mass_screenshots'){

      if(Array.isArray(data)){
        data.map((row) =>{
          devicesNames.value.push(row.name)
          mac_addresses.value.push(row.mac_address)
        });
      }

      store.dispatch(action == 'screenshot' ? "fetchScreenshot" : 'fetchMassScreenshot', { 
        entityId: companyId.value,
        kioskName : action == 'screenshot' ? data.name : devicesNames.value,
        macAddress : action == 'screenshot' ? data.mac_address : '',
        macAddresses : action == 'mass_screenshots' ? mac_addresses.value : '',
        userName : user.value.first_name+' '+ user.value.last_name,
        userId : user.value.user_id,
        connectionType : 'daemon',
      }).then((res) => {
        console.log('res', res);
        resetStoreData();
      }).catch((e) => {
          console.log("e", e);
      });

    }else if(action == 'refresh_get_screen'){

      if(Array.isArray(data)){
        data.map((row) =>{
          devicesNames.value.push(row.name)
          mac_addresses.value.push(row.mac_address)
        });
      }else {
        devicesNames.value.push(data.name)
        mac_addresses.value.push(data.mac_address)
      }

      store.dispatch('fetchDeviceRefreshGetScreen', { 
        entityId: companyId.value,
        kioskName : devicesNames.value,
        macAddresses : mac_addresses.value,
        userName : user.value.first_name+' '+ user.value.last_name,
        userId : user.value.user_id,
        connectionType : 'daemon',
      }).then((res) => {
        console.log('res', res);
        resetStoreData();
      }).catch((e) => {
          console.log("e", e);
      });

    }else if(action == 'refresh_device_info'){
      showSideInfo.value = true;
      deviceData.value = {deviceInfo:[]}
      deviceDataObj.value = data;
      await store.commit('setDeviceDataObject', deviceDataObj.value);
      fetchDeviceInfo(data)
    }else if(action == 'apply_job' || action == 'mass_apply_job'){
      state.applyJobModal = new Modal("#applyJobModal", {});
      state.applyJobModal.show();
    }
  }

  const resetStoreData = () =>{
    setTimeout( () => {
      store.commit('setModalState', false);
      store.commit('setModalData', {});
      store.commit('setModalAction', null);
    },400)
  };

  const closeSideInfo = () => {
    deviceDataObj.value = {};
    deviceData.value = {deviceInfo:[]}
    showSideInfo.value = false;
  }

  const addMenuItems = (e) => {
    console.log('e', e);
    if (e.target == "content" && e.row?.node?.data?.data_type == 'site' ) {
      // e.items can be undefined
      if (!e.items) e.items = [];
      // Add a custom menu item
      e.items.push({
          html: '<i class="bi bi-pencil-square"></i> Edit',
          onItemClick: () => {
            action('edit_site', e.row?.node?.data);
          }
      },{
          html: '<i class="bi bi-gear"></i> Apply Config',
          onItemClick: () => {
            action('site_config', e.row?.node?.data);
          }
      },{
          html: '<i class="bi bi-trash3"></i> Delete',
          onItemClick: () => {
            action('delete_site', e.row?.node?.data);
          }
      },{
          html: '<i class="bi bi-building-add"></i> Add Area',
          onItemClick: () => {
            action('add_area_with_site', e.row?.node?.data);
          }
      },{
          html: '<i class="bi bi-key"></i> Change Password',
          onItemClick: () => {
            action('check_user_password', e.row?.node?.data);
          }
      });
    }else if (e.target == "content" && e.row?.node?.data?.data_type == 'area' ) {
      // e.items can be undefined
      if (!e.items) e.items = [];
      // Add a custom menu item
      e.items.push({
          html: '<i class="bi bi-pencil-square"></i> Edit',
          onItemClick: () => {
            action('edit_area', e.row?.node?.data);
          }
      },{
          html: '<i class="bi bi-arrow-left-right"></i> Move',
          onItemClick: () => {
            action('move_area', e.row?.node?.data);
          }
      },{
          html: '<i class="bi bi-gear"></i> Apply Config',
          onItemClick: () => {
            action('area_config', e.row?.node?.data);
          }
      },{
          html: '<i class="bi bi-trash3"></i> Delete',
          onItemClick: () => {
            action('delete_area', e.row?.node?.data);
          }
      },{
          html: '<i class="bi bi-tablet"></i> Add Device',
          visible : checkLoggedInUserRole(),
          onItemClick: () => {
            action('add_device', e.row?.node?.data);
          }
      });
    } else if (e.target == "content" && e.row?.node?.data?.data_type == 'device' ) {
      // e.items can be undefined
      if (!e.items) e.items = [];
      // Add a custom menu item
      e.items.push({
          html: '<i class="bi bi-pencil-square"></i> Edit',
          onItemClick: () => {
            action('edit_device', e.row?.node?.data);
          }
      },
      // {
      //     html: '<i class="bi bi-door-open"></i> Open Door',
      //     onItemClick: () => {
      //       action('open_door', e.row?.node?.data);
      //     }
      // },
      {
        html: '<i class="bi bi-columns-gap"></i> Get Apps',
        visible : checkDeviceMenuItems(e.row?.node?.data),
        onItemClick: () => {
          action('device_apps', e.row?.node?.data);
        }
      },
      {
          html: '<i class="bi bi-phone-flip"></i> Restart Device',
          visible : checkDeviceMenuItems(e.row?.node?.data),
          onItemClick: () => {
            action('restart_device', e.row?.node?.data);
          }
      },{
          html: '<i class="bi bi-fullscreen"></i> Screenshot',
          visible : checkDeviceMenuItems(e.row?.node?.data),
          onItemClick: () => {
            action('screenshot', e.row?.node?.data);
          }
      },{
          html: '<i class="bi bi-arrow-left-right"></i> Move',
          onItemClick: () => {
            action('move_device', e.row?.node?.data);
          }
      },{
          html: '<i class="bi bi-gear"></i> Apply Config',
          visible : checkDeviceMenuItems(e.row?.node?.data),
          onItemClick: () => {
            action('device_config', e.row?.node?.data);
          }
      },{
          html: '<i class="bi bi-list-check"></i> Apply Job',
          visible : checkDeviceMenuItems(e.row?.node?.data),
          onItemClick: () => {
            action('apply_job', e.row?.node?.data);
          }
      },{
          html: '<i class="bi bi-arrow-counterclockwise"></i> Restart App',
          visible : checkDeviceMenuItems(e.row?.node?.data),
          onItemClick: () => {
            action('restart_app', e.row?.node?.data);
          }
      },{
          html: '<i class="bi bi-download"></i> Install App',
          visible : checkDeviceMenuItems(e.row?.node?.data),
          onItemClick: () => {
            action('install_app', e.row?.node?.data);
          }
      },{
          html: '<i class="bi bi-list-columns"></i> Preview Logs',
          visible : checkDeviceMenuItems(e.row?.node?.data),
          onItemClick: () => {
            action('preview_logs', e.row?.node?.data);
          }
      },{
          html: '<i class="bi bi-pc-display-horizontal"></i> Remote Control',
          visible : checkDeviceMenuItems(e.row?.node?.data),
          onItemClick: () => {
            action('remote_control', e.row?.node?.data);
          }
      },{
          html: '<i class="bi bi-x-lg"></i> Uninstall Apps',
          visible : checkDeviceMenuItems(e.row?.node?.data),
          onItemClick: () => {
            action('uninstall_apps', e.row?.node?.data);
          }
      }
      // ,{
      //     html: '<i class="bi bi-info-circle"></i> Device Info',
      //     visible : checkDeviceMenuItems(e.row?.node?.data),
      //     onItemClick: () => {
      //       action('get_device_info', e.row?.node?.data);
      //     }
      // }
      ,{
          html: '<i class="bi bi-trash3"></i> De-register',
          onItemClick: () => {
            action('deregister_device', e.row?.node?.data);
          }
      }
      ,{
        html: '<i class="bi bi-arrow-repeat"></i> Refresh Remote Integration',
        visible : process.env.VUE_APP_SUPER_ADMIN_EMAIL == userEmail.value.user.email,
        onItemClick: () => {
          action('refresh_get_screen', e.row?.node?.data);
        }
      });
    } 
  }

  const updateSitePassword = async (data) => {
    // console.log('updateSitePassword data', data);
    await resetStoreData();
    await removeBackgroundModal();
    await action('set_site_password', data);
  }

  const showPasswordHistory = async (data) => {
    // console.log('showPasswordHistory data', data);
    await resetStoreData();
    await removeBackgroundModal();
    await action('password_history', data);
  }

  const fetchSitesGrid = () => {
    store.dispatch("fetchSitesGrid", companyId.value)
      .then((res) => {
        dataTreeGrid.value.endCustomLoading();
        // console.log(" fetchSitesGrid res", res);
        if (res.data && res.data.response) {
          sitesGrid.value = res.data.response.map((row) => {
            row.num_areas = row.area_count ? row.area_count : "-";
            row.num_devices = row.device_count ? row.device_count : "-";
            row.description = row.description ? row.description : "-";
            // row.contact_email = row.contact_email ? row.contact_email : "-";
            row.timezone = row.timezone ? row.timezone : "-";
            // row.android_serial = row.android_serial ? row.android_serial : "-";
            // row.lamasatech_serial = row.lamasatech_serial ? row.lamasatech_serial : "-";
            row.mac_address = row.mac_address ? row.mac_address : "-";
            row.is_offline = row.last_seen && !row.is_online ? 1 : 0;
            row.config_name =  row.configuration ? row.configuration.name : '-';
            row.status = row.data_type == "device" ? row.is_online ? "Online" : row.last_seen && row.is_offline  ? `Offline since ${moment(row.last_seen).format('DD/MM/YYYY')} at ${moment(row.last_seen).format('hh:mm A')}` : "-" : "-" 
            return row;
          });
        }

        console.log('sitesGrid.value', sitesGrid.value);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  onMounted(async () => {
    
    dataTreeGrid.value.beginCustomLoading();
    state.siteModal = new Modal("#siteModal", {});

    socket.on(`joinedRoom-${companyId.value}`, (data) => {
      if(data && data.do == 'refresh-grid'){
        console.log('socket refresh-grid', data);
        refreshTreeList();            
      }
    });
  });

  onBeforeMount(() => {
    fetchAllSites();
    store.dispatch("fetchAppsLibrary", companyId.value).then((res) => {
      appsOptions.value = res.data.data;
    }).catch((e)=> console.log(e));

    store.dispatch("fetchConfigsGrid", companyId.value).then((res) => {
      configsOptions.value = res.data;
    }).catch((e)=> console.log(e));

    fetchSitesGrid();
  });

</script>

<template>
  <DefaultLayout>

    <checkUserPassword v-if="getModalState" @updateSitePassword="updateSitePassword" @removeBackground="removeBackgroundModal"/>

    <addSite @refresh="refreshTreeListGrid" @refreshSitesArr="fetchAllSites" :closeModal="siteModal" />
    <editSite v-if="getModalState" @refreshSitesArr="fetchAllSites"  @refresh="refreshTreeListGrid"/>
    <deleteSite v-if="getModalState" @refresh="refreshTreeListGrid"/>

    <setSitePassword v-if="getModalState" @refresh="refreshTreeListGrid" @showPasswordHistory="showPasswordHistory"/>
    <passwordHistory v-if="getModalState" @refresh="refreshTreeListGrid" @removeBackground="removeBackgroundModal"/>


    <addArea v-if="getModalState" @refresh="refreshTreeListGrid" :closeModal="areaModal" :sitesOptions="sitesOptions"/>
    <editArea v-if="getModalState" @refresh="refreshTreeListGrid" :sitesOptions="sitesOptions" />
    <moveArea v-if="getModalState" @refresh="refreshTreeListGrid" :sitesOptions="sitesOptions"/>
    <deleteArea v-if="getModalState" @refresh="refreshTreeListGrid"/>

    <addDevice v-if="getModalState" @addDeviceRefresh="refreshTreeList" @removeBackground="removeBackgroundModal"
     :sitesOptions="sitesOptions" :configsOptions="configsOptions"/>

    <editDevice v-if="getModalState" @refresh="refreshTreeListGrid" :configsOptions="configsOptions"/>
    <!-- <addDeviceWithDetails v-if="getModalState" @refresh="refreshTreeListGrid" :closeModal="deviceModal" :sitesOptions="sitesOptions"/> -->
    <!-- <addDeviceWithGenerateToken v-if="getModalState" @refresh="refreshTreeListGrid" :closeModal="addDeviceWithGenerateTokenModal" :sitesOptions="sitesOptions"/> -->
    <openDoor v-if="getModalState" @refresh="refreshTreeListGrid"/>
    <restartDevice v-if="getModalState" @refresh="refreshTreeListGrid"/>
    <deviceSettings v-if="getModalState" @refresh="refreshTreeListGrid"/>
    <restartApp v-if="getModalState" @refresh="refreshTreeListGrid" :appsOptions="appsOptions"/>  
    <installApp v-if="getModalState" @refresh="refreshTreeListGrid" :appsOptions="appsOptions"/>
    <uninstallApps v-if="getModalState" @refresh="refreshTreeListGrid" :appsOptions="appsOptions"/>
    <!-- <clearAppData v-if="getModalState" @refresh="refreshTreeListGrid" :appsOptions="appsOptions"/> -->
    <refreshFaceLibrary v-if="getModalState" @refresh="refreshTreeListGrid"/>
    <deRegisterDevice v-if="getModalState" @refresh="refreshTreeListGrid"/>
    <moveDevice v-if="getModalState" @refresh="refreshTreeListGrid" :sitesOptions="sitesOptions"/>
    <deviceInfo v-if="getModalState" @refresh="refreshTreeListGrid"/>
    <previewLogs v-if="getModalState" @refresh="refreshTreeListGrid"/>
    <deviceApps v-if="getModalState" @refresh="refreshTreeListGrid"/>
    <remoteControl v-if="getModalState" @refresh="refreshTreeListGrid"/>
    <deviceToken v-if="getModalState" @removeBackground="removeBackgroundModal"/>
    <deviceConfiguration v-if="getModalState" @refresh="refreshTreeListGrid" :configsOptions="configsOptions"/>

    <applyJob v-if="getModalState" @removeBackground="removeBackgroundModal" />

    <div v-if="showMassAction" class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h4 class="grid-header-title">Sites & Devices</h4>
        <b-dropdown text="Add" class="add-dropdown-actions">
          <b-dropdown-item @click="siteModal('show')">Site</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="areaModal('show')">Area</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item :title="checkLoggedInUserRole() ? 'You are not authorized to perform this action!': ''" :disabled="checkLoggedInUserRole()" @click="deviceModal('show')">Device</b-dropdown-item>
        </b-dropdown>
      </div>

      <div v-if="!showSideInfo">
        <button :disabled="isSelectedDevices" @click="getSelectedData('mass_restart_devices')" type="button" class="btn btn btn-outline-success m-5 font-14 mass-action-grid-btn">
          <i class="bi bi-phone-flip"></i> Restart
        </button>
  
        <button :disabled="isSelectedDevices" @click="getSelectedData('mass_screenshots')" type="button" class="btn btn-outline-success m-5 font-14 mass-action-grid-btn ">
          <i class="bi bi-fullscreen"></i> Screenshot
        </button>
  
        <button :disabled="isSelectedDevices" @click="getSelectedData('mass_move_devices')" type="button" class="btn btn btn-outline-success m-5 font-14 mass-action-grid-btn">
          <i class="bi bi-arrow-left-right"></i> Move
        </button>
  
        <button :disabled="isSelectedDevices" @click="getSelectedData('mass_devices_config')" type="button" class="btn btn btn-outline-success m-5 font-14 mass-action-grid-btn">
          <i class="bi bi-gear"></i> Config
        </button>
  
        <!-- <button :disabled="isSelectedDevices" @click="getSelectedData('mass_restart_app')" type="button" class="btn btn btn-outline-success m-5 font-14 mass-action-grid-btn">
          <i class="bi bi-arrow-counterclockwise"></i> Restart App
        </button> -->
  
        <button :disabled="isSelectedDevices" @click="getSelectedData('mass_install_app')" type="button" class="btn btn btn-outline-success m-5 font-14 mass-action-grid-btn">
          <i class="bi bi-download"></i> Install App
        </button>

        <button :disabled="isSelectedDevices" @click="getSelectedData('mass_apply_job')" type="button" class="btn btn btn-outline-success m-5 font-14 mass-action-grid-btn">
          <i class="bi bi-list-check"></i> Job
        </button>
  
        <button :disabled="isSelectedDevices" @click="getSelectedData('mass_deregister_devices')" type="button" class="btn btn btn-outline-success m-5 font-14 mass-action-grid-btn">
          <i class="bi bi-trash3"></i> De-register
        </button>
      </div>
    </div>

    <section class="grid-white-section sites-grid main-page-container-animation">
      <div class="col-12 row padding-0">
        <div class="padding-0" :class="showSideInfo ? 'col-8' : 'col-12'">
          <DxTreeList
          id="treelist"
          ref="dataGridTreeRefName"
          :data-source="sitesGrid"
          :show-borders="false"
          :show-column-lines="false"
          :show-row-lines="true"
          :column-auto-width="true"
          :word-wrap-enabled="true"
          :allowColumnResizing="true"
          :row-alternation-enabled="false"
          :two-way-binding-enabled="true"
          :allow-column-reordering="true"
          :focused-row-enabled="true"
          :focused-row-index="0"
          :expanded-row-keys="expandedRowKeys"
          :selected-row-keys="selectedRowKeys"
          key-expr="id"
          parent-id-expr="mod_Parent_ID"
          @content-ready="contentReady($event)"
          @editor-preparing="onEditorPreparing($event)"
          @context-menu-preparing="addMenuItems"
          @focused-row-changed="onFocusedRowChanged"
          @selection-changed="onSelectionChanged"
          @cell-prepared="OnCellPrepared"
          @row-click="onClickedRowChanged"
          >
        >
          <DxStateStoring
            :enabled="true"
            type="custom"
            :customSave="customSave"
            :customLoad="customLoad"
          />
  
          <DxHeaderFilter :visible="true" />

          <DxSearchPanel :width="200" :visible="true" placeholder="Search ..."/>
  
          <DxSelection mode="multiple" :recursive="true" />        
          <DxColumnChooser :enabled="true" mode="select" />
          <!-- <DxFilterRow :visible="true" /> -->
          <DxLoadPanel :enabled="true" />
  
          <!-- start::Toolbar -->
          <DxToolbar>
            <!-- <DxItem location="before" name="columnChooserButton"/> -->
            <!-- <DxItem location="before" name="exportButton" /> -->
            <DxItem location="before" template="columnButtonTemplate"/>
            <DxItem location="before" template="exportButtonTemplate"/>
            <DxItem location="before" template="clearAllTemplate"/>
            <!-- <DxItem location="center" template="openDoorButtonTemplate"/> -->
            <!-- <DxItem location="center" template="restartDeviceButtonTemplate"/> -->
            <!-- <DxItem location="center" template="screenshotButtonTemplate"/> -->
            <!-- <DxItem location="center" template="moveButtonTemplate"/> -->
            <!-- <DxItem location="center" template="applyConfigButtonTemplate"/> -->
            <!-- <DxItem location="center" template="restartAppButtonTemplate"/> -->
            <!-- <DxItem location="center" template="installAppButtonTemplate"/> -->
            <!-- <DxItem location="center" template="uninstallAppButtonTemplate" /> -->
            <!-- <DxItem location="center" template="deleteButtonTemplate" /> -->
            <DxItem location="after" name="searchPanel" />
            <!-- <DxItem location="after" name="exportButton" /> -->

          </DxToolbar>
  
          <!-- end::Toolbar -->
  
          <!-- start::scrolling -->
          <DxScrolling mode="standard" :use-native="true" />
          <!-- end::scrolling -->
  
          <DxColumn
            data-field="name"
            caption="Name"
            cell-template="name-cell"
            :allow-searching="true"
            :allow-reordering="false"
            :allow-exporting="true"
            :allow-header-filtering="false"
            data-type="string"
            :minWidth="300"
          />
  
          <DxColumn
            data-field="description"
            :visible="false"
            caption="Description"
            :allow-searching="true"
            :allow-header-filtering="false"
            :filter-operations="allowedOperations"
            data-type="string"
          />
  
          <DxColumn
            data-field="num_areas"
            :visible="false"
            caption="Areas Num"
            :allow-searching="true"
            :allow-header-filtering="false"
            :filter-operations="allowedOperations"
            data-type="number"
          />
  
          <DxColumn
            data-field="num_devices"
            caption="Devices Num"
            :allow-searching="true"
            :allow-sorting="false"
            :allow-header-filtering="false"
            :filter-operations="allowedOperations"
            data-type="number"
          />
  
          <!-- <DxColumn
            data-field="contact_email"
            :visible="false"
            caption="Contact Email"
            :allow-searching="true"
            :allow-header-filtering="false"
            :filter-operations="allowedOperations"
            data-type="string"
          /> -->

          <DxColumn
            data-field="config_name"
            caption="Configuration"
            :allow-searching="true"
            :allow-sorting="false"
            :allow-header-filtering="true"
            :filter-operations="allowedOperations"
            data-type="string"
          />
  
          <DxColumn
            data-field="timezone"
            caption="Time Zone"
            :allow-searching="true"
            :allow-sorting="false"
            :allow-header-filtering="true"
            :filter-operations="allowedOperations"
            data-type="string"
          />
  
          <DxColumn
            data-field="status"
            caption="Status"
            cell-template="status-cell"
            :allow-searching="true"
            :allow-header-filtering="false"
            :filter-operations="allowedOperations"
            data-type="string"
          />
  
          <template #name-cell="{ data }">
            <span v-if="data.data.data_type == 'site' || data.data.data_type == 'area'" > {{data.data.name}}</span>
            <span v-if="data.data.data_type == 'device'" class="d-flex" >  
              <i @click="action('preview_logs', data.data)" class="cursor-pointer"  :title="data.data.jobs_status == 'succeeded' ? 'Succeeded' : data.data.jobs_status == 'failed' ? 'Failed' : data.data.jobs_status == 'pending' ? 'Pending' : 'No Jobs'"
              :class="data.data.jobs_status == 'succeeded' ? 'bi bi-check-circle-fill succeeded-status' 
              : data.data.jobs_status == 'failed' ? 'bi bi-x-circle-fill failed-status' 
              : data.data.jobs_status == 'pending' ? 'bi bi-exclamation-circle-fill pending-status' 
              : 'bi bi-record-fill'"></i> <span class="ml-1"> {{data.data.name}}</span> 
            </span>
          </template>
  
          <template #status-cell="{ data }">
            <span :class="data.data.is_online ? 'online-status' : data.data.is_offline ? 'offline-status' : ''" >{{data.data.status}}</span>
          </template>
  
          <template #exportButtonTemplate>
            <!-- <DxDropDownButton  class="exportGridHeader" :items="buttonData" text="Export" @item-click="onItemClick" /> -->
            <button  @click="onItemClick('excel')" type="button" class="btn action-btn m-5 font-20"><i class="bi bi-filetype-xlsx"></i> </button>
          </template>
  
          <template #columnButtonTemplate>
            <!-- <button @click="showColumnChooser" class="custom-columns mx-1">
              <span class="custom-text">Columns </span>
              <span class="custom-icon">
                <i class="dx-icon dx-icon-spindown dx-icon-right"></i>
              </span>
            </button> -->

            <button  @click="showColumnChooser" type="button" class="btn action-btn font-20 m-5"><i class="bi bi-columns"></i> </button>
          </template>
  
          <template #clearAllTemplate>
            <div v-if="showClearAll" class="clear-cols">
              <div class="custom-line"></div>

              <!-- <Button
                text="Clear All"
                @click="clearFilter()"
                styling-mode="outlined"
                type="danger"
                class="clear-colums-filter"
              /> -->

              <button @click="clearFilter()" type="button" class="btn action-btn clear-colums-filter m-5">Clear Filters </button>
            </div>
          </template>
  
          <!-- <template #openDoorButtonTemplate>
            <button @click="getSelectedData('open-door')" type="button" id="openDoorMassAction" class="btn action-btn m-5"><i class="bi bi-door-open"></i> 
              <DxTooltip :target="'#openDoorMassAction'" show-event="mouseenter" hide-event="mouseleave" :position="'top'"
               :hide-on-outside-click="false">Open Door</DxTooltip>
            </button>
          </template> -->
  
  
          <!-- <template #restartDeviceButtonTemplate>
            <button @click="getSelectedData('mass_restart_devices')" type="button" id="restartDeviceMassAction" class="btn action-btn m-5 font-14 ">
              <i class="bi bi-phone-flip"></i> Restart
              <DxTooltip :target="'#restartDeviceMassAction'" show-event="mouseenter" hide-event="mouseleave" :position="'top'"
              :hide-on-outside-click="false">Restart Device</DxTooltip>
            </button>
          </template> -->
  
          <!-- <template #screenshotButtonTemplate>
            <button @click="getSelectedData('mass_screenshots')" type="button" id="screenshotMassAction" class="btn action-btn m-5 font-14 ">
              <i class="bi bi-fullscreen"></i> Screenshot
              <DxTooltip :target="'#screenshotMassAction'" show-event="mouseenter" hide-event="mouseleave" :position="'top'"
              :animation="animationConfigFade" :hide-on-outside-click="false">Screenshot</DxTooltip>
            </button>
          </template> -->
  
          <!-- <template #moveButtonTemplate>
            <button @click="getSelectedData('mass_move_devices')" type="button" id="moveDeviceMassAction" class="btn action-btn m-5 font-14 ">
              <i class="bi bi-arrow-left-right"></i> Move
              <DxTooltip :target="'#moveDeviceMassAction'" show-event="mouseenter" hide-event="mouseleave" :position="'top'"
              :animation="animationConfig" :hide-on-outside-click="false">Move Device</DxTooltip>
            </button>
          </template> -->
  
          <!-- <template #applyConfigButtonTemplate>
            <button @click="getSelectedData('mass_devices_config')" type="button" id="applyConfigDeviceMassAction" class="btn action-btn m-5 font-14 ">
              <i class="bi bi-gear"></i> Config
              <DxTooltip :target="'#applyConfigDeviceMassAction'" show-event="mouseenter" hide-event="mouseleave" :position="'top'"
              :animation="animationConfig" :hide-on-outside-click="false">Apply Config</DxTooltip>
            </button>
          </template> -->
  
          <!-- <template #restartAppButtonTemplate>
            <button @click="getSelectedData('mass_restart_app')" type="button" id="restartAppMassAction" class="btn action-btn m-5 font-14 ">
              <i class="bi bi-arrow-counterclockwise"></i> Restart App
              <DxTooltip :target="'#restartAppMassAction'" show-event="mouseenter" hide-event="mouseleave" :position="'top'"
              :animation="animationConfig" :hide-on-outside-click="false">Restart App</DxTooltip>
            </button>
          </template> -->
          
          <!-- <template #installAppButtonTemplate>
            <button @click="getSelectedData('mass_install_app')" type="button" id="installAppMassAction" class="btn action-btn m-5 font-14 ">
              <i class="bi bi-download"></i> Install App
              <DxTooltip :target="'#installAppMassAction'" show-event="mouseenter" hide-event="mouseleave" :position="'top'"
              :animation="animationConfig" :hide-on-outside-click="false">Install App</DxTooltip>
            </button>
          </template> -->
  
          <!-- <template #uninstallAppButtonTemplate>
            <button type="button" id="uninstallAppMassAction" class="btn action-btn m-5"><i class="bi bi-slash-circle"></i>
              <DxTooltip :target="'#uninstallAppMassAction'" show-event="mouseenter" hide-event="mouseleave" :position="'top'"
              :animation="animationConfig" :hide-on-outside-click="false">Uninstall App</DxTooltip>
            </button>
          </template> -->
  
          <!-- <template #deleteButtonTemplate>
            <button  @click="getSelectedData('mass_deregister_devices')" type="button" id="deregisterDeviceMassAction" class="btn action-btn m-5 font-14 ">
              <i class="bi bi-trash3"></i> De-register
              <DxTooltip :target="'#deregisterDeviceMassAction'" show-event="mouseenter" hide-event="mouseleave" :position="'top'"
              :animation="animationConfig" :hide-on-outside-click="false">De-register</DxTooltip>
            </button>
          </template> -->
  
  
        </DxTreeList>
        </div>
        <div v-if="showSideInfo" class="col-4 kiosk-actions-container" >
            
          <span @click="closeSideInfo()" class="kiosk-action-bar-close-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="22"
              height="22" viewBox="0 0 22 22" fill="none" >
                <path
                  d="M11 0C4.91333 0 0 4.91333 0 11C0 17.0867 4.91333 22 11 22C17.0867 22 22 17.0867 22 11C22 4.91333 17.0867 0 11 0ZM16.5978 14.7156C17.1111 15.2289 17.1111 16.06 16.5978 16.5733C16.3289 16.8422 16.0111 16.9644 15.6689 16.9644C15.3267 16.9644 14.9844 16.8422 14.74 16.5733L11.0733 12.9067L7.38222 16.5733C7.11333 16.8422 6.79556 16.9644 6.45333 16.9644C6.11111 16.9644 5.76889 16.8422 5.52444 16.5733C5.01111 16.06 5.01111 15.2289 5.52444 14.7156L9.21556 11.0244L5.52444 7.35778C5.01111 6.84444 5.01111 6.01333 5.52444 5.5C6.03778 4.98667 6.86889 4.98667 7.38222 5.5L11.0733 9.19111L14.7644 5.5C15.2778 4.98667 16.1089 4.98667 16.6222 5.5C17.1356 6.01333 17.1356 6.84444 16.6222 7.35778L12.9311 11.0489L16.5978 14.7156Z"
                  fill="#F46A6A"
                />
            </svg>
          </span>

          <!-- Start :: kiosk actions -->
            <section class="actions-item-container info-white-section" style="height: fit-content; overflow: hidden;">
              <template v-if="hideSkeletor">
                <div v-for="(value,key) in availableKioskActions" :key='key'>
                  <div class="actions-item" @click="action(value.action, deviceDataObj)">
                    <i :class="`bi ${value.icon}`"></i>
                    <p>{{value.text}}</p>
                  </div>
                </div>
              </template>
              <template v-else>
                <KioskActionsBarSkeletor/>
              </template>
            </section>  
          <!-- End :: kiosk actions -->

        
          <!-- Start:: Kiosk data -->
            <!-- Start:: Kiosk info -->
            <template v-if="deviceData">
              <section class="info-white-section pa-10" style="height: fit-content; overflow: hidden; margin-top: 10px;">
                <div class="mb-15">
                  <h5 v-if="hideSkeletor" class="underline section-header">Device Info</h5>
                  <Skeletor v-else width="150" as="div" />
                </div>
                  
                <div class="device-info-container">    
                    <div class="kiosk-info-section w-100">
                      <template v-if="hideSkeletor">
                        <template  v-for="(value,key) in deviceData.deviceInfo" :key="key">
                          <div v-for="(value,key) in value" :key="key" class="device-info-row d-flex">
                            <p class="kiosk-keys col-4 p-0">{{ `${key.charAt(0).toUpperCase()}${key.slice(1)}` }}</p>
                            <p class="device-info-value col-8 p-0"> {{ value }}</p>
                          </div>
                        </template>
                      </template>

                      <template v-else>
                          <DeviceInfoSkeletor/>
                      </template>
                    </div>
                  </div>
              </section>
            <!-- End:: Kiosk info -->

            <!-- Start:: Network -->
            <!-- <section class="info-white-section pa-10" style="height: fit-content; overflow: hidden; margin-top: 10px;">
              <div class="mb-15">
                <h5  v-if="hideSkeletor" class="underline section-header">Netowrk</h5>
                <Skeletor v-else width="150" as="div" />
              </div>
                <div class="device-info-container">    
                  <div class="kiosk-info-section w-100">
                    <template v-if="hideSkeletor">
                      <template  v-for="(value,key) in deviceData.network" :key="key">
                        <div v-for="(value,key) in value" :key="key" class="device-info-row d-flex">
                          <p class="kiosk-keys col-4 p-0">{{ `${key.charAt(0).toUpperCase()}${key.slice(1)}` }}</p>
                          <p class="device-info-value col-8 p-0"> {{ value }}</p>
                        </div>
                      </template>
                    </template>

                    <template v-else>
                        <DeviceInfoSkeletor/>
                    </template>
                  </div>
                </div>
              </section> -->
            <!-- End:: Network -->

            <!-- Start:: Disk -->
            <section class="white-bg disk-section">
              <div class="mb-15">
                <h5  v-if="hideSkeletor" class="underline section-header">Disk</h5>
                <Skeletor v-else width="150" as="div" />
              </div>
              <!-- <p class="section-info">Total Memory: {{ deviceData.disk?.total }}</p> -->

              <div v-if="hideSkeletor">
                <div class="disk-stats">
                  <p class="text-center">Total Disk Space: {{ (deviceData.disk?.total / 1024).toFixed(1) }} GB</p>

                  <DiskChart :diskData="deviceData.disk"/>
                  
                  <div class="disk-memory-stats"> 
                    <div class="d-flex">
                      <div class="used-space-stats"></div> 
                      <p>Used Disk Space: {{ (deviceData.disk?.used / 1024).toFixed(1) }} GB</p>
                    </div>

                    <div class="d-flex">
                      <div class="available-space-stats"></div> 
                      <p>Available Disk Space: {{ (deviceData.disk?.available / 1024).toFixed(1) }} GB</p>
                    </div>
                  </div>
                </div>
              </div>
              <template v-else>
                <DiskInfoSkeletor/>
              </template>
            </section>
            <!-- End:: Disk -->

            <section class="d-flex white-bg mt-10 pa-10 battery-memory-section">
              <!-- Start:: Battery -->
              <div
                class="battery-section"
                style="height: fit-content; overflow: hidden; margin-top: 10px"
              >
                <div class="mb-2">
                  <h5 v-if="hideSkeletor" class="underline section-header">Battery</h5>
                  <Skeletor v-else width="120" as="div" />
                </div>

                <div v-if="hideSkeletor">
                  <div v-if="deviceData.battery">
                    <p class="section-info">Main Battery </p>
                  
                    <b-progress :max="100">
                      <b-progress-bar variant="success" :value="deviceData.battery">
                        <span> {{ deviceData.battery }}%</span>
                      </b-progress-bar>
                      <b-progress-bar
                        :value="100 - deviceData.battery"
                        class="progress-total-bg"
                      />
                    </b-progress>
                  </div>

                  <div v-else class="no-battery-message">
                    <p>Not Detected</p>
                  </div>
                </div>
                <template v-else>
                  <BatterySkeletor/>
                </template>

              </div>
              <!-- End:: Battery -->

              <div class="vertical-line"></div>

              <!-- Start:: Memory -->
              <div
                v-if="deviceData.memory"
                class="memory-section "
                style="height: fit-content; overflow: hidden; margin-top: 10px"
              >
                <div class="mb-2">
                  <h5 v-if="hideSkeletor" class="underline section-header">Memory</h5>
                  <Skeletor v-else width="120" as="div" />
                </div>
                <div v-if="hideSkeletor">
                    <p class="section-info">Memory Usage</p>

                    <b-progress :max="deviceData.memory?.total">
                      <b-progress-bar
                        :value="deviceData.memory.used"
                        variant="success"
                      >
                        <span> {{ deviceData.memory.percentage }}%</span>
                      </b-progress-bar>

                      <b-progress-bar
                        :value="deviceData.memory.total - deviceData.memory.used"
                        class="progress-total-bg"
                      />
                    </b-progress>
                    <div class="memory-stats-container">
                      <p class="m-0"> {{deviceData.memory.used}}MB <b>used</b> memory</p>
                      <p class="m-0">{{deviceData.memory.total - deviceData.memory.used}}MB <b>free</b> of {{ deviceData.memory.total }}MB</p>
                    </div>
                  </div>

                  <template v-else>
                    <MemorySkeletor/>
                  </template>
                </div>
              <!-- End:: Memory -->
            </section>

            
          </template>
          <!-- End :: Kiosk data -->
          </div>

      </div>
    </section>
  </DefaultLayout>
</template>

<style>
.sites-grid{
  p{
    font-size:12px;
    line-height: 25px;
  }
}
.kiosk-actions-container{
  margin-top: -25px;
  height:80.7vh;
  overflow:auto;
}
.actions-item-container{
  display: flex;
  justify-content: space-evenly;
  column-gap: 30px;
  padding: 5px 20px;
}
.battery-section{
  margin-right:20px;
}
.memory-section{
  margin-left:20px;
}
.battery-section , .memory-section{
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
}
.actions-item{    
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 5px 5px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 23px !important;

  p{
    margin: 0;
  }
  .bi-phone-flip::before ,.bi-columns-gap::before ,.bi-pc-display-horizontal::before,.bi-arrow-repeat::before{
    font-size: 23px;
  }
}
.kiosk-info-section p{
    margin: 0;
}
.kiosk-keys{
  font-weight: 600;
}
.device-info-container{
display: flex;
justify-content: space-between;
}



.actions-item:hover{
  color: #198754;
}
.memory-stats-container{
  width: fit-content;
  margin: auto;
}
.underline{
  text-decoration: underline;
}
.section-header{
  font-size: 14px;
}

.section-info{
  margin: 0;
  font-weight: 600;
}
.disk-stats{
  margin-top:15px;

}

.used-space-stats{
  width: 21px;
  height: 23px;
  background-color: #377EB8;
  margin-right: 10px;
}
.available-space-stats{
  margin-right: 10px;
  width: 21px;
  height: 23px;
  background-color: #4DAF4A
}
.kiosk-action-bar-close-btn{
  display:flex;
  flex-direction:row-reverse;
  cursor:pointer;
}
.kiosk-action-bar-close-btn svg{
  transform:  translateY(15px) translateX(8px);
}
.progress-total-bg{
  background-color: #DCDCDC;
}
.white-bg{
  background: #fff !important;
}
.vertical-line{
  border-left: 1px solid #DCDCDC;
}
.total-memory-label{
  margin : 0 0 15px 28px;
}
.device-info-value{
  word-break :break-all;
  width: 60%;
  padding-right:35px;
}
.battery-memory-section{
  border-radius:8px;
}
.disk-section{
  height: fit-content;
  overflow:hidden !important;
  padding: 10px 10px 0 10px;
  margin-top:10px;
  border-radius:8px;
}
.disk-memory-stats{
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 15px auto;
}
.no-battery-message{
  height: auto;
  display: flex;
  justify-content: center;
  color: #495057;
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>