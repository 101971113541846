<script setup>
import { ref, defineProps, onMounted, reactive } from "vue";
import { Modal }  from "bootstrap";
import { useStore } from "vuex";
import router from "@/router";

// const user = computed(()=>JSON.parse(localStorage.getItem('userDetails')));
const store = useStore();

const props = defineProps({
  data: Object,
});

let items = ref([]);

const state = reactive({
  editConfigModal: null,
  configSettingModal : null,
  deleteConfigModal: null,
});

const action = async (action, data) => {

  await store.commit('setModalAction', action);
  store.commit('setModalState', true);
  await store.commit('setModalData', data);

  if(action == 'edit_config'){
    state.editConfigModal = new Modal("#editConfigModal", {});
    state.editConfigModal.show();
  }else if(action == 'config_setting'){
    state.configSettingModal = new Modal("#configSettingModal", {});
    state.configSettingModal.show();
  }
  else if(action == 'config_settings'){
    router.push(`/configurations/${props.data.id}`)
  } 
  else if(action == 'delete_config'){
    state.deleteConfigModal = new Modal("#deleteConfigModal", {});
    state.deleteConfigModal.show();
  }
}

onMounted(() => {

  items.value.push(
    {
      icon : 'bi bi-pencil-square',
      text: "Edit Config", 
      action : 'edit_config',
      visible: true,
      data : props.data,
    },
    {
      icon : 'bi bi-building-fill-gear',
      text: "Settings Config",
      action: "config_settings",
      visible: true,
      data : props.data,
    },
    {
      icon : 'bi bi-trash3',
      text: "Delete",
      action: "delete_config",
      visible: true,
      data : props.data,
    },
  );
    // console.log('items', items.value);
});
</script>

<template>
    <div>
      <button v-for="(item, index) in items" :key="index" @click="action(item.action, props.data)" type="button" class="btn action-btn" :title="item.text"><i :class="item.icon"></i></button>
    </div>
</template>