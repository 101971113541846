<script setup>
  import { ref, defineEmits, computed, onBeforeMount } from "vue";
  import { useStore } from "vuex";

  const emit = defineEmits(['refresh']);
  const store = useStore();
  const companyId = localStorage.getItem('currentCompanyId');

  let id = ref(null);
  let name = ref(null);
  let isSubmitted = ref(false);
  const getModalInfo = computed(()=> store.getters.getModalInfo);
  const getModalAction = computed(()=> store.getters.getModalAction);

  const onSubmit = () => {
    isSubmitted.value = true;
    store.dispatch("deleteConfig", {id : id.value, companyId })
    .then((res) => {
      console.log('res', res);
      resetStoreData();
      emit('refresh');
    })
    .catch((e) => {
      console.log("e", e);
      isSubmitted.value = false;
    });
  };

  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);
  };

  onBeforeMount(()=>{
    if(getModalAction.value == 'delete_config'){
        console.log('getModalAction.valueeee', getModalAction.value);
        console.log('getModalInfo.valueeee', getModalInfo.value);
        id.value = getModalInfo.value.id;
        name.value = getModalInfo.value.name;
    }
  });
</script>
<template>
  <DModal id="deleteConfigModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Delete Configuration </template>
    <template #body>
      <div class="delete-text">
        <span>Are you sure you want to <b>"Delete"</b> the config <b>{{ name }}</b> ?</span>
      </div>
    </template>

    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button @click="resetStoreData" type="button" class="btn btn-primary add-button mr-30" data-bs-dismiss="modal">Cancel</button>
          <button :disabled="isSubmitted" @click="onSubmit" type="button" class="btn btn-secondary cancel-button" >
            Delete <span v-if="isSubmitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </template>
  </DModal>
</template>
  