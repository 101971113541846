import Api from "./Api";

export default {
  getCompany(id) {
    return Api().get(`/company-details/${id}`);
  },
  editCompany(payload) {
    return Api().put(`/company-details/${payload.id}`, payload);
  },
  getCompanySettings(id) {
    return Api().get(`/company-settings/${id}`);
  },
  updateCompanySettings(payload) {
    return Api().put(`/company-settings/${payload.companyId}`, payload.settings);
  },
  generateManageSubscriptionUrl(payload) {
    return Api().get(`/manage-subscription/${payload.companyId}/${payload.chargebeeId}`);
  },
  generateToken(companyId) {
    return Api().post(`/generate-device-token/${companyId}`);
  },
  getSubscriptionInfo(id) {
    return Api().get(`/subscription/${id}`);
  },
  // getAllCompanyApps(id) {
  //   return Api({ daemonRequest: true }).post(`/kiosks/get-all-company-apps/${id}`);
  // },
  getAllCompanyApps(id) {
    return Api().get(`/company-apps/${id}`);
  },
  getPreInstalledCompanyApps(id) {
    return Api({ daemonRequest: true }).post(`/kiosks/get-all-company-apps/${id}`);
  },
  getUnreadMessagesCounter(id) {
    return Api().get(`/count-unread-messages/${id}`);
  },
  getMessagesDetails(payload) {
    return Api().get(`/message/${payload.companyId}/${payload.id}`);
  },
};