<script setup>
  import { ref, defineEmits, onBeforeMount, computed } from "vue";
  import { useStore } from "vuex";

  const emit = defineEmits(['refresh']);
  const store = useStore();

  let isSubmitted = ref(false);
  let name = ref(null);
  let mac_address = ref(null);
  const company_id = computed(()=>localStorage.getItem("currentCompanyId"));
  // const global_user_name = inject(['global_user_name']);
  const user = computed(()=>JSON.parse(localStorage.getItem('userDetails')));

  const getModalInfo = computed(()=> store.getters.getModalInfo);
  const getModalAction = computed(()=> store.getters.getModalAction);

  const onSubmit = () => {
    isSubmitted.value = true;
    let data = { 
      entityId: company_id.value,
      kioskName : name.value,
      macAddress : mac_address.value,
      userName : user.value.first_name+' '+ user.value.last_name,
      userId : user.value.user_id,
      connectionType : 'daemon',
    }

    store.dispatch("refreshFaceLibrary", data)
    .then((res) => {
      console.log('res', res);
      resetStoreData();
      emit('refresh');
    })
    .catch((e) => {
      console.log("e", e);
      isSubmitted.value = false;
    });
  };

  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);
  };

  onBeforeMount(()=>{
    if(getModalInfo.value && getModalInfo.value.data_type == 'device' && getModalAction.value == 'refresh_face_library'){
      console.log('getModalInfo', getModalInfo.value);
      name.value = getModalInfo.value.name;
      mac_address.value = getModalInfo.value.mac_address;
    }
  });
</script>

<template>
    <DModal id="refreshFaceLibraryModal" :main_classes="[]" :dialog_classes="[]">
      <template #title> Refresh Face Library </template>
  
      <template #body>
        <div class="delete-text">
          <span>Are you sure you want to <b>"Refresh"</b> the device "{{ name }}" ?</span>
        </div>
      </template>
  
      <template #footer>
        <div class="w-100">
          <div class="seperator text-center"></div>
          <div class="d-flex mt-20 justify-content-center">
            <button :disabled="isSubmitted" 
            @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
              Refresh <span v-if="isSubmitted"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
            </button>
            <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>
      </template>
    </DModal>
  </template>
  