<script setup>
  import { ref, defineEmits, computed, onBeforeMount } from "vue";
  import { useStore } from "vuex";
  
  const store = useStore();
  const emit = defineEmits(["refresh"]);
  const company_id = localStorage.getItem("currentCompanyId");
  const getModalInfo = computed(()=> store.getters.getModalInfo);
  const getModalAction = computed(()=> store.getters.getModalAction);

  let id = ref(null);
  let name = ref(null);
  let description = ref(null);
  let isSubmitted = ref(false);

  const onSubmit = () => {
    isSubmitted.value = true;
    const data = {
        id : id.value,
        name: name.value,
        description: description.value,
        company_id,
    };

    store.dispatch("editConfig", data).then(() => {
      isSubmitted.value = false;
      emit("refresh");
      resetStoreData();
    }).catch((e) => {
      console.log(e);
      isSubmitted.value = false;
    });
  };


  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);

  };

  onBeforeMount(()=>{
    console.log('getModalAction.valueeee', getModalAction.value);
    console.log('getModalInfo.valueeee', getModalInfo.value);
    if(getModalAction.value == 'edit_config'){
      id.value = getModalInfo.value.id;
      name.value = getModalInfo.value.name;
      description.value = getModalInfo.value.description;
    }
  });
</script>

<template>
  <DModal id="editConfigModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Edit Config </template>

    <template #body>

      <div class="form-floating mb-3">
        <input type="text" v-model.trim="name" class="form-control" id="name" placeholder=""/>
        <label for="name">Name</label>
      </div>

      <div class="form-floating mb-3">
        <textarea v-model="description" class="form-control none-resize h-105" placeholder="description" id="description"/>
        <label for="description">Description</label>
      </div>

    </template>
    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button :disabled="!name || isSubmitted"
            @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
            Edit <span v-if="isSubmitted"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
          </button>
          <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </template>
  </DModal>
</template>
