<script setup>
  import { ref, onBeforeMount, onUnmounted} from "vue";
  import { useRoute } from 'vue-router'
  import router from "@/router";  

  import DefaultLayout from "@/views/layouts/DefaultLayout.vue";
  import moment from "moment-timezone";
  import { useStore } from "vuex";
  const company_id = localStorage.getItem("currentCompanyId");

  const store = useStore();
  const route = useRoute()
  let id = ref(null);
  let name = ref(null);
  let description = ref(null);
  let device_name = ref(null);
  let time_zone = ref(null);
  let timeZoneNames = ref([]);
  let system_fan = ref(true);
  let show_status_bar = ref(false);
  let schedule_reboot = ref(false);
  let gps = ref(false);
  let bluetooth = ref(false);
  let mobile_data = ref(false);
  let schedule_reboot_time = ref({
      hours: 0,
      minutes: 0,
  });
  let brightness = ref(null);
  let volume = ref(null);
  let settingsArr = ref([]);
  let preinstalledApps = ref([]);
  let appsOptions = ref([]);
  let preinstalledAppsOptions = ref([]);
  let appMode = ref({
    type : '' , 
    apps : '', 
    timeMillisecond: 0, 
    broadcastEnable : 1
  });
  let networkGuard = ref({ 
    enable: 1 , 
    type: 0, 
    time: 15, 
    ipInternet: null, 
    ipIntranet: null,
    logPath : 'directory/NetworkProtectLog',
    reboot : 1,
  });

  // let daemonApp = ref({
  //   packageName: '' , 
    
  // });

  let appModeTypeOptions = ref(['Single', 'None']);
  let isLoadingApps = ref(true);
  let isLoadingPreinstalledApps = ref(true);
  let isDisableAppType = ref(false);

  let isLoading = ref(true);
  let isSubmitted = ref(false);
  let applicattionModaSelection = ref(true);

  let autoBrightnessFields = ref([]);
  let autoOnOffFields = ref([]);

  let networkGuardTypeOptions = ref([
    {id : 0, name : 'Automatic'},
    {id : 1, name :'Ethernet'}, 
    {id : 2, name :'WIFI'}, 
    {id : 3, name :'Mobile network'}
  ]);

  let networkGuardType = ref(null);
  // let daemonAppPackageName = ref(null);
  let isInvalidNetworkGuardTime = ref(false);
  
  // let ipInternet = ref([]);
  // let ipIntranet = ref([]);
  // let ipInternetCode = ref('');
  // let ipIntranetCode = ref('');

  // let regexNumberPattern = /^\d*$/

  // watch(ipInternet.value, async (value) => {
  //   console.log('currentValue', value);
  //   ipInternetCode.value = '';
  //   ipInternetCode.value = await value.join('.');
  //   console.log('ipInternet', value);
  //   console.log('ipInternetCode', ipInternetCode.value);
  // });

  // watch(ipIntranet.value, async (value) => {
  //   console.log('currentValue', value);
  //   ipIntranetCode.value = '';
  //   ipIntranetCode.value = await value.join('.');
  //   console.log('ipIntranet', value);
  //   console.log('ipIntranetCode', ipIntranetCode.value);
  // });

  const addAutoBrightnessField = () => {
    let currentDateTime = moment(new Date()).format('HH:mm');
    currentDateTime = currentDateTime.split(":");

    let currentDateTimeObj = ref({
      hours: currentDateTime[0],
      minutes: currentDateTime[1],
    })
    autoBrightnessFields.value.push({ start : currentDateTimeObj, end : currentDateTimeObj, value: 50, from : '', to : '' });
  };

  const changeTypeVal = () => {
    console.log('appMode.value.type', appMode.value.type);

    isDisableAppType.value = false;
    applicattionModaSelection.value = false;
    appMode.value.apps = '';
    appMode.value.timeMillisecond = 0;
    appMode.value.broadcastEnable = 0;

    if(appMode.value.type == 'Single'){
      applicattionModaSelection.value = false;
    }else if(appMode.value.type == 'Multiple'){
      applicattionModaSelection.value = true;
    }else if(appMode.value.type == 'None'){
      isDisableAppType.value = true;
    }

  }
  
  const removeAutoBrightnessField = (index)  =>  {
    // if (autoBrightnessFields.value.length > 1) {
      autoBrightnessFields.value.splice(index, 1)
    // }
  };

  const addAutoOnOffField = () => {
    let currentDateTime = moment(new Date()).format('HH:mm');
    currentDateTime = currentDateTime.split(":");

    let currentDateTimeStartObj = ref({
      hours: currentDateTime[0],
      minutes: currentDateTime[1],
    })
    let currentDateTimeEndObj = ref({
      hours: currentDateTime[0],
      minutes: currentDateTime[1],
    })

    autoOnOffFields.value.push({ start : currentDateTimeStartObj, end : currentDateTimeEndObj, value: 'off', from : '', to : '' });
  };

  // const removeAutoOnOffField = (index)  =>  {
  //   // if (autoOnOffFields.value.length > 1) {
  //     autoOnOffFields.value.splice(index, 1)
  //   // }
  // };
  
  const formatingTimeObject = async (array) => {
    if(array.length) {
      await array.map( async(obj) => {

        let fromTime = obj.from.split(":");
        let toTime = obj.to.split(":");

        obj.start = await {  hours: fromTime[0], minutes: fromTime[1] };
        obj.end = await { hours: toTime[0], minutes: toTime[1] };

        if(obj.value == 'on' || obj.value == 'off'){
          if(obj.value == 'on') obj.value = true;
          else obj.value = false
        }
      });

      return array;
    }
  }

  const reformatingTimeObjectBeforeSubmit = async (array) => {

    if(array.length) {
      await array.map( async(obj) => {

        obj.from = await moment(obj.start).format('HH:mm');
        obj.to = await moment(obj.end).format('HH:mm');

        if(obj.value === true || obj.value === false){
          if(obj.value) obj.value = 'on';
          else obj.value = 'off'
        }
      });
      
      return array;
    }
  }

  const changeNetworkGuardTime = () => {
    isInvalidNetworkGuardTime.value = false;
    if( networkGuard.value.time < 3 || networkGuard.value.time > 15 )  isInvalidNetworkGuardTime.value = true;
  }

  // const moveToNext = async (e, type, index) => {
  //   // console.log('e moveToNext', e);
  //   // console.log('index', index);
  //   console.log('type', type);
  //   if(e.target.value > 255){
  //     e.target.value = 255;
  //     if(type == 'ip-internet-input'){
  //       ipInternet.value[index] = 255;
  //     }else if(type == 'ip-intranet-input'){
  //       ipIntranet.value[index] = 255;
  //     }
  //     if(index < 3)document.getElementsByClassName(type)[index + 1].focus();
  //   }else if(e.which == 8 && e.key == 'Backspace' && !e.target.value) {
  //     if(type == 'ip-internet-input'){
  //       ipInternet.value.splice(index, 1);
  //     }else if(type == 'ip-intranet-input'){
  //       ipIntranet.value.splice(index, 1);
  //     }
  //     if(index > 0 ) document.getElementsByClassName(type)[index - 1].focus();
  //   } else if(!isNaN(Number(e.key)) && e.target.value.length  == 3){
  //     // e.target?.nextSibling?.focus(); 
  //     if(index < 3)document.getElementsByClassName(type)[index + 1].focus()
  //   } else if(!regexNumberPattern.test(e.target.value)) {
  //     e.target.value = '';
  //     e.returnValue = false;
  //     await e.preventDefault();
  //     await e.stopImmediatePropagation();
  //     return false;
  //   }
  // }


  const onSubmit = async() => {

    isSubmitted.value = true;
    let scheduleRebootTimeString = await moment(schedule_reboot_time.value).format('HH:mm');
    let autoBrightnessVal = await reformatingTimeObjectBeforeSubmit(autoBrightnessFields.value);
    let autoOnOffVal = await reformatingTimeObjectBeforeSubmit(autoOnOffFields.value);

    let preinstalledAppsArr = [];
    console.log('preinstalledApps.value', preinstalledApps.value)

    preinstalledApps.value.map((app) =>{
      let appObj = {};
      appObj.id = app.id;
      appObj.name = app.name;
      appObj.package_name  = app.metadata?.package_name;
      appObj.application_label  = app.metadata?.application_label;
      appObj.application_version_code = app.metadata?.application_version_code;
      appObj.application_version_name = app.metadata?.application_version_name;
      appObj.url = app.url;
      preinstalledAppsArr.push(appObj);
    });

    console.log('networkGuardType.value', networkGuardType.value)

    settingsArr.value.map( (obj) =>{
      if(obj.name == 'device_name'){
        obj.value = device_name.value;
      }else if(obj.name == 'system_fan'){
        obj.value =  system_fan.value ? 1 : 0 ;
      }else if(obj.name == 'schedule_reboot'){
        obj.value =  schedule_reboot.value ? 1 : 0 ;
      }else if(obj.name == 'show_status_bar'){
        obj.value =  show_status_bar.value ? 1 : 0 ;
      }else if(obj.name == 'gps'){
        obj.value =  gps.value ? 1 : 0 ;
      }else if(obj.name == 'bluetooth'){
        obj.value =  bluetooth.value ? 1 : 0 ;
      }else if(obj.name == 'mobile_data'){
        obj.value =  mobile_data.value ? 1 : 0 ;
      }else if(obj.name == 'schedule_reboot_time'){
        obj.value = scheduleRebootTimeString;
      }else if(obj.name == 'brightness'){
        obj.value = brightness.value;
      }else if(obj.name == 'volume'){
        obj.value = volume.value;
      }else if(obj.name == 'time_zone'){
        obj.value = time_zone.value;
      }else if(obj.name == 'auto_brightness'){
        obj.value = autoBrightnessFields.value.length ? JSON.stringify(autoBrightnessVal) : JSON.stringify([]);
      }else if(obj.name == 'auto_on_off'){
        obj.value = autoOnOffFields.value.length ? JSON.stringify(autoOnOffVal) : JSON.stringify([]);
      }else if(obj.name == 'app_mode'){
        appMode.value.broadcastEnable = appMode.value.broadcastEnable ? 1 : 0
        appMode.value.timeMillisecond = appMode.value.timeMillisecond ? appMode.value.timeMillisecond * 1000 : 0 ;
        if(appMode.value.type == 'Single'){
          appMode.value.apps = [appMode.value.apps];
        }else if(appMode.value.type == 'None'){
          appMode.value.apps = [];
        }
        obj.value = JSON.stringify(appMode.value);
      }else if(obj.name == 'pre_installed_apps'){
        obj.value = JSON.stringify(preinstalledAppsArr);
      }else if(obj.name == 'network_guard'){

        networkGuard.value.enable = networkGuard.value.enable ? 1 : 0;
        networkGuard.value.reboot = networkGuard.value.reboot ? 1 : 0;
        networkGuard.value.type = networkGuardType.value ? networkGuardType.value.id : 0;

        // networkGuard.value.ipInternet = ipInternetCode.value ? ipInternetCode.value : null;
        // networkGuard.value.ipIntranet = ipIntranetCode.value ? ipIntranetCode.value : null;

        obj.value =  JSON.stringify(networkGuard.value);

        console.log('networkGuard', networkGuard.value);

      }
      // else if(obj.name == 'daemon_app'){
      //   daemonApp.value.broadcastEnable = daemonApp.value.broadcastEnable ? 1 : 0
      //   daemonApp.value.timeMillisecond = daemonApp.value.timeMillisecond ? daemonApp.value.timeMillisecond * 1000 : 0 ;
      //   daemonApp.value.packageName = daemonAppPackageName.value ? daemonAppPackageName.value.package_name : '';

      //   obj.value =  JSON.stringify(daemonApp.value); 
      // }
    });

    console.log('settingsArr.value', settingsArr.value);

    const data = {
      id : id.value,
      name : name.value,
      description : description.value,
      settings : settingsArr.value,
      company_id,
    };
    console.log('data', data);
    store.dispatch("editConfig", data)
    .then((res) => {
      console.log('res', res);
      router.push("/configurations");
    }).catch((e) => {
      isSubmitted.value = false;
      console.log(e);
    });

  };
  
  onBeforeMount( async () => {
    id.value = route.params.id
    timeZoneNames = moment.tz.names();

    await store.dispatch("fetchAllCompanyApps", company_id )
    .then( async(res) => {
      // console.log('res', res);
      appsOptions.value = res.data;
      isLoadingApps.value = false;
    }).catch( (e) => console.log(e))

    await store.dispatch("fetchAppsLibrary", company_id )
    .then( async(res) => {
      // console.log('res', res);
      preinstalledAppsOptions.value = res.data.data;
      isLoadingPreinstalledApps.value = false;
    }).catch( (e) => console.log(e))

    store.dispatch("getConfigById", {company_id , id : id.value })
    .then( async(res) => {
      name.value = res.data.name;
      description.value = res.data.description;
      if(res && res.data && res.data.settings.length){
        await res.data.settings.map( async (obj) => {
          if(obj.name == 'system_fan'){
              system_fan.value = await parseInt(obj.pivot.value) == 1 ? true : false;
          }else if(obj.name == 'schedule_reboot'){
              schedule_reboot.value = await parseInt(obj.pivot.value) == 1 ? true : false;
          }else if(obj.name == 'show_status_bar'){
              show_status_bar.value = await parseInt(obj.pivot.value) == 1 ? true : false;
          }else if(obj.name == 'gps'){
            gps.value = await parseInt(obj.pivot.value) == 1 ? true : false;
          }else if(obj.name == 'bluetooth'){
            bluetooth.value = await parseInt(obj.pivot.value) == 1 ? true : false;
          }else if(obj.name == 'mobile_data'){
            mobile_data.value = await parseInt(obj.pivot.value) == 1 ? true : false;
          }else if(obj.name == 'device_name'){
              device_name.value = await obj.pivot.value
          }else if(obj.name == 'schedule_reboot_time'){
            let scheduleRebootTime = obj.pivot.value.split(":");
            schedule_reboot_time.value = await {
              hours: scheduleRebootTime[0],
              minutes: scheduleRebootTime[1],
            }
          }else if(obj.name == 'brightness'){
              brightness.value = await parseInt(obj.pivot.value)
          }else if(obj.name == 'volume'){
              volume.value = await parseInt(obj.pivot.value)
          }else if(obj.name == 'time_zone'){
              time_zone.value = await obj.pivot.value
          }else if(obj.name == 'auto_brightness'){
          let arrAutoBrightnessVal = JSON.parse(obj.pivot.value);
          if(arrAutoBrightnessVal.length){
            autoBrightnessFields.value = await formatingTimeObject(arrAutoBrightnessVal);
          }
          obj.pivot.value = autoBrightnessFields.value;
          }else if(obj.name == 'auto_on_off'){
            let arrAutoOnOffVal = JSON.parse(obj.pivot.value);
            if(arrAutoOnOffVal.length){
              autoOnOffFields.value = await formatingTimeObject(arrAutoOnOffVal);
            }
            obj.pivot.value = autoOnOffFields.value;
          }else if(obj.name == 'app_mode'){
            obj.pivot.value = JSON.parse(obj.pivot.value);
            appMode.value = obj.pivot.value 
            appMode.value.broadcastEnable = appMode.value.broadcastEnable ? true : false;
            appMode.value.timeMillisecond = appMode.value.timeMillisecond ? appMode.value.timeMillisecond / 1000 : 0;

            if(appMode.value.type == 'Multiple' || appMode.value.type == 'None'){
              appMode.value.broadcastEnable = false;
              appMode.value.timeMillisecond = 0;
            }

            if(!appMode.value.apps){
              if(appMode.value.type == 'Single'){
                applicattionModaSelection.value = false;
              }else if(appMode.value.type == 'Multiple'){
                applicattionModaSelection.value = true;
              }else if(appMode.value.type == 'None'){
                isDisableAppType.value = true;
              }
              appMode.value.apps = [];
            }else{

              if(appMode.value.type == 'Single'){
                applicattionModaSelection.value = false;
                appMode.value.apps = appMode.value.apps[0];
              }else if(appMode.value.type == 'Multiple'){
                applicattionModaSelection.value = true;
              }
            }

          }else if(obj.name == 'pre_installed_apps'){
            obj.pivot.value = JSON.parse(obj.pivot.value);
            if(obj.pivot.value && obj.pivot.value.length){
              obj.pivot.value.map((pivot) => {
                preinstalledAppsOptions.value.map((app) => {
                  if(pivot.id == app.id) preinstalledApps.value.push(app);
                })
              });
            }
          }else if(obj.name == 'network_guard'){

            obj.pivot.value = JSON.parse(obj.pivot.value);
            networkGuard.value = obj.pivot.value;
            networkGuardTypeOptions.value.map( (option) =>{
              if(option.id == networkGuard.value.type) networkGuardType.value = option;
            });
            networkGuard.value.enable = networkGuard.value.enable ? true : false;
            networkGuard.value.reboot = networkGuard.value.reboot ? true : false; 

            // if(networkGuard.value.ipInternet) {
            //   ipInternetCode.value = networkGuard.value.ipInternet;
            //   let ipInternetVal = networkGuard.value.ipInternet.split('.');
            //   for(let i=0 ; i < ipInternetVal.length; i++){
            //     ipInternet.value[i] = ipInternetVal[i];
            //   }
            // }

            // if(networkGuard.value.ipIntranet){
            //   ipIntranetCode.value = networkGuard.value.ipIntranet;
            //   let ipIntranetVal = networkGuard.value.ipIntranet.split('.');
            //   for(let i=0 ; i < ipIntranetVal.length; i++){
            //     ipIntranet.value[i] = ipIntranetVal[i];
            //   }
            // } 

            // console.log('ipInternet.value', ipInternet.value);
            // console.log('ipInternetCode.value', ipInternetCode.value);

            // console.log('ipIntranet.value', ipIntranet.value);
            // console.log('ipIntranetCode.value', ipIntranetCode.value);

          }
          // else if(obj.name == 'daemon_app'){

          //   obj.pivot.value = JSON.parse(obj.pivot.value);
          //   daemonApp.value = obj.pivot.value
          //   daemonApp.value.broadcastEnable = daemonApp.value.broadcastEnable ? true : false;
          //   daemonApp.value.timeMillisecond = daemonApp.value.timeMillisecond ? daemonApp.value.timeMillisecond / 1000 : 0;

          //   if(daemonApp.value && daemonApp.value.packageName){
          //     appsOptions.value.map((app) => {
          //       if(app.package_name == daemonApp.value.packageName) daemonAppPackageName.value = app;
          //     })
          //   }
          // }

          let newObj = {};
          newObj.id = obj.id;
          newObj.name = obj.name;
          newObj.value = obj.pivot.value;
          settingsArr.value.push(newObj);

        });
      }

      // console.log('settingsArr', settingsArr.value);

      isLoading.value = false;
    }).catch((e) => {
      console.log(e);
    })
    
  });

  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);
  };

  onUnmounted( () => {
    resetStoreData();
  });

</script>

<template>
  <DefaultLayout>
    <div class="settings-card">
      <!-- Nav tabs -->
      <div v-if="isLoading" class="d-flex justify-content-center mb-3">
          <div class="spinner-border text-success" role="status">
              <span class="visually-hidden">Loading...</span>
          </div>
      </div>
      <div v-else>
        <ul class="nav nav-tabs mb-40" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
              <button class="nav-link active" id="general-tab" data-bs-toggle="tab" data-bs-target="#general" type="button" role="tab" aria-controls="general" aria-selected="true">General</button>
          </li>
          <li class="nav-item" role="presentation">
              <button class="nav-link" id="screen-tab" data-bs-toggle="tab" data-bs-target="#screen" 
              type="button" role="tab" aria-controls="screen" aria-selected="false">Power Saving</button>
          </li>
          <li class="nav-item" role="presentation">
              <button class="nav-link" id="app-mode-tab" data-bs-toggle="tab" data-bs-target="#app-mode" type="button" role="tab" aria-controls="app-mode" aria-selected="false">App Mode</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="preinstalled-apps-tab" data-bs-toggle="tab" data-bs-target="#preinstalled-apps" type="button" role="tab" aria-controls="preinstalled-apps" aria-selected="false">Pre-Installed Apps</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="network-tab" data-bs-toggle="tab" data-bs-target="#network" type="button" role="tab" aria-controls="network" aria-selected="false">Network Guard</button>
          </li>

          <!-- <li class="nav-item" role="presentation">
            <button class="nav-link" id="daemon-app-tab" data-bs-toggle="tab" data-bs-target="#daemon-app" type="button" role="tab" aria-controls="daemon-app" aria-selected="false">Daemon App</button>
          </li> -->

        </ul>
          
        <!-- Tab panes -->
        <div class="tab-content ">
          <div class="tab-pane active" id="general" role="tabpanel" aria-labelledby="general-tab" tabindex="0">

            <div class="col-12 d-flex mb-40">

              <div class="col-3 d-flex p-0">
                <label class="form-check-label text w-140">System Fan</label>

                <!-- <div class="form-check form-switch mt-24 d-flex">
                  <input v-model="system_fan" class="form-check-input" type="checkbox" role="switch" id="systemFanSwitch"/>
                </div> -->

                <div class="d-flex">
                  <div class="button r radio-with-text  d-flex">
                    <input v-model="system_fan" type="checkbox" class="checkbox" />
                    <div class="knobs"></div>
                    <div class="layer"></div>
                  </div>
                </div>

              </div>

              <!-- <div class="line-between"></div> -->

              <div class="col-3 d-flex p-0">
                <label class="form-check-label text w-140">Schedule Reboot</label>

                <div class="d-flex">
                  <div class="button r radio-with-text  d-flex">
                    <input v-model="schedule_reboot" type="checkbox" class="checkbox" />
                    <div class="knobs"></div>
                    <div class="layer"></div>
                  </div>
                </div>
              </div>

              <!-- <div class="line-between"></div> -->

              <div class="col-3 d-flex p-0">
                <label class="form-check-label text w-140" >Hide Status Bar</label >

                <div class="d-flex">
                  <div class="button r radio-with-text  d-flex">
                    <input v-model="show_status_bar" type="checkbox" class="checkbox" />
                    <div class="knobs"></div>
                    <div class="layer"></div>
                  </div>
                </div>

              </div>

              <div class="col-3 d-flex p-0">
                <label class="form-check-label text w-140">Bluetooth</label>

                <div class="d-flex">
                  <div class="button r radio-with-text  d-flex">
                    <input v-model="bluetooth" type="checkbox" class="checkbox" />
                    <div class="knobs"></div>
                    <div class="layer"></div>
                  </div>
                </div>

              </div>
              
            </div> 

            <!-- <div class="col-12 d-flex mb-3"> -->

              <!-- <div class="col-4 d-flex p-0">
                <label class="form-check-label text w-140">GPS</label>

                <div class="d-flex">
                  <div class="button r radio-with-text  d-flex">
                    <input v-model="gps" type="checkbox" class="checkbox" />
                    <div class="knobs"></div>
                    <div class="layer"></div>
                  </div>
                </div>

              </div> -->

              <!-- <div class="line-between"></div> -->

              <!-- <div class="col-4 d-flex p-0">
                <label class="form-check-label text w-140">Bluetooth</label>

                <div class="d-flex">
                  <div class="button r radio-with-text  d-flex">
                    <input v-model="bluetooth" type="checkbox" class="checkbox" />
                    <div class="knobs"></div>
                    <div class="layer"></div>
                  </div>
                </div>

              </div> -->

              <!-- <div class="line-between"></div> -->
              
              <!-- <div class="col-4 d-flex p-0">
                <label class="form-check-label text w-140" >Mobile Data</label>
                <div class=" d-flex">
                  <div class="button r radio-with-text  d-flex">
                    <input v-model="mobile_data" type="checkbox" class="checkbox" />
                    <div class="knobs"></div>
                    <div class="layer"></div>
                  </div>
                </div>

              </div> -->
              
            <!-- </div>  -->
              
            <div class="setting-seperator text-center"></div>
      
            <div class="d-flex col-12 padding-none mb-0">
              <div class="col-6 ">
                <div class="d-flex align-items-center justify-content-between w-416 config_settings_time">
                    <p class="mb-0 reboot-time form-check-label">Reboot Time</p>
                    <div class="timepacker">
                      <VueDatePicker v-model="schedule_reboot_time" time-picker auto-apply format="HH:mm" :clearable="true">
                        <template #input-icon>
                          <i class="input-slot-image">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                              <path :fill=" schedule_reboot_time ? '#2D8425' : '#787878'" d="M8.75 0C3.9375 0 0 3.9375 0 8.75C0 13.5625 3.9375 17.5 8.75 17.5C13.5625 17.5 17.5 13.5625 17.5 8.75C17.5 3.9375 13.5625 0 8.75 0ZM11.8125 10.5C11.55 10.9375 11.025 11.025 10.5875 10.85L8.3125 9.5375C8.05 9.3625 7.875 9.1 7.875 8.75V4.375C7.875 3.85 8.225 3.5 8.75 3.5C9.275 3.5 9.625 3.85 9.625 4.375V8.225L11.4625 9.275C11.9 9.5375 11.9875 10.0625 11.8125 10.5Z" />
                              </svg>
                          </i>
                        </template>
                        <template #clear-icon="{ clear }">
                          <span @click="clear" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 22 22" fill="none" >
                              <path fill="#F46A6A"
                                d="M11 0C4.91333 0 0 4.91333 0 11C0 17.0867 4.91333 22 11 22C17.0867 22 22 17.0867 22 11C22 4.91333 17.0867 0 11 0ZM16.5978 14.7156C17.1111 15.2289 17.1111 16.06 16.5978 16.5733C16.3289 16.8422 16.0111 16.9644 15.6689 16.9644C15.3267 16.9644 14.9844 16.8422 14.74 16.5733L11.0733 12.9067L7.38222 16.5733C7.11333 16.8422 6.79556 16.9644 6.45333 16.9644C6.11111 16.9644 5.76889 16.8422 5.52444 16.5733C5.01111 16.06 5.01111 15.2289 5.52444 14.7156L9.21556 11.0244L5.52444 7.35778C5.01111 6.84444 5.01111 6.01333 5.52444 5.5C6.03778 4.98667 6.86889 4.98667 7.38222 5.5L11.0733 9.19111L14.7644 5.5C15.2778 4.98667 16.1089 4.98667 16.6222 5.5C17.1356 6.01333 17.1356 6.84444 16.6222 7.35778L12.9311 11.0489L16.5978 14.7156Z"
                              />
                            </svg>
                          </span>
                      </template>
                      </VueDatePicker>
                    </div>
                  </div>
              </div>

              <div class="col-6 mb-3">
                <div class="p-relative">
                    <span v-if="time_zone"  :class="!timeZoneNames.length || time_zone?'disabled-span-multiselect':'multiselect-title-span'" >Time Zone</span>
                    <VMultiselect v-model="time_zone" :options="timeZoneNames" :searchable="true"
                      :disabled="!timeZoneNames.length || time_zone ? true : false" :loading="!timeZoneNames.length" 
                      :close-on-select="true" :show-labels="false" placeholder="Time Zone"
                    />
                </div>
              </div>
            </div>

            <div class="setting-seperator text-center"></div>

            <div class="d-flex col-12 padding-none ">

              <div class="col-6 ">
                <div class="d-flex align-items-center justify-content-center mb-3 w-416" >
                  <label for="brightnessRange" class="form-label brightness form-check-label">Brightness</label>
                  <div class="ms-auto percentage-box">
                    <p class="percentege">{{ brightness }}%</p>
                  </div>
                </div>
                <input type="range" class="form-range mt-44 w-416" id="brightnessRange" v-model="brightness"/>
              </div>

              <div class="col-6 ">
                <div class="d-flex align-items-center justify-content-center mb-3 w-416">
                  <label for="volumeRange" class="volume form-check-label">Volume</label>
                  <div class="ms-auto percentage-box">
                    <p class="percentege">{{ volume }}%</p>
                  </div>
                </div>
                <input type="range" class="form-range mt-44 w-416" id="volumeRange" v-model="volume"/>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="screen" role="tabpanel" aria-labelledby="screen-tab" tabindex="0">
            <div class="mb-40">
              <label class="form-label mb-20 form-check-label text">Auto Brightness Times</label>

              <div v-if="autoBrightnessFields && autoBrightnessFields.length">
                <div class="d-flex mb-2" v-for="(field, index) in autoBrightnessFields" :key="index">  
                  <div class="d-flex align-items-center justify-content-between config_settings_time">
                    <div class="d-flex">
                      <p class="time-input-text">From</p>
                      <div class="timepacker">
                        <VueDatePicker v-model="field.start" time-picker auto-apply format="HH:mm" :clearable="false" id="auto-brightness-time">
                          <template #input-icon>
                            <i class="input-slot-image">
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path :fill=" field.start ? '#2D8425' : '#787878'" d="M8.75 0C3.9375 0 0 3.9375 0 8.75C0 13.5625 3.9375 17.5 8.75 17.5C13.5625 17.5 17.5 13.5625 17.5 8.75C17.5 3.9375 13.5625 0 8.75 0ZM11.8125 10.5C11.55 10.9375 11.025 11.025 10.5875 10.85L8.3125 9.5375C8.05 9.3625 7.875 9.1 7.875 8.75V4.375C7.875 3.85 8.225 3.5 8.75 3.5C9.275 3.5 9.625 3.85 9.625 4.375V8.225L11.4625 9.275C11.9 9.5375 11.9875 10.0625 11.8125 10.5Z" />
                                </svg>
                            </i>
                          </template>
                        </VueDatePicker>
                      </div>
                    </div>
  
                    <div class="d-flex ml-20 mr-30">
                      <p class="time-input-text">To</p>
                      <div class="timepacker">
                        <VueDatePicker  v-model="field.end" time-picker auto-apply format="HH:mm" :clearable="false" id="auto-brightness-time">
                          <template #input-icon>
                            <i class="input-slot-image">
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path :fill=" field.end ? '#2D8425' : '#787878'" d="M8.75 0C3.9375 0 0 3.9375 0 8.75C0 13.5625 3.9375 17.5 8.75 17.5C13.5625 17.5 17.5 13.5625 17.5 8.75C17.5 3.9375 13.5625 0 8.75 0ZM11.8125 10.5C11.55 10.9375 11.025 11.025 10.5875 10.85L8.3125 9.5375C8.05 9.3625 7.875 9.1 7.875 8.75V4.375C7.875 3.85 8.225 3.5 8.75 3.5C9.275 3.5 9.625 3.85 9.625 4.375V8.225L11.4625 9.275C11.9 9.5375 11.9875 10.0625 11.8125 10.5Z" />
                                </svg>
                            </i>
                          </template>
                        </VueDatePicker>
              
                      </div>
                    </div>
  
                    <div class="ml-20">
  
                      <div class=" col-12 d-flex justify-content-center">
                        <p class="time-input-text">Brightness</p>
                        <div class="col-12 d-flex align-items-center justify-content-center">
                          <div class="col-8">
                            <input type="range" class="form-range" v-model="field.value"/>
                          </div>
                          
                          <div class="ms-auto percentage-box ml-20 col-4">
                            <p class="percentege">{{ field.value }} %</p>
                          </div>
                        </div>
                      </div>
                
                    </div>
  
                    <span @click="removeAutoBrightnessField(index)" class="ml-40 cursor-pointer">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 22 22" fill="none" >
                        <path fill="#F46A6A"
                          d="M11 0C4.91333 0 0 4.91333 0 11C0 17.0867 4.91333 22 11 22C17.0867 22 22 17.0867 22 11C22 4.91333 17.0867 0 11 0ZM16.5978 14.7156C17.1111 15.2289 17.1111 16.06 16.5978 16.5733C16.3289 16.8422 16.0111 16.9644 15.6689 16.9644C15.3267 16.9644 14.9844 16.8422 14.74 16.5733L11.0733 12.9067L7.38222 16.5733C7.11333 16.8422 6.79556 16.9644 6.45333 16.9644C6.11111 16.9644 5.76889 16.8422 5.52444 16.5733C5.01111 16.06 5.01111 15.2289 5.52444 14.7156L9.21556 11.0244L5.52444 7.35778C5.01111 6.84444 5.01111 6.01333 5.52444 5.5C6.03778 4.98667 6.86889 4.98667 7.38222 5.5L11.0733 9.19111L14.7644 5.5C15.2778 4.98667 16.1089 4.98667 16.6222 5.5C17.1356 6.01333 17.1356 6.84444 16.6222 7.35778L12.9311 11.0489L16.5978 14.7156Z"
                        />
                      </svg>
                    </span>
  
                  </div>
                </div>
  
                <button  @click="addAutoBrightnessField" type="button" class="btn btn-outline-success m-5 font-14 mass-action-grid-btn  pull-right">
                  Add New
                </button>
              </div>

              <div v-else class="d-flex justify-content-center">
                <div class="no-times-card mt-40">
                    <p class="second-success-card-email-message mt-15 text-center">No times exist for auto brightness </p>
                    <div class="text-center">
                      <button  @click="addAutoBrightnessField" type="button" class="btn btn-outline-success m-5 font-14 mass-action-grid-btn">
                        Add New
                      </button>
                    </div>
                </div>
              </div>

            </div>

            <div class="setting-seperator-2 text-center"></div>

            <div class="mb-40">
              <!-- <label class="form-label mb-20 form-check-label text">Auto On / Off Times</label> -->

              <div v-if="autoOnOffFields && autoOnOffFields.length">

                <div class="d-flex mb-2" v-for="(field, index) in autoOnOffFields" :key="index">

                    <!-- <div class="align-items-center justify-content-center d-flex mr-30" > -->
                      <!-- <p class="time-input-text">Power</p>
                      
                      <div class="form-check form-switch">
                        <input v-model="field.value" class="form-check-input" type="checkbox" role="switch" :id="'modeOnOff'+index"/>
                      </div> -->


                      <!-- <div class="mt-24 d-flex">
                        <label class="form-check-label text w-140"> Enable </label>
                        <div class="button r radio-with-text  d-flex">
                          <input v-model="field.value" type="checkbox" class="checkbox" />
                          <div class="knobs"></div>
                          <div class="layer"></div>
                        </div>
                      </div> -->

                    <!-- </div> -->

                  <div class="d-flex align-items-center justify-content-between config_settings_time">

                    <div class="d-flex mr-20">
                      <label class="text mr-20">Auto On / Off Times</label>
                      <div class="d-flex">
                        <div class="button r radio-with-text  d-flex">
                          <input v-model="field.value" type="checkbox" class="checkbox" />
                          <div class="knobs"></div>
                          <div class="layer"></div>
                        </div>
                      </div>
                    </div>
  
                    <div class="d-flex">
                      <p class="time-input-text">Start Up</p>
                      <div class="timepacker">
                        <VueDatePicker :disabled="field.value ? false : true" v-model="field.start" time-picker auto-apply format="HH:mm" :clearable="false" id="auto-brightness-time">
                          <template #input-icon>
                            <i class="input-slot-image" :class="{'disabled-style' : field.value ? false : true}">
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path :fill=" field.start ? '#2D8425' : '#787878'" d="M8.75 0C3.9375 0 0 3.9375 0 8.75C0 13.5625 3.9375 17.5 8.75 17.5C13.5625 17.5 17.5 13.5625 17.5 8.75C17.5 3.9375 13.5625 0 8.75 0ZM11.8125 10.5C11.55 10.9375 11.025 11.025 10.5875 10.85L8.3125 9.5375C8.05 9.3625 7.875 9.1 7.875 8.75V4.375C7.875 3.85 8.225 3.5 8.75 3.5C9.275 3.5 9.625 3.85 9.625 4.375V8.225L11.4625 9.275C11.9 9.5375 11.9875 10.0625 11.8125 10.5Z" />
                                </svg>
                            </i>
                          </template>
                        </VueDatePicker>
                      </div>
                    </div>
  
                    <div class="d-flex ml-20 mr-30">
                      <p class="time-input-text">Shut Down</p>
                      <div class="timepacker">
                        <VueDatePicker  :disabled="field.value ? false : true" v-model="field.end" time-picker auto-apply format="HH:mm" :clearable="false" id="auto-brightness-time">
                          <template #input-icon>
                            <i class="input-slot-image" :class="{'disabled-style' : field.value ? false : true}">
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path :fill=" field.end ? '#2D8425' : '#787878'" d="M8.75 0C3.9375 0 0 3.9375 0 8.75C0 13.5625 3.9375 17.5 8.75 17.5C13.5625 17.5 17.5 13.5625 17.5 8.75C17.5 3.9375 13.5625 0 8.75 0ZM11.8125 10.5C11.55 10.9375 11.025 11.025 10.5875 10.85L8.3125 9.5375C8.05 9.3625 7.875 9.1 7.875 8.75V4.375C7.875 3.85 8.225 3.5 8.75 3.5C9.275 3.5 9.625 3.85 9.625 4.375V8.225L11.4625 9.275C11.9 9.5375 11.9875 10.0625 11.8125 10.5Z" />
                                </svg>
                            </i>
                          </template>
                        </VueDatePicker>
              
                      </div>
                    </div>
  

  
                    <!-- <span @click="removeAutoOnOffField(index)" class="ml-40 cursor-pointer">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 22 22" fill="none" >
                        <path fill="#F46A6A"
                          d="M11 0C4.91333 0 0 4.91333 0 11C0 17.0867 4.91333 22 11 22C17.0867 22 22 17.0867 22 11C22 4.91333 17.0867 0 11 0ZM16.5978 14.7156C17.1111 15.2289 17.1111 16.06 16.5978 16.5733C16.3289 16.8422 16.0111 16.9644 15.6689 16.9644C15.3267 16.9644 14.9844 16.8422 14.74 16.5733L11.0733 12.9067L7.38222 16.5733C7.11333 16.8422 6.79556 16.9644 6.45333 16.9644C6.11111 16.9644 5.76889 16.8422 5.52444 16.5733C5.01111 16.06 5.01111 15.2289 5.52444 14.7156L9.21556 11.0244L5.52444 7.35778C5.01111 6.84444 5.01111 6.01333 5.52444 5.5C6.03778 4.98667 6.86889 4.98667 7.38222 5.5L11.0733 9.19111L14.7644 5.5C15.2778 4.98667 16.1089 4.98667 16.6222 5.5C17.1356 6.01333 17.1356 6.84444 16.6222 7.35778L12.9311 11.0489L16.5978 14.7156Z"
                        />
                      </svg>
                    </span> -->
  
                  </div>
                </div>
  
                <!-- <button  @click="addAutoOnOffField" type="button" class="btn btn-outline-success m-5 font-14 mass-action-grid-btn  pull-right">
                  Add New
                </button> -->

              </div>

              <div v-else class="d-flex justify-content-center">
                <div class="no-times-card mt-40">
                    <p class="second-success-card-email-message mt-15 text-center">No times exist for auto on / off </p>
                    <div class="text-center">
                      <button  @click="addAutoOnOffField" type="button" class="btn btn-outline-success m-5 font-14 mass-action-grid-btn">
                        Add New
                      </button>
                    </div>
                </div>
              </div>

            </div>

            <!-- <div class="setting-seperator-2 text-center"></div> -->
          </div>
          
          <div class="tab-pane" id="app-mode" role="tabpanel" aria-labelledby="app-mode-tab" tabindex="0">
            <div class="col-12 padding-none d-flex">
              <div class="col-6 padding-none">

                <div class="p-relative mb-3"> 
                  <span v-if="appMode.type" class="multiselect-title-span">Type</span>
                  <VMultiselect @select="changeTypeVal" v-model="appMode.type" :options="appModeTypeOptions" :loading="!appModeTypeOptions.length" 
                  :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Type" />
                </div>
    
                <div class="p-relative">
                  <span v-if="(appMode.type == 'Multiple' && appMode.apps.length) || (appMode.type == 'Single' && appMode.apps) " class="multiselect-title-span">Applications</span>
                  <VMultiselect :class="{'multiselect-options' : appMode.type == 'Multiple' && appMode.apps.length}" 
                  v-model="appMode.apps" :options="appsOptions" :disabled="!appsOptions.length || isDisableAppType || appMode.type == 'None'" 
                  :multiple="applicattionModaSelection" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Applications"
                  trackBy="package_name" label="name" :loading="isLoadingApps"
                  />
                </div>
  
              </div>
  
  
              <div v-if="appMode.type == 'Single'" class="col-6 padding-none">
  
                <div class="col-12 d-flex p-0 mb-5">
                  <label class="form-check-label text w-140">Enable broadcast</label>
                  <div class="d-flex">
                    <div class="button r radio-with-text  d-flex">
                      <input v-model="appMode.broadcastEnable" type="checkbox" class="checkbox" />
                      <div class="knobs"></div>
                      <div class="layer"></div>
                    </div>
                  </div>
                </div>
  
                <div class="col-12 d-flex p-0">
  
                  <div class="form-floating d-flex"> 
                    <input v-model="appMode.timeMillisecond" min="0" type="number" class="w-335 form-control input-remove-right-border-radius " id="appModeTime" placeholder="Time" />
                    <label for="appModeTime">Time</label> 
                    <div class="input-border-wrapper">
                      <div class="input-border"></div>
                    </div>
                    <div class="minutes-label">
                      <div class="minutes">Seconds</div>
                    </div>
                  </div>
  
                </div>
  
              </div>
            </div>
            




          </div>
          <div class="tab-pane" id="preinstalled-apps" role="tabpanel" aria-labelledby="preinstalled-apps-tab" tabindex="0">

            <div class="p-relative">
              <span v-if="preinstalledApps.length " class="multiselect-title-span">Applications (Optional)</span>
              <VMultiselect :class="{'multiselect-options' : preinstalledApps.length }" 
              v-model="preinstalledApps" :options="preinstalledAppsOptions" :disabled="!preinstalledAppsOptions.length" 
              :multiple="true" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Applications (Optional)"
              trackBy="id" label="name" :loading="isLoadingPreinstalledApps"
              />
            </div>
            <span class="hint-input"><b>Note:</b> You can't delete the selected applications from apps library </span>  


          </div>

          <div class="tab-pane" id="network" role="tabpanel" aria-labelledby="network-tab" tabindex="0">
              <div class="mb-40">
              <div class="col-12 d-flex mb-3 padding-none">

                <div class="col-6 p-0">
                  <div class="d-flex"> 
                    <label class="form-check-label text w-140"> Enable </label>
                    <div class="d-flex">
                      <div class="button r radio-with-text  d-flex">
                        <input v-model="networkGuard.enable" type="checkbox" class="checkbox" />
                        <div class="knobs"></div>
                        <div class="layer"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="line-between"></div> -->

                <div class="col-6 p-0">
                  <div class="d-flex">
                    <label class="form-check-label text w-140">Reboot</label>
                    <div class="d-flex">
                      <div class="button r radio-with-text  d-flex">
                        <input v-model="networkGuard.reboot" type="checkbox" class="checkbox" />
                        <div class="knobs"></div>
                        <div class="layer"></div>
                      </div>
                    </div>
                    <div>

                    <b-button id="rebootNetwork" class="btn info-popover"><i class="bi bi-info-circle"></i></b-button>
                    <b-popover target="rebootNetwork" triggers="focus" content="Whether to restart after repairing network failure Reboot, if the repair fals, restart the system to re-diagnose: if it fails to restart several times in a row, it will enter the dormant state: if the network changes are monitored, it will resume the guardian and start detection, 
                    and continue to sleep if the detection fails."></b-popover>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-12 d-flex padding-none mb-3">

                <div class="col-6 padding-none d-flex">

                  <div class="p-relative"> 
                    <span v-if="networkGuard.type >= 0 && networkGuardType" class="multiselect-title-span">Type</span>
                    <VMultiselect @select="changeTypeVal" v-model="networkGuardType" :options="networkGuardTypeOptions" :loading="!networkGuardTypeOptions.length" 
                    :searchable="true" :close-on-select="true" trackBy="id" label="name"  :show-labels="false" placeholder="Type" />
                  </div>


                  <b-button id="typeNetwork" class="btn info-popover "><i class="bi bi-info-circle"></i></b-button>
                  <b-popover target="typeNetwork" triggers="focus" content="Selecting Automatic will try to repair all network types, it is recommended to select the current network environment."></b-popover>

                </div>
 
                <div class="col-6 padding-none">

                  <div class="d-flex">
                    <div class="form-floating d-flex" > 

                      <input :class="{'is-invalid' : isInvalidNetworkGuardTime}" @input="changeNetworkGuardTime" v-model="networkGuard.time" min="3" max="15" type="number" class="w-335 form-control input-remove-right-border-radius " id="networkGuardTime" placeholder="Time" />
                      <label for="networkGuardTime">Time</label> 
  
                      <div class="input-border-wrapper" >
                        <div class="input-border"></div>  
                      </div>
                      <div class="minutes-label">
                        <div class="minutes">Minutes</div>
                      </div>
                      
                    </div>


                    <b-button id="timeNetwork" class="btn info-popover"><i class="bi bi-info-circle"></i></b-button>
                    <b-popover target="timeNetwork" triggers="focus" content="Interval time for re-diagnosis after diagnosis and repair, no less than 3 minutes; it is not recommended to set too short a time to cause too frequent detection."></b-popover>



                  </div>
                  
                  <div v-if="isInvalidNetworkGuardTime" class="invalid-message"> Enter number between 3 and 15</div>


                </div>

              </div>

              <div class="col-12 d-flex padding-none mb-3">

                <div class="col-6 padding-none d-flex">

                  <div class="form-floating mb-3">
                    <input type="text" v-model="networkGuard.ipInternet" class="form-control" id="ipInternet" placeholder=""/>
                    <label for="ipInternet">IP Internet</label>
                  </div>


                  <!-- <div class="form-floating d-flex ip-input-container">
                    <input type="text" maxlength="3" v-model="ipInternet[0]" @keyup="moveToNext($event, 'ip-internet-input', 0)" class="form-control ip-input-box ip-internet-input
                     border-right none-border-top-right-raduis none-border-bottom-right-raduis" id="ipInternetGroup" placeholder=""/>
                    <span v-if="ipInternetCode ">.</span>
                    <input type="text" maxlength="3" v-model="ipInternet[1]" @keyup="moveToNext($event, 'ip-internet-input', 1)" class="form-control ip-input-box ip-internet-input 
                    border-right none-border-top-right-raduis none-border-bottom-right-raduis" placeholder=""/>
                    <span v-if="ipInternetCode">.</span>
                    <input type="text" maxlength="3" v-model="ipInternet[2]" @keyup="moveToNext($event, 'ip-internet-input', 2)" class="form-control ip-input-box ip-internet-input 
                    border-right none-border-top-right-raduis none-border-bottom-right-raduis" placeholder=""/>
                    <span v-if="ipInternetCode">.</span>
                    <input type="text" maxlength="3" v-model="ipInternet[3]" @keyup="moveToNext($event, 'ip-internet-input', 3)" class="form-control ip-input-box ip-internet-input" placeholder=""/>
                    <label for="ipInternetGroup">IP Internet</label>
                  </div> -->

                  <b-button id="internetNetwork" class="btn info-popover"><i class="bi bi-info-circle"></i></b-button>
                  <b-popover target="internetNetwork" triggers="focus" content="Used to detect whether the external network is communicating."></b-popover>

                </div>

                


                <div class="col-6 padding-none d-flex">

                  <div class="form-floating mb-3">
                    <input type="text" v-model="networkGuard.ipIntranet" class="form-control" id="ipIntranet" placeholder=""/>
                    <label for="ipIntranet">IP Intranet</label>
                  </div>


                  <!-- <div class="form-floating d-flex ip-input-container">
                    <input type="text" maxlength="3" v-model="ipIntranet[0]" @keyup="moveToNext($event, 'ip-intranet-input', 0)" class="form-control ip-input-box ip-intranet-input
                    border-right none-border-top-right-raduis none-border-bottom-right-raduis" id="ipInternetGroup" placeholder=""/>
                    <span v-if="ipIntranetCode ">.</span>
                    <input type="text" maxlength="3" v-model="ipIntranet[1]" @keyup="moveToNext($event, 'ip-intranet-input', 1)" class="form-control ip-input-box ip-intranet-input
                    border-right none-border-top-right-raduis none-border-bottom-right-raduis none-border-top-left-raduis none-border-bottom-left-raduis" placeholder=""/>
                    <span v-if="ipIntranetCode">.</span>
                    <input type="text" maxlength="3" v-model="ipIntranet[2]" @keyup="moveToNext($event, 'ip-intranet-input', 2)" class="form-control ip-input-box ip-intranet-input
                    border-right none-border-top-right-raduis none-border-bottom-right-raduis none-border-top-left-raduis none-border-bottom-left-raduis" placeholder=""/>
                    <span v-if="ipIntranetCode">.</span>
                    <input type="text" maxlength="3" v-model="ipIntranet[3]" @keyup="moveToNext($event, 'ip-intranet-input', 3)" class="form-control ip-input-box ip-intranet-input" placeholder=""/>
                    <label for="ipIntranetGroup">IP Intranet</label>
                  </div> -->


                  <b-button id="intranetNetwork" class="btn info-popover"><i class="bi bi-info-circle"></i></b-button>
                  <b-popover target="intranetNetwork" triggers="focus" content="The gateway is automatically used for initialization, and it is not necessary to fill in if the intranet is not used."></b-popover>

                </div>

              </div>

              <div class="col-12 d-flex padding-none">

                <div class="col-6 padding-none">

                  <div class="form-floating mb-3">
                    <input type="text" v-model="networkGuard.logPath" class="form-control" id="logPath" placeholder=""/>
                    <label for="logPath">Log Path</label>
                  </div>
                </div>
              </div>
            </div> 


          </div>

          <!-- <div class="tab-pane" id="daemon-app" role="tabpanel" aria-labelledby="daemon-app-tab" tabindex="0">

            <div class="mb-40">
              


              <div class="col-12 d-flex  padding-none">

                <div class="col-6 padding-none">

                  <div class="p-relative">
                    <span v-if="daemonAppPackageName" class="multiselect-title-span">Package Name</span>
                    <VMultiselect  v-model="daemonAppPackageName" :options="appsOptions" :disabled="!appsOptions.length" 
                    :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Package Name"
                    trackBy="package_name" label="name" :loading="isLoadingApps"
                    />
                  </div>

                </div>

            


              </div>

            </div>

          </div> -->

        </div>
          
        <div class="d-flex mt-20 pull-right right-bottom-btn">
          <button :disabled="isSubmitted || isInvalidNetworkGuardTime" @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
            Submit <span v-if="isSubmitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
        </div>
        
      </div>
    </div>
  </DefaultLayout>
</template>