<script setup>
    import { ref, defineProps, onMounted, reactive } from "vue";
    import { Modal }  from "bootstrap";
    import { useStore } from "vuex";

    const store = useStore();
    const props = defineProps({
        data: Object,
    });

    let items = ref([]);

    const state = reactive({
        forceStopAppModal: null,
        launchAppModal : null,
        clearAppDataModal : null,
        clearAppCacheModal : null,
        deviceAppsModal : null,
        uninstallAppModal : null,
    });

    const resetStoreData = () =>{
        store.commit('setModalState', false);
        store.commit('setModalData', {});
        store.commit('setModalAction', null);
    };

    const action = async (action, data) => {
        resetStoreData();
        // console.log('data', data);
        await store.commit('setModalAction', action);
        store.commit('setModalState', true);
        await store.commit('setModalData', data);

        if(action == 'fore_stop_app'){
            state.forceStopAppModal = new Modal("#forceStopAppModal", {});
            state.forceStopAppModal.show();
        }else if(action == 'launch_app'){
            state.launchAppModal = new Modal("#launchAppModal", {});
            state.launchAppModal.show();
        }else if(action == 'clear_app_data'){
            state.clearAppDataModal = new Modal("#clearAppDataModal", {});
            state.clearAppDataModal.show();
        }else if(action == 'clear_app_cache'){
            state.clearAppCacheModal = new Modal("#clearAppCacheModal", {});
            state.clearAppCacheModal.show();
        }else if(action == 'uninstall_app'){
            state.uninstallAppModal = new Modal("#uninstallAppModal", {});
            state.uninstallAppModal.show();
        }    
    }

onMounted(() => {

    items.value.push(
        { 
            icon : 'bi bi-tropical-storm',
            text: "Launch", 
            action: "launch_app",
            visible: props.data?.packageId != process.env.VUE_APP_PACKAGE_ID,
            data : props.data,
        },
        {
            icon : 'bi bi-eraser',
            text: "Clear Cache",
            action: "clear_app_cache",
            visible: props.data?.packageId != process.env.VUE_APP_PACKAGE_ID,
            data : props.data,
        },
        { 
            icon : 'bi bi-trash2',
            text: "Clear Data", 
            action: "clear_app_data",
            visible: props.data?.packageId != process.env.VUE_APP_PACKAGE_ID,
            data : props.data,
        },
        { 
            icon : 'bi bi-slash-circle',
            text: "Force Stop", 
            action: "fore_stop_app",
            visible: props.data?.packageId != process.env.VUE_APP_PACKAGE_ID,
            data : props.data,
        },
        { 
            icon : 'bi bi-x-lg',
            text: "Uninstall", 
            action: "uninstall_app",
            visible: props.data?.packageId != process.env.VUE_APP_PACKAGE_ID,
            data : props.data,
        },
    );
});
</script>

<template>
    <div>
        <button v-for="(item, index) in items" :key="index" v-show="item.visible" @click="action(item.action, props.data)" type="button" :class="item.customStyle ? 'mt-1' :''" class="btn action-btn" :title="item.text"><i :class="item.icon"></i> </button>
    </div>
</template>