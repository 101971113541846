
<script setup>
    import { ref, onBeforeMount, computed, defineEmits} from "vue";
    import { useStore } from "vuex";
    import { useToast } from "vue-toastification";    
    import { useRouter } from 'vue-router'
    const router = useRouter();

    const store = useStore();
    const toast = useToast();

    const getModalInfo = computed(()=> store.getters.getModalInfo);
    const getModalAction = computed(()=> store.getters.getModalAction);

    // const companyId = computed(()=>localStorage.getItem("currentCompanyId"));
    let id = ref(null);
    let url = ref(null);
    let name = ref(null);
    // const user = computed(()=>JSON.parse(localStorage.getItem('userDetails')));
    // let intervalId = ref(null);
    // let roomIntervlCounter = ref(0);
    const emit = defineEmits(["refresh"]);

    const resetStoreData = () => {

      setTimeout(() => {
        store.commit('setModalState', false);
        store.commit('setModalData', {});
        store.commit('setModalAction', null);
        // window.clearInterval(intervalId.value);
        emit('refresh');
      },500)
    };

    const removeAgent = (id) => {
      store.dispatch("getScreenRemoveAgentConnection",  {'id' : id})
      .then( async (res) => {
        console.log('getScreenRemoveAgentConnection res', res);
      })
      .catch(async (e)=>{
        console.log(e);
      })
    }

    onBeforeMount(()=>{
        if(getModalInfo.value && getModalInfo.value.data_type == 'device' && getModalAction.value == 'remote_control'){
          console.log('getModalInfo', getModalInfo.value);
          id.value = getModalInfo.value.id;
          name.value = getModalInfo.value.name;
          // let data = {
          //   kioskName: getModalInfo.value.name,
          //   macAddress: getModalInfo.value.mac_address,
          //   entityId : companyId.value,
          //   userId : user.value.user_id,
          //   userName : user.value.first_name+' '+ user.value.last_name,
          //   isSuperAdmin: 0,
          //   connectionType: 'daemon',
          // }
          store.dispatch("getScreenAgentsList",  getModalInfo.value.remote_integration_name)
          .then((res) => {  
              console.log('getScreenAgentsList res', res);
             
              //logic with remote server lamasatech
              
              // intervalId.value = setInterval(async () => {
              //   roomIntervlCounter.value = roomIntervlCounter.value + 1;
              //   console.log('roomIntervlCounter.value', roomIntervlCounter.value)
              //   //Get room info (session id , password)

              //   if(roomIntervlCounter.value < 8){

              //     store.dispatch("fetchRoomInfo", getModalInfo.value.mac_address )
              //     .then((res) => {
              //       console.log('res', res)
              //       if(res.data && res.data.session_id && res.data.session_password){
              //         const sessionId = res.data.session_id;
              //         const sessionPassword = res.data.session_password;
              //         window.open(
              //           `${process.env.VUE_APP_REMOTE_SERVER_URL}/web-admin/?roomId=${sessionId}&password=${sessionPassword}&deviceName=${getModalInfo.value.name}`,
              //           "_blank"
              //         );
              //         window.clearInterval( intervalId.value );
              //         resetStoreData();
              //       }
              //     })
              //     .catch((e)=>{
              //         window.clearInterval( intervalId.value);
              //         resetStoreData();
              //       console.log(e)
              //     })

              //   }else {

              //     resetStoreData();
              //     toast.error("Can't retrive room data"); 

              //     }

              // }, 5000);

              // new logic with get screen
              if(res.data && res.data.length){

                for(let i=0 ; i < res.data.length;i++){

                  store.dispatch("getScreenAgentConnection",  res.data[i]?.device_id)
                  .then( async (res) => {
                    console.log('getScreenAgentConnection res', res);
                    // window.open(res.data?.data?.url,"_blank");

                    url.value = await res.data?.data?.url;
                    await resetStoreData();

                    // await store.commit('setRemoteControlDataObject', {
                    //   link : res.data?.data?.url,
                    //   name : name.value,
                    // });

                    await localStorage.setItem("remoteControlData"+id.value, JSON.stringify({
                      link : res.data?.data?.url,
                      name : name.value,
                    }));

                    const routeData = router.resolve({name: 'RemoteControlView', query: {device_id : id.value}});
                    window.open(routeData.href, '_blank');
                    
                  })
                  .catch(async (e)=>{
                    console.log(e);
                    await removeAgent(res.data[i].id);
                  })
                }
              }else {
               toast.warning("Can't retrive agent list");
               resetStoreData();
              }
            })
          .catch( async (e)=> {
            console.log(e);
            await toast.error("Can't retrive connection url"); 
            resetStoreData();
          });
        }
    });

</script>

<template>
  <DModal id="remoteControlModal" :main_classes="[]" :dialog_classes="[]">
    <template #title>
      <div style="display: flex; align-items: center">
        <span style="margin-right: 351px;">Remote Control</span>
        <span @click="resetStoreData" >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M11 0C4.91333 0 0 4.91333 0 11C0 17.0867 4.91333 22 11 22C17.0867 22 22 17.0867 22 11C22 4.91333 17.0867 0 11 0ZM16.5978 14.7156C17.1111 15.2289 17.1111 16.06 16.5978 16.5733C16.3289 16.8422 16.0111 16.9644 15.6689 16.9644C15.3267 16.9644 14.9844 16.8422 14.74 16.5733L11.0733 12.9067L7.38222 16.5733C7.11333 16.8422 6.79556 16.9644 6.45333 16.9644C6.11111 16.9644 5.76889 16.8422 5.52444 16.5733C5.01111 16.06 5.01111 15.2289 5.52444 14.7156L9.21556 11.0244L5.52444 7.35778C5.01111 6.84444 5.01111 6.01333 5.52444 5.5C6.03778 4.98667 6.86889 4.98667 7.38222 5.5L11.0733 9.19111L14.7644 5.5C15.2778 4.98667 16.1089 4.98667 16.6222 5.5C17.1356 6.01333 17.1356 6.84444 16.6222 7.35778L12.9311 11.0489L16.5978 14.7156Z"
              fill="#F46A6A"
            />
          </svg>
        </span>
      </div>
    </template>

    <template #body >
      <!-- <p>Loading...</p> -->

      <div class="d-flex justify-content-center mb-3">
        <div class="spinner-border text-success" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      
    </template>

  </DModal>
</template>
<style lang="scss">
  div#remoteControlModal {
    .modal-body{
      padding-right:46px!important ;
    }
  }
</style>
