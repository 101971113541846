import Api from "./Api";
export default {
    getUsersGrid(companyId) {
        return Api().get(`/users/${companyId}`);
    },
    getUsersRoles(companyId) {
        return Api().get(`/role/${companyId}`);
    },
    addUser(payload) {
        return Api().post(`/user/${payload.companyId}`, payload);
    },
    editUser(payload) {
        return Api().put(`/user/${payload.companyId}`, payload);
    },
    deleteUser(payload) {
        return Api().delete(`/user/${payload.companyId}/${payload.id}`);
    },
    getDuplicateField(payload) {
        return Api().get(`/check_duplicate_field/${payload.companyId}`, {params : payload.params });
    },
};
