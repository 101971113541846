<script setup>
  import { ref, defineEmits, computed, onBeforeMount } from "vue";
  import { useStore } from "vuex";

  const emit = defineEmits(['callDeviceApps']);
  const store = useStore();
  let isSubmitted = ref(false);
  let name = ref(null);
  let packageId = ref(null);
  let mac_address = ref(null);
  let company_id = computed(()=>localStorage.getItem("currentCompanyId"));
  const getModalInfo = computed(()=> store.getters.getModalInfo);
  const getModalAction = computed(()=> store.getters.getModalAction);
  const getDeviceDataObject = computed(()=> store.getters.getDeviceDataObject);

  // const global_user_name = inject(['global_user_name']);
  const user = computed(()=>JSON.parse(localStorage.getItem('userDetails')));

  const onSubmit = () => {
    isSubmitted.value = true;
    let data = {
      entityId: company_id.value,
      kioskName : getDeviceDataObject.value.name,
      macAddress : mac_address.value,
      userName : user.value.first_name+' '+ user.value.last_name,
      userId : user.value.user_id,
      connectionType : 'daemon',
      packageName : packageId.value
    }
    
    store.dispatch("launchApp", data)
    .then((res) => {
      console.log('res', res);
      isSubmitted.value = false;
      resetStoreData();
    })
    .catch((e) => {
      console.log("e", e);
      isSubmitted.value = false;
    });
  };

  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);
    document.querySelector(".modal-backdrop").remove();
    document.querySelector(".dx-device-desktop").classList.remove('modal-open');

    emit('callDeviceApps');
  };


  onBeforeMount(()=>{
    if(getModalInfo.value && getModalAction.value == 'launch_app'){
      console.log('getModalInfo', getModalInfo.value);
      name.value = getModalInfo.value.name;
      packageId.value = getModalInfo.value.packageId;
      mac_address.value = getDeviceDataObject.value.mac_address
    }
  });
  
</script>
<template>
  <DModal id="launchAppModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Launch App </template>

    <template #body>
      <div class="delete-text">
        <span>Are you sure you want to <b>"Launch"</b> the app "{{ name }}" ?</span>
      </div>
    </template>

    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button :disabled="isSubmitted" 
          @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
            Launch <span v-if="isSubmitted"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
          </button>
          <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </template>
  </DModal>
</template>
  