<script setup>
    import { ref, defineEmits, computed, onBeforeMount, onMounted } from "vue";
    import { useStore } from "vuex";

    const store = useStore();
    const emit = defineEmits(['refresh', 'removeBackground']);
    const company_id = localStorage.getItem('currentCompanyId');
    const user = computed(()=>JSON.parse(localStorage.getItem('user')));
    import useEncryption from "@/utils/helpers/encryption";
    const { showPassword } = useEncryption();

    const getModalInfo = computed(()=> store.getters.getModalInfo);
    const getModalAction = computed(()=> store.getters.getModalAction);
  
    let siteData = ref(null);
    let password = ref(null);
    let isSubmitted = ref(false);


    const resetStoreData = () =>{
        store.commit('setModalState', false);
        store.commit('setModalData', {});
        store.commit('setModalAction', null);
    };

    const onSubmit = () => {
        isSubmitted.value = true;
        const data = {
        email : user.value.user.email,
        password : password.value, 
        company_id 
        };
        
        store.dispatch('checkUserPassword', data).then(() => {
        resetStoreData();
        emit('updateSitePassword', siteData.value);
        }).catch( (e) => {
            isSubmitted.value = false;
            console.log(e)
        });
        
    };

    onMounted(() => {
        showPassword('userPassword', '.showUserPassword i');
    });


    onBeforeMount( () => {
        if(getModalInfo.value && getModalInfo.value.data_type == 'site' && getModalAction.value == 'check_user_password'){
            console.log('getModalInfo', getModalInfo.value);
            siteData.value = getModalInfo.value;
        }

    });
  
</script>
<template>
  <DModal id="checkUserPasswordModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Enter your login password </template>
    
    <template #body>
      <div class="form-floating mb-3">
        <input v-model="password" type="password" class="form-control" id="userPassword" placeholder="Password" />
        <label for="userPassword">Password</label>
        <span class="showPassword showUserPassword"><i class="bi bi-eye-slash"></i></span>
      </div>

    </template>

    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button :disabled="!password || isSubmitted" @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">Continue 
            <span v-if="isSubmitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
          <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal"> Cancel </button>
        </div>
      </div>
    </template>
  </DModal>
</template>
