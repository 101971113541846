<script setup>
import { ref, defineProps, onMounted, reactive, computed } from "vue";
// import DxDropDownButton from "devextreme-vue/drop-down-button";
import { Modal }  from "bootstrap";
import { useStore } from "vuex";

const user = computed(()=>JSON.parse(localStorage.getItem('userDetails')));
const currentCompany = computed(()=>JSON.parse(localStorage.getItem('currentCompany')));
const store = useStore();

const props = defineProps({
  data: Object,
});

let items = ref([]);

const state = reactive({
  editUserModal: null,
  deleteUserModal: null,
});

const action = async (action, data) => {

  await store.commit('setModalAction', action);
  store.commit('setModalState', true);
  await store.commit('setModalData', data);

  if(action == 'edit_user'){
    state.editUserModal = new Modal("#editUserModal", {});
    state.editUserModal.show();
  }else if(action == 'delete_user'){
    state.deleteUserModal = new Modal("#deleteUserModal", {});
    state.deleteUserModal.show();
  }
}

onMounted(() => {

  items.value.push(
    {
      icon : 'bi bi-pencil-square',
      text: "Edit User", 
      action : 'edit_user',
      data : props.data,
      visible : (currentCompany.value.pivot?.role?.name == 'admin' && props.data.role.name != 'owner') || (currentCompany.value.pivot?.role?.name == 'owner') ? false : true,
    },
    {
      icon : 'bi bi-trash3',
      text: "Delete User",
      action: "delete_user",
      visible: (user.value.user_id != props.data.id) && (currentCompany.value.pivot?.role?.name == 'admin' && props.data.role.name != 'owner') || (currentCompany.value.pivot?.role?.name == 'owner' && user.value.user_id != props.data.id) ? false : true,
      data : props.data,
    },
  );
    // console.log('items', items.value);
});
</script>

<template>
  <!-- <DxDropDownButton
    :items="items"
    :drop-down-options="{ width: 140, maxHeight: 179 }"
    :scrollable="true"
    id="AnnouncementActionsCell"
    class="announcementsActions"
    :text="'Select Actions'"
    itemTemplate="actionsTemp"
  >
      <template #actionsTemp="{ data }">
        <div class="dropdown-wrapper announcementsActionsDropdown"
        :class="{ 'dropdown-actions-seperator' : data.action != 'delete_user' && data.text != items.filter(item => item.visible)[items.filter(item => item.visible).length - 1].text,
        'high-dropdown-actions-seperator' : data.action == 'delete_user' && data.text != items.filter(item => item.visible)[items.filter(item => item.visible).length - 1].text }"
         title="">
        
            <div @click="action(data.action, data.data)" class="dropdown-items sites-and-devices-items" 
            :class="{ 'red-remove': data.action == 'delete_user' }" title="" >
            {{ data.text }}
            </div>                
        </div>
      </template>
    </DxDropDownButton> -->
    <div>
      <button v-for="(item, index) in items" :key="index" :disabled="item.visible" @click="action(item.action, props.data)" type="button" class="btn action-btn" :title="item.text"><i :class="item.icon"></i></button>
      <!-- <button v-if="(user.role?.name == 'admin' && props.data.role.name != 'owner') || (user.role?.name == 'owner')" @click="action('edit_user', props.data)" type="button" class="btn  action-btn" title="Edit"><i class="bi bi-pencil-square"></i></button>
      <button v-if="user.id != props.data.id && props.data.role.name != 'owner' " @click="action('delete_user', props.data)" type="button" class="btn action-btn" title="Delete"><i class="bi bi-trash3"></i></button> -->
    </div>
</template>