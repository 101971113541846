<script setup>
  import { ref, onBeforeMount, onUnmounted} from "vue";
  import router from "@/router";

  import DefaultLayout from "@/views/layouts/DefaultLayout.vue";
  import { useStore } from "vuex";
  const companyId = localStorage.getItem("currentCompanyId");

  const store = useStore();
  let settingsArr = ref([]);
  let isLoading = ref(true);
  let notify_when_online = ref(null)
  let notify_when_is_active = ref(null)
  let notify_interval = ref(null)
  let isSubmitted = ref(false);
  
  const onSubmit = async() => {

    isSubmitted.value = true;
    settingsArr.value.map( (obj) =>{
        if(obj.name == 'offline_device_check'){
            obj.value.interval = notify_when_is_active.value ? notify_interval.value.toString() : 5;
            obj.value.is_active = notify_when_is_active.value ? 'true' : 'false' ;
            obj.value.notify_when_online =  notify_when_online.value ? 'true' : 'false' ;
        }
        obj.value = JSON.stringify(obj.value);
    });

    // console.log('settingsArr.value', settingsArr.value);

    const data = {
      settings : settingsArr.value,
      companyId,
    };

    store.dispatch("updateCompanySettings", data)
    .then((res) => {
      console.log('res', res);
      isSubmitted.value = false;
      router.push("/dashboard");


    }).catch((e) => {
      isSubmitted.value = false;
      console.log(e);
    });

  };
  
  onBeforeMount( () => {
    store.dispatch("getCompanySettings", companyId)
    .then( async(res) => {
      if(res && res.data && res.data.settings.length){
        await res.data.settings.map( async (obj) => {
        if(obj.name == 'offline_device_check'){
            obj.pivot.value = await JSON.parse(obj.pivot.value);
            notify_when_online.value = await obj.pivot?.value?.notify_when_online === 'true' ? true : false;
            notify_interval.value = await parseInt(obj.pivot?.value?.interval);
            notify_when_is_active.value = await obj.pivot?.value?.is_active === 'true' ? true : false;
        }

        let newObj = {};
          newObj.id = obj.id;
          newObj.name = obj.name;
          newObj.value = obj.pivot.value;
          settingsArr.value.push(newObj);
        });
      }

    //   console.log('settingsArr', settingsArr.value);

      isLoading.value = false;
    }).catch((e) => {
      console.log(e);
    })
  });

  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);
  };

  onUnmounted( () => {
    resetStoreData();
  });

</script>

<template>
  <DefaultLayout>
    <div class="general-settings-card main-page-container-animation">
      <!-- Nav tabs -->
      <div v-if="isLoading" class="d-flex justify-content-center mb-3">
          <div class="spinner-border text-success" role="status">
              <span class="visually-hidden">Loading...</span>
          </div>
      </div>
      <div v-else>
        <ul class="nav nav-tabs mb-4" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
              <button class="nav-link active" id="notifications-tab" data-bs-toggle="tab" data-bs-target="#notifications" type="button" role="tab" aria-controls="notifications" aria-selected="true">Notification Policy</button>
          </li>
        </ul>
          
        <!-- Tab panes -->
        <div class="tab-content">
          <div class="tab-pane active" id="notifications" role="tabpanel" aria-labelledby="notifications-tab" tabindex="0">
            <div class="justify-content-between align-items-center mb-3 text-center">
                <div class="d-flex align-items-center">
                    <div class="form-check mr-30">
                        <input class="form-check-input" type="checkbox" v-model="notify_when_is_active" id="deviceOffline">
                        <label class="form-check-label ml-10" for="deviceOffline">
                            Connection Policy
                        </label>
                    </div>

                    <div class="form-floating d-flex" :class="{'disabled-dev' : !notify_when_is_active }"> 
                        <input :disabled="!notify_when_is_active"  v-model="notify_interval" min="5" type="number" class="w-335 form-control input-remove-right-border-radius " id="interval" placeholder="Interval" />
                        <label for="interval">Notify when device is not connected for</label> 
                        <div class="input-border-wrapper">
                          <div class="input-border"></div>
                        </div>
                        <div class="minutes-label">
                          <div class="minutes">Minutes</div>
                        </div>
                    </div>

                </div>

                <div class="d-flex align-items-center mt-20">

                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="notify_when_online" id="deviceOnline">
                      <label class="form-check-label ml-10" for="deviceOnline">
                          Notify when device is comes back
                      </label>
                    </div>

                </div>


            </div> 

          </div>
        </div>
          
        <div class="d-flex mt-20 pull-right right-bottom-btn">
          <button :disabled="isSubmitted" @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
            Submit <span v-if="isSubmitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>