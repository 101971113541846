<script setup>
  import { computed, ref, reactive, onMounted, onBeforeMount } from "vue";
  import DefaultLayout from "./layouts/DefaultLayout.vue";
  import { useStore } from "vuex";
  import {
    DxDataGrid,
    DxColumn,
    // DxFilterRow,
    DxHeaderFilter,
    DxToolbar,
    DxItem,
    DxPaging,
    DxPager,
    DxSorting,
    DxScrolling,
    DxColumnChooser,
    DxLoadPanel,
    DxStateStoring,
    DxSearchPanel,
  } from "devextreme-vue/data-grid";
  // import DxDropDownButton from "devextreme-vue/drop-down-button";
  // import { default as Button } from "devextreme-vue/button";
  import { exportDataGrid } from "devextreme/excel_exporter";
  import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
  import { Workbook } from "exceljs";
  import saveAs from "file-saver";
  import { jsPDF } from "jspdf";
  import { Modal }  from "bootstrap";
  import ActionCell from "../components/configurations/ActionCell.vue";
  import addConfig from "@/components/configurations/addConfig.vue";
  import editConfig from "@/components/configurations/editConfig.vue";
  import deleteConfig from "@/components/configurations/deleteConfig.vue";
  const companyId = computed(()=>localStorage.getItem("currentCompanyId"));
  const adminPreferenceId = computed(()=>localStorage.getItem("adminPreferenceId"));
  let getModalState = computed(()=> store.getters.getModalState);

  const store = useStore();
  const dataGridRefName = ref(null);
  let showClearAll = ref(false);
  const allowedOperations = ref(["contains"]);
  // const buttonData = ref(["Excel", "CSV", "PDF"]);
  let configurationsArr = ref([]);

  const state = reactive({
    configModal: null,
  });

  const configModal = (type) => {
    if (type == "show") state.configModal.show();
    else state.configModal.hide();
  };

  const dataGrid = computed(() => {
    return dataGridRefName.value.instance;
  });

  const contentReady = (e) => {
    let sortedExp = getSortedColumns();
    let filterExp = e.component.getCombinedFilter();
    if (filterExp || sortedExp) {
      showClearAll.value = true;
    } else {
      showClearAll.value = false;
    }
    setColumnChooserPosition();
    
  };

  const getSortedColumns = () => {
    let allColumns = Array.from(Array(dataGrid.value.columnCount()).keys()).map(
      (index) => dataGrid.value.columnOption(index)
    );
    allColumns = allColumns.find((col) => col.sortIndex != null);
    return allColumns;
  };

  const setColumnChooserPosition = () => {
    var columnChooserView = dataGrid.value.getView("columnChooserView");
    if (!columnChooserView._popupContainer) {
      columnChooserView._initializePopupContainer();
      columnChooserView.render();
    }
    columnChooserView._popupContainer.option("position", {
      of: dataGrid.value.element(),
      my: "left top",
      at: "left top",
      offset: "0 50",
    });
  };

  const onEditorPreparing = (e) => {
    if (e.parentType == "filterRow") {
      e.editorOptions.placeholder = "Search";
    }
  };

  const clearFilter = () => {
    dataGrid.value.clearFilter("row");
    dataGrid.value.clearFilter("header");
    dataGrid.value.clearFilter("search");
    dataGrid.value.clearSorting();
  };

  const customLoad = async () => {
    await store.dispatch('onTableHeader', {url: "/configurations", companyId : companyId.value
  }).catch((e)=> console.log(e));
    let value = store.getters['getTableHeaders'];
    console.log("customLoad", value);
    return value;
      
  }
  const customSave = (gridState) => {
    gridState.pageIndex = 0;
    gridState.pageSize = 10;
    let value = JSON.stringify(gridState);
    value = value.replace(',"selectedRowKeys":[]', "");

    if (!store.getters['getTableHeaders']) {
      console.log('createTableHeaders');
      store.dispatch('createTableHeaders',{
        url: "/configurations",
        headers: value,
        filters: "[]",
        companyId : companyId.value,

      }).catch((e)=> console.log(e));
    } else if (value != JSON.stringify(store.getters['getTableHeaders'])) {
      console.log('updateTableHeaders');
      store.dispatch('updateTableHeaders',{
        url: "/configurations",
        headers: value,
        filters: "[]",
        companyId : companyId.value,
        adminPreferenceId : adminPreferenceId.value,
      }).catch((e)=> console.log(e));
    }
    console.log("customSave value",value != JSON.stringify(store.getters['getTableHeaders']));
  }

  const refreshGrid = () => {
    console.log("grid refreshed");
    document.querySelector(".modal-backdrop").remove();
    document.querySelector(".dx-device-desktop").classList.remove('modal-open');
    getConfigs();
  }

  const onItemClick = (type) => {
    if (type == "Excel") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");
      exportDataGrid({
          component: dataGrid.value,
          worksheet: worksheet,
          customizeCell: function (options) {
            const excelCell = options;
            excelCell.font = { name: "Arial", size: 12 };
            excelCell.alignment = { horizontal: "left" };
          },
      }).then(function () {
          workbook.xlsx.writeBuffer().then(function (buffer) {
              saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              "configurations.xlsx"
              );
          });
      });
      // e.cancel = true;
    } else if (type == "CSV") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");
      exportDataGrid({
      component: dataGrid.value,
          worksheet: worksheet,
          customizeCell: function (options) {
            const excelCell = options;
            excelCell.font = { name: "Arial", size: 12 };
            excelCell.alignment = { horizontal: "left" };
          },
      }).then(function () {
          workbook.csv.writeBuffer().then(function (buffer) {
            saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "configurations.csv"
            );
          });
      });
    } else if (type == "PDF") {
      const doc = new jsPDF("landscape");
      exportDataGridToPdf({
          jsPDFDocument: doc,
          component: dataGrid.value,
          customizeCell: function(options) {
              const { gridCell, pdfCell } = options;
              if(gridCell.rowType === 'data') {
                // data columns style
                pdfCell.styles = {
                  font: 'times',
                  fontStyle: 'lato',
                  fontSize: 8,
                  cellWidth: 'auto'
                }
              }else{
                // header columns style
                pdfCell.styles = {
                  font: 'times',
                  fontStyle: 'lato',
                  fontSize: 8,
                  fillColor : '#2680EB',
                  textColor : '#FFFFFF',
                  cellWidth: 'auto'
                }
              }
          }
      }).then(() => {
          const pageCount = doc.internal.getNumberOfPages();
          for(let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            const pageSize = doc.internal.pageSize;
            const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            const header = 'Configurations';
            const headerWidth = doc.getTextDimensions(header).w;
            const footer = `Page ${i} of ${pageCount}`;

            // Header
            doc.text(header, (pageWidth - headerWidth) / 2, 10);

            // Footer
            doc.text(footer, pageWidth / 2 - (doc.getTextWidth(footer) / 2), pageHeight - 5, { baseline: 'bottom' });
          }

          doc.save("configurations.pdf");
      });
    }
  }

  const getConfigs = () => {
    store.dispatch('fetchConfigsGrid', companyId.value).then((res) => {
      dataGrid.value.endCustomLoading();
      if(res.data && res.data.length){
        res.data.map((config) => {
          config.description = config.description ? config.description  : '-'; 
        })
      }
      configurationsArr.value = res.data;
    }).catch( (e) => {
      console.log(e)
    })
  }

  onMounted(() => {
    dataGrid.value.beginCustomLoading();
    state.configModal = new Modal("#configModal", {});
  });

  onBeforeMount(()=>{
    getConfigs();
  })

</script>

<template>
  <DefaultLayout>
    <div class="d-flex align-items-center">
      <h4 class="grid-header-title">Configurations</h4>
      <button @click="configModal('show')" pill class="grid-header-action-btn">
        Add
      </button>
    </div>

    <addConfig @refresh="refreshGrid" :closeModal="configModal"/>
    <editConfig v-if="getModalState" @refresh="refreshGrid"/>
    <deleteConfig v-if="getModalState" @refresh="refreshGrid"/>


    <section class="grid-white-section main-page-container-animation">
      <DxDataGrid
        :data-source="configurationsArr"
        ref="dataGridRefName"
        :remote-operations="true"
        :showBorders="false"
        :columnAutoWidth="true"
        :allowColumnReordering="true"
        :allowColumnResizing="true"
        :rowAlternationEnabled="false"
        :showColumnLines="false"
        :showRowLines="true"
        :repaint-changes-only="true"
        :two-way-binding-enabled="true"
        :no-data-text="'No configurations yet'"
        @editor-preparing="onEditorPreparing($event)"
        @content-ready="contentReady($event)"
      >
        <DxLoadPanel :show-indicator="true" :show-pane="true" :enabled="true"/>
        <DxScrolling mode="standard" :use-native="true" />
        <DxPaging :page-size="10" />
        <DxStateStoring :enabled="true" type="custom"  :customSave="customSave" :customLoad="customLoad"/>

        <DxPager
          :visible="true"
          :allowed-page-sizes="[10, 25, 50, 100]"
          :display-mode="'full'"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
        />

        <!-- <DxFilterRow :visible="true" /> -->
        <DxSearchPanel :width="200" :visible="true" placeholder="Search ..."/>

        <DxSorting mode="single" />
        <DxHeaderFilter :visible="true" />
        <DxColumnChooser :enabled="true" mode="select" />

        <DxToolbar>
          <DxItem location="before" template="columnButtonTemplate" />
          <DxItem location="before" template="exportButtonTemplate" />
          <DxItem location="before" template="clearAllTemplate" />
          <DxItem location="after" name="searchPanel" />

        </DxToolbar>

        <DxColumn
          data-field="name"
          caption="Name"
          :allow-header-filtering="false"
          :filter-operations="allowedOperations"
          data-type="string"
          :allow-searching="true"
        />

        <DxColumn
          data-field="description"
          caption="Description"
          :allow-editing="false"
          :visible="true"
          :allow-filtering="true"
          :filter-operations="allowedOperations"
          :allow-header-filtering="false"
          data-type="string"
          :allow-searching="true"
        />

        <DxColumn
          data-field="actions"
          caption="Actions"
          :allow-filtering="false"
          :allow-exporting="false"
          :allow-editing="false"
          :allow-sorting="false"
          cell-template="action-cell"
          data-type="object"
        />

        <template #action-cell="{data}">
            <ActionCell :data="data.data"/>         
          </template>

        <!-- <template #columnButtonTemplate>
          <button class="custom-columns mx-1" @click="dataGrid.showColumnChooser()">
            <span class="custom-text">Columns </span>
            <span class="custom-icon">
              <i class="dx-icon dx-icon-spindown dx-icon-right"></i>
            </span>
          </button>
        </template>

        <template #exportButtonTemplate>
          <DxDropDownButton class="exportGridHeader" :items="buttonData" text="Export" @item-click="onItemClick" />
        </template>

        <template #clearAllTemplate>
          <div v-if="showClearAll" class="clear-cols">
            <div class="custom-line"></div>
            <Button
              text="Clear All"
              @click="clearFilter()"
              styling-mode="outlined"
              type="danger"
              class="clear-colums-filter"
            />
          </div>
        </template> -->

        <template #exportButtonTemplate>
          <button  @click="onItemClick('Excel')" type="button" class="btn action-btn m-5 font-20"><i class="bi bi-filetype-xlsx"></i> </button>
        </template>

        <template #columnButtonTemplate>
          <button  @click="dataGrid.showColumnChooser()" type="button" class="btn action-btn font-20 m-5"><i class="bi bi-columns"></i> </button>
        </template>

        <template #clearAllTemplate>
          <div v-if="showClearAll" class="clear-cols">
            <div class="custom-line"></div>
            <button @click="clearFilter()" type="button" class="btn action-btn clear-colums-filter m-5">Clear Filters </button>
          </div>
        </template>
        
      </DxDataGrid>
    </section>
  </DefaultLayout>
</template>
