<template>
  <div>
    <Skeletor class="memory-skeletor-elem" width="100" as="div" />
    <Skeletor class="memory-skeletor-elem" as="div" />
    <div class="memory-details-skeletor">
      <Skeletor class="memory-skeletor-elem" width="150" as="div" />
      <Skeletor class="memory-skeletor-elem" width="150" as="div" />
    </div>
  </div>
</template>

<style>
.memory-details-skeletor {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: auto;
}
.memory-skeletor-elem{
  margin-bottom:10px;
}
</style>
