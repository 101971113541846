
<script setup>
  import { ref, defineEmits, onBeforeMount, computed } from "vue";
  import { useStore } from "vuex";
  const emit = defineEmits(['refresh']);
  const store = useStore();

  // const global_user_name = inject(['global_user_name']);
  const user = computed(()=>JSON.parse(localStorage.getItem('userDetails')));

  let isSubmitted = ref(false);
  let name = ref(null);
  let mac_address = ref(null);
  let apps = ref([]);
  let appsOptions = ref([]);
  let isLoading = ref(true);
  const getModalInfo = computed(()=> store.getters.getModalInfo);
  const getModalAction = computed(()=> store.getters.getModalAction);
  const company_id = computed(()=>localStorage.getItem("currentCompanyId"));

  const onSubmit = () => {
    isSubmitted.value = true;

    let packagesArr = [];

    apps.value.map((app) => {
      packagesArr.push(app.packageId)
    });

    let data = { 
      entityId: company_id.value,
      kioskName : name.value,
      macAddress : mac_address.value,
      userName : user.value.first_name+' '+ user.value.last_name,
      userId : user.value.user_id,
      connectionType : 'daemon',
      packages : packagesArr,
    }

    store.dispatch("uninstallApp", data)
    .then((res) => {
      console.log('res', res);
      resetStoreData();
      emit('refresh');
    })
    .catch((e) => {
      console.log("e", e);
      isSubmitted.value = false;
    });
  };

  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);
  };

  onBeforeMount(()=>{
    if(getModalInfo.value && getModalInfo.value.data_type == 'device' && getModalAction.value == 'uninstall_apps'){
      console.log('getModalInfo', getModalInfo.value);
      name.value = getModalInfo.value.name;
      mac_address.value = getModalInfo.value.mac_address;

      let data = { 
        entityId: company_id.value,
        kioskName : getModalInfo.value.name,
        macAddress : getModalInfo.value.mac_address,
        userName : user.value.first_name+' '+ user.value.last_name,
        userId : user.value.user_id,
        connectionType : 'daemon',
      }

      store.dispatch("fetchInstalledAppsGrid",data )  
      .then((res) => {
        console.log('res', res.data);
        appsOptions.value = JSON.parse(res.data);
        isLoading.value = false;
      })
      .catch((e)=> {
        console.log(e);
      });
    }
  });

</script>

<template>
  <DModal id="uninstallAppsModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Uninstall Apps </template>

    <template #body>
      <div class="delete-text mb-3">
        <span>Select the applications you want to  <b>"Uninstall"</b> on the device "{{ name }}" ?</span>
      </div>

      <div class="p-relative mb-3">
        <span v-if="apps.length" class="multiselect-title-span">Applications</span>
        <VMultiselect :class="{'multiselect-options' : apps.length}" v-model="apps" :options="appsOptions" :disabled="!appsOptions.length" :multiple="true"
          :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Applications" trackBy="packageId" label="name" :loading="isLoading"
        />
      </div>

    </template>

    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button :disabled="!apps.length || isSubmitted " 
          @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
            Uninstall <span v-if="isSubmitted"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
          </button>
          <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </template>
  </DModal>
</template>
