import Api from "./Api";

export default {
  getConfigs(companyId) {
    return Api().get(`/configurations/${companyId}`);
  },
  getConfigById(payload) {
    return Api().get(`/configurations/${payload.company_id}/${payload.id}`);
  },
  addConfig(payload) {
    return Api().post(`/configurations`, payload);
  },
  editConfig(payload) {
    return Api().put(`/configuration/${payload.company_id}/${payload.id}`, payload);
  },
  deleteConfig(payload) {
    return Api().delete(`/configurations/${payload.companyId}/${payload.id}`);
  },
};