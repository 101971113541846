<script setup>
    import { ref, onMounted, onBeforeMount } from "vue";
    import { useStore } from "vuex";
    const currentCompany = JSON.parse(localStorage.getItem("currentCompany"));
    const userName = `${currentCompany.pivot?.first_name.charAt(0).toUpperCase() + currentCompany.pivot?.first_name.slice(1).toLowerCase()}  ${currentCompany.pivot?.last_name.charAt(0).toUpperCase() + currentCompany.pivot?.last_name.slice(1).toLowerCase()}`
    const store = useStore();
    import { useRoute } from 'vue-router'
    const route = useRoute()

    import { socket } from "@/socket";

    import useValidation from '@/utils/helpers/validations';

    const { checkLoggedInUserRole } = useValidation();

    let UnreadMessagesCounter = ref(null)
    const logout = () => {
        store.dispatch('logout');
    };

    const fetchUnreadMessagesCounter = () => {
        store.dispatch("fetchUnreadMessagesCounter",currentCompany.id )
        .then((res) => {
            // console.log('res', res.data);
            UnreadMessagesCounter.value = res.data
        })
        .catch((e)=> {
            console.log(e);
        });
    }

    onMounted(() => {
        socket.on(`joinedRoom-${currentCompany.id}`, (data) => {
            if(data && data.do == 'refresh-inbox-counter'){
                console.log('socket refresh-inbox-counter', data);
                fetchUnreadMessagesCounter();
            }
        });
    });

    onBeforeMount( () => {
        fetchUnreadMessagesCounter();
    });

</script>
<template>
    <div class="header-page">
        <div class="header-page-items d-flex align-items-center">
            <router-link to="/">
                <img class="logo h-45" src="@/assets/images/logo/logo.png" />
            </router-link>


            <div class="d-flex align-items-center">

                <router-link v-if="route.name != 'CompaniesListView' && !checkLoggedInUserRole() " class="nav-item" to="/inbox">
                    <div class="cursor-pointer notification mr-20" >
                        <span class="font-25 notification-icon"><i class="bi bi-chat-square-text"></i></span>
                        <!-- <span class="font-20"><i class="bi bi-envelope-fill"></i></span> -->
                        <span v-if="UnreadMessagesCounter" class="text-center unread-badge">
                            <span class="p-1">{{ UnreadMessagesCounter }}</span>
                        </span>
                    </div>
                </router-link>
                 
                <div v-if="route.name != 'CompaniesListView' && !checkLoggedInUserRole()" class="line-between"></div>

                <div @click="logout()" v-if="route.name == 'CompaniesListView'">
                    <span class="nav-label logout-action">
                        <i class="bi bi-door-open-fill"></i><span class="ml-5">Logout</span>
                    </span>
                </div>

                <b-dropdown v-else right class="header-dropdown-actions ml-20">
                    <template v-slot:button-content>
                        <div class="d-flex align-items-center">
                          <div class="d-xl-inline-block ml-1 text-left">
                            <p class="user-fullName mb-0">{{ userName }}</p>
                          </div>
                          <svg style="margin-left: 12px" width="9"
                            height="5"
                            viewBox="0 0 9 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M3.66772 4.64C3.75812 4.74474 3.87001 4.82877 3.99581 4.88637C4.12161 4.94396 4.25836 4.97377 4.39672 4.97377C4.53508 4.97377 4.6718 4.94396 4.7976 4.88637C4.9234 4.82877 5.03532 4.74474 5.12573 4.64L8.57272 0.809998C8.97272 0.362998 8.81571 0 8.21472 0H0.578698C-0.0213019 0 -0.179273 0.365998 0.220727 0.809998L3.66772 4.64Z"
                              fill="#636161"
                            />
                          </svg>
                        </div>
                    </template>

                    <b-dropdown-item >
                        <router-link  class="nav-item" to="/company-details">
                            <span class="nav-label">
                                <svg class="suchicon" width="14.88" height="20" viewBox="0 0 16 20" fill="#787878" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.7766 0.1C15.6766 0 15.5766 0 15.4766 0L1.17656 3C1.07656 3 0.976562 3.2 0.976562 3.3V19.7C0.976562 19.8 0.976562 19.9 1.07656 19.9C1.07656 20 1.07656 20 1.17656 20H6.97656V15.7C6.97656 15.5 7.07656 15.4 7.27656 15.4H9.37656C9.47656 15.4 9.57656 15.4 9.57656 15.5C9.67656 15.6 9.67656 15.6 9.67656 15.7V20H15.4766C15.5766 20 15.6766 20 15.6766 19.9C15.7766 19.8 15.7766 19.8 15.7766 19.7V0.3C15.8766 0.2 15.8766 0.1 15.7766 0.1ZM7.47656 12.9C7.47656 13 7.47656 13.1 7.37656 13.1C7.27656 13.2 7.27656 13.2 7.17656 13.2H5.17656C4.97656 13.2 4.87656 13.1 4.87656 12.9V10.4C4.87656 10.2 4.97656 10.1 5.17656 10.1H7.17656C7.27656 10.1 7.37656 10.1 7.37656 10.2C7.47656 10.3 7.47656 10.3 7.47656 10.4V12.9ZM7.47656 8C7.47656 8.1 7.47656 8.2 7.37656 8.2C7.27656 8.3 7.17656 8.3 7.07656 8.3H5.07656C4.97656 8.3 4.77656 8.2 4.77656 8V5.5C4.77656 5.3 4.87656 5.2 5.07656 5.2H7.07656C7.17656 5.2 7.27656 5.2 7.27656 5.3C7.37656 5.4 7.37656 5.4 7.37656 5.5V8H7.47656ZM11.9766 12.9C11.9766 13 11.9766 13.1 11.8766 13.1C11.7766 13.2 11.7766 13.2 11.6766 13.2H9.67656C9.47656 13.2 9.37656 13.1 9.37656 12.9V10.4C9.37656 10.2 9.47656 10.1 9.67656 10.1H11.6766C11.7766 10.1 11.8766 10.1 11.8766 10.2C11.9766 10.3 11.9766 10.3 11.9766 10.4V12.9ZM11.9766 8C11.9766 8.1 11.9766 8.2 11.8766 8.2C11.7766 8.3 11.7766 8.3 11.6766 8.3H9.67656C9.47656 8.3 9.37656 8.2 9.37656 8V5.5C9.37656 5.3 9.47656 5.2 9.67656 5.2H11.6766C11.7766 5.2 11.8766 5.2 11.8766 5.3C11.9766 5.4 11.9766 5.4 11.9766 5.5V8Z"/>
                                </svg> 
                                <span class="ml-5">Company Details</span>
                            </span>
                        </router-link>
                    </b-dropdown-item>
                    <b-dropdown-divider ></b-dropdown-divider>
                    <b-dropdown-item>
                        <router-link class="nav-item" to="/general-settings">
                            <span class="nav-label">
                                <i class="bi bi-gear-fill"></i> 
                                <span class="ml-5">General Settings</span>
                            </span>
                        </router-link>
                    </b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="logout()"><span class="nav-label logout-action">
                        <i class="bi bi-door-open-fill"></i><span class="ml-5">Logout</span></span>
                    </b-dropdown-item>
                </b-dropdown>

            </div>
        </div>
    </div>
</template>