<script setup>
  import { ref, defineProps, defineEmits, onBeforeMount, computed, onMounted } from "vue";
  import { useStore } from "vuex";
  import "leaflet/dist/leaflet.css";
  import * as L from 'leaflet';
  import 'leaflet.markercluster/dist/MarkerCluster.css';
  import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
  import "leaflet.markercluster";
  import icon from '@/assets/images/location_icon.png'
  const markers = L.markerClusterGroup();
  
  const props = defineProps({
    sitesOptions : Array,
  });

  const store = useStore();
  const emit = defineEmits(["refresh"]);
  const company_id = localStorage.getItem("currentCompanyId");
  const site = ref(null);
  const parent_area = ref(null);
  let parentAreas = ref([]);
  let description = ref(null);
  let id = ref(null);
  let name = ref(null);
  let latitude = ref(null);
  let longitude = ref(null);
  let isSubmitted = ref(false);
  let isLoadingParentAreas = ref(false);
  const initialMap = ref(null);
  const getModalInfo = computed(()=> store.getters.getModalInfo);
  const getModalAction = computed(()=> store.getters.getModalAction);
  import * as GeoSearch from 'leaflet-geosearch';

  const myIcon = L.icon({
    iconUrl: icon,
    iconSize: [30, 30],
    iconAnchor: [15, 30],
    popupAnchor: [-3, -76],
    shadowSize: [60, 30],
    shadowAnchor: [22, 94]
  });

  const changeSiteVal = () => {
    parent_area.value = null
    parentAreas.value = [];
    // console.log('site', site.value);
    if(site.value){
      isLoadingParentAreas.value = true;
      store.dispatch("fetchParentAreas", {
        siteId :site.value.id,
        companyId : company_id,
      }).then((res) => {
        // console.log('res', res.data);
        parentAreas.value = res.data;
        isLoadingParentAreas.value = false;
      });
    }

  }

  const resetData = () => {
    site.value = null;
    name.value = null;
    latitude.value = null;
    longitude.value = null;
    description.value = null;
    parent_area.value = null;
    parentAreas.value = [];
    isLoadingParentAreas.value = false;
    isSubmitted.value = false;
  };

  const onSubmit = () => {
    isSubmitted.value = true;
    const data = {
      id: id.value,
      name: name.value,
      latitude : latitude.value.toString(),
      longitude : longitude.value.toString(),
      description: description.value,
      site_id: site.value.id,
      parent_id: parent_area.value ? parent_area.value.id : null,
      company_id,
    };

    store.dispatch("editArea", data).then(() => {
      resetData();
      resetStoreData();
      emit("refresh");
    }).catch((e) => {
      console.log(e);
      isSubmitted.value = false;
    });
  };

  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);

  };

  const pickLocation = async (e) => {
    console.log('e', e);

    initialMap.value.eachLayer(async (layer) => {
      if (layer instanceof L.Marker) {
        await layer.remove();
      }
    });

    let marker = new L.marker([e.latlng.lat , e.latlng.lng], {icon: myIcon})
    .bindTooltip(`lat: ${e.latlng.lat} <br> long: ${e.latlng.lng}`)
    .bindPopup(`lat: ${e.latlng.lat} <br> long: ${e.latlng.lng}`)
    .addTo(initialMap.value);

    markers.addLayer(marker);
    initialMap.value.addLayer(markers);

    initialMap.value.latLngToLayerPoint([e.latlng.lat , e.latlng.lng])

    latitude.value = e.latlng.lat;
    longitude.value = e.latlng.lng;
  }

  onMounted(()=> {
    if(getModalInfo.value && getModalAction.value == 'edit_area'){
      setTimeout(async () => {


        initialMap.value = L.map('editMapArea', {zoomControl: true,zoom:1,zoomAnimation:false,fadeAnimation:true,markerZoomAnimation:true}).setView([latitude.value ,longitude.value], 20);
        initialMap.value.on('click', pickLocation);

        let marker = L.marker([latitude.value ,longitude.value], {icon: myIcon})
        .bindTooltip(`${name.value} `)
        .bindPopup(` ${name.value} `)
        .addTo(initialMap.value);

        await markers.clearLayers();  
        markers.addLayer(marker);

        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
        }).addTo(initialMap.value);

        const search = new GeoSearch.GeoSearchControl({
          provider: new GeoSearch.OpenStreetMapProvider(),
        });

        initialMap.value.addControl(search);
        await markers.clearLayers();

      },500);
    }
  });

  onBeforeMount( () => {

    if(getModalInfo.value && getModalInfo.value.data_type == 'area' && getModalAction.value == 'edit_area'){
      console.log('getModalInfo', getModalInfo.value);

      site.value = props.sitesOptions.filter((site)=>{
        if(site.id == getModalInfo.value.site_id) return site
      });

      id.value = getModalInfo.value.id;
      name.value = getModalInfo.value.name;
      latitude.value = getModalInfo.value.metadata && getModalInfo.value.metadata.latitude ? Number(getModalInfo.value.metadata?.latitude) : '';
      longitude.value = getModalInfo.value.metadata && getModalInfo.value.metadata.longitude ? Number(getModalInfo.value.metadata?.longitude) : '';
      description.value = getModalInfo.value.description;

      isLoadingParentAreas.value = true;

      store.dispatch("fetchParentAreas", {
        siteId : getModalInfo.value.site_id,
        companyId : company_id,
      }).then((res) => {
        if(res.data && res.data.length){
          parentAreas.value = res.data.filter((area)=>{
            if(area.id != getModalInfo.value.id){
              if(getModalInfo.value.parent_id){
                parent_area.value = area
              }
              return area;
            }
          });
        }
        isLoadingParentAreas.value = false;
      });
    }

  });
</script>

<template>
  <DModal id="editAreaModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Edit Area </template>

    <template #body>

      <div class="mb-3">
        <span class="map-hint">Please pick up the area location on the map</span>
        </div>
  
      <div class="map-card p-0 mb-3">
        <div id="editMapArea" style="height:34vh;"></div>
      </div>

      <div class="p-relative mb-3">
        <span v-if="site" class="multiselect-title-span">Site</span>
        <VMultiselect @select="changeSiteVal" @remove="changeSiteVal" v-model="site" :options="props.sitesOptions" :disabled="!props.sitesOptions.length || site"
          :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Site" trackBy="id" label="name"
        />
      </div>

      <div class="form-floating mb-3">
        <input type="text" v-model.trim="name" class="form-control" id="editAreaName" placeholder=""/>
        <label for="editAreaName">Area Name</label>
      </div>

      <div class="form-floating mb-3">
        <textarea v-model="description" class="form-control none-resize h-105" placeholder="description" id="editDescription"/>
        <label for="editDescription">Description</label>
      </div>

      <div class="p-relative mb-3">
        <span v-if="parent_area" class="multiselect-title-span" >Parent Area</span>
        <VMultiselect v-model="parent_area" :options="parentAreas" trackBy="id" label="name" 
          :disabled="true" :loading="isLoadingParentAreas" :searchable="true"
          :close-on-select="true" :show-labels="false" placeholder="Parent Area"
        />
      </div>

      <p class="delete-text"><b>Note:</b> To change the area’s parent, click the action “Move Area” from the "Sites & Devices" page.</p>

    </template>
    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button :disabled="!site || !name || !latitude || !longitude || isSubmitted"
            @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
            Edit <span v-if="isSubmitted"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
          </button>
          <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </template>
  </DModal>
</template>
