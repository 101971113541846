<script setup>
  import { ref, onBeforeMount, computed, reactive, defineEmits, onMounted } from "vue";
  import { useStore } from "vuex";
  import {
    DxDataGrid,
    DxColumn,
    // DxFilterRow,
    DxHeaderFilter,
    DxToolbar,
    DxItem,
    DxPaging,
    DxPager,
    DxSorting,
    DxScrolling,
    DxColumnChooser,
    DxStateStoring,
    DxLoadPanel,
    DxSearchPanel,
    } from 'devextreme-vue/data-grid';
    // import DxDropDownButton from "devextreme-vue/drop-down-button";
    // import { default as Button } from "devextreme-vue/button";
    import { exportDataGrid } from "devextreme/excel_exporter";
    import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
    import { Workbook } from "exceljs";
    import saveAs from "file-saver";
    import { jsPDF } from "jspdf";
    import { Modal } from "bootstrap";

    import DeviceAppsActionCell from "@/components/kiosks/DeviceAppsActionCell.vue";
    import clearAppCache from "@/components/kiosks/clearAppCache.vue";
    import clearAppData from "@/components/kiosks/clearAppData.vue";
    import forceStopApp from "@/components/kiosks/forceStopApp.vue";
    import launchApp from "@/components/kiosks/launchApp.vue";
    import uninstallApp from "@/components/kiosks/uninstallApp.vue";

    const dataGridRefName = ref(null);
    const allowedOperations = ref(["contains"]);
    // const buttonData = ref(["Excel", "CSV", "PDF"]);
    let apps = ref([]);

    const emit = defineEmits(['refresh']);
    const store = useStore();
    let showClearAll = ref(false);

    let name = ref(null);
    const getModalAction = computed(()=> store.getters.getModalAction);
    const getModalInfo = computed(()=> store.getters.getModalInfo);
    const getModalState = computed(()=> store.getters.getModalState);
    const companyId = computed(()=>localStorage.getItem("currentCompanyId"));
    const adminPreferenceId = computed(()=>localStorage.getItem("adminPreferenceId"));
    const user = computed(()=>JSON.parse(localStorage.getItem('userDetails')));
    const getDeviceDataObject = computed(()=> store.getters.getDeviceDataObject);

    const dataGrid = computed(()=>{
        return dataGridRefName.value.instance;
    });

    const state = reactive({
      deviceAppsModal: null,
    });

    const showDeviceAppsModal = async () => {
      document.querySelector(".modal-backdrop").remove();
      document.querySelector(".dx-device-desktop").classList.remove('modal-open');
      // console.log('showDeviceAppsModal');
      await store.commit('setModalAction', 'device_apps');
      store.commit('setModalState', true);
      await store.commit('setModalData', getDeviceDataObject.value);

      // console.log('getDeviceDataObject.value', getDeviceDataObject.value);
      setTimeout(() => {
        state.deviceAppsModal = new Modal("#deviceAppsModal", {});
        state.deviceAppsModal.show();
      }, 100)
    }

    const contentReady = (e) =>{
      
      let sortedExp = getSortedColumns();
      let filterExp = e.component.getCombinedFilter();
      if(filterExp || sortedExp) {
          showClearAll.value = true;
      }else{
          showClearAll.value = false;
      }
      setColumnChooserPosition();
      
    }

    const getSortedColumns = () =>{
        let allColumns = Array.from(Array(dataGrid.value.columnCount()).keys()).map(index => dataGrid.value.columnOption(index));
        allColumns = allColumns.find(col => col.sortIndex != null);
        return allColumns;
    }

    const customLoad = async () => {
      if(getModalAction.value == 'device_apps'){
        await store.dispatch('onTableHeader', {url: "/device-apps", companyId : companyId.value})
        .catch((e)=> console.log(e));
        let value = store.getters['getTableHeaders'];
        console.log("customLoad", value);
        return value;
      }
    }

    const customSave = (gridState) => {
      if(getModalAction.value == 'device_apps'){
        gridState.pageIndex = 0;
        gridState.pageSize = 10;
        let value = JSON.stringify(gridState);
        value = value.replace(',"selectedRowKeys":[]', "");

        if (!store.getters['getTableHeaders']) {
        console.log('createTableHeaders');
        store.dispatch('createTableHeaders',{
            url: "/device-apps",
            headers: value,
            filters: "[]",
            companyId : companyId.value,
        }).catch((e)=> console.log(e));
        } else if (value != JSON.stringify(store.getters['getTableHeaders'])) {
        console.log('updateTableHeaders');
        store.dispatch('updateTableHeaders',{
            url: "/device-apps",
            headers: value,
            filters: "[]",
            companyId : companyId.value,
            adminPreferenceId : adminPreferenceId.value,

        }).catch((e)=> console.log(e));
        }
        console.log("customSave value",value != JSON.stringify(store.getters['getTableHeaders']));
      }
    }

    const setColumnChooserPosition = () => {
        var columnChooserView = dataGrid.value.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
        }
        columnChooserView._popupContainer.option("position", {
            of: dataGrid.value.element(),    
            my: "left top",
            at: "left top",
            offset: "0 50",
        });
    }

    const onEditorPreparing = (e) => {
        if (e.parentType == "filterRow") {
            e.editorOptions.placeholder = "Search";
        }
    }

    const clearFilter = () =>{
        dataGrid.value.clearFilter("row");
        dataGrid.value.clearFilter("header");
        dataGrid.value.clearFilter("search");
        dataGrid.value.clearSorting();
    }

    const onItemClick = (type) => {
        if (type == "Excel") {
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet("Main sheet");
          exportDataGrid({
              component: dataGrid.value,
              worksheet: worksheet,
              customizeCell: function (options) {
                  const excelCell = options;
                  excelCell.font = { name: "Arial", size: 12 };
                  excelCell.alignment = { horizontal: "left" };
              },
          }).then(function () {
              workbook.xlsx.writeBuffer().then(function (buffer) {
                  saveAs(
                  new Blob([buffer], { type: "application/octet-stream" }),
                  "device-apps.xlsx"
                  );
              });
          });
        // e.cancel = true;
        } else if (type == "CSV") {
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet("Main sheet");
          exportDataGrid({
          component: dataGrid.value,
              worksheet: worksheet,
              customizeCell: function (options) {
                  const excelCell = options;
                  excelCell.font = { name: "Arial", size: 12 };
                  excelCell.alignment = { horizontal: "left" };
              },
          }).then(function () {
              workbook.csv.writeBuffer().then(function (buffer) {
                  saveAs(
                  new Blob([buffer], { type: "application/octet-stream" }),
                  "device-apps.csv"
                  );
              });
          });
        } else if (type == "PDF") {
          const doc = new jsPDF("landscape");
          exportDataGridToPdf({
              jsPDFDocument: doc,
              component: dataGrid.value,
              customizeCell: function(options) {
                  const { gridCell, pdfCell } = options;
                  if(gridCell.rowType === 'data') {
                      // data columns style
                      pdfCell.styles = {
                      font: 'times',
                      fontStyle: 'lato',
                      fontSize: 8,
                      cellWidth: 'auto'
                      }
                  }else{
                      // header columns style
                      pdfCell.styles = {
                      font: 'times',
                      fontStyle: 'lato',
                      fontSize: 8,
                      fillColor : '#2680EB',
                      textColor : '#FFFFFF',
                      cellWidth: 'auto'
                      }
                  }
              }
          }).then(() => {
              const pageCount = doc.internal.getNumberOfPages();
              for(let i = 1; i <= pageCount; i++) {
                  doc.setPage(i);
                  const pageSize = doc.internal.pageSize;
                  const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
                  const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                  const header = 'Device Apps';
                  const headerWidth = doc.getTextDimensions(header).w;
                  const footer = `Page ${i} of ${pageCount}`;

                  // Header
                  doc.text(header, (pageWidth - headerWidth) / 2, 10);

                  // Footer
                  doc.text(footer, pageWidth / 2 - (doc.getTextWidth(footer) / 2), pageHeight - 5, { baseline: 'bottom' });
              }
              doc.save("device-apps.pdf");
          });
        }
    }
  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);
    emit('refresh');
  };

  onMounted (() => {
    setTimeout(() => {
      dataGrid.value.beginCustomLoading();
    },300)
  })

  onBeforeMount(async ()=>{
    // console.log('getModalAction', getModalAction.value);

    if(getModalInfo.value && getModalInfo.value.data_type == 'device' && getModalAction.value == 'device_apps'){

      await store.commit('setDeviceDataObject', getModalInfo.value);

      let data = { 
        entityId: companyId.value,
        kioskName : getModalInfo.value.name,
        macAddress : getModalInfo.value.mac_address,
        userName : user.value.first_name+' '+ user.value.last_name,
        userId : user.value.user_id,
        connectionType : 'daemon',
      } 
      store.dispatch("fetchInstalledAppsGrid",data )
      .then((res) => {
        // console.log('res', res.data);
        dataGrid.value.endCustomLoading();
        apps.value = JSON.parse(res.data);
      })
      .catch((e)=> {
        console.log(e);
      });

      name.value = getModalInfo.value.name;
    }
  });

</script>

<template>

  <clearAppCache v-if="getModalState" @callDeviceApps="showDeviceAppsModal"/>
  <clearAppData v-if="getModalState" @callDeviceApps="showDeviceAppsModal"/>
  <forceStopApp v-if="getModalState" @callDeviceApps="showDeviceAppsModal"/>
  <launchApp v-if="getModalState" @callDeviceApps="showDeviceAppsModal"/>
  <uninstallApp v-if="getModalState" @callDeviceApps="showDeviceAppsModal"/>

  <DModal id="deviceAppsModal" :main_classes="['modal-xl']" :dialog_classes="[]">
    <template #title>
      <div style="display: flex; align-items: center">
        <span style="margin-right: 346px;">Apps ({{ name }})</span>
        <span @click="resetStoreData" >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M11 0C4.91333 0 0 4.91333 0 11C0 17.0867 4.91333 22 11 22C17.0867 22 22 17.0867 22 11C22 4.91333 17.0867 0 11 0ZM16.5978 14.7156C17.1111 15.2289 17.1111 16.06 16.5978 16.5733C16.3289 16.8422 16.0111 16.9644 15.6689 16.9644C15.3267 16.9644 14.9844 16.8422 14.74 16.5733L11.0733 12.9067L7.38222 16.5733C7.11333 16.8422 6.79556 16.9644 6.45333 16.9644C6.11111 16.9644 5.76889 16.8422 5.52444 16.5733C5.01111 16.06 5.01111 15.2289 5.52444 14.7156L9.21556 11.0244L5.52444 7.35778C5.01111 6.84444 5.01111 6.01333 5.52444 5.5C6.03778 4.98667 6.86889 4.98667 7.38222 5.5L11.0733 9.19111L14.7644 5.5C15.2778 4.98667 16.1089 4.98667 16.6222 5.5C17.1356 6.01333 17.1356 6.84444 16.6222 7.35778L12.9311 11.0489L16.5978 14.7156Z"
              fill="#F46A6A"
            />
          </svg>
        </span>
      </div>
    </template>
  
    <template #body>

      <section class="grid-white-section">
  
        <DxDataGrid :data-source="apps" 
        ref="dataGridRefName"
        :remote-operations="true"
        :showBorders="false"
        :columnAutoWidth="true"
        :allowColumnReordering="true"
        :allowColumnResizing="true"
        :rowAlternationEnabled="false"
        :showColumnLines="false"
        :showRowLines="true"
        :repaint-changes-only="true"
        :two-way-binding-enabled="true"
        :no-data-text="'No apps yet'"
        @editor-preparing="onEditorPreparing($event)"
        @content-ready="contentReady($event)"
        >


            <DxLoadPanel :show-indicator="true" :show-pane="true" :enabled="true"/>
            <DxScrolling mode="standard" :use-native="true" />
            <DxPaging :page-size="10" />
            <DxStateStoring :enabled="true" type="custom"  :customSave="customSave" :customLoad="customLoad"/>
    
            <DxPager
            :visible="true"
            :allowed-page-sizes="[10, 25, 50, 100]"
            :display-mode="'full'"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
            />
    
            <!-- <DxFilterRow :visible="true" /> -->
            <DxSearchPanel :width="200" :visible="true" placeholder="Search ..."/>
            <DxSorting mode="single" />
            <DxHeaderFilter :visible="true" />
            <DxColumnChooser :enabled="true" mode="select" />

            <DxToolbar>
              <DxItem location="before" template="columnButtonTemplate" />
              <DxItem location="before" template="exportButtonTemplate" />
              <DxItem location="before" template="clearAllTemplate"/>
              <DxItem location="after" name="searchPanel" />
            </DxToolbar>
            
            <DxColumn data-field="icon" caption="Icon" :allow-header-filtering="false" cell-template="icon-cell"
            :allow-searching="true"  data-type="string"/>

            <DxColumn data-field="name" caption="Name" :allow-header-filtering="false"
            :allow-searching="true" :filter-operations="allowedOperations" data-type="string"/>

            <DxColumn data-field="packageId" caption="Package" :allow-header-filtering="false"
            :allow-searching="true" :filter-operations="allowedOperations" data-type="string"/>

            <DxColumn data-field="version" caption="Version" :allow-header-filtering="false"
            :allow-searching="true" :filter-operations="allowedOperations" data-type="string"/>

            <DxColumn caption="Actions" :allow-filtering="false" :allow-editing="false"
            :allow-reordering="false" cell-template="action-cell" css-class="select-actions select-actionsDAsh"
            />

            <template #icon-cell="{ data }">
              <div>
                <img class="app-icon-grid" :src="data.data.icon" crossorigin="anonymous"/>
              </div>
            </template>

            <template #action-cell="{ data }">
              <DeviceAppsActionCell :data="data.data"/>
            </template>

            <template #exportButtonTemplate>
              <button  @click="onItemClick('Excel')" type="button" class="btn action-btn m-5 font-20"><i class="bi bi-filetype-xlsx"></i> </button>
            </template>
    
            <template #columnButtonTemplate>
              <button  @click="dataGrid.showColumnChooser()" type="button" class="btn action-btn font-20 m-5"><i class="bi bi-columns"></i> </button>
            </template>
    
            <template #clearAllTemplate>
              <div v-if="showClearAll" class="clear-cols">
                <div class="custom-line"></div>
                <button @click="clearFilter()" type="button" class="btn action-btn clear-colums-filter m-5">Clear Filters </button>
              </div>
            </template>
        </DxDataGrid>
      </section>
    </template>
  </DModal>
</template>