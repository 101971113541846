
<script setup>
    import { onBeforeMount, computed, ref } from "vue";
    import router from "@/router";
    import CompaniesLayout from "./layouts/CompaniesLayout.vue";
    const userCompanies = computed(()=>JSON.parse(localStorage.getItem('userCompanies')));

    // let currentPage = 1;
    let searchName = ref(null);
    let allCompanies = ref([]);
    let isCompanySelected = ref(false);

    const searchByName = () => {
        if(searchName.value.length > 0){
            allCompanies.value = userCompanies.value.filter((company)=>{
                if((company.name.toLowerCase()).includes(searchName.value.toLowerCase())) return company;
            })
        }else{
            allCompanies.value = userCompanies.value;
        }
    };

    // const getPaginatedCompanies = (pageNumber) =>{
    //     console.log('pageNumber',pageNumber);
    // };  

    const setDefaultCompany = (company) => {
        console.log('company', company);
        isCompanySelected.value = true;
        let spinner = document.getElementById('companySpinner'+company.id);
        spinner.classList.remove("d-none");
        localStorage.setItem('currentCompanyId', company.id);
        localStorage.setItem('currentCompany', JSON.stringify(company));
        setTimeout(()=>{router.push("/sites-devices")},2000);
    }

    onBeforeMount(()=>{
        allCompanies.value = userCompanies.value;
    });

</script>

<template>
    <CompaniesLayout>
        <div class="companies-card details-card" style="min-height:500px">
            <div class="header">
                <div class="title-wrapper">
                    <p class="title">All Companies </p>
                </div>
                <div class="position-relative">
                    <div class="input-group mb-3">
                        <span class="input-group-text" id="searchComapnyVal"><i class="bi bi-search"></i></span>
                        <input style="width: 519px;" type="text" class="form-control" placeholder="Search comapny" aria-label="Search comapny" 
                        aria-describedby="searchComapnyVal" v-model="searchName" @input="searchByName">
                    </div>
                </div>
            </div>
        
            <!-- <div v-if="allCompanies.length > 0" class="entities-list">
                <div :class="{'disableCompanyCard': isCompanySelected }" class="entity-item" v-for="company of allCompanies" :key="company.id" @click="setDefaultCompany(company.id)">
                    <span class="mr-1 entity-width">{{ company.name }}</span>
                    <span :id="'companySipnner'+company.id" class="spinner-border spinner-border text-success d-none" role="status" aria-hidden="true"></span>
                </div>
            </div> -->

            <div v-if="allCompanies.length > 0" class=" entities-list">
                <div class="d-grid gap-2 " v-for="company of allCompanies" :key="company.id">
                    <button :disabled="isCompanySelected? true : false" @click="setDefaultCompany(company)" class="btn company-item" type="button">{{ company.name }}
                        <span :id="'companySpinner'+company.id" class="spinner-border spinner-border text-success pull-right d-none" role="status" aria-hidden="true"></span>

                    </button>
                </div>
            </div>
            
            <div v-else class="delete-text mb-3">
                <span>No Companies yet</span>
            </div>
            <!-- <div v-if="allCompanies.length > 0" class="pagination">
                <div class="overflow-auto mx-auto" style="padding: 0px 10px">
                    <b-pagination v-model="currentPage" :total-rows="allCompanies.length"  :per-page="5"
                        aria-controls="my-table" @change="getPaginatedCompanies" >
                    </b-pagination>
                </div>
            </div> -->
        </div>
    </CompaniesLayout>
</template>
