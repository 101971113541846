<template>
  <div class="disk-skeletor">
    <Skeletor class="disk-skeletor-elem" width="200" as="div" />
    <Skeletor
      class="disk-skeletor-elem disk-chart-skeletor"
      width="150"
      height="150"
      as="div"
    />
    <Skeletor class="disk-skeletor-elem" width="200" as="div" />
    <Skeletor class="disk-skeletor-elem" width="200" as="div" />
  </div>
</template>

<style>
.disk-skeletor {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: auto;
}
.disk-skeletor-elem{
  margin-bottom:10px;
}
.disk-chart-skeletor {
  border-radius: 10px;
  margin: 0 auto 10px auto;
}
</style>
