
<script setup>
  import { ref, defineProps, defineEmits, onBeforeMount, computed } from "vue";
  import { useStore } from "vuex";

  const emit = defineEmits(["addDeviceRefresh", "removeBackground"]);
  const store = useStore();
  const props = defineProps({
    sitesOptions : Array,
    configsOptions : Array,
    
  });

  const company_id = localStorage.getItem("currentCompanyId");
  const getModalInfo = computed(()=> store.getters.getModalInfo);
  const getModalAction = computed(()=> store.getters.getModalAction);

  let areasOptions = ref([]);
  let site = ref(null);
  let area = ref(null);
  let name = ref(null);
  let token = ref(null);
  let config = ref(null);
  let deviceToken = ref(null);
  let deviceName = ref(null);
  let isLoadingAreas = ref(null);
  let isSubmitted = ref(false);
  let addFromAreaActions = ref(false);
  // let copyText = ref('Copy to clipboard');

  const changeSiteVal = () => {
    area.value = null
    areasOptions.value = [];
    // console.log('site', site.value);
    if(site.value){
      isLoadingAreas.value = true;
      store.dispatch("fetchAllAreas", {
        siteId :site.value.id,
        companyId : company_id,
      }).then((res) => {
        // console.log('res', res.data);
        areasOptions.value = res.data;
        isLoadingAreas.value = false;
      });
    }
  }

  // const copyToClipboard = () => {
  //   navigator.clipboard.writeText(deviceToken.value);
  //   copyText.value = 'Copied!';
  //   setTimeout(()=>{
  //       copyText.value = 'Copy to clipboard';
  //   },1000)
  // }

  const resetData = () => {
    site.value = null;
    area.value = null;
    areasOptions.value = [];
    name.value = null;
    isLoadingAreas.value = false;
    isSubmitted.value = false
  };

  const onSubmit = () => {
    isSubmitted.value = true;
    const data = {
      name : name.value,
      area_id : area.value.id,
      token : token.value,
      config_id : config.value.id, 
      company_id,
    }; 
    
    store.dispatch("addDevice", data).then(() => {
      resetStoreData();
      // deviceName.value = res.data.name;
      // deviceToken.value = res.data.token;
      resetData();
      emit("addDeviceRefresh");
      isSubmitted.value = false;
    }).catch((e) => {
      console.log(e);
      isSubmitted.value = false;
    });
  }

  onBeforeMount(() => {
    if(getModalInfo.value && getModalInfo.value.data_type == 'area' && getModalAction.value == 'add_device'){
      console.log('getModalInfo', getModalInfo.value);
      addFromAreaActions.value = true;
      props.sitesOptions.map((obj) => {
        if(obj.id == getModalInfo.value.site_id){
          site.value = obj;
        }
      })
      store.dispatch("fetchAllAreas", {
        siteId :site.value.id,
        companyId : company_id,
      }).then((res) => {
        // console.log('res', res.data);
        if(res.data && res.data.length){
          areasOptions.value = res.data;
          res.data.map((obj)=>{
            if(obj.id == getModalInfo.value.id){
              area.value = obj
            }
          });
          isLoadingAreas.value = false;
        }
      });
    }
  });

  const resetStoreData = () =>{
    deviceToken.value = null;
    deviceName.value = null;
    emit('removeBackground');
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);
  };
  
</script>

<template>
  <DModal id="deviceModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> 
      <span> Add Device </span>
      <!-- <span v-else> {{ deviceName }} Token </span> -->
    </template>
    <template #body>
      
      <!-- <div v-if="!deviceToken"> -->
        <div class="p-relative mb-3">
          <span v-if="site" class="multiselect-title-span" >Site</span>
          <VMultiselect @select="changeSiteVal" @remove="changeSiteVal" v-model="site" :options="props.sitesOptions" trackBy="id" label="name"
          :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Site" :disabled="!props.sitesOptions.length || addFromAreaActions"
          />
        </div>
  
        <div class="p-relative mb-3">
          <span v-if="area" class="multiselect-title-span" >Area</span>
          <VMultiselect v-model="area" :options="areasOptions" trackBy="id" label="name" :disabled="!site || isLoadingAreas" 
          :loading="isLoadingAreas" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Area"
          />
        </div>
  
        <div class="form-floating mb-3">
          <input v-model.trim="name" type="text" class="form-control"  id="deviceName" placeholder="Device Name" />
          <label for="deviceName">Device Name</label>
        </div>

        <div class="form-floating mb-3">
          <input v-model.trim="token" maxlength="6" type="text" class="form-control"  id="deviceToken" placeholder="Device Token" />
          <label for="deviceName">Device Token</label>
        </div>

        <div class="p-relative">
          <span v-if="config" class="multiselect-title-span" >Configuration</span>
          <VMultiselect v-model="config" :options="props.configsOptions" trackBy="id" label="name"
            :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Configuration"
          />
        </div>
      <!-- </div> -->

      <!-- <div v-else @click="copyToClipboard">
        <div class="d-flex text-center align-items-center">        
          <div class="text-center w-100" title="Copy to clipboard">
            <span class="generated-token">{{ deviceToken }}</span>
          </div>
        </div>
        <div class="text-center "> 
          <span class="click-message" v-text="copyText"></span>
        </div>
      </div> -->

      <!-- <div v-else class="col-12 text-center padding-none d-flex align-items-center">
        <div class="col-12 padding-none" @click="copyToClipboard">
          <div  class="text-center" title="Copy to clipboard">
            <span class="generated-token">{{ deviceToken }}</span>
          </div>
          <span class="click-message" v-text="copyText"></span>
        </div>
      </div> -->
    </template>

    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button v-if="!deviceToken" :disabled="!site || !area || !name || !token || token.length != 6 || !config || isSubmitted" 
          @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
            Add <span v-if="isSubmitted"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
          </button>
          <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </template>
  </DModal>
</template>