<script setup>
  import { ref, computed, onBeforeMount } from "vue";
  import { sideMenuItems } from "../sideMenuItems";
  import { useStore } from "vuex";
  
  const userDetails = computed(() => JSON.parse(localStorage.getItem("user")));
  const currentCompany = computed(() => JSON.parse(localStorage.getItem("currentCompany")));
  const superAdminEmail = process.env.VUE_APP_SUPER_ADMIN_EMAIL;
  const store = useStore();
  let menuItems = ref([])

  const isCollapsed = computed(() => store.getters.getCollapsedState);

  const collapse = () => {
    // console.log(isCollapsed.value);
    store.dispatch("updateCollapseState", !isCollapsed.value);
  };

  onBeforeMount( ()=> {
    menuItems.value = sideMenuItems.filter( (item) => {
      if(!item.isSuperAdmin && userDetails.value.user.email != superAdminEmail && userDetails.value.user.companies && userDetails.value.user.companies.length == 1 ) return item;
      else if(userDetails.value.user.email == superAdminEmail || (userDetails.value.user.companies && userDetails.value.user.companies.length > 1 ) ) return item;
    });

    // console.log('menuItems', menuItems.value);
  });

</script>

<template>
  <div class="nav-container" style="display: flex; flex-direction: row-reverse">
    <button
      class="toggle"
      :class="{
        'rotate-0 translate-right': isCollapsed,
      }"
      @click="collapse()"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
        <path
          d="M10.294 9.698a.988.988 0 010-1.407 1.01 1.01 0 011.419 0l2.965 2.94a1.09 1.09 0 010 1.548l-2.955 2.93a1.01 1.01 0 01-1.42 0 .988.988 0 010-1.407l2.318-2.297-2.327-2.307z"
          fill="currentColor"
          fill-rule="evenodd"
        ></path>
      </svg>
    </button>

    <nav
      class="vertical-side-menu"
      :class="{ 'vertical-side-menu-collapse': isCollapsed }"
    >
      <div
        v-if="currentCompany && currentCompany.name.length > 16"
        class="company-name"
        :class="{'opacity-0 ':isCollapsed}"
        :title="currentCompany.name"
      >
        {{ currentCompany.name.slice(0, 16).trim() }} ...
      </div>
      <div
        v-if="currentCompany && currentCompany.name.length < 16"
        class="company-name"
        :class="{'opacity-0 ':isCollapsed}"
      >
        {{ currentCompany.name }}
      </div>
      <div class="h-line-company" v-if="currentCompany && !isCollapsed"></div>

      <ul class="nav-items">
        <li class="mb-15" v-for="item of menuItems" :key="item.id" @click="$router.push(item.link)">
          <!-- <router-link
            v-if="item.isSuperAdmin && userDetails.email == superAdminEmail"
            class="nav-item"
            :to="item.link"
          >
            <span
              class="svg-nav-icon inactive-svg-nav-icon"
              :class="{
                'm-auto': isCollapsed,
              }"
              v-html="item.svg"
            ></span>
            <span class="nav-label">{{ item.label }} </span>
          </router-link> -->

          <router-link
            class="nav-item"
            :to="item.link"
          >
            <span
              class="svg-nav-icon inactive-svg-nav-icon"
              :class="{
                'm-auto': isCollapsed,
              }"
              v-html="item.svg"
            ></span>

            <span class="nav-label">{{ item.label }} </span>
          </router-link>

        </li>
      </ul>
    </nav>
  </div>
</template>