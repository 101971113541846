<script setup>
  import { ref, defineEmits, onBeforeMount, computed} from "vue";
  import { useStore } from "vuex";
  const company_id = localStorage.getItem("currentCompanyId");
  const getModalInfo = computed(()=> store.getters.getModalInfo);
  const getModalAction = computed(()=> store.getters.getModalAction);

  const emit = defineEmits(['refresh']);
  const store = useStore();
  let id = ref(null);
  let name = ref(null);
  let jobName = ref(null);
  let isSubmitted = ref(false);


  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);

  };

  const onSubmit = () => {
    isSubmitted.value = true;

    let data =  {   
        job_id : id.value , 
        company_id : company_id,
        name : name.value,
    };

    store.dispatch("duplicateJob", data)
    .then((res) => {
      console.log('res', res);
      resetStoreData();
      emit('refresh');
    })
    .catch((e) => {
      console.log("e", e);
      isSubmitted.value = false;
    });

  };

  onBeforeMount(()=>{
    if(getModalInfo.value && getModalAction.value == 'duplicate_job'){
      console.log('getModalInfo', getModalInfo.value);
      id.value = getModalInfo.value.id;
      name.value = getModalInfo.value.name;
      jobName.value = `${name.value} - Copy`
    }
  });
</script>
<template>
  <DModal id="duplicateJobModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Duplicate Job </template>

    <template #body>

      <div class="delete-text mb-3">
        <span>Are you sure you want to <b>"Duplicate"</b> the job <b>{{ name }}</b> ?</span>
      </div>

      <div class="form-floating mb-3">
        <input type="text" v-model="jobName" class="form-control" id="jobName" placeholder=""/>
        <label for="jobName">Job Name</label>
    </div>

    </template>

    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
            <button :disabled="!jobName || isSubmitted"
            @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
            Duplicate <span v-if="isSubmitted"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
            </button>
            <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </template>
  </DModal>
</template>
  