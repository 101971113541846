import { ref, computed } from "vue";
const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
const regUrl = /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\/#[-a-z\d_]*)?$/i;

let isValidEmail = ref(true);
let isValidUrl = ref(true);
const currentCompany = computed(()=>JSON.parse(localStorage.getItem("currentCompany")));

export default function useValidation(){

    function checkEmailValidation(email) {
        if (email.length && !regEmail.test(email)) {
            isValidEmail.value = false;
        }else{
            isValidEmail.value = true;
        }
        return isValidEmail.value;
    }

    function checkUrlValidation(url) {
        if (url.length && !regUrl.test(url)) {
            isValidUrl.value = false;
        }else{
            isValidUrl.value = true;
        }
        return isValidUrl.value;
    }

    function checkApkUrlValidation(url) {
        if (url.length && !regUrl.test(url)) {
            isValidUrl.value = false;
        }else{
            isValidUrl.value = true;
            if(isValidUrl.value){
                // console.log('isValidUrl', isValidUrl.value);
                let n = url.lastIndexOf('/');
                let file = url.substring(n + 1);
                if(file){
                    let x = file.lastIndexOf('.');
                    let fileExtension = file.substring(x + 1);
                    if(fileExtension != 'apk') isValidUrl.value = false
                }
              }
        }
        return isValidUrl.value;
    }

    function checkLoggedInUserRole (){
        if(currentCompany.value.pivot?.role?.name == 'owner' || currentCompany.value.pivot?.role?.name == 'admin') return false 
        else return true
    }

    return { checkEmailValidation, checkUrlValidation, checkApkUrlValidation, checkLoggedInUserRole }
}