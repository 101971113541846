<script setup>
    import { ref, computed, onMounted, onBeforeMount } from "vue";
    import { useStore } from "vuex";
    import DefaultLayout from "./layouts/DefaultLayout.vue";
    import "leaflet/dist/leaflet.css";
    import * as L from 'leaflet';
    import 'leaflet.markercluster/dist/MarkerCluster.css';
    import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
    import "leaflet.markercluster";
    import icon from '@/assets/images/location_icon.png'
    import DxPieChart, {
        DxSeries,
        DxLabel,
        DxLegend,
        DxConnector,
        DxFont,
    } from 'devextreme-vue/pie-chart';

    import {
        DxChart,
        DxCommonSeriesSettings,
        DxSeriesTemplate
    } from 'devextreme-vue/chart';

    const store = useStore();
    let usersCount = ref(0);
    let unreadMessagesCount = ref(0);
    let areas = ref([]);
    let devicesCount = ref(0);
    let maxDevices = ref(0);
    let offlineDevice = ref(0);
    let onlineDevice = ref(0);
    const currentCompany = computed(()=> JSON.parse(localStorage.getItem("currentCompany")));
    const markers = L.markerClusterGroup();
    const initialMap = ref(null);

    const myIcon = L.icon({
        iconUrl: icon,
        iconSize: [30, 30],
        iconAnchor: [15, 30],
        popupAnchor: [-3, -76],
        shadowSize: [60, 30],
        shadowAnchor: [22, 94]
    });

    let devicesDataArr = ref([]);
    let statusDataArr = ref([]);
    let devicesLogsDataArr = ref([]);

    const sitesDataArr = ref([]);
 
    const formatLabel =  ({ valueText }) =>  `${valueText}`;
    // const formatChartLabel =  ({ valueText }) =>  `${valueText}`;

    const customizePoint = (point) => {
        // console.log('point', point)
        let fillId;
        switch (point.argument) {
            case 'Free':
            fillId = '#22a7f0';
            break;
            case 'Used':
            fillId = '#87bc45';
            break;
            case 'Online':
            fillId = '#87bc45';
            break;
            case 'Offline':
            fillId = '#e60049';
            break;
            case 'Failed':
            fillId = '#e60049';
            break;
            case 'Success':
            fillId = '#87bc45   ';
            break;
            case 'Running':
            fillId = '#2680EB';
            break;
            case 'Processing':
            fillId = '#ffa500';
            break;
            default:
            break;
        }
        return { color: { fillId } };
    }

    const animateValue = (obj, start, end, duration) => {
        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            obj.innerHTML = Math.floor(progress * (end - start) + start);
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };
        window.requestAnimationFrame(step);
    }

    const dashboardData = ()=> {
        
        usersCount.value = 0;
        unreadMessagesCount.value = 0;
        areas.value = [];
        devicesCount.value = 0;
        maxDevices.value = 0;
        offlineDevice.value = 0;
        onlineDevice.value = 0;
        devicesDataArr.value = [];
        statusDataArr.value = [];
        devicesLogsDataArr.value = [];
        markers.clearLayers() 

        store.dispatch("getCompany", currentCompany.value.id).then((res) => {
            // console.log('res', res);
            areas.value = res.data.areas_devices;
            usersCount.value = res.data.users_count;
            unreadMessagesCount.value = res.data.unread_messages;
            devicesCount.value = res.data.devices_count;
            maxDevices.value = res.data.max_devices;
            offlineDevice.value = res.data.offline_devices;
            onlineDevice.value = res.data.online_devices;

            devicesDataArr.value = [{
                devices: 'Free',
                value: res.data.max_devices - res.data.devices_count,
                fill : '#2385dd00',
                }, {
                devices: 'Used',
                value: res.data.devices_count,
                fill : '#e2c21f00'
            }]

            statusDataArr.value = [{
                status: 'Online',
                value: res.data.online_devices,
                }, {
                status: 'Offline',
                value: res.data.offline_devices,
            }]

            devicesLogsDataArr.value = [
                {
                    status: 'Success',
                    value: res.data.actions_report?.success_requests_total,
                }, {
                    status: 'Failed',
                    value: res.data.actions_report?.failed_requests_total,
                }, {
                    status: 'Running',
                    value: res.data.actions_report?.running_requests_total,
                }, {
                    status: 'Processing',
                    value: res.data.actions_report?.processing_requests_total,
                },
            ]

            sitesDataArr.value = res.data.sites_data && res.data.sites_data.length > 0 &&
                res.data.sites_data.map((site)=>{
                    let obj = {};
                    obj.name = site.name;
                    obj.value = site.count_online_devices + site.count_offline_devices;
                    obj.count_offline_devices = site.count_offline_devices;
                    obj.count_online_devices = site.count_online_devices;
                    return obj;
                });

            // console.log('areas', areas.value);

            areas.value.forEach((area) => {
                const each_marker = new L.marker([Number(area.latitude), Number(area.longitude)], {icon: myIcon})
                    .bindPopup(`<span><strong> ${area.name} </strong></span> <br> <span style="color:green">Online: ${area.count_online_devices}</span> <br> <span style="color:red">Offline: ${area.count_offline_devices}</span>`)
                    .addTo(initialMap.value);

                markers.addLayer(each_marker);

                initialMap.value.latLngToLayerPoint(each_marker.getLatLng());
            });

            const usersIncreasing = document.getElementById("usersCounter");
            const unreadMessagesIncreasing = document.getElementById("unreadMessagesCounter");
            animateValue(usersIncreasing, 0, res.data.users_count , 1000);
            animateValue(unreadMessagesIncreasing, 0, res.data.unread_messages, 1000);
        }).catch((e)=> console.log(e));

    }

    onMounted(()=> {

        initialMap.value = L.map('map', {zoomControl: true,zoom:1,zoomAnimation:false,fadeAnimation:true,markerZoomAnimation:true}).setView([45, 30], 2 );
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19, 
            // noWrap: true
            // attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(initialMap.value);
    

    });

    onBeforeMount(() =>{
        dashboardData();
    })
</script>

<template>
    <DefaultLayout>
        <div class="main-page-container-animation" >
            <div class="col-12 map-card">
                <div  class="map-refresh">
                    <span class="map-refresh-icon" @click="dashboardData()"><i class="bi bi-arrow-repeat"></i></span>
                </div>
                <div id="map" style="height:44vh;"></div>
            </div>
            <div class="col-12 col-md-12 row">
                <div class="col-12 mt-10 p-0 d-flex">
    
                    <div class="col-4 col-md-4 d-flex p-0">
                        <div class="pies-container">
                            <DxPieChart :data-source="devicesDataArr" title="Devices" class="pie" size-group="piesGroup" 
                            type="donut" :customize-point="customizePoint">
                                <DxSeries argument-field="devices" value-field="value" :customize-point="customizePoint">
                                    <DxLabel :visible="true" :customize-text="formatLabel" format="fixedPoint" >
                                        <DxConnector :visible="true" :width="0.5"/>
                                        <DxFont :size="13"/>
                                    </DxLabel>
                                </DxSeries>
                                <DxLegend :margin="0" horizontal-alignment="right" vertical-alignment="top"/>
                            </DxPieChart>
                        </div>
                    </div>
        
                    <div class="col-4 col-md-4 d-flex p-0 mr-10 ml-10">
                    
                        <div class="pies-container">
                            <DxPieChart :data-source="statusDataArr" title="Status"
                            class="pie" size-group="piesGroup" type="donut" :customize-point="customizePoint">
                                <DxSeries argument-field="status" value-field="value">
                                    <DxLabel :visible="true" :customize-text="formatLabel" format="fixedPoint" >
                                        <DxConnector :visible="true" :width="0.5"/>
                                        <DxFont :size="13"/>
                                    </DxLabel>
                                </DxSeries>
                                <DxLegend
                                :margin="0"
                                horizontal-alignment="right"
                                vertical-alignment="top"
                              />
                            </DxPieChart>
                        </div>
                           
                    </div>
         
                    <div class="col-4  col-md-4 d-flex p-0">
                    
                        <div class="pies-container">
                            <DxPieChart :data-source="devicesLogsDataArr" title="Devices Logs"
                            class="pie" size-group="piesGroup" type="donut" :customize-point="customizePoint">
                                <DxSeries argument-field="status" value-field="value">
    
                                <DxLabel :visible="true" :customize-text="formatLabel" format="fixedPoint">
                                    <DxConnector :visible="true" :width="0.5"/>
                                    <DxFont :size="13"/>
                                </DxLabel>
    
                                </DxSeries>
                                <DxLegend :column-count="4" orientation="horizontal" item-text-position="right" 
                                horizontal-alignment="center" vertical-alignment="bottom" />
                            </DxPieChart>
                        </div>
                           
                    </div>
                    
                </div>
        
                <div class="col-12 col-md-12 mt-10 mb-20 p-0 d-flex">
        
                    <div class="col-8 col-md-8 p-0">
                        
                        <DxChart id="chart" :data-source="sitesDataArr"  title="Countable of devices in sites" palette="Soft">
                            <DxCommonSeriesSettings :ignore-empty-points="true" 
                                argument-field="name" value-field="value" type="bar"
                            />
                          <DxSeriesTemplate name-field="name"/>
                        </DxChart>
                        
                    </div>
         
                    <div class="col-4 col-md-4 p-0 ml-10">
                        
                        <div class="justify-content-center">
                            <div class="widget-horizontal-card">
                                <p class="dashboard-card-title d-flex justify-content-center align-items-center">Users</p>
                                <div class="dashboard-card-content d-flex justify-content-center align-items-center">
                                    <span id="usersCounter" class="">{{ usersCount }}</span> 
                                </div>
                            </div>
    
                        </div>
        
                        <div class="mt-10 justify-content-center">
                            <div class="widget-horizontal-card">
                                <p class="dashboard-card-title d-flex justify-content-center align-items-center">Unread Messages</p>
                                <div class="dashboard-card-content d-flex justify-content-center align-items-center">
                                    <span id="unreadMessagesCounter" class="">{{ unreadMessagesCount }}</span> 
                                </div>
                            </div>
    
                        </div>
        
                    </div>
                    
                </div>
            </div>
        </div>


    </DefaultLayout>
</template>

<style>
    .pies-container {
        max-width: 100%;
        position: relative;
        border-radius: 8px;
        background: #fff;
    }

    .pies-container > .pie {
        max-width: 100%;
        height: 400px;
        padding: 10px;

    }

    #chart {
        height: 410px;
        padding: 10px;
        max-width: 100%;
        width: auto;
        margin: auto;
        border-radius: 8px;
        background: #fff;
    }

    .textChart {
        font-size: 20px !important;
        background-color: black !important;
    }
</style>