
<script setup>
  import { onBeforeMount, computed, defineEmits, ref } from "vue";
  import { useStore } from "vuex";
  const store = useStore();

  const getModalInfo = computed(()=> store.getters.getModalInfo);
  const getModalAction = computed(()=> store.getters.getModalAction);

  const emit = defineEmits(["refresh"]);

  let deviceInfo = ref(null);
  let loading = ref(true);

  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);
    emit('refresh');
  };

  onBeforeMount(()=>{
    if(getModalInfo.value && getModalInfo.value.data_type == 'device' && getModalAction.value == 'get_device_info'){
      console.log('getModalInfo', getModalInfo.value);
      store.dispatch("fetchDeviceInfo",getModalInfo.value.mac_address )
      .then((res) => {
        deviceInfo.value = res.data ? JSON.parse(res.data) : '';
        loading.value = false;
        // console.log('res', res.data);
      })
      .catch((e)=> {
        console.log(e);
      });
    }
  });

</script>

<template>
  <DModal id="deviceInfoModal" :main_classes="[]" :dialog_classes="[]">
    <template #title>
      <div style="display: flex; align-items: center">
        <span style="margin-right: 391px;">Device Info</span>
        <span v-if="deviceInfo || loading"  @click="resetStoreData" >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M11 0C4.91333 0 0 4.91333 0 11C0 17.0867 4.91333 22 11 22C17.0867 22 22 17.0867 22 11C22 4.91333 17.0867 0 11 0ZM16.5978 14.7156C17.1111 15.2289 17.1111 16.06 16.5978 16.5733C16.3289 16.8422 16.0111 16.9644 15.6689 16.9644C15.3267 16.9644 14.9844 16.8422 14.74 16.5733L11.0733 12.9067L7.38222 16.5733C7.11333 16.8422 6.79556 16.9644 6.45333 16.9644C6.11111 16.9644 5.76889 16.8422 5.52444 16.5733C5.01111 16.06 5.01111 15.2289 5.52444 14.7156L9.21556 11.0244L5.52444 7.35778C5.01111 6.84444 5.01111 6.01333 5.52444 5.5C6.03778 4.98667 6.86889 4.98667 7.38222 5.5L11.0733 9.19111L14.7644 5.5C15.2778 4.98667 16.1089 4.98667 16.6222 5.5C17.1356 6.01333 17.1356 6.84444 16.6222 7.35778L12.9311 11.0489L16.5978 14.7156Z"
              fill="#F46A6A"
            />
          </svg>
        </span>
      </div>
    </template>

    <template #body >

      <div v-if="loading" class="d-flex justify-content-center mb-3">
        <div class="spinner-border text-success" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <div v-if="deviceInfo && !loading" class="mb-3">
        <div v-for="(value, key) in deviceInfo" :key="key" class="mb-3">
          <h5 class="info-header">{{ key }}</h5>
          <p :id="key" class="info-content">{{ value }}</p>
        </div>
      </div>

      <div v-if="!deviceInfo && !loading" class="mb-3">
        <div class="delete-text">
          <span>No data found, please update your kiosk VisiPoint app.</span>
        </div>
      </div>

    </template>

    <template #footer>
      <div v-if="!deviceInfo && !loading" class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button @click="resetStoreData" type="button" class="btn btn-primary add-button mr-30" data-bs-dismiss="modal"> Okay </button>
        </div>
      </div>
    </template>

  </DModal>
</template>

<style lang="scss">
div#deviceInfoModal {
  .modal-body{
    padding-right:46px!important ;
    padding-left: 46px!important;
  }
}
h5.info-header {
  color: var(--New1, #2d8425);
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 5px;
  line-height: 17px;
  letter-spacing: 0.25px;
}

.info-content {
  color: rgba(0, 0, 0, 0.87);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  width: 487px;
  font-weight: 700;
  margin-bottom: 32px;
  line-height: 17px;
  letter-spacing: 0.25px;
  opacity: 0.8;
}
</style>
