<script setup>
    import { ref, onBeforeMount, computed } from "vue";
    import { useRoute } from 'vue-router';
    const route = useRoute();
    let url = ref(null);
    let name = ref(null);

    // import { useStore } from "vuex";
    // const store = useStore();
    // const getRemoteControlDataObject = computed(()=> store.getters.getRemoteControlDataObject);
    const remoteControlData = computed(()=> JSON.parse(localStorage.getItem("remoteControlData"+route.query.device_id)));


    onBeforeMount(()=>{
        if(remoteControlData.value && remoteControlData.value.link){
            name.value = remoteControlData.value.name;
            url.value = remoteControlData.value.link;
        }
    });
    
</script>
<template>
    <div>
        <div class="mb-2 d-flex justify-content-center remote-control-device-name">Device ( {{name}} )</div>
        <div class="container-iframe">
            <iframe class="responsive-iframe" :src="url"></iframe>
        </div>
    </div>

</template>
  