
<script setup>
  import { ref, defineEmits, onBeforeMount, computed } from "vue";
  import { useStore } from "vuex";

  const emit = defineEmits(["removeBackground"]);
  const getModalInfo = computed(()=> store.getters.getModalInfo);
  const getModalAction = computed(()=> store.getters.getModalAction);

  const store = useStore();

  let name = ref(null);
  let deviceToken = ref(null);
  let copyText = ref('Copy to clipboard');

  const copyToClipboard = () => {
    navigator.clipboard.writeText(deviceToken.value);
    copyText.value = 'Copied!';
    setTimeout(()=>{
        copyText.value = 'Copy to clipboard';
    },1000)
  }

  onBeforeMount(() => {
    if(getModalInfo.value && getModalInfo.value.data_type == 'device' && getModalAction.value == 'device_token'){
      console.log('getModalInfo', getModalInfo.value);
        name.value = getModalInfo.value.name;
        deviceToken.value = getModalInfo.value.token;
    }
  });

  const resetStoreData = () =>{
    emit('removeBackground');
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);
  };
</script>

<template>
  <DModal id="deviceTokenModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> {{name}} Token </template>
    <template #body>
    
        <div class="col-12 text-center padding-none d-flex align-items-center">
            <div class="col-12 padding-none" @click="copyToClipboard">
            <div  class="text-center" title="Copy to clipboard">
                <span class="generated-token">{{ deviceToken }}</span>
            </div>
            <span class="click-message" v-text="copyText"></span>
            </div>
        </div>
    </template>

    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex justify-content-center">
          <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </template>
  </DModal>
</template>