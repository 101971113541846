import Api from "./Api";
export default {
  getTableHeaders(params) {
    return Api().get(
      `/admin_preference/${params.companyId}`,
      {
        params: {
          page_url: params.url,
        },
      }
    );
  },
  createTableHeaders(params) {
    return Api().post(`/admin_preference`, {
      company_id: params.companyId,
      page_url: params.url,
      table_head: params.headers,
      url: params.filters,
    });
  },
  updateTableHeaders(params) {
    console.log(params)
    return Api().put(`/admin_preference/${params.adminPreferenceId}`, {
      company_id: params.companyId,
      page_url: params.url,
      table_head: params.headers,
      url: params.filters,
    });
  },
};
