<script setup>
    import { ref, onBeforeMount, defineEmits, computed } from "vue";
    import { useStore } from "vuex";
    import moment from "moment";

    const store = useStore();
    const emit = defineEmits(["removeBackground"]);
    const company_id = localStorage.getItem("currentCompanyId");


    let devicesIds = ref([]);
    let job = ref(null);
    // let devicesOptions = ref([]);
    let jobsOptions = ref([]);
    // let scheduleTimesFields = ref([{ dateTime : new Date(), from : ''}]);
    let scheduleDateTime  = ref(null);
    // let job_id = ref(null);
    let isSubmitted = ref(false);
    // let isLoadingDevices = ref(true);
    let isLoadingJobs = ref(true);
    let applyNow = ref(false);

    const getModalInfo = computed(()=> store.getters.getModalInfo);
    const getModalAction = computed(()=> store.getters.getModalAction);

    // const addScheduleTime = () => {
    //     scheduleTimesFields.value.push({ dateTime : new Date(), from : ''});
    // };

    // const removeScheduleTime = (index)  =>  {
    //     if (scheduleTimesFields.value.length > 1) {
    //         scheduleTimesFields.value.splice(index, 1)
    //     }
    // };

    // const formatingDateTimeObject = async (array) => {
    //     let timesArr = [];

    //     if(array.length) {
    //         await array.map( async(obj) => {
    //             let dateTime = await moment(obj.dateTime).format('Y-M-D HH:mm:ss');

    //             timesArr.push(dateTime)
    //         });
    //     }
    //     return timesArr;
    // }

    const onSubmit = async () => {
        isSubmitted.value = true;
        // let devicesIds = [];

        // await devices.value.map((device) => {
        //     devicesIds.push(device.id);
        // });
        // let schedule = formatingDateTimeObject(scheduleTimesFields.value);

        const data = {
            company_id,
            job_id: job.value.id,   
            devices : devicesIds.value,
            schedule : scheduleDateTime.value ? [ moment(scheduleDateTime.value).format('Y-M-D HH:mm:ss') ] : null ,
            apply_now : applyNow.value ? true : false,
        };


        store.dispatch("applyJob", data).then(() => {
            isSubmitted.value = false;
            resetStoreData();
            emit("removeBackground");
        }).catch((e) => {
            console.log(e);
            isSubmitted.value = false;
        });
    };

    const resetStoreData = () =>{
        store.commit('setModalState', false);
        store.commit('setModalData', {});
        store.commit('setModalAction', null);
    };

    // const fetchAllDevices = () => {
    //     isLoadingDevices.value = true;
    //     store.dispatch("fetchAllDevices", company_id).then((res) => {
    //         console.log('res', res.data);
    //         devicesOptions.value = res.data;
    //         isLoadingDevices.value = false;

    //     }).catch((e) => {
    //         console.log(e);
    //     });
    // }

    const fetchJobsList = () => {
        isLoadingJobs.value = true;
        store.dispatch("fetchJobsList", company_id).then( (res) => {
            console.log('res', res.data);
            jobsOptions.value = res.data;
            isLoadingJobs.value = false;
        }).catch((e) => {
            console.log(e);
        });
    }

    onBeforeMount(() => {
        if( getModalInfo.value && (getModalAction.value == 'apply_job' || getModalAction.value == 'mass_apply_job') ){
            fetchJobsList();
            if(getModalAction.value == 'apply_job'){
                console.log('getModalInfo', getModalInfo.value);
                devicesIds.value.push(getModalInfo.value.id);
            }else if(getModalAction.value == 'mass_apply_job') {
                if(Array.isArray(getModalInfo.value)){
                    getModalInfo.value.map((row) =>{
                        devicesIds.value.push(row.id)
                    });
                }
            }
        }
    });

</script>


<template>
    <DModal id="applyJobModal" :main_classes="[]" :dialog_classes="[]">
        <template #title> Apply Job </template>
  
        <template #body>
            
            <div class="p-relative mb-3">
                <span v-if="job" class="multiselect-title-span">Job</span>
                <VMultiselect  v-model="job" :options="jobsOptions" :disabled="!jobsOptions.length"
                  :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Job" trackBy="id" label="name"
                  :loading="isLoadingJobs"
                />
            </div>

            <!-- <div class=" mb-2">
                <div class="d-flex mb-2" v-for="(field, index) in scheduleTimesFields" :key="index">  
                    <div class="d-flex align-items-center justify-content-between job_date_time">
                    <div class="d-flex">
                        <div class="timepacker">
                        <VueDatePicker v-model="field.dateTime" auto-apply :clearable="false" enable-seconds format="MM/dd/yyyy HH:mm:ss" id="auto-brightness-time">
                            <template #input-icon>
                            <i class="input-slot-image">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path :fill=" field.dateTime ? '#2D8425' : '#787878'" d="M8.75 0C3.9375 0 0 3.9375 0 8.75C0 13.5625 3.9375 17.5 8.75 17.5C13.5625 17.5 17.5 13.5625 17.5 8.75C17.5 3.9375 13.5625 0 8.75 0ZM11.8125 10.5C11.55 10.9375 11.025 11.025 10.5875 10.85L8.3125 9.5375C8.05 9.3625 7.875 9.1 7.875 8.75V4.375C7.875 3.85 8.225 3.5 8.75 3.5C9.275 3.5 9.625 3.85 9.625 4.375V8.225L11.4625 9.275C11.9 9.5375 11.9875 10.0625 11.8125 10.5Z" />
                                </svg>
                            </i>
                            </template>
                        </VueDatePicker>
                        </div>
                    </div>
    
                    <span v-if="index > 0" @click="removeScheduleTime(index)" class="ml-40 cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 22 22" fill="none" >
                        <path fill="#F46A6A"
                            d="M11 0C4.91333 0 0 4.91333 0 11C0 17.0867 4.91333 22 11 22C17.0867 22 22 17.0867 22 11C22 4.91333 17.0867 0 11 0ZM16.5978 14.7156C17.1111 15.2289 17.1111 16.06 16.5978 16.5733C16.3289 16.8422 16.0111 16.9644 15.6689 16.9644C15.3267 16.9644 14.9844 16.8422 14.74 16.5733L11.0733 12.9067L7.38222 16.5733C7.11333 16.8422 6.79556 16.9644 6.45333 16.9644C6.11111 16.9644 5.76889 16.8422 5.52444 16.5733C5.01111 16.06 5.01111 15.2289 5.52444 14.7156L9.21556 11.0244L5.52444 7.35778C5.01111 6.84444 5.01111 6.01333 5.52444 5.5C6.03778 4.98667 6.86889 4.98667 7.38222 5.5L11.0733 9.19111L14.7644 5.5C15.2778 4.98667 16.1089 4.98667 16.6222 5.5C17.1356 6.01333 17.1356 6.84444 16.6222 7.35778L12.9311 11.0489L16.5978 14.7156Z"
                        />
                        </svg>
                    </span>
    
                    </div>
                </div>
    
                <div class="modal-right-bottom-btn" @click="addScheduleTime()">
                    <button type="button" class="btn btn-outline-success m-5 font-14 mass-action-grid-btn pull-right">
                        Add New
                    </button>
                </div>
            </div> -->


            <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex">
                    <div class="p-relative">
                        <span v-if="scheduleDateTime" class="datepicker-title-span">Schedule Date (Optional)    </span>
                        <VueDatePicker id="auto-brightness-time" auto-apply :clearable="scheduleDateTime ? true : false" 
                        enable-seconds format="MM/dd/yyyy HH:mm:ss" v-model="scheduleDateTime"
                         type="date">

                            <template #dp-input="{ value }">
                                <input placeholder="Schedule Date (Optional)" type="text" :value="value"  class="custom-date-picker " :class="scheduleDateTime ? 'pt-15' : '' " />
                             </template>

                            <template v-if="!scheduleDateTime" #input-icon>
                                <i class="input-slot-image">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path :fill=" scheduleDateTime ? '#2D8425' : '#787878'"
                                        d="M0.666626 14.8327C0.666626 16.2493 1.74996 17.3327 3.16663 17.3327H14.8333C16.25 17.3327 17.3333 16.2493 17.3333 14.8327V8.16602H0.666626V14.8327ZM14.8333 2.33268H13.1666V1.49935C13.1666 0.999349 12.8333 0.666016 12.3333 0.666016C11.8333 0.666016 11.5 0.999349 11.5 1.49935V2.33268H6.49996V1.49935C6.49996 0.999349 6.16663 0.666016 5.66663 0.666016C5.16663 0.666016 4.83329 0.999349 4.83329 1.49935V2.33268H3.16663C1.74996 2.33268 0.666626 3.41602 0.666626 4.83268V6.49935H17.3333V4.83268C17.3333 3.41602 16.25 2.33268 14.8333 2.33268Z"
                                        />
                                    </svg>
                                </i>
                            </template>
                            <template v-if="scheduleDateTime" #clear-icon="{ clear }">
                                <span @click="clear" >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 22 22" fill="none" >
                                    <path fill="#F46A6A"
                                      d="M11 0C4.91333 0 0 4.91333 0 11C0 17.0867 4.91333 22 11 22C17.0867 22 22 17.0867 22 11C22 4.91333 17.0867 0 11 0ZM16.5978 14.7156C17.1111 15.2289 17.1111 16.06 16.5978 16.5733C16.3289 16.8422 16.0111 16.9644 15.6689 16.9644C15.3267 16.9644 14.9844 16.8422 14.74 16.5733L11.0733 12.9067L7.38222 16.5733C7.11333 16.8422 6.79556 16.9644 6.45333 16.9644C6.11111 16.9644 5.76889 16.8422 5.52444 16.5733C5.01111 16.06 5.01111 15.2289 5.52444 14.7156L9.21556 11.0244L5.52444 7.35778C5.01111 6.84444 5.01111 6.01333 5.52444 5.5C6.03778 4.98667 6.86889 4.98667 7.38222 5.5L11.0733 9.19111L14.7644 5.5C15.2778 4.98667 16.1089 4.98667 16.6222 5.5C17.1356 6.01333 17.1356 6.84444 16.6222 7.35778L12.9311 11.0489L16.5978 14.7156Z"
                                    />
                                  </svg>
                                </span>
                            </template>
                        </VueDatePicker>
                    </div>
                </div>
            </div>
            
            <div class="form-check mr-30 mt-10">
                <input class="form-check-input" type="checkbox" v-model="applyNow" id="applyNow">
                <label class="form-check-label ml-10" for="applyNow">
                    Apply Now
                </label>
            </div>
    
        </template>
        
        <template #footer>
            <div class="w-100">
                <div class="seperator text-center"></div>
                <div class="d-flex mt-20 justify-content-center">
                    <button @click="onSubmit" type="button" class="btn btn-primary add-button mr-30" :disabled="!scheduleDateTime && !applyNow || !job || isSubmitted">
                    Apply <span v-if="isSubmitted"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
                    </button>
                    <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
      </template>
    </DModal>
</template>
  