<template>
  <div class="device-info-row-skeletor device-info-skeletor">
    <Skeletor class="info-skeletor left-skeletor" width="200" as="div" />
    <Skeletor class="info-skeletor" width="250" as="div" />
  </div>

  <div class="device-info-row-skeletor device-info-skeletor">
    <Skeletor class="info-skeletor left-skeletor" width="200" as="div" />
    <Skeletor class="info-skeletor" width="250" as="div" />
  </div>

  <div class="device-info-row-skeletor device-info-skeletor">
    <Skeletor class="info-skeletor left-skeletor" width="200" as="div" />
    <Skeletor class="info-skeletor" width="250" as="div" />
  </div>

  <div class="device-info-row-skeletor device-info-skeletor">
    <Skeletor class="info-skeletor left-skeletor" width="200" as="div" />
    <Skeletor class="info-skeletor" width="250" as="div" />
  </div>

  <div class="device-info-row-skeletor device-info-skeletor">
    <Skeletor class="info-skeletor left-skeletor" width="200" as="div" />
    <Skeletor class="info-skeletor" width="250" as="div" />
  </div>

  <div class="device-info-row-skeletor device-info-skeletor">
    <Skeletor class="info-skeletor left-skeletor" width="200" as="div" />
    <Skeletor class="info-skeletor" width="250" as="div" />
  </div>

  <div class="device-info-row-skeletor device-info-skeletor">
    <Skeletor class="info-skeletor left-skeletor" width="200" as="div" />
    <Skeletor class="info-skeletor" width="250" as="div" />
  </div>

  <div class="device-info-row-skeletor device-info-skeletor">
    <Skeletor class="info-skeletor left-skeletor" width="200" as="div" />
    <Skeletor class="info-skeletor" width="250" as="div" />
  </div>
</template>

<style>
.device-info-row-skeletor {
  display: flex;
  /* justify-content: space-between; */
}
.device-info-skeletor {
  justify-content: start;
  padding-bottom: 10px;
}
.left-skeletor {
  margin-right: 15px;
}
</style>
