import Api from "./Api";
export default {
  getSitesGrid(companyId) {
    return Api().get(`/site_grid/${companyId}`);
  },
  getSites(companyId) {
    return Api(companyId).get(`/sites/${companyId}`);
  },
  exportSitesGrid(payload) {
    return Api().get(`/sites_export/${payload.companyId}/${payload.type}`);
  },
  addSite(payload) {
    return Api().post(`/sites`, payload);
  },
  editSite(payload) {
    return Api().put(`/site/${payload.id}`, payload);
  },
  deleteSite(payload) {
    return Api().delete(`/site/${payload.companyId}/${payload.id}`);
  },
  applySiteAndAreaConfig(payload) {
    return Api().post(`/apply-config-to-${payload.type}`, payload);
  },
  setSitePassord(payload) {
    return Api().post(`/set-site-password`, payload);
  },
};