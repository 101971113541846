
<script setup>
  import { ref, defineProps, defineEmits, onBeforeMount, computed } from "vue";
  import { useStore } from "vuex";
  const emit = defineEmits(['refresh']);
  const store = useStore();

  const props = defineProps({
    configsOptions: Array,
  });

  // const global_user_name = inject(['global_user_name']);
  const user = computed(()=>JSON.parse(localStorage.getItem('userDetails')));

  let isSubmitted = ref(false);
  let id = ref(null);
  let name = ref(null);
  let type = ref(null);
  let devicesNames = ref([]);
  let mac_address = ref(null);
  let mac_addresses = ref([]);
  let company_id = computed(()=>localStorage.getItem("currentCompanyId"));
  let config = ref(null);
  let config_id = ref(null);
  let buttonText = ref(null);
  const getModalInfo = computed(()=> store.getters.getModalInfo);
  const getModalAction = computed(()=> store.getters.getModalAction);

  const changeConfigVal = (config) => {
    if(config && config.id == config_id.value) buttonText.value = 'Re-install';
    else buttonText.value = 'Install';
    console.log('config', config);
  }

  const removeConfigVal = (config) => {
    buttonText.value = 'Install';
    console.log('config', config);
  }

  const onSubmit = () => {
    isSubmitted.value = true;

    let data = {};
    let action = '';
    if(getModalAction.value == 'device_config' || getModalAction.value == 'mass_devices_config'){
      if(getModalAction.value == 'device_config'){
        action = 'applyConfig'
      }else{
        action = 'massApplyConfigs'
      }

      data = { 
        entityId: company_id.value,
        kioskName : getModalAction.value == 'device_config' ? name.value : devicesNames.value,
        macAddress : getModalAction.value == 'device_config' ? mac_address.value : '',
        macAddresses : getModalAction.value == 'mass_devices_config' ? mac_addresses.value : '',
        userName : user.value.first_name+' '+ user.value.last_name,
        userId : user.value.user_id,
        connectionType : 'daemon',
        config : config.value,
      }

    }else{

      action = 'applySiteAndAreaConfig';
      data = {
        id : id.value, 
        type : type.value,
        company_id : company_id.value,
        config_id : config.value.id,
      }
      
    }

    store.dispatch(action, data)
    .then((res) => {
      console.log('res', res);
      resetStoreData();
      emit('refresh');
    })
    .catch((e) => {
      console.log("e", e);
      isSubmitted.value = false;
    });

  };

  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);
  };

  onBeforeMount(()=>{
    if(getModalInfo.value && getModalInfo.value.data_type == 'device' && getModalAction.value == 'device_config'){
      console.log('getModalInfo', getModalInfo.value);
      id.value = getModalInfo.value.id;
      name.value = getModalInfo.value.name;
      config.value = getModalInfo.value.configuration;
      config_id.value = getModalInfo.value.configuration.id;
      mac_address.value = getModalInfo.value.mac_address;
      type.value = getModalInfo.value.data_type;
      buttonText.value = 'Re-install';
      
    }else if(getModalAction.value == 'mass_devices_config') {
      // console.log('mass_devices_config getModalInfo', getModalInfo.value);
      if(Array.isArray(getModalInfo.value)){
        getModalInfo.value.map((row) =>{
          devicesNames.value.push(row.name)
          mac_addresses.value.push(row.mac_address)
        });
        name.value = devicesNames.value.toString();
        // console.log('devicesNames', devicesNames.value);
        // console.log('mac_addresses', mac_addresses.value);
      }       
    }else if(getModalAction.value == 'site_config' || getModalAction.value == 'area_config'){
      id.value = getModalInfo.value.id;
      name.value = getModalInfo.value.name;
      type.value = getModalInfo.value.data_type;
      if(getModalInfo.value.configuration){
        config.value = getModalInfo.value.configuration;
        config_id.value = getModalInfo.value.configuration.id;
        buttonText.value = 'Re-install';
      }else{
        buttonText.value = 'Install';
      }
    }
  });

</script>

<template>
  <DModal id="deviceConfigurationModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Install Configuration </template>

    <template #body>
      <div class="delete-text mb-3">
        <span v-if="type === 'device'">Select the configuration you want to <b>"Install"</b> on the device "{{ name }}" ?</span>
        <span v-if="type === 'site'">Select the configuration you want to <b>"Install"</b> on the site "{{ name }}" ?</span>
        <span v-if="type === 'area'">Select the configuration you want to <b>"Install"</b> on the area "{{ name }}" ?</span>
      </div>

      <div class="p-relative">
        <span v-if="config" class="multiselect-title-span" >Configuration</span>
        <VMultiselect @select="changeConfigVal" @remove="removeConfigVal" v-model="config" :options="props.configsOptions" trackBy="id" label="name"
          :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Configuration"
        />
      </div>


    </template>

    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button :disabled="!config || isSubmitted " 
          @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
          {{ buttonText }} <span v-if="isSubmitted"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
          </button>
          <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </template>
  </DModal>
</template>
