<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import router from "@/router";
import useValidation from "@/utils/helpers/validations";
const { checkEmailValidation } = useValidation();

const store = useStore();
const email = ref(null);
const password = ref(null);
let isValidEmail = ref(true);
let isLoginError = ref(false);
let isSubmitted = ref(false);

const validEmail = () => {
  isLoginError.value = false;
  isValidEmail.value = checkEmailValidation(email.value);
  return isValidEmail.value;
};

const changeInputsValue = () => {
  isLoginError.value = false;
};

const onSubmit = () => {
  isLoginError.value = false;
  isSubmitted.value = true;
  const data = {
    email: email.value,
    password: password.value,
  };
  store
    .dispatch("login", data)
    .then(async (res) => {
      console.log('res', res);
      const userDetails = await JSON.parse(localStorage.getItem('user'));
      console.log('userDetails', userDetails);
      await setTimeout(() => {
        if(userDetails.user.email == process.env.VUE_APP_SUPER_ADMIN_EMAIL || (userDetails.user.companies && userDetails.user.companies.length > 1)){
          router.push("/companies");
        }else{
          router.push("/sites-devices")
        }
      },2000);
       
    })
    .catch((e) => {
      console.log("e", e);
      isLoginError.value = true;
      isSubmitted.value = false;
    });
};
</script>

<template>
    <div class="auth-container col-lg-5 col-md-9 m-auto">
        <div class="auth-card">
            <!-- <div class="auth-logo"/> -->
            <div class="text-center">
                <img class="logo" src="@/assets/images/logo/logo.png">
            </div>
            <p class="welcome-message mt-24">WELCOME</p>
            <p class="second-welcome-message">Please Login</p>
            <div class="form-floating mb-3">
                <input @input="validEmail" v-model="email" type="email" class="form-control" :class="{'is-invalid' : !isValidEmail || isLoginError}" id="email" placeholder="name@example.com"/>
                <label for="email">Email address</label>
                <div v-if="!isValidEmail" class="invalid-feedback">
                    Invalid Email.
                </div>
            </div>
            <div class="form-floating">
                <input @input="changeInputsValue" v-model="password" type="password" class="form-control" :class="{'is-invalid' : isLoginError}" id="password" placeholder="Password" />
                <label for="password">Password</label>
            </div>
            <div class="text-left ml-12" style="margin-top: 10px;" >
                <router-link class="none-underline" to="/forget-password"><span class="auth-notes">Forgot password?</span></router-link>
            </div>
            <p v-if="isLoginError" class="error-login-message mt-24" style="margin-bottom: 0px;">Login credentials are incorrect</p>

      <button
        @click="onSubmit"
        :disabled="
          !email || !password || !isValidEmail || isSubmitted ? true : false
        "
        type="button"
        class="form-action-btn btn btn-success mt-40"
      >
        Login
        <span
          v-if="isSubmitted"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
    </div>
  </div>
</template>
