<script setup>
import { defineProps } from "vue";

const props = defineProps({
  isSingle: Boolean,
  width: {
    type: Number,
    default: 120,
  },
  as: {
    type: String,
    default: "p",
  },
});
</script>

<template>
  <div class="d-flex flex-column">
    <Skeletor :width="props.width" :as="props.as" class="mb-2" />
    <Skeletor v-if="!props.isSingle" :width="props.width" as="div" />
  </div>
</template>

<style>
.vue-skeletor {
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.12);
}
.vue-skeletor:not(.vue-skeletor--shimmerless):after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.3),
    rgba(37, 22, 22, 0)
  );
  animation: shimmer 1s infinite;
  content: "";
}
.vue-skeletor--rect,
.vue-skeletor--circle {
  display: block;
}
.vue-skeletor--circle {
  border-radius: 50%;
}
.vue-skeletor--pill,
.vue-skeletor--text {
  border-radius: 9999px;
}
.vue-skeletor--text {
  line-height: 1;
  display: inline-block;
  width: 100%;
  height: inherit;
  vertical-align: middle;
  top: -1px;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>
