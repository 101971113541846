import LoginView from'../views/auth/LoginView'
import RegisterView from'../views/auth/RegisterView'
import ForgetPasswordView from'../views/auth/ForgetPasswordView'
import ResetPasswordView from'../views/auth/ResetPasswordView'
import DashboardView from'../views/DashboardView'
import SitesAndDevicesView from'../views/SitesAndDevicesView'
import AppsLibraryView from'../views/AppsLibraryView'
import DeviceLogsView from'../views/DeviceLogsView'
import NotFoundView from'../views/NotFoundView'
import CompaniesListView from'../views/CompaniesListView'
import CompanyDetailsView from'../views/CompanyDetailsView'
import UsersView from'../views/UsersView'
import ConfigurationsView from'../views/ConfigurationsView'
import GeneralSettingsView from'../views/GeneralSettingsView'
import InboxView from'../views/InboxView'
import JobsView from'../views/JobsView'
import configSettings from'../components/configurations/configSettings'
import RemoteControlView from'../components/kiosks/RemoteControlView.vue'

import router from '.'
// import store from "@/store";
// import { route_acl_map } from "../components/route-acl-map";

// limit access for stand alone device
const protectedRoutes = (routeTo, routeFrom, next) => {
  const user = JSON.parse(localStorage.getItem('user'));
  // const dashboardUrl = window.location.origin
  // const permissions = store.getters["getUserPermissions"]
  // const currentRouteAcl = route_acl_map[routeTo?.matched[0]?.path]
  // const isAllowed = permissions[currentRouteAcl]?.pivot.allowed

  //   if (isAllowed === 1) {
  //     next();
  //   } else if (isAllowed === 0) {
  //     window.location.href = dashboardUrl
  //   } else { //remove when all routes are handled in acl
  //     next()
  //   }
  if(!user) router.push('/login');
  next()
};

const beforeEnterAuth = (routeTo, routeFrom, next) => {
  const user = JSON.parse(localStorage.getItem('user'));
  if(user) router.push('/'); 
  next(); 
}

const beforeEnterAllCompanies = (routeTo, routeFrom, next) => {
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const superAdminEmail = process.env.VUE_APP_SUPER_ADMIN_EMAIL;
  if(userDetails){
    if(userDetails.user.email != superAdminEmail && userDetails.user.companies && userDetails.user.companies.length == 1 ){
      router.push('/');
    }
  }else{
    router.push('/login');
  }
  next();
}

const routes = [
    {
      path : '/:catchall(.*)*',
      name: 'NotFoundView',
      component: NotFoundView
    },
    {
      path: '/login',
      name: 'LoginView',
      component: LoginView,
      beforeEnter: beforeEnterAuth,
      meta: {
        title: 'Login',
      },
    },
    {
      path: '/register',
      name: 'RegisterView',
      component: RegisterView,
      beforeEnter: beforeEnterAuth,
      meta: {
        title: 'Register',
      },
    },
    {
      path: '/forget-password',
      name: 'ForgetPasswordView',
      component: ForgetPasswordView,
      beforeEnter: beforeEnterAuth,
      meta: {
        title: 'Forget Password',
      },
    },
    {
      path: '/reset-password/:code/:email',
      name: 'ResetPasswordView',
      component: ResetPasswordView,
      beforeEnter: beforeEnterAuth,
      meta: {
        title: 'Reset Password',
      },
    },
    {
      path: '/',
      redirect : '/dashboard',
      beforeEnter: protectedRoutes,
    },
    {
      path: '/dashboard',
      name: 'DashboardView',
      component: DashboardView ,
      beforeEnter: protectedRoutes,
      meta: {
        title: 'Dashboard',
      },
    },
    {
      path: '/sites-devices',
      name: 'SitesAndDevicesView',
      component: SitesAndDevicesView ,
      beforeEnter: protectedRoutes,
      meta: {
        title: 'Sites & Devices',
      },
    },
    {
      path: '/users',
      name: 'UsersView',
      component: UsersView,
      beforeEnter: protectedRoutes,
      meta: {
        title: 'Users',
      },
    },
    {
      path: '/apps-library',
      name: 'AppsLibraryView',
      component: AppsLibraryView,
      beforeEnter: protectedRoutes,
      meta: {
        title: 'Apps Library',
      },
    },
    {
      path: '/device-logs',
      name: 'DeviceLogsView',
      component: DeviceLogsView,
      beforeEnter: protectedRoutes,
      meta: {
        title: 'Device Logs',
      },
    },
    {
      path: '/companies',
      name: 'CompaniesListView',
      component: CompaniesListView,
      beforeEnter: beforeEnterAllCompanies,
      meta: {
        title: 'Companies',
      },
    },
    {
      path: '/company-details',
      name: 'CompanyDetailsView',
      component: CompanyDetailsView,
      beforeEnter: protectedRoutes,
      meta: {
        title: 'Company Details',
        alignSidebar: true
      },
    },
    {
      path: '/configurations',
      name: 'ConfigurationsView',
      component: ConfigurationsView,
      beforeEnter: protectedRoutes,
      meta: {
        title: 'Configurations',
      }
    },
    {
      path: '/configurations/:id',
      name: 'configSettings',
      component: configSettings,
      beforeEnter: protectedRoutes,
      meta: {
        title: 'Configuration Settings',
      }
    },
    {
      path: '/general-settings',
      name: 'GeneralSettingsView',
      component: GeneralSettingsView,
      beforeEnter: protectedRoutes,
      meta: {
        title: 'General Settings',
      }
    },
    {
      path: '/inbox',
      name: 'InboxView',
      component: InboxView,
      beforeEnter: protectedRoutes,
      meta: {
        title: 'Inbox',
      }
    },
    {
      path: '/jobs',
      name: 'JobsView',
      component: JobsView,
      beforeEnter: protectedRoutes,
      meta: {
        title: 'Jobs',
      }
    },
    {
      path: '/remote-control',
      name: 'RemoteControlView',
      component: RemoteControlView,
      beforeEnter: protectedRoutes,
      meta: {
        title: 'Remote Control',
      }
    },

  ]

export default routes