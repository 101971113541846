<script setup>
  import { ref, onBeforeMount, defineProps, defineEmits } from "vue";
  const props = defineProps({
    closeModal: Function,
  });
  // import useValidation from '@/utils/helpers/validations';
  import { useStore } from "vuex";
  import moment from "moment-timezone";

  const store = useStore();
  // const { checkEmailValidation } = useValidation();
  const emit = defineEmits(['refresh', 'refreshSitesArr']);
  const company_id = localStorage.getItem('currentCompanyId');
  const name = ref(null);
  const contact_email = ref(null);
  const timezone = ref(null);
  let timeZoneNames = ref([]);
  let isValidEmail = ref(true);
  let isSubmitted = ref(false);

  onBeforeMount(()=>{
    timeZoneNames = moment.tz.names();
  });

  const resetData = () => {
    name.value = null;
    contact_email.value = null;
    timezone.value = null;
    isValidEmail.value = true;
    isSubmitted.value = false;
  };

  // const validEmail = () => {
  //   isValidEmail.value = checkEmailValidation(contact_email.value);
  //   return isValidEmail.value;
  // };

  const onSubmit = () => {
    isSubmitted.value = true;
    const data = { 
      name : name.value, 
      // contact_email :contact_email.value, 
      timezone : timezone.value, 
      company_id 
    };
    store.dispatch('addSite', data).then(() => {
      emit('refreshSitesArr');
      emit('refresh');
      resetData();
      props.closeModal();
    }).catch( (e) => {
      console.log(e);
      isSubmitted.value = false;
    });
  }
</script>
<template>
  <DModal id="siteModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Add Site </template>
    
    <template #body>
      <div class="form-floating mb-3">
        <input v-model.trim="name" type="text" class="form-control" id="sideName" placeholder="Site Name" />
        <label for="sideName">Site Name</label>
      </div>

      <!-- <div class="form-floating mb-3"> 
        <input @input="validEmail" v-model="contact_email"  type="email" :class="{'is-invalid' : !isValidEmail}" class="form-control" id="contactEmail" placeholder="Contact Email" />
        <label for="contactEmail">Contact Email</label> 
        <div v-if="!isValidEmail" class="invalid-feedback">
          Invalid Email.
        </div>
      </div> -->

      <div class="p-relative"> 
        <span v-if="timezone" class="multiselect-title-span">Time Zone</span>
        <VMultiselect v-model="timezone" :options="timeZoneNames" :disabled="!timeZoneNames.length" :loading="!timeZoneNames.length" 
        :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Time Zone" />
      </div>
    </template>

    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button :disabled="!name || !timezone || isSubmitted" @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">Add 
            <span v-if="isSubmitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
          <button @click="resetData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal"> Cancel </button>
        </div>
      </div>
    </template>
  </DModal>
</template>
