<script setup>
    import { ref, defineEmits, onBeforeMount, computed } from "vue";
    import { useStore } from "vuex";
  
    const store = useStore();
    const emit = defineEmits(["refresh"]);
    const company_id = localStorage.getItem("currentCompanyId");

    let name = ref(null);
    let parent_id = ref(null);
    let isSubmitted = ref(false);

    const getModalInfo = computed(()=> store.getters.getModalInfo);
    const getModalAction = computed(()=> store.getters.getModalAction);

    const onSubmit = () => {
        isSubmitted.value = true;
        const data = {
            name: name.value,
            parent_id: parent_id.value,
            company_id,
        };

        store.dispatch("createFolder", data).then(() => {
            isSubmitted.value = false;
            resetStoreData();
            emit("refresh");
        }).catch((e) => {
            console.log(e);
            isSubmitted.value = false;
        });
    };

    const resetStoreData = () =>{
        store.commit('setModalState', false);
        store.commit('setModalData', {});
        store.commit('setModalAction', null);
    };

    onBeforeMount(() => {
        if(getModalInfo.value && getModalAction.value == 'create_folder' ){
            console.log('getModalInfo', getModalInfo.value);
            parent_id.value = getModalInfo.value.id ? getModalInfo.value.id : null;

            console.log('parent_id.value', parent_id.value);
        }
    });
</script>


<template>
    <DModal id="createFolderModal" :main_classes="[]" :dialog_classes="[]">
        <template #title> Create new Folder </template>
  
        <template #body>
        
            <div class="form-floating mb-3">
                <input type="text" v-model.trim="name" class="form-control" id="folderName" placeholder=""/>
                <label for="folderName">Folder Name</label>
            </div>
    
        </template>
        <template #footer>
            <div class="w-100">
                <div class="seperator text-center"></div>
                <div class="d-flex mt-20 justify-content-center">
                    <button :disabled="!name || isSubmitted"
                    @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
                    Create <span v-if="isSubmitted"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
                    </button>
                    <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
      </template>
    </DModal>
  </template>
  