import Api from "./Api";

export default {
  getAppsLibrary(companyId) {
    return Api().get(`/applications/${companyId}`);
  },
  addApp(payload) {
    return Api({ hasFile: true }).post(`/app-upload`, payload);
  },
  editApp(payload) {
    return Api().put(`/app-update/${payload.id}`, payload.body);
  },
  deleteApp(payload) {
    return Api().delete(`/application/${payload.companyId}/${payload.id}`);
  },
};