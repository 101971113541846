
<script setup>
  import { ref, defineEmits, computed, onBeforeMount } from "vue";
  import { useStore } from "vuex";
  const emit = defineEmits(['refresh']);
  const store = useStore();


  // const global_user_name = inject(['global_user_name']);
  const user = computed(()=>JSON.parse(localStorage.getItem('userDetails')));
  let isSubmitted = ref(false);
  let name = ref(null);
  let mac_address = ref(null);
  let app = ref(null);
  let appsOptions = ref([]);
  let isLoading = ref(true);

  const company_id = computed(()=>localStorage.getItem("currentCompanyId"));
  const getModalInfo = computed(()=> store.getters.getModalInfo);
  const getModalAction = computed(()=> store.getters.getModalAction);

  const onSubmit = () => {
    isSubmitted.value = true;
    // let packagesArr = [];

    // apps.value.map((app) => {
    //   packagesArr.push(app.packageId)
    // });
    
    let data = { 
      entityId: company_id.value,
      kioskName : name.value,
      macAddress : mac_address.value,
      userName : user.value.first_name+' '+ user.value.last_name,
      userId : user.value.user_id,
      connectionType : 'daemon',
      packages : [app.value.packageId],
    }

    store.dispatch("restartApp", data)
    .then((res) => {
      console.log('res', res);
      resetStoreData();
      emit('refresh');
    })
    .catch((e) => {
      console.log("e", e);
      isSubmitted.value = false;
    });
  };

  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);
  };

  onBeforeMount(()=>{
    if(getModalInfo.value && getModalInfo.value.data_type == 'device' && getModalAction.value == 'restart_app'){
      console.log('getModalInfo', getModalInfo.value);
      name.value = getModalInfo.value.name;
      mac_address.value = getModalInfo.value.mac_address;

      let data = { 
        entityId: company_id.value,
        kioskName : getModalInfo.value.name,
        macAddress : getModalInfo.value.mac_address,
        userName : user.value.first_name+' '+ user.value.last_name,
        userId : user.value.user_id,
        connectionType : 'daemon',
      }

      store.dispatch("fetchInstalledAppsGrid",data )
      .then((res) => {
        console.log('res', res.data);
        appsOptions.value = JSON.parse(res.data);
        isLoading.value = false;
      })
      .catch((e)=> {
        console.log(e);
      });
    }
  });
</script>

<template>
  <DModal id="restartAppModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Restart App </template>

    <template #body>
      <div class="delete-text mb-3">
        <span>Select the application you want to <b>"Restart"</b> on the device "{{ name }}" ?</span>
      </div>

      <div class="p-relative">
        <span v-if="app" class="multiselect-title-span" >Application</span>
        <VMultiselect v-model="app" :options="appsOptions" trackBy="packageId" label="name" :disabled="!appsOptions.length" :loading="isLoading" 
          :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Application"
        />
      </div>

    </template>

    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button :disabled="!app || isSubmitted "  
          @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
            Restart <span v-if="isSubmitted"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
          </button>
          <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </template>

  </DModal>
</template>
