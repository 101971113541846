<script setup>
  import { ref, defineProps, defineEmits, onBeforeMount, computed } from "vue";
  import { useStore } from "vuex";

  const props = defineProps({
    sitesOptions : Array,
  });

  const emit = defineEmits(['refresh']);
  const store = useStore();
  let id = ref(null);
  let site = ref(null);
  let area = ref(null);
  let isLoadingAreas = ref(null);
  let areasOptions = ref([]);
  let isSubmitted = ref(false);
  let devicesIds = ref([]);
  const getModalInfo = computed(()=> store.getters.getModalInfo);
  const getModalAction = computed(()=> store.getters.getModalAction);
  const company_id = computed(()=>localStorage.getItem("currentCompanyId"));

  const changeSiteVal = () => {
    area.value = null
    areasOptions.value = [];
    // console.log('site', site.value);
    if(site.value){
      isLoadingAreas.value = true;
      store.dispatch("fetchParentAreas", {
        siteId :site.value.id,
        companyId : company_id.value,
      }).then((res) => {
        // console.log('res', res.data);
        areasOptions.value = res.data;
        isLoadingAreas.value = false;
      });
    }
  }

  const onSubmit = () => {
    isSubmitted.value = true;
    let data = {};
    let action = '';
    if(getModalAction.value == 'move_device'){
      action = 'moveDevice';
      data = {
        id : id.value,
        area_id: area.value.id,
        company_id: company_id.value,
      }

    }else if(getModalAction.value == 'mass_move_devices') {
      action = 'massMoveDevices';
      data = { 
        devices : devicesIds.value,
        area_id: area.value.id,
        company_id: company_id.value,
      }
    }

    store.dispatch(action, data)
    .then((res) => {
      console.log('res', res);
      resetStoreData();
      emit('refresh');
    })
    .catch((e) => {
      console.log("e", e);
      isSubmitted.value = false;
    });
  };

  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);
  };

  onBeforeMount(()=>{
    if(getModalInfo.value && getModalInfo.value.data_type == 'device' && getModalAction.value == 'move_device'){
      console.log('getModalInfo', getModalInfo.value);
      id.value = getModalInfo.value.id;
    }else if(getModalAction.value == 'mass_move_devices') {
      console.log('getModalInfo', getModalInfo.value);
      if(Array.isArray(getModalInfo.value)){
        getModalInfo.value.map((row) =>{
          devicesIds.value.push(row.id)
        });
        console.log('devicesIds', devicesIds.value);
      }
    }
  });
</script>


<template>
  <DModal id="moveDeviceModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Move Device </template>

    <template #body>
      <div class="p-relative mb-3">
        <span v-if="site" class="multiselect-title-span" >Site</span>
        <VMultiselect @select="changeSiteVal" @remove="changeSiteVal" v-model="site" :options="props.sitesOptions" trackBy="id" label="name"
        :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Site" :disabled="!props.sitesOptions.length"
        />
      </div>

      <div class="p-relative ">
        <span v-if="area" class="multiselect-title-span" >Area</span>
        <VMultiselect v-model="area" :options="areasOptions" trackBy="id" label="name" :disabled="!site || isLoadingAreas" 
        :loading="isLoadingAreas" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Area"
        />
      </div>
    </template>

    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button :disabled="!site || !area || isSubmitted" 
          @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
            Move <span v-if="isSubmitted"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
          </button>
          <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </template>
  </DModal>
</template>
