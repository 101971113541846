<script setup>
  import { ref, defineEmits, computed, onBeforeMount } from "vue";
  import { useStore } from "vuex";

  const emit = defineEmits(['refresh']);
  const store = useStore();
  let name = ref(null);
  let id = ref(null);
  let isSubmitted = ref(false);  
  let devicesNames = ref([]);
  let devicesIds = ref([]);
  
  const getModalInfo = computed(()=> store.getters.getModalInfo);
  const getModalAction = computed(()=> store.getters.getModalAction);
  const companyId = computed(()=>localStorage.getItem("currentCompanyId"));

  const onSubmit = () => {
    isSubmitted.value = true;
    let data = {};
    let action = '';
    if(getModalAction.value == 'deregister_device'){
      action = 'deregisterDevice';
      data = { 
        id : id.value, 
        companyId : companyId.value
      }

    }else if(getModalAction.value == 'mass_deregister_devices') {
      action = 'massDeregisterDevices';
      data = { 
        companyId : companyId.value,
        body : {
          devices : devicesIds.value,
          _method : 'DELETE',
        },

      }
    }
    console.log('data', data);
    store.dispatch(action, data)
    .then((res) => {
      console.log('res', res);
      resetStoreData();
      emit('refresh');
    })
    .catch((e) => {
      console.log("e", e);
      isSubmitted.value = false;
    });
  };

  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);
  };

  onBeforeMount(()=>{
    if(getModalInfo.value && getModalInfo.value.data_type == 'device' && getModalAction.value == 'deregister_device'){
      console.log('getModalInfo', getModalInfo.value);
      name.value = getModalInfo.value.name;
      id.value = getModalInfo.value.id;
    }else if(getModalAction.value == 'mass_deregister_devices') {
      console.log('getModalInfo', getModalInfo.value);
      if(Array.isArray(getModalInfo.value)){
        getModalInfo.value.map((row) =>{
          devicesNames.value.push(row.name)
          devicesIds.value.push(row.id)
        });
        console.log('devicesNames', devicesNames.value);
        console.log('devicesIds', devicesIds.value);
        name.value = devicesNames.value.join(", ");
      }
    }
  });
</script>

<template>
  <DModal id="deRegisterDeviceModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> De-register Device </template>

    <template #body>
      <div class="delete-text mb-3">
        <span v-if="getModalAction == 'deregister_device'">Are you sure you want to  <b>"De-register"</b> the device "{{ name }}" ?</span>
        <span v-if="getModalAction == 'mass_deregister_devices'">Are you sure you want to  <b>"De-register"</b> the devices "{{ name }}" ?</span>
      </div>
      <p class="delete-text">De-registering a device will delete all data stored on it.</p>
    </template>

    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button @click="resetStoreData" type="button" class="btn btn-primary add-button mr-30" data-bs-dismiss="modal">Cancel</button>
          <button :disabled="isSubmitted" @click="onSubmit" type="button" class="btn btn-secondary cancel-button" >
            De-register <span v-if="isSubmitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </template>
  </DModal>
</template>
