<script setup>
    import { ref, onBeforeMount, defineEmits, computed, onMounted } from "vue";
    import { useStore } from "vuex";

    const store = useStore();
    const emit = defineEmits(['refresh', 'showPasswordHistory', 'removeBackground']);
    const company_id = localStorage.getItem('currentCompanyId');
    import useEncryption from "@/utils/helpers/encryption";
    const { showPassword, decryptionPassword } = useEncryption();

    let id = ref(null);
    let password = ref(null);
    let isSubmitted = ref(false);
    let siteData = ref(null);
    const getModalInfo = computed(()=> store.getters.getModalInfo);
    const getModalAction = computed(()=> store.getters.getModalAction);
  
    onMounted(() => {
      showPassword('sitePassword', '.showSitePassword i');
    });

    onBeforeMount( () => {
      if(getModalInfo.value && getModalInfo.value.data_type == 'site' && getModalAction.value == 'set_site_password'){
        console.log('getModalInfo', getModalInfo.value);
        siteData.value = getModalInfo.value;
        id.value = getModalInfo.value.id;
        password.value = getModalInfo.value.sitePasswords[0]?.password ? decryptionPassword(getModalInfo.value.sitePasswords[0]?.password).slice(1,-1) : null;
      }
    });

    const resetStoreData = () =>{
      store.commit('setModalState', false);
      store.commit('setModalData', {});
      store.commit('setModalAction', null);
    };


    const showPasswordHistory = async() => {
      await emit('showPasswordHistory', siteData.value);
    };
  
    const onSubmit = () => {
      isSubmitted.value = true;
      const data = {
      site_id : id.value,
      password : password.value, 
      company_id 
      };
      
      store.dispatch('setSitePassword', data).then( async() => {
          await emit('refresh');
          await resetStoreData();

      }).catch( (e) => {
          isSubmitted.value = false;
          console.log(e)
      });
    };
  
</script>
<template>
  <DModal id="setSitePasswordModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Site Password </template>
    
    <template #body>
      <div class="form-floating mb-3">
        <input v-model="password" type="password" maxlength="6" class="form-control" id="sitePassword" placeholder="Password" />
        <label for="sitePassword">Password</label>
        <span class="showPassword showSitePassword"><i class="bi bi-eye-slash"></i></span>
      </div>
      <span class="hint-input d-flex align-items-center"><b>Note:</b> You can show password list <button @click="showPasswordHistory()" class="btn btn-text">Here</button></span>  
    </template>

    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button :disabled="!password || isSubmitted" @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">Update 
            <span v-if="isSubmitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
          <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal"> Cancel </button>
        </div>
      </div>
    </template>
  </DModal>
</template>
