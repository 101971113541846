<script setup>
  import { ref, defineEmits, computed, onBeforeMount } from "vue";
  import { useStore } from "vuex";
  import {
    DxDataGrid,
    DxColumn,
    // DxFilterRow,
    DxHeaderFilter,
    DxToolbar,
    DxItem,
    DxPaging,
    DxPager,
    DxSorting,
    DxScrolling,
    DxColumnChooser,
    DxStateStoring,
    DxLoadPanel,
    DxSearchPanel,
  } from 'devextreme-vue/data-grid';
  // import DxDropDownButton from "devextreme-vue/drop-down-button";
  // import { default as Button } from "devextreme-vue/button";
  import { exportDataGrid } from "devextreme/excel_exporter";
  import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
  import { Workbook } from "exceljs";
  import saveAs from "file-saver";
  import { jsPDF } from "jspdf";
  // import { Modal } from "bootstrap";

  import PreviewLogsActionCell from "@/components/kiosks/PreviewLogsActionCell.vue";
  // import deleteRequestJobModal from "@/components/kiosks/deleteRequestJobModal.vue";
  import Api from "@/services/Api";
  import CustomStore from 'devextreme/data/custom_store';

  const dataGridRefName = ref(null);
  // const allowedOperations = ref(["contains"]);
  // const buttonData = ref(["Excel", "CSV", "PDF"]);
  // let deviceLogs = ref([]);

  const emit = defineEmits(['refresh']);
  const store = useStore();
  let showClearAll = ref(false);
  let name = ref(null);

  let statusLogsDataFilter = ref([]);
  let typesLogsDataFilter = ref([]);

  const getModalAction = computed(()=> store.getters.getModalAction);
  const getModalInfo = computed(()=> store.getters.getModalInfo);
  const companyId = computed(()=>localStorage.getItem("currentCompanyId"));
  const adminPreferenceId = computed(()=>localStorage.getItem("adminPreferenceId"));
  const user = computed(()=>JSON.parse(localStorage.getItem('userDetails')));
  // const getDeviceDataObject = computed(()=> store.getters.getDeviceDataObject);
  let multipleFilters = ref([]);
  let allFilterHeaders = ref(['type', 'status'])

  const dataGrid = computed(()=>{
      return dataGridRefName.value.instance;
  });

  const isNotEmpty = (value) => {
        return value !== undefined && value !== null && value !== "";
    }

  
  const customStore = new CustomStore({
      key: 'id',
      async load(loadOptions) {
        if(getModalInfo.value && getModalInfo.value.data_type == 'device' && getModalAction.value == 'preview_logs'){

          name.value = getModalInfo.value.name;
          console.log('loadOptions', loadOptions);
          let searchText = null;
            let sortText = 'desc';
            if(loadOptions.filter && loadOptions.filter[0]){
                searchText = loadOptions.filter[0].filterValue;
            }
            if(loadOptions.sort && loadOptions.sort[0]){
                sortText = loadOptions.sort[0].desc ? 'desc' : 'asc';
            }
            console.log('searchText', searchText);
        //   let params = "?";
          let filterQuery = "";
          let sortObject = { sortField: null, sortOrder: "" };
          [
            "skip",
            "take",
            "requireTotalCount",
            "requireGroupCount",
            "sort",
            "filter",
            "totalSummary",
            "group",
            "groupSummary",
          ].forEach((i) => {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) {
            // params += `${i}=${JSON.stringify(loadOptions[i])}&`;
              if (i == "filter")
                filterQuery += `${JSON.stringify(loadOptions[i])}`;
              if (i == "sort") {
                sortObject.sortField = loadOptions[i][0].selector;
                sortObject.sortOrder = loadOptions[i][0].desc ? "desc" : "asc";
              }
            }
          });
          // params.slice(0, -1);
          // params = params.slice(0, -1);
          let filters;
          let finalFilterObject = {};
          multipleFilters.value = [];
          if (filterQuery) filters = JSON.parse(filterQuery);
          if (filters) console.log("filters", filters);
          if (filters && typeof filters[0] === "string") {
            if (filters[0] == "!") {
              console.log("not");
              if (typeof filters[1] === "object") {
                const excludeIds = [];
                const columnHeader =
                  typeof filters[1][0] === "object"
                    ? filters[1][0][0] + "HeaderFilter"
                    : filters[1][0] + "HeaderFilter";
                const columnHeaderFilter =
                  columnHeader.charAt(0).toLowerCase() + columnHeader.slice(1);
                console.log(columnHeader); // name of column filter
                if (typeof filters[1][0] === "object") {
                  console.log("obj");
                  filters[1].map((item) => {
                    if (typeof item === "object") {
                      console.log("item", item[2][0]);
                      excludeIds.push(item[2][0]);
                    }
                  });
                } else {
                  console.log("else");
                  // filters[1].map(item => {
                  // if (typeof item === "object") {
                  console.log("item", filters[1][2][0]);
                  excludeIds.push(filters[1][2][0]);
                  // }
                  // });
                }
                console.log(this[columnHeaderFilter]);
                const arr = this[columnHeaderFilter].filter(
                  (el) => !excludeIds.includes(el.value[2][0])
                );
                console.log("arr", arr, excludeIds);
                if (typeof filters[1][0] === "object") {
                  finalFilterObject[filters[1][0][0]] = arr.map((item) => {
                    return item.value[2][0];
                  });
                } else {
                  finalFilterObject[filters[1][0]] = arr.map((item) => {
                    return item.value[2][0];
                  });
                }
                console.log("finalFilterObject", finalFilterObject);
                if (multipleFilters.value.length > 0) {
                  multipleFilters.value.push(finalFilterObject);
                }
              }
            } else {
              if((filters[1] === "contains" || filters[1] === "=") && allFilterHeaders.value.includes(filters[0])) filters[2] = [filters[2]]; // make it array;
              finalFilterObject[filters[0]] =
                filters[1] === "contains" || filters[1] === "="
                  ? filters[2]
                  : filters[2];
            }
            if (multipleFilters.value.length > 0) {
              multipleFilters.value.push(finalFilterObject);
            }
          } else {
            // const isOr = filters[1] === 'or';
            // const isAnd = filters[1] === 'and';
            if (filters)
              for (const filter of filters) {
                // console.log('filter', filter);
                // not filter
                if (filter[0] == "!") {
                  // console.log('multiple filters not');
                  if (typeof filter[1] === "object") {
                    const excludeIds = [];
                    const columnHeader =
                      typeof filter[1][0] === "object"
                        ? filter[1][0][0] + "HeaderFilter"
                        : filter[1][0] + "HeaderFilter";
                    const columnHeaderFilter =
                      columnHeader.charAt(0).toLowerCase() +
                      columnHeader.slice(1);
                    // console.log(columnHeader); // name of column filter
                    if (typeof filter[1][0] === "object") {
                      // console.log('obj');
                      filter[1].map((item) => {
                        if (typeof item === "object") {
                          // console.log('item', item[2][0]);
                          excludeIds.push(item[2][0]);
                        }
                      });
                    } else {
                      // console.log('else');
                      // filters[1].map(item => {
                      // if (typeof item === "object") {
                      // console.log('item', filter[1][2][0]);
                      excludeIds.push(filter[1][2][0]);
                      // }
                      // });
                    }
                    // console.log(this[columnHeaderFilter]);
                    const arr = this[columnHeaderFilter].filter(
                      (el) => !excludeIds.includes(el.value[2][0])
                    );
                    // console.log('arr', arr, excludeIds);
                    if (typeof filter[1][0] === "object") {
                      finalFilterObject[filter[1][0][0]] = arr.map((item) => {
                        return item.value[2][0];
                      });
                    } else {
                      finalFilterObject[filter[1][0]] = arr.map((item) => {
                        return item.value[2][0];
                      });
                    }
                    // console.log('finalFilterObject', finalFilterObject);
                    if (multipleFilters.value.length > 0) {
                      multipleFilters.value.push(finalFilterObject);
                    }
                  }
                }
                let orExist;
                let andExist;
                if (typeof filter === "object") {
                  // console.log('obj', filter);
                  orExist = filter.find((item) => item === "or");
                  andExist = filter.find((item) => item === "and");
                  if (filter[1] == "or") {
                    // Header Filter - Gather In One Condition
                    if (
                      filter[0][1] === ">=" ||
                      filter[0][1] === "<=" ||
                      filter[0][1] === "<"
                    ) {
                      const finalFilter = {};
                      const condition = [];
                      for (const filterItem of filter) {
                        if (filterItem[2]) condition.push(filterItem[2][0]);
                      }
                      finalFilter[filter[0][0]] = {
                        start: condition[0].replace(/\//g, "-"),
                        end: condition[1].replace(/\//g, "-"),
                      };
                      multipleFilters.value.push(finalFilter);
                    } else {
                      // Gather In One Condition
                      for (const filterItem of filter) {
                        if (filterItem[1] === "contains" || filterItem[1] === "=") {
                          if (allFilterHeaders.value.includes(filterItem[0])) filterItem[2] = [filterItem[2]]; // make it array;
                          const finalFilter = {};
                          finalFilter[filterItem[0]] =
                            filterItem[1] === "contains" || filterItem[1] === "="
                              ? filterItem[2]
                              : filterItem[2];

                          // Header Filter - Gather In One Condition
                          const filterExist = multipleFilters.value.find(
                            (item) => item[filterItem[0]]
                          );
                          if (!filterExist)
                            multipleFilters.value.push(finalFilter);
                          else filterExist[filterItem[0]].push(filterItem[2][0]);
                        //   console.log('wee', filterItem, multipleFilters.value);
                        }
                      }
                    }
                  } // -------------- Header Filter --------------------
                  if (filter[1] == "and" && filter[0][1] == ">=") {
                    // Range Condition
                    const finalFilter = {};
                    const condition = [];
                    //"Arrival":{"start":"2022-03-10 00:00:00","end":"2022-03-12 23:59:59"}
                    for (const filterItem of filter) {
                      if (typeof filterItem === "object" && filterItem[2])
                        condition.push(filterItem[2]);
                    }
                    finalFilter[filter[0][0]] = {
                      start: condition[0].replace(/\//g, "-"),
                      end: condition[1].replace(/\//g, "-"),
                    };
                    multipleFilters.value.push(finalFilter);
                  } // -------------- Range Condition --------------------
                }
                if (
                  filter.length > 0 &&
                  filter[0] &&
                  filter[0] !== "Actions" &&
                  !orExist &&
                  !andExist
                ) {
                  if (filter[1] === "contains" || filter[1] === "=") {
                    if (allFilterHeaders.value.includes(filter[0])) filter[2] = [filter[2]]; // make it array;
                    const finalFilter = {};
                    finalFilter[filter[0]] =
                      filter[1] === "contains" || filter[1] === "="
                        ? filter[2]
                        : filter[2];

                    // Header Filter - Gather In One Condition
                    const filterExist = multipleFilters.value.find(
                      (item) => item[filter[0]]
                    );
                    if (!filterExist) multipleFilters.value.push(finalFilter);
                    else filterExist[filter[0]].push(filter[2][0]);
                  }
                  if (
                    filter[1] === ">=" ||
                    filter[1] === "<=" ||
                    filter[1] === "<"
                  ) {
                    const finalFilter = {};
                    const filter2 =
                      typeof filter[2] !== "number" && filter[2].includes("/")
                        ? filter[2].replace(/\//g, "-")
                        : filter[2];
                    finalFilter[filter[0]] = { start: "", end: "" };
                    // Range Condition
                    const filterExist = multipleFilters.value.find(
                      (item) => item[filter[0]]
                    );
                    if (!filterExist) {
                      finalFilter[filter[0]].start = filter2;
                      multipleFilters.value.push(finalFilter);
                    } else (filterExist[filter[0]]).end = filter2;
                  }
                } else if (andExist) {
                  for (const andFilter of filter) {
                    if (
                      andFilter.length > 0 &&
                      andFilter[0] &&
                      andFilter[0] !== "Actions"
                    ) {
                      if (andFilter[1] === "contains" || andFilter[1] === "=") {
                        if (allFilterHeaders.value.includes(andFilter[0])) andFilter[2] = [andFilter[2]]; // make it array;
                        const finalFilter = {};
                        finalFilter[andFilter[0]] =
                          andFilter[1] === "contains" || andFilter[1] === "="
                            ? andFilter[2]
                            : andFilter[2];
                        multipleFilters.value.push(finalFilter);
                      }
                      if (andFilter[1] === "and" && andFilter[0][1] == ">=") {
                        // range condition
                        const finalFilter = {};
                        const condition = [];
                        for (const filterItem of andFilter) {
                          const filterItem2 =
                            typeof filterItem[2] !== "number" &&
                            filterItem[2].includes("/")
                              ? filterItem[2].replace(/\//g, "-")
                              : filterItem[2];
                          if (typeof filterItem === "object" && filterItem[2])
                            condition.push(filterItem2);
                        }
                        finalFilter[andFilter[0][0]] = {
                          start: condition[0].replace(/\//g, "-"),
                          end: condition[1].replace(/\//g, "-"),
                        };
                        multipleFilters.value.push(finalFilter);
                      }
                    }
                  }
                }
              }
          }
          finalFilterObject =
            multipleFilters.value && multipleFilters.value.length > 0
              ? Object.assign({}, ...multipleFilters.value)
              : finalFilterObject;
          // console.log(
          //   "fffff",
          //   finalFilterObject,
          //   JSON.stringify(finalFilterObject)
          // );

        const queryObject = {
                "skip": loadOptions.skip,
                "take": loadOptions.take,
                "search": finalFilterObject,
                "sort": sortText,
            }

            console.log('queryObject', queryObject);

          try {
              const result = await Api({ daemonRequest: true }).post(`/kiosks/requests/${getModalInfo.value.mac_address}/${user.value.user_id}`, queryObject);
              // console.log('result', result);

              await result.data.map( (row) => {
                  row.type = row.label;
                  row.status = row.status_formatted;
                  row.reason_of_failure = row.reason_of_failure ? row.reason_of_failure : '-' ;
              })

              return {
                  data: result.data,
                  totalCount: result.total,
              };
          } catch (err) {
              console.log('err', err);
          }
        }else{
          return {
              data: [],
              totalCount: 0,
          };
        }

      },
  });

    // const state = reactive({
    //   previewLogsModal: null,
    // });

    const contentReady = (e) =>{
      
      let sortedExp = getSortedColumns();
      let filterExp = e.component.getCombinedFilter();
      if(filterExp || sortedExp) {
          showClearAll.value = true;
      }else{
          showClearAll.value = false;
      }
      setColumnChooserPosition();
      
    }

    const getSortedColumns = () =>{
        let allColumns = Array.from(Array(dataGrid.value.columnCount()).keys()).map(index => dataGrid.value.columnOption(index));
        allColumns = allColumns.find(col => col.sortIndex != null);
        return allColumns;
    }

    const customLoad = async () => {
      if(getModalAction.value == 'preview_logs'){
        await store.dispatch('onTableHeader', {url: "/device-logs-page", companyId : companyId.value})
        .catch((e)=> console.log(e));
        let value = store.getters['getTableHeaders'];
        console.log("customLoad", value);
        return value;
      }
    }

    const customSave = (gridState) => {
      if(getModalAction.value == 'preview_logs'){
        gridState.pageIndex = 0;
        gridState.pageSize = 10;
        let value = JSON.stringify(gridState);
        value = value.replace(',"selectedRowKeys":[]', "");

        if (!store.getters['getTableHeaders']) {
        console.log('createTableHeaders');
        store.dispatch('createTableHeaders',{
            url: "/device-logs-page",
            headers: value,
            filters: "[]",
            companyId : companyId.value,
        }).catch((e)=> console.log(e));
        } else if (value != JSON.stringify(store.getters['getTableHeaders'])) {
        console.log('updateTableHeaders');
        store.dispatch('updateTableHeaders',{
            url: "/device-logs-page",
            headers: value,
            filters: "[]",
            companyId : companyId.value,
            adminPreferenceId : adminPreferenceId.value,

        }).catch((e)=> console.log(e));
        }
        console.log("customSave value",value != JSON.stringify(store.getters['getTableHeaders']));
      }
    }

    const setColumnChooserPosition = () => {
        var columnChooserView = dataGrid.value.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
        }
        columnChooserView._popupContainer.option("position", {
            of: dataGrid.value.element(),    
            my: "left top",
            at: "left top",
            offset: "0 50",
        });
    }

    const onEditorPreparing = (e) => {
        if (e.parentType == "filterRow") {
            e.editorOptions.placeholder = "Search";
        }
    }

    const clearFilter = () =>{
        dataGrid.value.clearFilter("row");
        dataGrid.value.clearFilter("header");
        dataGrid.value.clearFilter("search");
        dataGrid.value.clearSorting();
    }

    const onItemClick = (type) => {
        if (type == "Excel") {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet("Main sheet");
            exportDataGrid({
                component: dataGrid.value,
                worksheet: worksheet,
                customizeCell: function (options) {
                    const excelCell = options;
                    excelCell.font = { name: "Arial", size: 12 };
                    excelCell.alignment = { horizontal: "left" };
                },
            }).then(function () {
                workbook.xlsx.writeBuffer().then(function (buffer) {
                    saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "device-logs.xlsx"
                    );
                });
            });
        // e.cancel = true;
        } else if (type == "CSV") {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet("Main sheet");
            exportDataGrid({
            component: dataGrid.value,
                worksheet: worksheet,
                customizeCell: function (options) {
                    const excelCell = options;
                    excelCell.font = { name: "Arial", size: 12 };
                    excelCell.alignment = { horizontal: "left" };
                },
            }).then(function () {
                workbook.csv.writeBuffer().then(function (buffer) {
                    saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "device-logs.csv"
                    );
                });
            });
        } else if (type == "PDF") {
            const doc = new jsPDF("landscape");
            exportDataGridToPdf({
                jsPDFDocument: doc,
                component: dataGrid.value,
                customizeCell: function(options) {
                    const { gridCell, pdfCell } = options;
                    if(gridCell.rowType === 'data') {
                        // data columns style
                        pdfCell.styles = {
                        font: 'times',
                        fontStyle: 'lato',
                        fontSize: 8,
                        cellWidth: 'auto'
                        }
                    }else{
                        // header columns style
                        pdfCell.styles = {
                        font: 'times',
                        fontStyle: 'lato',
                        fontSize: 8,
                        fillColor : '#2680EB',
                        textColor : '#FFFFFF',
                        cellWidth: 'auto'
                        }
                    }
                }
            }).then(() => {
                const pageCount = doc.internal.getNumberOfPages();
                for(let i = 1; i <= pageCount; i++) {
                    doc.setPage(i);
                    const pageSize = doc.internal.pageSize;
                    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
                    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                    const header = 'Device Logs';
                    const headerWidth = doc.getTextDimensions(header).w;
                    const footer = `Page ${i} of ${pageCount}`;

                    // Header
                    doc.text(header, (pageWidth - headerWidth) / 2, 10);

                    // Footer
                    doc.text(footer, pageWidth / 2 - (doc.getTextWidth(footer) / 2), pageHeight - 5, { baseline: 'bottom' });
                }
                doc.save("device-logs.pdf");
            });
        }
    }
  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);
    emit('refresh');
  };

  // const showPreviewLogsModal = async () => {
  //   console.log('showPreviewLogsModal');
  //   await store.commit('setModalAction', 'preview_logs');
  //   store.commit('setModalState', true);
  //   await store.commit('setModalData', getDeviceDataObject.value);

  //   console.log('getDeviceDataObject.value', getDeviceDataObject.value);
  //   setTimeout(() => {
  //     state.previewLogsModal = new Modal("#previewLogsModal", {});
  //     state.previewLogsModal.show();
  //   }, 100)
  // }

  const fetchDeviceStatusAndTypesLogs = () => {
    store.dispatch('fetchDeviceStatusAndTypesLogs')
    .then( async (res) => {
        let status = res.data[0].status;
        let types = res.data[1].types;
        for(let key in status){
            statusLogsDataFilter.value.push({text :status[key].charAt(0).toUpperCase() + status[key].slice(1), value : status[key] })
        }
        for(let key in types){
            let type = (types[key].charAt(0).toUpperCase() + types[key].slice(1)).replaceAll('_', ' ');
            typesLogsDataFilter.value.push({text : type, value : types[key] })
        }
    }).catch( (e) => {
        console.log(e)
    })
  }


  onBeforeMount(() => {
    if(getModalInfo.value && getModalInfo.value.data_type == 'device' && getModalAction.value == 'preview_logs'){
      fetchDeviceStatusAndTypesLogs();
    }
  })

</script>

<template>

  <!-- <deleteRequestJobModal v-if="getModalState" @callPreviewLogs="showPreviewLogsModal"/> -->

  <DModal id="previewLogsModal" :main_classes="['modal-xl']" :dialog_classes="[]">
    <template #title>
      <div style="display: flex; align-items: center">
        <span style="margin-right: 346px;">Preview Logs ({{ name }})</span>
        <span @click="resetStoreData" >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M11 0C4.91333 0 0 4.91333 0 11C0 17.0867 4.91333 22 11 22C17.0867 22 22 17.0867 22 11C22 4.91333 17.0867 0 11 0ZM16.5978 14.7156C17.1111 15.2289 17.1111 16.06 16.5978 16.5733C16.3289 16.8422 16.0111 16.9644 15.6689 16.9644C15.3267 16.9644 14.9844 16.8422 14.74 16.5733L11.0733 12.9067L7.38222 16.5733C7.11333 16.8422 6.79556 16.9644 6.45333 16.9644C6.11111 16.9644 5.76889 16.8422 5.52444 16.5733C5.01111 16.06 5.01111 15.2289 5.52444 14.7156L9.21556 11.0244L5.52444 7.35778C5.01111 6.84444 5.01111 6.01333 5.52444 5.5C6.03778 4.98667 6.86889 4.98667 7.38222 5.5L11.0733 9.19111L14.7644 5.5C15.2778 4.98667 16.1089 4.98667 16.6222 5.5C17.1356 6.01333 17.1356 6.84444 16.6222 7.35778L12.9311 11.0489L16.5978 14.7156Z"
              fill="#F46A6A"
            />
          </svg>
        </span>
      </div>
    </template>
  
    <template #body>

      <section class="grid-white-section">
  
        <DxDataGrid :data-source="customStore" ref="dataGridRefName" :remote-operations="true"
        :showBorders="false" :columnAutoWidth="true" :allowColumnReordering="true" :allowColumnResizing="false"
        :rowAlternationEnabled="false" :showColumnLines="false" :showRowLines="true" :repaint-changes-only="true"
        :two-way-binding-enabled="true" :no-data-text="'No logs yet'" @editor-preparing="onEditorPreparing($event)"
        @content-ready="contentReady($event)"
        >
            <DxLoadPanel :show-indicator="true" :show-pane="true" :enabled="true"/>
            <DxScrolling mode="standard" :use-native="true" />
            <DxPaging :page-size="10" />
            <DxStateStoring :enabled="true" type="custom"  :customSave="customSave" :customLoad="customLoad"/>
    
    
            <DxPager
            :visible="true"
            :allowed-page-sizes="[10, 25, 50, 100]"
            :display-mode="'full'"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
            />
    
            <!-- <DxFilterRow :visible="true" /> -->
            <DxSearchPanel :width="200" :visible="true" placeholder="Search ..."/>
            <DxSorting mode="single" />
            <DxHeaderFilter :visible="true" />
            <DxColumnChooser :enabled="true" mode="select" />

            <DxToolbar>
              <DxItem location="before" template="columnButtonTemplate" />
              <DxItem location="before" template="exportButtonTemplate" />
              <DxItem location="before" template="clearAllTemplate"/>
              <DxItem location="after" name="searchPanel" />

            </DxToolbar>
  
            <DxColumn data-field="id" caption="ID" data-type="string" :visible="false" :allow-header-filtering="false"
            :allow-filtering="false" :allow-searching="false"/>

            <DxColumn data-field="date" format="yyyy/MM/dd HH:mm:ss" caption="Date" :allow-editing="false"
            :allow-header-filtering="false" :allow-filtering="false" :allow-searching="false" data-type="string"/>

            <DxColumn data-field="type" caption="Type" :allow-header-filtering="true" :allow-filtering="false" :allow-searching="false"  data-type="string">
                <DxHeaderFilter :data-source="typesLogsDataFilter"/>
            </DxColumn>

            <DxColumn data-field="status" caption="Status" :allow-filtering="false" :allow-searching="false" cell-template="status-cell" :allow-header-filtering="true" data-type="string">
                <DxHeaderFilter :data-source="statusLogsDataFilter"/>
            </DxColumn>

            <DxColumn data-field="reason_of_failure" :width="200" :visible="true" caption="Status Message" :allow-header-filtering="false"
            :allow-filtering="true" data-type="string" :allow-searching="true" css-class="text-rows"/>

            <DxColumn data-field="actions" caption="Actions" :allow-filtering="false"
            :allow-exporting="false" :allow-editing="false" :allow-sorting="false"
             data-type="object" :width="100" cell-template="action-cell"/>


            <template #status-cell="{data}">
                <span :class="{'success-style': data.data.status == 'Succeeded',
                    'failed-style': data.data.status == 'Failed','other-status-style':
                      data.data.status != 'Failed' && data.data.status != 'Succeeded',
                    }"
                > {{ data.data.status }}
                </span>
              </template>

              <template #action-cell="{ data }">
                <PreviewLogsActionCell :data="data.data" @refreshGrid="resetStoreData"/>
              </template>

              <template #exportButtonTemplate>
                <button  @click="onItemClick('Excel')" type="button" class="btn action-btn m-5 font-20"><i class="bi bi-filetype-xlsx"></i> </button>
              </template>
      
              <template #columnButtonTemplate>
                <button  @click="dataGrid.showColumnChooser()" type="button" class="btn action-btn font-20 m-5"><i class="bi bi-columns"></i> </button>
              </template>
      
              <template #clearAllTemplate>
                <div v-if="showClearAll" class="clear-cols">
                  <div class="custom-line"></div>
                  <button @click="clearFilter()" type="button" class="btn action-btn clear-colums-filter m-5">Clear Filters </button>
                </div>
              </template>

        </DxDataGrid>
    </section>


    </template>
  </DModal>
</template>