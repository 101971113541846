
<script setup>
  import { ref, defineProps, defineEmits, onBeforeMount, computed } from "vue";
    import useValidation from '@/utils/helpers/validations';
  import { useStore } from "vuex";
  const emit = defineEmits(['refresh']);
  const store = useStore();

  const props = defineProps({
    appsOptions: Array,
  });

  // const global_user_name = inject(['global_user_name']);
  const user = computed(()=>JSON.parse(localStorage.getItem('userDetails')));
  const { checkApkUrlValidation } = useValidation();

  let isSubmitted = ref(false);
  let name = ref(null);
  let devicesNames = ref([]);
  let mac_address = ref(null);
  let mac_addresses = ref([]);
  let company_id = computed(()=>localStorage.getItem("currentCompanyId"));
  let app = ref(null);
  let url = ref(null);
  let type = ref('apps_library');
  let isValidUrl = ref(true)
  const getModalInfo = computed(()=> store.getters.getModalInfo);
  const getModalAction = computed(()=> store.getters.getModalAction);

    const validUrl = () => {
      isValidUrl.value = true;
      if(url.value.length > 0){
        isValidUrl.value = checkApkUrlValidation(url.value);
        return isValidUrl.value;
      }

    };

  const onSubmit = () => {
    isSubmitted.value = true;
    let data = { 
      entityId: company_id.value,
      kioskName : devicesNames.value,
      macAddresses : mac_addresses.value,
      userName : user.value.first_name+' '+ user.value.last_name,
      userId : user.value.user_id,
      connectionType : 'daemon',
      key : type.value == 'apps_library'? app.value.url : url.value,
      downloadableFile : type.value == 'apps_library'? app.value.url : url.value,
    }

    store.dispatch('massInstallApp', data)
    .then((res) => {
      console.log('res', res);
      resetStoreData();
      emit('refresh');
    })
    .catch((e) => {
      console.log("e", e);
      isSubmitted.value = false;
    });
  };

  const changeAppSourceVal = () => {
    app.value = null;
    url.value = null;
    isValidUrl.value = true;
  }

  const resetStoreData = () =>{
    store.commit('setModalState', false);
    store.commit('setModalData', {});
    store.commit('setModalAction', null);
  };

  onBeforeMount(()=>{
    if(getModalInfo.value && getModalInfo.value.data_type == 'device' && getModalAction.value == 'install_app'){

      console.log('getModalInfo', getModalInfo.value);
      name.value = getModalInfo.value.name;
      mac_address.value = getModalInfo.value.mac_address;
      devicesNames.value.push(getModalInfo.value.name)
      mac_addresses.value.push(getModalInfo.value.mac_address)      
    }else if(getModalAction.value == 'mass_install_app') {
      if(Array.isArray(getModalInfo.value)){
        getModalInfo.value.map((row) =>{
          devicesNames.value.push(row.name)
          mac_addresses.value.push(row.mac_address)
        });
        name.value = devicesNames.value.join(", ");
        // console.log('devicesNames', devicesNames.value);
        // console.log('mac_addresses', mac_addresses.value);
      }
    }
  });

</script>

<template>
  <DModal id="installAppModal" :main_classes="[]" :dialog_classes="[]">
    <template #title> Install App </template>

    <template #body>
      <div class="delete-text mb-3">
        <span>Select the application you want to  <b>"Install"</b> on the device "{{ name }}" ?</span>
      </div>


      <div class="col-12 d-flex  unique-radio-inputs p-0 mb-3" >
        
        <div class="col-6 radioOne p-0" >
          <input @change="changeAppSourceVal" class="form-check-input" type="radio" name="type" id="lamasaTechOption" value="apps_library" v-model="type" />
          <label class="form-check-label" for="lamasaTechOption">Apps Libarary</label>
          
        </div>

        <div class="col-6 radioTwo p-0" >
          <input @change="changeAppSourceVal" class="form-check-input" type="radio" name="type" id="partnerOption" value="url" v-model="type"/>
          <label class="form-check-label" for="partnerOption">URL</label>
         
        </div>
       
      </div>

      <div v-if="type == 'apps_library'" class="p-relative">
        <span v-if="app" class="multiselect-title-span" >App</span>
        <VMultiselect v-model="app" :options="props.appsOptions" trackBy="id" label="name"
          :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Application"
        />
      </div>

      
      <div v-else class="form-floating">
        <input @input="validUrl" v-model="url" type="text" id="url" :class="{'is-invalid' : !isValidUrl}" class="form-control w-100"  placeholder="URL" />
        <label for="url">URL</label>
        <div v-if="!isValidUrl" class="invalid-feedback">
          Invalid URL.
        </div>
      </div>
      


    </template>

    <template #footer>
      <div class="w-100">
        <div class="seperator text-center"></div>
        <div class="d-flex mt-20 justify-content-center">
          <button :disabled="(!app && !url) || isSubmitted || !isValidUrl " 
          @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
            Install <span v-if="isSubmitted"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
          </button>
          <button @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </template>
  </DModal>
</template>
