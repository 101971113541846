<script setup>
import { computed, defineProps } from "vue";
const props = defineProps({
  id: String,
  main_classes: Array,
  dialog_classes: Array,
});

const base_classes = ["modal", "fade"];
const base_dialog_classes = ["modal-dialog", "modal-dialog-centered"];

const final_classes = computed(() => {
  return [...props.main_classes, ...base_classes];
});

const final_dialog_classes = computed(() => {
  return [...props.dialog_classes, ...base_dialog_classes];
});

</script>
<template>
  <!-- Modal -->
  <div
    :id="props.id"
    :class="final_classes"
    tabindex="-1"
    :aria-labelledby="props.id + '_label'"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div :class="final_dialog_classes">
      <div class="modal-content">
        <div class="modal-header pt-40 pl-46">
          <h5 class="modal-title" :id="props.id + '_label'">
            <slot name="title" />
          </h5>
        </div>
        <div class="modal-body">
          <slot name="body" />
        </div>
        
        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

