<script setup>
    import { ref, defineEmits, onBeforeMount, computed } from "vue";
    import { useStore } from "vuex";
  
    const store = useStore();
    const emit = defineEmits(["refresh"]);
    const company_id = localStorage.getItem("currentCompanyId");
    import useValidation from '@/utils/helpers/validations';
    import axios from 'axios';
    import { useToast } from "vue-toastification";    

    const toast = useToast();

    const { checkApkUrlValidation } = useValidation();

    const user = JSON.parse(localStorage.getItem("user"));

    let headers = {
        "Accept": "application/json",
        "Content-type": "multipart/form-data",
        "Authorization" : "Bearer "+user.access_token,
    }

    let name = ref(null);
    let description = ref(null);
    let url = ref(null);
 
    let script = ref(null);
    let urlFile = ref(null);
    let file = ref(null);
    let path = ref(null);
    let fileType = ref('new_file');
    let appSource = ref('upload_app')
    let filesOptions = ref([]);
    let parent_id = ref(null);
    let isSubmitted = ref(false);
    let isValidUrl = ref(true)
    let isLoadingFiles = ref(true);
    let fileError = ref(false);
    let appFileError = ref(false);
    let uploadPercentage = ref(0)


    const getModalInfo = computed(()=> store.getters.getModalInfo);
    const getModalAction = computed(()=> store.getters.getModalAction);

    const validUrl = () => {
      isValidUrl.value = true;
      if(url.value.length > 0){
        isValidUrl.value = checkApkUrlValidation(url.value);
        return isValidUrl.value;
      }
    };

    const changeFileSourceVal = (type) => {
        fileError.value = false;
        appFileError.value = false;
        urlFile.value = null;
        file.value = null;
        url.value = null;
        
        if(type == 'transfer_file' && !filesOptions.value.length) fetchAllFilesCompany(); // fetch here to waiting for the job is finishing after uploading the files 
    }

    const uploadFile = async (e, type)=>{    
        
        file.value = null;
        fileError.value = false;        
        appFileError.value = false;
        uploadPercentage.value = 0;
        let selectedFile = e.target.files[0];
        if(selectedFile){
            let reader = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.onload = () => {
                // console.log('selectedFile.size', selectedFile.size);
                if ( selectedFile.size > 157286400 ) {
                    if(type == 'transfer_file'){
                        fileError.value = true;
                    }else if(type == 'install_app'){
                        appFileError.value = true;
                    }
                    return 
                }else {
                    if(type == 'transfer_file'){
                        file.value = selectedFile;
                    }else if(type == 'install_app'){
                        if (selectedFile && (selectedFile.type == 'application/vnd.android.package-archive' || selectedFile.type == 'application/octet-stream')) {
                            file.value = selectedFile;
                        }
                    }
                }
            }
        }
    }

    const disabledSubmitedButton = () => {
        let value = false;
        if(!name.value || isSubmitted.value ){
            value = true;
        }else if (getModalAction.value == 'run_script' && !script.value){
            value = true;
        }else if(getModalAction.value == 'install_app'){
            if(appSource.value == 'upload_app' && ( !file.value || appFileError.value )){
                value = true;
            }else if(appSource.value == 'url' && ( !url.value || !isValidUrl.value )){
                value = true;
            }
        }else if(getModalAction.value == 'transfer_file') {
            if(fileType.value == 'new_file' && (!path.value || !file.value || fileError.value )){
                value = true;
            }else if(fileType.value == 'files_library' && (!path.value || !urlFile.value)){
                value = true;
            }
        }
        return value;
    }

    const onSubmit = () => {
        isSubmitted.value = true;
        const data = {
            name: name.value,
            description: description.value,
            type : getModalAction.value,
            parent_id: parent_id.value,
            url : getModalAction.value == 'install_app' ? url.value : getModalAction.value == 'transfer_file' && fileType.value == 'files_library'  ? urlFile.value.fileDetails?.url : null,
            script : script.value,
            path : path.value,
            file : file.value,
            company_id,
        };

        // store.dispatch("addJob", data).then(() => {
        //     isSubmitted.value = false;
        //     resetStoreData();
        //     emit("refresh");
        // }).catch((e) => {
        //     console.log(e);
        //     isSubmitted.value = false;
        // });


        axios.post(`${process.env.VUE_APP_BASE_URL}/api/jobs`, data, {
            headers: headers,
            onUploadProgress: function( progressEvent ) {
                uploadPercentage.value = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) 
            )}.bind(this)
        }).then((res) => {
            toast.success(res.data.message);
            isSubmitted.value = false;
            resetStoreData();
            emit("refresh");
        })
        .catch((e) => {
            console.log(e);
            toast.error(e.response.data.message);
            isSubmitted.value = false;
        });

    };

    const resetStoreData = () =>{
        store.commit('setModalState', false);
        store.commit('setModalData', {});
        store.commit('setModalAction', null);
    };

    const fetchAllFilesCompany = () => {
        isLoadingFiles.value = true;
        store.dispatch("fetchAllFilesCompany", company_id).then((res) => {
            // console.log('res', res.data);
            filesOptions.value = res.data;
            isLoadingFiles.value = false;

        }).catch((e) => {
            console.log(e);
        });
    }

    onBeforeMount(() => {
        if( (getModalInfo.value && (getModalAction.value == 'install_app' || getModalAction.value == 'run_script' ||
        getModalAction.value == 'transfer_file'))){
            console.log('getModalInfo', getModalInfo.value);
            parent_id.value = getModalInfo.value.id ? getModalInfo.value.id : null;
            console.log('parent_id.value', parent_id.value);
        }
    });

</script>


<template>
    <DModal id="addJobModal" :main_classes="[]" :dialog_classes="[]">
        <template #title> Add Job </template>
  
        <template #body>
        
            <div class="form-floating mb-3">
                <input type="text" v-model.trim="name" class="form-control" id="jobName" placeholder=""/>
                <label for="jobName">Job Name</label>
            </div>
    
            <div class="form-floating mb-3">
                <textarea v-model="description" class="form-control none-resize h-105" placeholder="description" id="jobDescription"/>
                <label for="jobDescription">Description</label>
            </div>


            <div v-if="getModalAction == 'install_app'" class="form-floating mb-3">


                <div class="col-12 d-flex  unique-radio-inputs p-0 mb-3">
        
                    <div class="col-6 radioTwo p-0" >
                        <input @change="changeFileSourceVal('install_app')" class="form-check-input" type="radio" name="app_source" id="uploadAppOption" value="upload_app" v-model="appSource"/>
                        <label class="form-check-label" for="uploadAppOption">Upload App</label>
                    </div>

                    <div class="col-6 radioOne p-0" >
                        <input @change="changeFileSourceVal('install_app')" class="form-check-input" type="radio" name="app_source" id="filesLibraryOption" value="url" v-model="appSource" />
                        <label class="form-check-label" for="filesLibraryOption">URL</label>
                    </div>
            
                </div>
            
                <div v-if="appSource == 'upload_app'" class="form-floating">
                    <input :class="{'is-invalid' : appFileError}" ref="appFile" accept=".apk" type="file"  @change="uploadFile($event, 'install_app')" 
                    id="newAppFile" class="form-control w-100"  placeholder="App File" />
                    <label for="newAppFile">App File</label>
                    <span class="hint-input" :class="{'hint-input-error' : appFileError }"><b>Note:</b> Uploaded file size should be 30MB or less.</span>  
                </div>
        
                <div v-if="appSource == 'url'" class="form-floating" >
                    <input @input="validUrl" type="text" v-model.trim="url" class="form-control" id="jobUrl" :class="{'is-invalid' : !isValidUrl}" placeholder="URL"/>
                    <label for="jobUrl">URL</label>
                    <div v-if="!isValidUrl" class="invalid-feedback"> Invalid URL.</div>
                </div>

            </div>
    
            <div v-if="getModalAction == 'run_script'" class="form-floating mb-3">
                <textarea v-model.trim="script" class="form-control none-resize h-150" placeholder="Script" id="jobScript"/>
                <label for="jobScript">Script</label>
            </div>

            <div v-if="getModalAction == 'transfer_file'">

                <div class="form-floating mb-3">
                    <input type="text" v-model.trim="path" class="form-control" id="path" placeholder=""/>
                    <label for="path">Path</label>
                </div>

                <div class="col-12 d-flex  unique-radio-inputs p-0 mb-3">
        
                    <div class="col-6 radioTwo p-0" >
                        <input @change="changeFileSourceVal('transfer_file')" class="form-check-input" type="radio" name="type" id="newFileOption" value="new_file" v-model="fileType"/>
                        <label class="form-check-label" for="newFileOption">New File</label>
                    </div>

                    <div class="col-6 radioOne p-0" >
                        <input @change="changeFileSourceVal('transfer_file')" class="form-check-input" type="radio" name="type" id="filesLibraryOption" value="files_library" v-model="fileType" />
                        <label class="form-check-label" for="filesLibraryOption">Files Libarary</label>
                    </div>
            
                </div>
            
                <div v-if="fileType == 'files_library'" class="p-relative">
                    <span v-if="urlFile" class="multiselect-title-span" >Files</span>
                    <VMultiselect v-model="urlFile" :options="filesOptions" :disabled="isLoadingFiles" trackBy="id" label="name" :isLoading='isLoadingFiles'
                        :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Select a File"
                    />
                </div>
        
                <div v-if="fileType == 'new_file'" class="form-floating">
                    <input :class="{'is-invalid' : fileError}" ref="appFile" type="file"  @change="uploadFile($event, 'transfer_file')" id="newFile" class="form-control w-100"  placeholder="File" />
                    <label for="newFile">File</label>
                    <span class="hint-input" :class="{'hint-input-error' : fileError }"><b>Note:</b> Uploaded file size should be 150MB or less.</span>
                </div>

                <b-progress class="mt-20" v-if="isSubmitted && fileType == 'new_file'" height="1rem" :max='100' striped animated>
                    <b-progress-bar :value="uploadPercentage" variant="success" :label="`${uploadPercentage}%`"></b-progress-bar>
                </b-progress>
                  
            </div>
    
        </template>
        <template #footer>
            <div class="w-100">
                <div class="seperator text-center"></div>
                <div class="d-flex mt-20 justify-content-center">
                    <button :disabled="disabledSubmitedButton()"
                    @click="onSubmit" type="button" class="btn btn-primary add-button mr-30">
                    Add <span v-if="isSubmitted"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
                    </button>
                    <button :disabled="isSubmitted" @click="resetStoreData" type="button" class="btn btn-secondary cancel-button" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
      </template>
    </DModal>
  </template>
  